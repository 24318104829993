// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  saveNewBeneficiary as saveNewBeneficiaryService,
  fetchBeneficiary as fetchBeneficiaryService,
  fetchBeneficiaryOrganizations as fetchBeneficiaryOrganizationsService,
} from '@services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Grid } from '@mui/material';
import Button from '@components/atoms/Button/Button';
import Checkbox from '@components/atoms/Checkbox/Checkbox';

// Styles
import Heading from '@components/atoms/Heading/Heading';
import { StyledTitle } from './AddToOrganization.styles';

// Component
const AddToOrganization = ({
  saveNewBeneficiary,
  fetchBeneficiary,
  beneficiary,
  beneficiaryId,
  setOpenFn,
  organization,
  refreshBeneficieryOrganizations,
  fetchBeneficiaryOrganizations,
  newBeneficiary,
  headerTitle,
  setnewOrganizationID,
  updateBeneficiaryId,
}) => {
  useEffect(() => {
    if (beneficiaryId) {
      fetchBeneficiary(beneficiaryId);
    }
  }, [beneficiaryId]);

  const initialVal = {
    id: organization.id,
    isAdministrator: false,
  };

  return (
    <Formik
      initialValues={initialVal}
      validationSchema={Yup.object().shape({
        isAdministrator: Yup.boolean()
          .required('To pole jest wymagane!')
          .oneOf([true], 'To pole jest wymagane!'),
      })}
      enableReinitialize
      onSubmit={(values) => {
        saveNewBeneficiary(beneficiaryId ? beneficiary : newBeneficiary, {
          organization: values,
        }).then((res) => {
          if (res.data.cwo_id) {
            if (refreshBeneficieryOrganizations) {
              fetchBeneficiaryOrganizations(beneficiaryId || res.data.beneficiary.beneficiary_id);
            }
          }

          if (updateBeneficiaryId && res.data.beneficiary) {
            updateBeneficiaryId(res.data.beneficiary.beneficiary_id);
          }

          if (setnewOrganizationID) {
            setnewOrganizationID(null);
          }

          if (setOpenFn) {
            setOpenFn(false);
          }
        });
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {headerTitle && (
              <StyledTitle>
                <Heading.Subtitle1>{headerTitle}</Heading.Subtitle1>
              </StyledTitle>
            )}
            {organization && (
              <>
                <Grid item xs={12}>
                  <Checkbox
                    value={values.isAdministrator}
                    label="Potwierdzam, że organizacja jest administratorem danych beneficjenta"
                    onChange={handleChange}
                    name="isAdministrator"
                    isEditable
                  />
                  <ErrorMessage name="isAdministrator">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid container justifyContent="flex-end">
                  {setOpenFn && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenFn(false);

                        if (setnewOrganizationID) {
                          setnewOrganizationID(null);
                        }
                      }}
                    >
                      Anuluj
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    style={{ marginLeft: setOpenFn ? 15 : 0 }}
                    onClick={handleSubmit}
                  >
                    Zapisz
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </Formik>
  );
};

AddToOrganization.propTypes = {
  refreshBeneficieryOrganizations: PropTypes.bool,
  saveNewBeneficiary: PropTypes.func,
  fetchBeneficiary: PropTypes.func,
  beneficiary: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    pesel: PropTypes.string,
    passport: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string,
  }),
  beneficiaryId: PropTypes.number,
  setOpenFn: PropTypes.func,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  newBeneficiary: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    pesel: PropTypes.string,
    passport: PropTypes.string,
    gender: PropTypes.string,
    dob: PropTypes.string,
  }),
  headerTitle: PropTypes.string,
  fetchBeneficiaryOrganizations: PropTypes.func,
  setnewOrganizationID: PropTypes.func,
  updateBeneficiaryId: PropTypes.func,
};

AddToOrganization.defaultProps = {
  refreshBeneficieryOrganizations: false,
  saveNewBeneficiary: null,
  fetchBeneficiary: null,
  beneficiary: null,
  beneficiaryId: null,
  organization: null,
  newBeneficiary: null,
  setOpenFn: null,
  fetchBeneficiaryOrganizations: null,
  headerTitle: '',
  setnewOrganizationID: null,
  updateBeneficiaryId: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  saveNewBeneficiary: (beneficiary, connections) =>
    dispatch(saveNewBeneficiaryService(beneficiary, connections)),
  fetchBeneficiary: (id) => dispatch(fetchBeneficiaryService(id)),
  fetchBeneficiaryOrganizations: (id) => dispatch(fetchBeneficiaryOrganizationsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToOrganization);
