// React
import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchUserPermissions as fetchUserPermissionsService,
  editRolaSystemowa as editRolaSystemowaService,
  removeUserRole as removeUserRoleService,
} from '@services/userServices';

import { isCoordinator } from '@utils/functions';

// Actions
import { clearUserPermissions as clearUserPermissionsAction } from '@actions/userActions';
import { showAlert as showAlertAction } from '@actions/appActions';

// Data
import {
  COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_COORDINATOR,
  PROGRAM_GUARDIAN,
  TASK_COORDINATOR,
  // LECTURER,
  // LECTURER_SENSITIVE_DATA,
  rolesTranslator,
} from '@constants/roles';
import { NIE_MASZ_UPRAWNIEN } from '@constants/alerts';

// Elements
import { Box, Grid, Switch } from '@mui/material';
// import SwitchButton from '@components/atoms/SwitchButton/SwitchButton';

import Heading from '@components/atoms/Heading/Heading';
import Table from '@components/organisms/Table/Table';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import DialogUserPermissions from '@components/templates/DialogUserPermissions/DialogUserPermissions';
import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// Styles
import { StyledFormControlLabel } from '@components/styled/StyledComponents';
import { StyledWrapper } from './UserPermissions.styles';

// Data
const headCellsOrganizations = [
  { type: 'organization_name', id: 'organization_name', label: 'Organizacja' },
  { type: 'role_name', id: 'role_name', label: 'Rola' },
];

const headCellsPrograms = [
  { type: 'name', id: 'name', label: 'Program' },
  { type: 'organizations', id: 'organizations', label: 'Organizacje w programie' },
  { type: 'organization_from', label: 'Organizacja' },
  { type: 'role_name', id: 'role_name', label: 'Rola' },
];

const headCellsGuardians = [
  { type: 'program_name', id: 'program_name', label: 'Program' },
  { type: 'role_name', id: 'role_name', label: 'Rola' },
];

// Component
const UserPermissions = ({
  showAlert,
  clearUserPermissions,
  fetchUserPermissions,
  permissions,
  editRolaSystemowa,
  removeUserRole,
  me,
}) => {
  const { id } = useParams();
  const [currentItem, setCurrentItem] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [openDialogConfirmOrganization, setOpenDialogConfirmOrganization] = useState(false);

  const history = useHistory();

  useEffect(() => {
    clearUserPermissions();
  }, []);

  const triggerFetchUserPermissions = useCallback(
    (myid) => {
      if (myid !== undefined) {
        return fetchUserPermissions(myid);
      }

      return null;
    },
    [fetchUserPermissions],
  );

  useEffect(() => {
    triggerFetchUserPermissions(id);
  }, [id, triggerFetchUserPermissions]);

  return (
    <>
      {!(
        me.user_role.includes(COORDINATOR) ||
        me.user_role.includes(ORGANIZATION_ADMINISTRATOR) ||
        me.user_role.includes(PROGRAM_COORDINATOR) ||
        me.user_role.includes(TASK_COORDINATOR)
      ) &&
        me.id !== parseInt(id, 10) && (
          <Heading.Subtitle3>
            <br />
            Nie masz uprawnień do edycji uprawnień użytkownika.
          </Heading.Subtitle3>
        )}
      {permissions && me.user_role.includes(COORDINATOR) ? (
        <GuardedComponent allowed_user_roles={[COORDINATOR]}>
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Heading.Subtitle3>Rola w systemie:</Heading.Subtitle3>
            </Grid>
            <Grid item xs={8}>
              <StyledFormControlLabel
                name="keyAction"
                control={
                  <Switch
                    checked={permissions.system === COORDINATOR}
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked !== (permissions.system === COORDINATOR)) {
                        editRolaSystemowa(id, e.target.checked);
                      }
                    }}
                    name="adminRole"
                  />
                }
                labelPlacement="end"
                label="Posiada uprawnienia administratora aplikacji"
                disabled={false}
              />
            </Grid>
          </StyledWrapper>
        </GuardedComponent>
      ) : (
        me.id === parseInt(id, 10) && (
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Heading.Subtitle3>
                Zalogowany jako: {me.name} {me.surname}
              </Heading.Subtitle3>
            </Grid>
            <Grid item xs={8}>
              <Heading.Subtitle3>
                Rola w systemie:{' '}
                {me && me.user_role.includes(COORDINATOR) ? 'Administrator systemu' : 'Użytkownik'}
              </Heading.Subtitle3>
            </Grid>
          </StyledWrapper>
        )
      )}

      {permissions &&
      permissions.organizations &&
      (me.user_role.includes(COORDINATOR) || me.user_role.includes(ORGANIZATION_ADMINISTRATOR)) ? (
        <GuardedComponent allowed_user_roles={[COORDINATOR, ORGANIZATION_ADMINISTRATOR]}>
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Box mb={2}>
                <Heading.Subtitle3>Organizacje:</Heading.Subtitle3>
              </Box>
              <Table
                headCells={headCellsOrganizations}
                data={permissions.organizations}
                noSort
                noPagination
                rowActions={[
                  {
                    name: 'zmień',
                    action: (row) => {
                      setOpenDialog(true);
                      setCurrentItem({
                        permission_id: row.id,
                        id: row.organization_id,
                        role: row.role,
                        type: 'organizacja',
                      });
                    },
                  },
                  {
                    name: 'usuń',
                    action: (row) => {
                      setCurrentItem({
                        permission_id: row.id,
                        id: row.organization_id,
                        role: row.role,
                        type: 'organizacja',
                      });
                      setOpenDialogConfirmOrganization(true);
                    },
                  },
                ]}
                tableBene
              />
            </Grid>
          </StyledWrapper>
        </GuardedComponent>
      ) : (
        me &&
        me.id === parseInt(id, 10) &&
        me.user_organizations && (
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Box mb={2}>
                <Heading.Subtitle3>Organizacje:</Heading.Subtitle3>
              </Box>
              <Table
                headCells={headCellsOrganizations}
                isnav
                rowLink="/organizacje"
                data={me.user_organizations.map((item) => ({
                  ...item,
                  organization_name: item.name,
                  role_name: rolesTranslator(item.role),
                }))}
                noSort
                noPagination
                tableBene
              />
            </Grid>
          </StyledWrapper>
        )
      )}
      {permissions &&
      permissions.programs.length > 0 &&
      (me.user_role.includes(COORDINATOR) ||
        me.user_role.includes(PROGRAM_COORDINATOR) ||
        me.user_role.includes(TASK_COORDINATOR) ||
        me.user_role.includes(ORGANIZATION_ADMINISTRATOR)) ? (
        <GuardedComponent
          allowed_user_roles={[
            COORDINATOR,
            TASK_COORDINATOR,
            PROGRAM_COORDINATOR,
            ORGANIZATION_ADMINISTRATOR,
          ]}
        >
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Box mb={2}>
                <Heading.Subtitle3>Programy:</Heading.Subtitle3>
              </Box>
              <Table
                headCells={headCellsPrograms}
                data={permissions.programs}
                noSort
                noPagination
                rowActions={
                  me.user_role.includes(COORDINATOR) ||
                  me.user_role.includes(PROGRAM_COORDINATOR) ||
                  me.user_role.includes(ORGANIZATION_ADMINISTRATOR)
                    ? [
                        {
                          name: 'zmień',
                          action: (row) => {
                            setOpenDialog(true);
                            setCurrentItem({
                              permission_id: row.id,
                              organization_user_id: row.organization_user_id,
                              program_organization_id: row.program_organization_id,
                              role: row.role,
                              type: 'program',
                            });
                          },
                        },
                        {
                          name: 'usuń',
                          action: (row) => {
                            if (row.role === PROGRAM_GUARDIAN && !isCoordinator(me.user_role)) {
                              showAlert(NIE_MASZ_UPRAWNIEN);
                            } else {
                              setCurrentItem({
                                permission_id: row.id,
                                id: row.program_id,
                                role: row.role,
                                type: 'program',
                              });
                              setOpenDialogConfirm(true);
                            }
                          },
                        },
                      ]
                    : []
                }
                tableBene
              />
            </Grid>
          </StyledWrapper>
        </GuardedComponent>
      ) : (
        me &&
        me.id === parseInt(id, 10) &&
        // ||
        //   me.user_role.includes(TASK_COORDINATOR) ||
        //   me.user_role.includes(LECTURER) ||
        //   me.user_role.includes(LECTURER_SENSITIVE_DATA)
        me.user_programs && (
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Box mb={2}>
                <Heading.Subtitle3>Programy:</Heading.Subtitle3>
              </Box>
              <Table
                headCells={headCellsPrograms}
                data={me.user_programs.map((item) => ({
                  ...item,
                  role_name: rolesTranslator(item.role),
                }))}
                noSort
                noPagination
                isnav={false}
                // rowLink="/programy"
                rowActions={[
                  {
                    name: 'zobacz program',
                    action: (row) => history.push(`/programy/${row.program_id}`),
                  },
                ]}
              />
            </Grid>
          </StyledWrapper>
        )
      )}

      {permissions &&
      permissions.guardians &&
      permissions.guardians.length > 0 &&
      me.user_role.includes(COORDINATOR) ? (
        <GuardedComponent allowed_user_roles={[COORDINATOR]}>
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Box mb={2}>
                <Heading.Subtitle3>Opiekun Programu:</Heading.Subtitle3>
              </Box>
              <Table
                headCells={headCellsGuardians}
                data={permissions.guardians}
                noSort
                noPagination
                rowActions={[
                  {
                    name: 'usuń',
                    action: (row) => {
                      if (row.role === PROGRAM_GUARDIAN && !isCoordinator(me.user_role)) {
                        showAlert(NIE_MASZ_UPRAWNIEN);
                      } else {
                        setCurrentItem({
                          permission_id: row.id,
                          id: row.program_id,
                          role: row.role,
                          type: 'guardian',
                        });
                        setOpenDialogConfirm(true);
                      }
                    },
                  },
                ]}
                tableBene
              />
            </Grid>
          </StyledWrapper>
        </GuardedComponent>
      ) : (
        me &&
        me.id === parseInt(id, 10) &&
        me.user_guardians && (
          <StyledWrapper className="documents" container spacing={2}>
            <Grid item xs={8}>
              <Box mb={2}>
                <Heading.Subtitle3>Opiekun Programu:</Heading.Subtitle3>
              </Box>
              <Table
                headCells={headCellsPrograms}
                data={me.user_guardians.map((item) => ({
                  ...item,
                  role_name: rolesTranslator(item.role),
                }))}
                noSort
                noPagination
                isnav={false}
                rowActions={[
                  {
                    name: 'zobacz program',
                    action: (row) => history.push(`/programy/${row.program_id}`),
                  },
                ]}
              />
            </Grid>
          </StyledWrapper>
        )
      )}

      <GuardedComponent
        allowed_user_roles={[COORDINATOR, PROGRAM_COORDINATOR, ORGANIZATION_ADMINISTRATOR]}
      >
        <DialogUserPermissions
          user={id}
          item={currentItem}
          permissions={permissions}
          open={openDialog}
          setOpenFn={setOpenDialog}
        />
        <DialogConfirm
          item={currentItem}
          removeFunction={() => removeUserRole(currentItem.permission_id, currentItem.type, id)}
          title="Potwierdź usunięcie roli, zmiana jest nieodwracalna!"
          open={openDialogConfirm}
          setOpenFn={setOpenDialogConfirm}
        />
        <DialogConfirm
          item={currentItem}
          removeFunction={() => removeUserRole(currentItem.permission_id, currentItem.type, id)}
          title="Pamiętaj, aby przed usunięciem Użytkownika z Organizacji usunąć jego rolę w Programach. Jeśli usuniesz przypisanie do Organizacji bez usunięcia przypisania go do Programów, użytkownik nadal będzie miał do nich dostęp, ale Ty nie będziesz tym dalej zarządzać"
          open={openDialogConfirmOrganization}
          setOpenFn={setOpenDialogConfirmOrganization}
        />
        <FloatingButton
          onClick={() => {
            setOpenDialog(true);
            setCurrentItem(null);
          }}
        />
      </GuardedComponent>
    </>
  );
};

UserPermissions.propTypes = {
  clearUserPermissions: PropTypes.func,
  fetchUserPermissions: PropTypes.func,
  permissions: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.object]),
  editRolaSystemowa: PropTypes.func,
  removeUserRole: PropTypes.func,
  showAlert: PropTypes.func,
  me: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    user_programs: PropTypes.arrayOf(PropTypes.any),
    user_guardians: PropTypes.arrayOf(PropTypes.any),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
};

UserPermissions.defaultProps = {
  clearUserPermissions: null,
  fetchUserPermissions: null,
  permissions: null,
  editRolaSystemowa: null,
  removeUserRole: null,
  showAlert: null,
  me: {
    id: null,
    name: null,
    surname: null,
    user_programs: [],
    user_organizations: [],
    user_guardians: [],
    user_role: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  permissions: userReducer.permissions,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearUserPermissions: () => dispatch(clearUserPermissionsAction()),
  fetchUserPermissions: (id) => dispatch(fetchUserPermissionsService(id)),
  editRolaSystemowa: (id, data) => dispatch(editRolaSystemowaService(id, data)),
  removeUserRole: (id, type, user_id) => dispatch(removeUserRoleService(id, type, user_id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
