// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Elements
import { IconButton, FormControl, TextField } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

import Calendar from '@components/atoms/Calendar/Calendar';
import Button from '@components/atoms/Button/Button';

// Styles
import { makeStyles } from '@mui/styles';

import {
  StyledDrawer,
  StyledTitle,
  StyledCloseIcon,
  StyledAutocomplete,
} from './FilterPanel.styles';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '1.5rem',
    minWidth: '80%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
}));

// Component
const FilterPanel = ({
  isFilterHide,
  setIsFilterHide,
  possibleOptions,
  setFilterValue,
  currentFilter,
}) => {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      setIsFilterHide(true);
    };
  }, [setIsFilterHide]);

  return (
    <StyledDrawer anchor="right" variant="permanent" hide={isFilterHide ? 1 : 0}>
      <StyledTitle>
        <Heading.Body1>Filtruj</Heading.Body1>
        <IconButton onClick={() => setIsFilterHide(true)}>
          <StyledCloseIcon />
        </IconButton>
      </StyledTitle>
      {Object.keys(possibleOptions).map((key) => {
        return (
          <FormControl variant="outlined" className={classes.formControl} key={key}>
            {possibleOptions[key].type === 'range_from' ||
            possibleOptions[key].type === 'range_to' ? (
              <Calendar
                label={possibleOptions[key].label}
                variant="filled"
                name="dates[0]"
                clearable
                onChange={(event, value) => {
                  setFilterValue({ [key]: value !== null && value });
                }}
                value={currentFilter[key] ? currentFilter[key] : null}
              />
            ) : (
              <StyledAutocomplete
                className={classes.auto}
                options={possibleOptions[key].options}
                onChange={(event, value) => {
                  setFilterValue({ [key]: value !== null && value });
                }}
                getOptionLabel={(option) => {
                  if (typeof option !== 'object' && possibleOptions[key].translator) {
                    return possibleOptions[key].translator(option);
                  }
                  if (typeof option !== 'object') {
                    return option;
                  }
                  return '';
                }}
                getOptionSelected={(option, value) => option === value}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label={possibleOptions[key].label} variant="outlined" />
                )}
                value={currentFilter[key] ? currentFilter[key] : null}
              />
            )}
          </FormControl>
        );
      })}
      <Button
        onClick={() =>
          Object.keys(possibleOptions).map((key) => {
            return setFilterValue({ [key]: false });
          })
        }
      >
        wyczyść filtry
      </Button>
    </StyledDrawer>
  );
};

FilterPanel.propTypes = {
  isFilterHide: PropTypes.bool,
  setIsFilterHide: PropTypes.func,
  setFilterValue: PropTypes.func,
  possibleOptions: PropTypes.arrayOf(PropTypes.any),
  currentFilter: PropTypes.arrayOf(PropTypes.any),
};

FilterPanel.defaultProps = {
  isFilterHide: true,
  setIsFilterHide: null,
  setFilterValue: null,
  possibleOptions: [],
  currentFilter: [],
};

export default FilterPanel;
