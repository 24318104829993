// React
import React from 'react';
import PropTypes from 'prop-types';

// Moment
import moment from 'moment';

// PDF
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

// Fonts
import robotoRegularSRC from '@font/Roboto-Regular.ttf';
import robotoBoldSRC from '@font/Roboto-Bold.ttf';

// Styles
import { styles } from '@utils/pdfStyles';

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegularSRC }, { src: robotoBoldSRC, fontWeight: 700 }],
});

const BeneficiaryNotesPDF = ({ data }) => {
  return data && Object.keys(data).length > 0 ? (
    <Document language="pl">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Notatka</Text>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Tytuł: </Text>
            <Text>{data.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Data sporządzenia: </Text>
            <Text>{moment(data.date).format('DD-MM-YYYY')}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Data dodania / edycji: </Text>
            <Text>
              {moment(data.date_added).format('DD-MM-YYYY')} /{' '}
              {data.date_edited ? moment(data.date_edited).format('DD-MM-YYYY') : '-'}
            </Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Dodana / edytowana przez: </Text>
            <Text>
              {data.who_added} / {data.who_edited ? data.who_edited : '-'}
            </Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Typ: </Text>
            <Text>{data.type_name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Działanie: </Text>
            <Text>{data.action}</Text>
          </View>
          <Text style={styles.title}>Treść:</Text>
          <Text style={styles.text}>{data.content}</Text>
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page size="A4" style={styles.page} />
    </Document>
  );
};

BeneficiaryNotesPDF.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
};

BeneficiaryNotesPDF.defaultProps = {
  data: {},
};

export default BeneficiaryNotesPDF;
