// Styles
import styled from 'styled-components';

export const StyledSVG = styled.svg`
  @media (max-width: 767px) {
    width: 13rem;
    height: 6rem;
    margin-right: -5rem;
  }
`;

export const StyledLogoText = styled.path`
  fill: ${({ theme }) => theme.color.black};
  @media (max-width: 767px) {
    display: none;
  }
`;
