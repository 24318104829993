import { FETCH_CONFIGS, CLEAR_CONFIGS } from '@constants/actionTypes';

const initialState = {
  settings: {
    login_settings: [],
    token_settings: [],
  },
};

const configReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_CONFIGS:
      return { ...state, settings: payload.data };
    case CLEAR_CONFIGS:
      return { ...state, settings: initialState.settings };
    default:
      return state;
  }
};

export default configReducer;
