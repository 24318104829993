import {
  FETCH_PWD_LIST,
  CLEAR_PWD_LIST,
  FETCH_PWD_PENDING,
  FETCH_SUBJECT_LIST,
  CLEAR_SUBJECT_LIST,
  FETCH_SUBJECT_PENDING,
  FETCH_EDUCATIONALFACILITY_LIST,
  CLEAR_EDUCATIONALFACILITY_LIST,
  FETCH_EDUCATIONALFACILITY_PENDING,
  FETCH_INDICATORPERIODS_LIST,
} from '@constants/actionTypes';

export function fetchSubjectListAction(payload) {
  return { type: FETCH_SUBJECT_LIST, payload };
}

export function clearSubjectList(payload) {
  return { type: CLEAR_SUBJECT_LIST, payload };
}

export function setSubjectPendingAction(payload) {
  return { type: FETCH_SUBJECT_PENDING, payload };
}

export function fetchPwdListAction(payload) {
  return { type: FETCH_PWD_LIST, payload };
}

export function clearPwdList(payload) {
  return { type: CLEAR_PWD_LIST, payload };
}

export function setPwdPendingAction(payload) {
  return { type: FETCH_PWD_PENDING, payload };
}

export function fetchEducationalFacilitiesListAction(payload) {
  return { type: FETCH_EDUCATIONALFACILITY_LIST, payload };
}

export function clearEducationalFacilitiesList(payload) {
  return { type: CLEAR_EDUCATIONALFACILITY_LIST, payload };
}

export function setEducationalFacilitiesPendingAction(payload) {
  return { type: FETCH_EDUCATIONALFACILITY_PENDING, payload };
}

export function fetchBeneficiaryIndicatorPeriodAction(payload) {
  return { type: FETCH_INDICATORPERIODS_LIST, payload };
}
