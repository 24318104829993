// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { fetchMe as fetchMeService } from '@services/userServices';

// Data
import { COORDINATOR, rolesTranslator } from '@constants/roles';

// Elements
import { Box, Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Table from '@components/organisms/Table/Table';

// Styles
import { StyledWrapper } from './UserRoles.styles';

// Data
const headCellsOrganizations = [
  { type: 'name', id: 'name', label: 'Organizacja' },
  { type: 'role_name', id: 'role_name', label: 'Rola' },
];

const headCellsPrograms = [
  { type: 'name', id: 'name', label: 'Program' },
  { type: 'organizations', id: 'organizations', label: 'Organizacje w programie' },
  { type: 'organization_from', label: 'Organizacja' },
  { type: 'role_name', id: 'role_name', label: 'Rola' },
];

const headCellsGuardians = [
  { type: 'program_name', id: 'program_name', label: 'Program' },
  { type: 'role_name', id: 'role_name', label: 'Rola' },
];

// Component
const UserRoles = ({ me, fetchMe }) => {
  const history = useHistory();

  useEffect(() => {
    fetchMe();
  }, []);

  return (
    <>
      <StyledWrapper className="documents" container spacing={2}>
        <Grid item xs={8}>
          <Heading.Subtitle3>
            Zalogowany jako: {me.name} {me.surname}
          </Heading.Subtitle3>
        </Grid>
        <Grid item xs={8}>
          <Heading.Subtitle3>Rola w systemie:</Heading.Subtitle3>
        </Grid>
        <Grid item xs={8}>
          <Heading.Subtitle3>
            {me && me.user_role.includes(COORDINATOR) ? 'Administrator systemu' : 'Użytkownik'}{' '}
          </Heading.Subtitle3>
        </Grid>
      </StyledWrapper>
      {me && me.user_organizations && (
        <StyledWrapper className="documents" container spacing={2}>
          <Grid item xs={8}>
            <Box mb={2}>
              <Heading.Subtitle3>Organizacje:</Heading.Subtitle3>
            </Box>
            <Table
              headCells={headCellsOrganizations}
              isnav
              rowLink="/organizacje"
              data={me.user_organizations.map((item) => ({
                ...item,
                role_name: rolesTranslator(item.role),
              }))}
              noSort
              noPagination
              tableBene
            />
          </Grid>
        </StyledWrapper>
      )}
      {me && me.user_programs && (
        <StyledWrapper className="documents" container spacing={2}>
          <Grid item xs={8}>
            <Box mb={2}>
              <Heading.Subtitle3>Programy:</Heading.Subtitle3>
            </Box>
            <Table
              headCells={headCellsPrograms}
              data={me.user_programs.map((item) => ({
                ...item,
                role_name: rolesTranslator(item.role),
              }))}
              noSort
              noPagination
              isnav={false}
              // rowLink="/programy"
              rowActions={[
                {
                  name: 'zobacz program',
                  action: (row) => history.push(`/programy/${row.program_id}`),
                },
              ]}
            />
          </Grid>
        </StyledWrapper>
      )}
      {me && me.user_guardians && me.user_guardians.length > 0 && (
        <StyledWrapper className="documents" container spacing={2}>
          <Grid item xs={8}>
            <Box mb={2}>
              <Heading.Subtitle3>Opiekun Programu:</Heading.Subtitle3>
            </Box>
            <Table
              headCells={headCellsGuardians}
              data={me.user_guardians.map((item) => ({
                ...item,
                program_name: item.program.name,
                role_name: rolesTranslator(item.role),
              }))}
              noSort
              noPagination
              tableBene
            />
          </Grid>
        </StyledWrapper>
      )}
    </>
  );
};

UserRoles.propTypes = {
  me: PropTypes.shape({
    name: PropTypes.string,
    surname: PropTypes.string,
    user_role: PropTypes.arrayOf(PropTypes.string),
    user_organizations: PropTypes.arrayOf(PropTypes.any),
    user_programs: PropTypes.arrayOf(PropTypes.any),
    user_guardians: PropTypes.arrayOf(PropTypes.any),
  }),
  fetchMe: PropTypes.func,
};

UserRoles.defaultProps = {
  fetchMe: null,
  me: {
    name: '',
    surname: '',
    user_role: [],
    user_guardians: [],
    user_organizations: [],
    user_programs: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMeService()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserRoles);
