// React
import React from 'react';
import PropTypes from 'prop-types';

// Assets
import DateRangeIcon from '@mui/icons-material/DateRange';
// import DateRangeIcon from '@mui/icons-material/DateRange';

// import DateRangeIcon from '@mui/icons-material/DateRange';

// Elements
import Button from '@components/atoms/Button/Button';

// Styles
import { Wrapper, StyledDateRangePicker } from './CalendarRange.styles';
import './CalendarRange.css';

// Component
const CalendarRange = ({
  removemargin,
  text,
  open,
  initialDateRange,
  setRangeCalendarOpen,
  setRangeCalendarDates,
  minDate,
  maxDate,
  disabled,
  ...props
}) => {
  const toggle = () => setRangeCalendarOpen(!open);

  // console.log('CalendarRange -> initialDateRange', initialDateRange, props);

  return (
    <Wrapper removemargin={removemargin}>
      <Button
        variant="text"
        startIcon={<DateRangeIcon />}
        onClick={() => {
          if (!disabled) {
            setRangeCalendarOpen(!open);
          }
        }}
      >
        {text}
      </Button>
      <StyledDateRangePicker
        isOpen={open}
        value={Object.values(initialDateRange)}
        minDate={minDate}
        maxDate={maxDate}
        toggle={toggle}
        clearIcon={null}
        calendarIcon={null}
        showLeadingZeros
        locale="pl-PL"
        onChange={(range) => {
          if (range) {
            range[0].setTime(range[0].getTime() + 1 * 60 * 60 * 1000);
            setRangeCalendarDates({
              startDate: range[0],
              endDate: range[1].getTime() >= range[0].getTime() ? range[1] : range[0],
            });
          }
        }}
        wrapperClassName="calendar-picker"
        disabled={disabled}
        {...props}
      />
    </Wrapper>
  );
};

CalendarRange.propTypes = {
  removemargin: PropTypes.string,
  text: PropTypes.string,
  open: PropTypes.bool.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  initialDateRange: PropTypes.shape({ root: PropTypes.string }),
  setRangeCalendarOpen: PropTypes.func.isRequired,
  setRangeCalendarDates: PropTypes.func.isRequired,
  rangeCalendarDates: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  disabled: PropTypes.bool,
};

CalendarRange.defaultProps = {
  removemargin: 'false',
  text: 'Wybierz daty',
  initialDateRange: {},
  minDate: null,
  maxDate: null,
  rangeCalendarDates: {},
  disabled: false,
};

export default CalendarRange;
