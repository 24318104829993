// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchOrganizationBeneficiaries as fetchOrganizationBeneficiariesService } from '@services/organizationServices';

// Actions
import { clearOrganizationBeneficiaries as clearOrganizationBeneficiariesAction } from '@actions/organizationActions';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

// Data
const headCells = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'gender', label: 'Płeć' },
  { type: 'pwd', label: 'PWD' },
  { type: 'edu_facility', label: 'Aktualna Placówka' },
  // { type: 'participant_type', label: 'Typ uczestnika' },
  { type: 'date_add', label: 'Data dodania' },
  // { type: 'date_modified', label: 'Data ostatniej modyfikacji' },
  // { type: 'modified_by', label: 'Modyfikowany przez' },
];

// Component
const OrganizationBeneficiary = ({
  clearOrganizationBeneficiaries,
  fetchBeneficiaries,
  beneficiaries,
}) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    clearOrganizationBeneficiaries();
  }, []);

  useEffect(() => {
    fetchBeneficiaries(id);
  }, [id]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={beneficiaries !== null ? beneficiaries : []}
        isnav
        link="/beneficjenci"
        tableName="Beneficjenci"
      />
      <FloatingButton onClick={() => history.push('/beneficjenci-dodaj')} />
    </>
  );
};

OrganizationBeneficiary.propTypes = {
  clearOrganizationBeneficiaries: PropTypes.func,
  fetchBeneficiaries: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
    }),
  ),
};

OrganizationBeneficiary.defaultProps = {
  clearOrganizationBeneficiaries: null,
  fetchBeneficiaries: null,
  beneficiaries: [],
};

const mapStateToProps = ({ organizationReducer }) => ({
  beneficiaries: organizationReducer.beneficiaries,
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganizationBeneficiaries: () => dispatch(clearOrganizationBeneficiariesAction()),
  fetchBeneficiaries: (id) => dispatch(fetchOrganizationBeneficiariesService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationBeneficiary);
