// Assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// Elements
import {
  TableContainer,
  TableRow,
  TableCell,
  TablePagination,
  Chip,
  TableSortLabel,
} from '@mui/material';
import Button from '@components/atoms/Button/Button';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import styled, { css } from 'styled-components';

export const StyledTooltip = styled.div`
  && {
    font-size: ${({ theme }) => theme.fontSize.body2};
    line-height: 1.1;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    > * {
      margin-bottom: 1rem;
    }

    p {
      &:first-child {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
`;

export const StyledTablePagination = styled(TablePagination)`
  && {
    color: ${({ theme }) => theme.color.darkGray};
    & p {
      color: ${({ theme }) => theme.color.darkGray};
      font-size: ${({ theme }) => theme.fontSize.body2};
    }
    .MuiSelect-icon {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  && {
    width: 100%;
    display: 'flex';
    &:hover {
      cursor: ${({ nav }) => (nav ? 'pointer' : 'auto')};
    }
  }
`;

export const StyledTableSortLabel = styled(TableSortLabel)`
  && {
    svg {
      color: ${({ theme }) => `${theme.color.darkGray} !important`};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    flex: 1;
    background-color: ${({ theme, header }) => (header ? theme.color.lightGray : 'transparent')};
    cursor: ${({ pointercursor }) => (pointercursor ? 'pointer' : 'default')};
    padding: 5px 8px;
    height: 70px;
    line-height: 1.1;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    font-size: ${({ theme }) => theme.fontSize.overline};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.color.darkGray};
    display: flex;
    ${({ type }) => {
      switch (type) {
        case 'red':
          return css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.red};
          `;
        case 'blue':
          return css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.blue};
          `;
        case 'yellow':
          return css`
            color: ${({ theme }) => theme.color.white};
            background-color: ${({ theme }) => theme.color.yellow};
          `;
        default:
          return null;
      }
    }}
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const CheckboxCell = styled(TableCell)`
  && {
    .MuiFormControlLabel-root {
      margin: 0;
    }
  }
`;

export const StyledRemoveCircleIcon = styled(RemoveCircleIcon)`
  && {
    fill: ${({ theme }) => theme.color.white};
  }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    fill: ${({ theme }) => theme.color.white};
  }
`;

export const StyledHeading = styled(Heading.Body2)`
  && {
    text-align: center;
  }
`;

export const StyledButton = styled(Button)`
  && {
    font-size: 1.2rem !important;
    color: ${({ theme }) => `${theme.color.darkGreyToBlack}  !important`};
    color: ${({ theme, button_type }) =>
      button_type && button_type !== 'empty' && `${theme.color.white} !important`};
    background-color: ${(props) => {
      if (props.button_type === 'full') {
        return `${props.theme.color.blue} !important`;
      }

      if (props.button_type === 'error') {
        return `${props.theme.color.red} !important`;
      }

      if (props.button_type === 'warning') {
        return `${props.theme.color.yellow} !important`;
      }

      if (props.button_type === 'empty') {
        return `${props.theme.color.grayToBlack} !important`;
      }

      return 'transparent';
    }};
    border: 1px solid transparent;
    border-color: ${(props) => {
      if (props.button_type === 'full') {
        return `${props.theme.color.blue} !important`;
      }

      if (props.button_type === 'error') {
        return `${props.theme.color.red} !important`;
      }

      if (props.button_type === 'warning') {
        return `${props.theme.color.yellow} !important`;
      }

      if (props.button_type === 'empty') {
        return `${props.theme.color.gray} !important`;
      }

      return 'transparent';
    }};
  }
`;
