// React
import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Data
import { currentUserTabs } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const CurrentUser = ({ user }) => {
  const { id } = useParams();

  return (
    <>
      <BreadcrumbsItem to={`/uzytkownicy/${id}`} order={4}>
        {`Użytkownik: ${user.name} ${user.surname}`}
      </BreadcrumbsItem>
      <Tabs renderViewArray={currentUserTabs} />
    </>
  );
};

CurrentUser.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
  }),
};

CurrentUser.defaultProps = {
  user: null,
};

const mapStateToProps = ({ userReducer }) => ({
  user: userReducer.user,
});

export default connect(mapStateToProps)(CurrentUser);
