// Elements
import { Card, CardActions, CardMedia, Divider, IconButton } from '@mui/material';

// Styles
import styled, { css } from 'styled-components';

export const StyledTitle = styled.div`
  && {
    padding: 2rem;
    display: flex;
    width: 70%;
    margin-bottom: -0.7em;
    align-items: flex-end;

    button {
      margin-left: 1rem;
    }
  }
`;

export const StyledBadge = styled.div`
  && {
    position: absolute;
    top: -1rem;
    right: 2rem;
    width: 5rem;
    height: 6rem;
    background-repeat: no-repeat;
    background-image: ${({ badge }) => `url(${badge})`};
  }
`;

export const StyledCard = styled(Card)`
  && {
    position: relative;
    background-color: ${({ theme }) => theme.color.white};
    transition: ease-out 0.2s;

    &:hover {
      box-shadow: 0 2.8px 2.2px ${({ shadows }) => shadows},
        0 6.7px 5.3px ${({ shadows }) => shadows}, 0 12.5px 10px ${({ shadows }) => shadows},
        0 22.3px 17.9px ${({ shadows }) => shadows}, 0 41.8px 33.4px ${({ shadows }) => shadows};
      transition: 0.3s;
    }
  }
`;

export const StyledCardMedia = styled(CardMedia)`
  && {
    height: 80%;
    display: flex;
    align-items: flex-end;
    background-image: ${({ image }) => image};
    background-size: contain;
    background-color: ${({ backcol }) => backcol};

    @media (max-width: 1601px) {
      background-position-x: 18rem;
    }

    ${StyledCard}:hover & {
      background-color: ${({ hovcol }) => hovcol};
    }
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    width: 5rem;
    margin-left: 2rem;
    height: 3px;
    border-radius: 3px;

    ${({ color }) =>
      color &&
      css`
        background-color: ${({ theme }) => theme.color[color]};
      `}
  }
`;

export const StyledCardActions = styled(CardActions)`
  && {
    width: 94%;
    margin: 0 auto;
    justify-content: flex-end;
  }
`;

export const StyledIconButton = styled(IconButton)`
  transition: transform 0.3s;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
    `}
`;
