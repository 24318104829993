// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchOrganizationPrograms as fetchOrganizationProgramsService } from '@services/organizationServices';

// Actions
import { clearOrganizationPrograms as clearOrganizationProgramsAction } from '@actions/organizationActions';

// Data
import { COORDINATOR } from '@constants/roles';
import { filterPrograms } from '@constants/tableFilters';

// Elements
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import TableTemplate from '@templates/TableTemplate';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa programu' },
  { type: 'type', id: 'type', label: 'Typ programu' },
  { type: 'organizations_number', label: 'Liczba organizacji' },
  { type: 'tasks_number', label: 'Liczba zadań' },
  { type: 'date_start', label: 'Data rozpoczęcia' },
  { type: 'date_end', label: 'Data zakończenia' },
  { type: 'planned_meeting_hours', label: 'Liczba godzin w ramach działań' },
  { type: 'coordinator', label: 'Koordynator' },
];

// Component
const OrganizationPrograms = ({ clearOrganizationPrograms, fetchPrograms, programs }) => {
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    clearOrganizationPrograms();
  }, []);

  useEffect(() => {
    fetchPrograms(id);
  }, [id]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={programs !== null ? programs : []}
        isnav
        filters={filterPrograms}
        link="/programy"
        tableName="Programy"
      />
      <GuardedComponent allowed_user_roles={[COORDINATOR]}>
        <FloatingButton onClick={() => history.push('/programy-dodaj')} />
      </GuardedComponent>
    </>
  );
};

OrganizationPrograms.propTypes = {
  clearOrganizationPrograms: PropTypes.func,
  fetchPrograms: PropTypes.func,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      surname: PropTypes.string,
    }),
  ),
};

OrganizationPrograms.defaultProps = {
  clearOrganizationPrograms: null,
  fetchPrograms: null,
  programs: [],
};

const mapStateToProps = ({ organizationReducer }) => ({
  programs: organizationReducer.programs,
});

const mapDispatchToProps = (dispatch) => ({
  clearOrganizationPrograms: () => dispatch(clearOrganizationProgramsAction()),
  fetchPrograms: (id) => dispatch(fetchOrganizationProgramsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationPrograms);
