export const NIE_MASZ_UPRAWNIEN = {
  snack: {
    code: 405,
    type: 'warning',
    isActive: true,
    message: 'Nie masz uprawnień do odwiedzenia tej części serwisu!',
  },
};

export const JUZ_ZANIMIZOWANO = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Beneficjent został już zanonimizowany!',
  },
};

export const ZANONIMIZOWANO_ZAKAZ_EDYCJI = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Beneficjent został zanonimizowany, nie można edytować!',
  },
};

export const DIAGNOSIS_NOT_COMPLETE = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Dokończ diagnozę przed pobraniem!',
  },
};

export const SURVEYS_NOT_COMPLETE = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Aby wyświetlić wynik lub pobrać PDF należy dokończyć ankietę!',
  },
};

export const SURVEYS_COMPLETE = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Ankietę zakończona pobierz pdf lub zobacz wynik!',
  },
};

export const RODO_WYBIERZ = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Należy wybrać organizację której dane są powierzane',
  },
};

export const RODO_SPRZECZNE = {
  snack: {
    type: 'warning',
    isActive: true,
    message: 'Wybrałeś sprzeczne dane – prosimy o ponowne sprawdzenie ustawień',
  },
};
