// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Service
// import { saveAdvancedrodoConfigProgram as saveAdvancedrodoConfigProgramService } from '@services/programServices';

// Data
import { surveyQuestions } from '@views/Dashboard/Programs/ProgramConsortium/programSurveyQuestions';

// Elements
import {
  Box,
  Accordion,
  AccordionSummary,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Dialog from '@components/organisms/Dialog/Dialog';

// Styles
import { StyledButton } from './ProgramSurvey.styles';
import './ProgramSurvey.scss';
// import { CollectionsBookmarkRounded } from '@mui/icons-material';

function useForceUpdate() {
  const [forceValue, setForceValue] = useState(0); // integer state
  if (forceValue === -1) console.log(forceValue);
  return () => setForceValue((value) => value + 1); // update state to force render
}

// Component
const ProgramRODOKatalog = ({
  details,
  //  saveAdvancedrodoConfigProgram,
  handleChange,
  setFieldValue,
  onlyShow,
  // group,
  // currentOrganizationIndex,
  indexFromParent,
  groupBeginningName,
  groupBeginningObject,
}) => {
  const [openDialog, setOpenDialog] = useState({});
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (details.program_organizations) {
      const newData = {};
      details.program_organizations.map((el, index) => {
        newData[index] = false;
        return true;
      });

      setOpenDialog(newData);
    }
  }, [details.program_organizations]);

  return (
    <>
      {groupBeginningName !== 'groupRules' && (
        <>
          <Heading.Subtitle3>Współdzielenie grup beneficjentów</Heading.Subtitle3>
          <FormControl className="radioGrupa" component="fieldset" style={{ paddingTop: '1rem' }}>
            <RadioGroup
              className="radioGrupa"
              name={`${groupBeginningName}.beneficiary`}
              value={groupBeginningObject.beneficiary}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Wszyscy beneficjenci z programu"
                control={<Radio color="primary" />}
                label="Wszyscy beneficjenci z programu"
                disabled={onlyShow}
              />
              <FormControlLabel
                value="Beneficjenci przypisani do zadań, do których przypisana jest organizacja"
                control={<Radio color="primary" />}
                label="Beneficjenci przypisani do zadań, do których przypisana jest organizacja"
                disabled={onlyShow}
              />
              <FormControlLabel
                value="Beneficjenci przypisani do działań, do których przypisana jest organizacja"
                control={<Radio color="primary" />}
                label="Beneficjenci przypisani do działań, do których przypisana jest organizacja"
                disabled={onlyShow}
              />
            </RadioGroup>
          </FormControl>
        </>
      )}
      <Heading.Subtitle3 style={{ marginTop: '1rem' }}>
        Katalog danych przetwarzanych
      </Heading.Subtitle3>
      <FormControl className="radioGrupa" component="fieldset" style={{ paddingTop: '1rem' }}>
        <RadioGroup
          className="radioGrupa"
          name={`${groupBeginningName}.data`}
          value={groupBeginningObject.data}
          onChange={handleChange}
        >
          <FormControlLabel
            value="all"
            control={<Radio color="primary" />}
            label="Wszystkie dane"
            disabled={onlyShow}
          />
          <FormControlLabel
            value="custom"
            control={<Radio color="primary" />}
            label="Wybierz dane"
            disabled={onlyShow}
            onClick={() =>
              setOpenDialog((prevState) => ({
                ...prevState,
                [indexFromParent]: true,
              }))
            }
          />
        </RadioGroup>
      </FormControl>
      <Dialog
        open={openDialog[indexFromParent]}
        setOpen={setOpenDialog}
        title="Katalog danych przetwarzanych"
        size="lg"
      >
        <>
          {surveyQuestions.map((item) => {
            return (
              <Accordion key={item.value} defaultExpanded className="acordion-wrapper">
                <AccordionSummary expandIcon={<ExpandMoreIcon />} className="header-acordion">
                  <Heading.Subtitle1>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={onlyShow}
                          onChange={(e, newValue) => {
                            e.stopPropagation();
                            item.radioLabels.forEach((ele) => {
                              if (!ele.required) {
                                const cur_items = groupBeginningObject.customData[item.value].items;
                                cur_items[ele.value].value = newValue;
                                setFieldValue(
                                  `${groupBeginningName}.customData[${item.value}].items`,
                                  cur_items,
                                );
                                forceUpdate();
                              }
                            });
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      }
                      label="zaznacz wszystkie"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />{' '}
                    {item.name}
                  </Heading.Subtitle1>
                </AccordionSummary>
                {item.radioLabels.map((ele) => {
                  return (
                    <div className="checkbox-survey checkbox-survey--13" key={ele.value}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={ele.value}
                            disabled={onlyShow || ele.required}
                            checked={
                              (groupBeginningObject &&
                                groupBeginningObject.customData &&
                                groupBeginningObject.customData[item.value].items[ele.value]
                                  ?.value) ||
                              false
                            }
                            name={`${groupBeginningName}.customData[${item.value}].items[${ele.value}].value`}
                            onChange={(_, newValue) => {
                              const cur_group = groupBeginningObject.customData[item.value];
                              cur_group.items[ele.value].value = newValue;
                              setFieldValue(
                                `${groupBeginningName}.customData[${item.value}]`,
                                cur_group,
                              );
                              forceUpdate();
                            }}
                            id={typeof sub === 'string' ? ele.value : ele.value.toString()}
                            color="default"
                          />
                        }
                        label={ele.name}
                      />
                    </div>
                  );
                })}
              </Accordion>
            );
          })}
        </>
        {!onlyShow && (
          <Box display="flex" justifyContent="flex-end">
            <StyledButton
              variant="contained"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              zatwierdź
            </StyledButton>
          </Box>
        )}
      </Dialog>
    </>
  );
};

ProgramRODOKatalog.propTypes = {
  indexFromParent: PropTypes.number,
  values: PropTypes.shape({
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        coAdministrator: PropTypes.bool,
        dataAdministrator: PropTypes.bool,
        checked: PropTypes.bool,
        beneficiary: PropTypes.string,
        data: PropTypes.string,
        selectedToEntrust: PropTypes.arrayOf(
          PropTypes.shape({
            beneficiary: PropTypes.string,
            data: PropTypes.string,
            checked: PropTypes.bool,
            customData: PropTypes.shape({
              beneficiary_data: PropTypes.shape({
                name: PropTypes.string,
              }),
            }),
          }),
        ),
      }),
    ),
    group: PropTypes.shape({
      coAdministrator: PropTypes.bool,
      dataAdministrator: PropTypes.bool,
      checked: PropTypes.bool,
      beneficiary: PropTypes.string,
      data: PropTypes.string,
      selectedToEntrust: PropTypes.arrayOf(
        PropTypes.shape({
          beneficiary: PropTypes.string,
          data: PropTypes.string,
          checked: PropTypes.bool,
          customData: PropTypes.shape({
            beneficiary_data: PropTypes.shape({
              name: PropTypes.string,
            }),
          }),
        }),
      ),
    }),
  }),

  // currentOrganizationIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // group: PropTypes.bool,
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,

    type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        organization_id: PropTypes.number,
        organization_name: PropTypes.string,
      }),
    ),
    program_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
      }),
    ),
  }),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  // saveAdvancedrodoConfigProgram: PropTypes.func,
  onlyShow: PropTypes.bool,
  groupBeginningName: PropTypes.string,
  groupBeginningObject: PropTypes.shape({
    beneficiary: PropTypes.string,
    data: PropTypes.string,
    checked: PropTypes.bool,
    customData: PropTypes.shape({
      beneficiary_data: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }),
};

ProgramRODOKatalog.defaultProps = {
  values: {},

  // group: null,
  // currentOrganizationIndex: null,
  details: {
    id: null,
    name: '',
    type: null,
    organizations: [],
    program_organizations: [],
  },
  handleChange: null,
  setFieldValue: null,
  // saveAdvancedrodoConfigProgram: null,
  onlyShow: false,
  indexFromParent: null,
  groupBeginningName: null,
  groupBeginningObject: { beneficiary: '1', data: '1', checked: false, customData: [] },
};

const mapStateToProps = ({ programReducer }) => ({
  details: programReducer.consortium,
});

const mapDispatchToProps = () => ({
  // saveAdvancedrodoConfigProgram: (program_id, data) =>
  //   dispatch(saveAdvancedrodoConfigProgramService(program_id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramRODOKatalog);
