import React, { lazy } from 'react';
import Applications from '@views/Dashboard/Applications/Applications';
import Beneficiary from '@views/Dashboard/Beneficiary/Beneficiary';
// import Dashboard from '@views/Dashboard/Dashboard';
import Indicators from '@views/Dashboard/Indicators/Indicators';
import EditIndicator from '@views/Dashboard/Indicators/EditIndicator/EditIndicator';
import CurrentOrganization from '@views/Dashboard/Organizations/OrganizationCurrent/OrganizationCurrent';
import OrganizationTabs from '@views/Dashboard/Organizations/OrganizationTabs/OrganizationTabs';
import Organizations from '@views/Dashboard/Organizations/Organizations';
import Programs from '@views/Dashboard/Programs/Programs';
import ProgramAdd from '@views/Dashboard/Programs/ProgramAdd/ProgramAdd';
import Reports from '@views/Dashboard/Reports/Reports';
import Tasks from '@views/Dashboard/Tasks/Tasks';
import CurrentUser from '@views/Dashboard/Users/CurrentUser/CurrentUser';
import AddUser from '@views/Dashboard/Users/AddUser/AddUser';
import Users from '@views/Dashboard/Users/Users';
import UserSettings from '@views/Dashboard/UserSettings/UserSettings';
import Config from '@views/Dashboard/Config/Config';
import Guide from '@views/Dashboard/Guide/Guide';
import Desktop from '@views/Dashboard/Desktop/Desktop';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import PollRoundedIcon from '@mui/icons-material/PollRounded';
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NoteIcon from '@mui/icons-material/Note';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ProgramTabs from '@views/Dashboard/Programs/ProgramTabs/ProgramTabs';
import BeneficiaryTabs from '@views/Dashboard/Beneficiary/BeneficiaryTabs/BeneficiaryTabs';
import AddBeneficiary from '@views/Dashboard/Beneficiary/AddBeneficiary/AddBeneficiary';
import AddBeneficiaryDiagnosis from '@views/Dashboard/Beneficiary/AddBeneficiaryDiagnosis/AddBeneficiaryDiagnosis';
import AddBeneficiaryIPP from '@views/Dashboard/Beneficiary/AddBeneficiaryIPP/AddBeneficiaryIPP';
import AddBeneficiarySurveys from '@views/Dashboard/Beneficiary/AddBeneficiarySurveys/AddBeneficiarySurveys';
import ResultBeneficiarySurveys from '@views/Dashboard/Beneficiary/ResultBeneficiarySurveys/ResultBeneficiarySurveys';
import AddReport from '@views/Dashboard/Reports/AddReport/AddReport';
import Task from '@views/Dashboard/Tasks/Task/Task';
import TaskAdd from '@views/Dashboard/Tasks/TaskAdd/TaskAdd';
import Actions from '@views/Dashboard/Actions/Actions';
import Action from '@views/Dashboard/Actions/Action/Action';
import ActionAdd from '@views/Dashboard/Actions/ActionAdd/ActionAdd';
import Login from '@views/Start/Start';
import UserRoles from '@views/Dashboard/UserRoles/UserRoles';
import ContactAdmin from '@views/Dashboard/ContactAdmin/ContactAdmin';
import MessagesList from '@views/Dashboard/Messages/MessagesList/MessagesList';
import MessagesSingle from '@views/Dashboard/Messages/MessagesSingle/MessagesSingle';
import AddMessages from '@views/Dashboard/Messages/AddMessages/AddMessages';

import {
  COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  ORGANIZATION_EMPLOYEE,
  PROGRAM_GUARDIAN,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
  ORGANIZATION_EXTERNAL_COLLABORATOR,
  INSTRUCTOR,
} from '@constants/roles';

const Dashboard = lazy(() => import('@views/Dashboard/Dashboard'));

export default [
  { path: '/', name: '', Component: Dashboard, route: 'root' },
  { path: '/start', name: 'start', Component: Login, route: 'start' },
  {
    path: '/dashboard',
    name: 'dashboard',
    Component: Desktop,
    route: 'sidebar',
    sidebarName: 'Pulpit',
    icon: <DashboardIcon />,
  },
  {
    path: '/dashboard/:type/:message',
    name: 'dashboard',
    Component: Desktop,
    icon: <DashboardIcon />,
  },
  {
    path: '/organizacje',
    name: 'Organizacje',
    Component: Organizations,
    route: 'sidebar',
    roles: [
      COORDINATOR,
      PROGRAM_GUARDIAN,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      ORGANIZATION_EXTERNAL_COLLABORATOR,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
    sidebarName: 'Organizacje',
    icon: <AccountBalanceIcon />,
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/uzytkownicy',
    name: 'Użytkownicy',
    Component: Users,
    route: 'sidebar',
    sidebarName: 'Użytkownicy',
    icon: <PeopleAltIcon />,
    roles: [
      COORDINATOR,
      PROGRAM_COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
    ],
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/beneficjenci',
    name: 'Beneficjenci',
    Component: Beneficiary,
    route: 'sidebar',
    sidebarName: 'Beneficjenci',
    icon: <SupervisedUserCircleRoundedIcon />,
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      ORGANIZATION_EXTERNAL_COLLABORATOR,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
  {
    path: '/programy',
    name: 'Programy',
    Component: Programs,
    route: 'sidebar',
    sidebarName: 'Programy',
    icon: <AccountTreeIcon />,
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/zadania',
    name: 'Zadania',
    Component: Tasks,
    route: 'sidebar',
    sidebarName: 'Zadania',
    icon: <AssignmentRoundedIcon />,
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      // PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },
  {
    path: '/dzialania',
    name: 'Działania',
    Component: Actions,
    route: 'sidebar',
    sidebarName: 'Działania',
    icon: <PeopleIcon />,
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      // PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },
  {
    path: '/wskazniki',
    name: 'Wskaźniki',
    Component: Indicators,
    route: 'sidebar',
    sidebarName: 'Wskaźniki',
    icon: <PollRoundedIcon />,
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/raporty',
    name: 'Raporty',
    Component: Reports,
    route: 'sidebar',
    sidebarName: 'Raporty',
    icon: <AssignmentTurnedInRoundedIcon />,
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/raporty-stworz/:type',
    name: 'Stwórz raport',
    Component: AddReport,
    route: 'report',
    strict: true,
    breadcrumbs: [{ name: 'Raport', path: '/raporty' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },
  {
    path: '/wnioski',
    name: 'Wnioski',
    Component: Applications,
    route: 'sidebar',
    sidebarName: 'Wnioski',
    icon: <NoteIcon />,
    roles: [COORDINATOR],
    breadcrumbs: [{ self: true }],
  },
  // {
  //   path: '/konfiguracja',
  //   name: 'Konfiguracja',
  //   Component: Config,
  //   route: 'sidebar',
  //   sidebarName: 'Konfiguracja',
  //   icon: <SettingsIcon />,
  //   roles: [COORDINATOR],
  //   breadcrumbs: [{ self: true }],
  // },
  {
    path: '/konfiguracja/(tab)?/:goto_tab?',
    name: 'Konfiguracja',
    Component: Config,
    route: 'dashboard',
    sidebarName: 'Konfiguracja',
    icon: <SettingsIcon />,
    roles: [COORDINATOR],
    breadcrumbs: [{ self: true }],
  },

  {
    path: '/moje-role',
    name: 'Moje role',
    Component: UserRoles,
    route: 'dashboard',
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/ustawienia/(tab)?/:goto_tab?',
    name: 'Ustawienia',
    Component: UserSettings,
    route: 'dashboard',
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/beneficjenci-dodaj',
    name: 'Dodaj Beneficjenta',
    Component: AddBeneficiary,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },
  {
    path: '/beneficjenci-dodaj/:beneficiary_id/(program)?/:program_id?',
    name: 'Dodaj Beneficjenta',
    Component: AddBeneficiary,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },

  {
    path: '/beneficjenci/:id/(tab)?/:goto_tab?',
    name: 'Edytuj Beneficjenta',
    Component: BeneficiaryTabs,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      ORGANIZATION_EXTERNAL_COLLABORATOR,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
  {
    path: '/dzialania-dodaj/:type/program/:program_id',
    name: 'Dodaj działanie',
    Component: ActionAdd,
    route: 'actions',
    breadcrumbs: [{ name: 'Działania', path: '/dzialania' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/dzialania-dodaj/:type/zadanie/:task_id',
    name: 'Dodaj działanie',
    Component: ActionAdd,
    route: 'actions',
    breadcrumbs: [{ name: 'Działania', path: '/dzialania' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/dzialania-dodaj/:type',
    name: 'Dodaj działanie',
    Component: ActionAdd,
    route: 'actions',
    strict: true,
    breadcrumbs: [{ name: 'Działania', path: '/dzialania' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/dzialania/:id/(tab)?/:goto_tab?',
    name: 'Edytuj Działanie',
    Component: Action,
    route: 'actions',
    breadcrumbs: [{ name: 'Działania', path: '/dzialania' }],
  },
  {
    path: '/diagnoza-dodaj/:id',
    name: 'Przeprowadź Diagnozę',
    Component: AddBeneficiaryDiagnosis,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/diagnoza-dodaj/program/:program_id',
    name: 'Przeprowadź Diagnozę',
    Component: AddBeneficiaryDiagnosis,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Programy', path: '/programy' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/diagnoza-dodaj',
    name: 'Przeprowadź Diagnozę',
    Component: AddBeneficiaryDiagnosis,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/diagnoza/:id_diagnose/:edit?',
    name: 'Przeprowadź Diagnozę',
    Component: AddBeneficiaryDiagnosis,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/ankieta-dodaj/:id',
    name: 'Utwórz Ankietę',
    Component: AddBeneficiarySurveys,
    route: 'beneficiary',
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/ankieta-dodaj',
    name: 'Utwórz Ankietę',
    Component: AddBeneficiarySurveys,
    route: 'beneficiary',
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/ankieta-wynik/:id_survey/',
    name: 'Wynik ankiety',
    Component: ResultBeneficiarySurveys,
    route: 'beneficiary',
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/ankieta/:id_survey/:edit?',
    name: 'Ankieta',
    Component: AddBeneficiarySurveys,
    route: 'beneficiary',
    breadcrumbs: [{ self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/indywidualny-plan-pracy-dodaj/:id',
    name: 'Utwórz Indywidualny Plan Pracy',
    Component: AddBeneficiaryIPP,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/indywidualny-plan-pracy-dodaj/program/:program_id',
    name: 'Utwórz Indywidualny Plan Pracy',
    Component: AddBeneficiaryIPP,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Programy', path: '/programy' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/indywidualny-plan-pracy-dodaj',
    name: 'Utwórz Indywidualny Plan Pracy',
    Component: AddBeneficiaryIPP,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/indywidualny-plan-pracy/:id_ipp/:edit?',
    name: 'Utwórz Indywidualny Plan Pracy',
    Component: AddBeneficiaryIPP,
    route: 'beneficiary',
    breadcrumbs: [{ name: 'Beneficjenci', path: '/beneficjenci' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
    ],
  },
  {
    path: '/wskaznik-dodaj/program/:program_id',
    name: 'Dodaj Wskaźnik',
    Component: EditIndicator,
    route: 'indicator_new',
    breadcrumbs: [{ name: 'Wskaźniki', path: '/wskazniki' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/wskaznik-dodaj/zadanie/:task_id',
    name: 'Dodaj Wskaźnik',
    Component: EditIndicator,
    route: 'indicator_new',
    breadcrumbs: [{ name: 'Wskaźniki', path: '/wskazniki' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/wskaznik-dodaj',
    name: 'Dodaj Wskaźnik',
    Component: EditIndicator,
    route: 'indicator_new',
    breadcrumbs: [{ name: 'Wskaźniki', path: '/wskazniki' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/wskazniki/:id/(tab)?/:goto_tab?',
    name: 'Edytuj Wskaźnik',
    Component: EditIndicator,
    route: 'indicator',
    breadcrumbs: [{ name: 'Wskaźniki', path: '/wskazniki' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },

  {
    path: '/uzytkownicy/:id/(tab)?/:goto_tab?',
    name: 'Edytuj Użytkownika',
    Component: CurrentUser,
    route: 'user',
    breadcrumbs: [{ name: 'Użytkownicy', path: '/uzytkownicy' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      ORGANIZATION_EXTERNAL_COLLABORATOR,
      PROGRAM_COORDINATOR,
      PROGRAM_GUARDIAN,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
      INSTRUCTOR,
    ],
  },
  {
    path: '/uzytkownicy-dodaj',
    name: 'Dodaj Użytkownika',
    Component: AddUser,
    route: 'user',
    roles: [COORDINATOR, ORGANIZATION_ADMINISTRATOR],
    breadcrumbs: [{ name: 'Użytkownicy', path: '/uzytkownicy' }, { self: true }],
  },
  {
    path: '/organizacje/:id/(tab)?/:goto_tab?',
    name: 'Edytuj Organizacje',
    Component: OrganizationTabs,
    route: 'organization_edit',
    breadcrumbs: [{ name: 'Organizacje', path: '/organizacje' }],
  },
  {
    path: '/organizacje-dodaj',
    name: 'Dodaj Organizację',
    Component: CurrentOrganization,
    route: 'organization_new',
    roles: [COORDINATOR],
    breadcrumbs: [{ name: 'Organizacje', path: '/organizacje' }, { self: true }],
  },
  {
    path: '/programy/:id/(tab)?/:goto_tab?',
    name: 'Edytuj Program',
    Component: ProgramTabs,
    route: 'programs',
    breadcrumbs: [{ name: 'Programy', path: '/programy' }],
  },
  {
    path: '/programy-dodaj',
    name: 'Dodaj Program',
    Component: ProgramAdd,
    route: 'programs',
    breadcrumbs: [{ name: 'Programy', path: '/programy' }, { self: true }],
    roles: [COORDINATOR],
  },
  {
    path: '/zadania/tabs/goto/(tab)?/:goto_tab?',
    name: 'Zadania',
    Component: Tasks,
    route: 'tasks_tabs',
    breadcrumbs: [{ name: 'Zadania', path: '/zadania' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
      LECTURER_SENSITIVE_DATA,
      LECTURER,
    ],
  },
  {
    path: '/zadania/:id/(tab)?/:goto_tab?',
    name: 'Edytuj zadanie',
    Component: Task,
    route: 'tasks',
    breadcrumbs: [{ name: 'Zadania', path: '/zadania' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/zadania-dodaj/program/:program_id',
    name: 'Dodaj zadanie',
    Component: TaskAdd,
    route: 'tasks',
    breadcrumbs: [{ name: 'Zadania', path: '/zadania' }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/zadania-dodaj',
    name: 'Dodaj zadanie',
    Component: TaskAdd,
    route: 'tasks',
    breadcrumbs: [{ name: 'Zadania', path: '/zadania' }, { self: true }],
    roles: [
      COORDINATOR,
      ORGANIZATION_ADMINISTRATOR,
      ORGANIZATION_EMPLOYEE,
      PROGRAM_GUARDIAN,
      PROGRAM_COORDINATOR,
      TASK_COORDINATOR,
    ],
  },
  {
    path: '/wiadomosci/:id',
    name: 'Wiadomość',
    Component: MessagesSingle,
    route: 'messages',
    breadcrumbs: [{ name: 'Wiadomości', path: '/wiadomosci' }],
  },
  {
    path: '/wiadomosci',
    name: 'Wiadomości',
    Component: MessagesList,
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/wiadomosci-dodaj/(uzytkownik)?/:user_id?',
    name: 'Stwórz wątek',
    Component: AddMessages,
    route: 'messages',
    breadcrumbs: [{ name: 'Wiadomości', path: '/wiadomosci' }, { self: true }],
  },
  {
    path: '/przewodnik',
    name: 'Przewodnik',
    Component: Guide,
    route: 'dashboard',
    breadcrumbs: [{ self: true }],
  },
  {
    path: '/kontakt-z-administratorem',
    name: 'Kontakt z administratorem',
    Component: ContactAdmin,
    route: 'dashboard',
    breadcrumbs: [{ self: true }],
  },
];
