// Elements
import { Grid } from '@mui/material';

// Stlyes
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;
