export const answers = [
  {
    id: 1,
    odp: 'zdecydowanie nie',
    value: 1,
  },
  {
    id: 2,
    odp: 'nie',
    value: 2,
  },
  {
    id: 3,
    odp: 'raczej nie',
    value: 3,
  },
  {
    id: 4,
    odp: 'raczej tak',
    value: 4,
  },
  {
    id: 5,
    odp: 'tak',
    value: 5,
  },
  {
    id: 6,
    odp: 'zdecydowanie tak',
    value: 6,
  },
];

export const ageRange = [
  // {
  //   value: 'CHILD_EVALUATION_QUESTIONNAIRE_0_6',
  //   name: '0-6',
  // },
  {
    value: 'CHILD_EVALUATION_QUESTIONNAIRE_6_12',
    name: '6-12',
  },
  {
    value: 'CHILD_EVALUATION_QUESTIONNAIRE_13_18',
    name: '13-18',
  },
];

export const surveysQuestions = {
  CHILD_EVALUATION_QUESTIONNAIRE_0_6: [
    {
      id: '10',
      label:
        'Wersja dla prowadzącego zajęcia. Poczucie skuteczności w obszarze kompetencji rodzicielskich.',
      questions: [
        {
          id: '145',
          label: 'Sądzę, że trafnie odczytuje potrzeby swojego dziecka. ',
          value: null,
          order: '1',
          reverseScoring: '0',
        },
        {
          id: '146',
          label: 'Mam wrażenie, że rozumie wpływ wieku dziecka na jego zachowanie.  ',
          value: null,
          order: '2',
          reverseScoring: '0',
        },
        {
          id: '147',
          label: 'Prawidłowo zaspokaja potrzeby fizyczne swojego dziecka. ',
          value: null,
          order: '3',
          reverseScoring: '0',
        },
        {
          id: '148',
          label: 'Prawidłowo zaspokaja potrzeby psychiczne swojego dziecka.',
          value: null,
          order: '4',
          reverseScoring: '0',
        },
        {
          id: '149',
          label: 'Uważam, że ma dobry kontakt z dzieckiem.  ',
          value: null,
          order: '5',
          reverseScoring: '0',
        },
        {
          id: '150',
          label: 'Uważam, że dobrze radzi sobie ze stresem związanym w wychowaniem dziecka. ',
          value: null,
          order: '6',
          reverseScoring: '0',
        },
        {
          id: '151',
          label: 'Chętnie aktywnie spędza czas z dzieckiem. ',
          value: null,
          order: '7',
          reverseScoring: '0',
        },
        {
          id: '152',
          label: 'Bywa, że ignoruje dziecko, nie zwraca na nie uwagi. ',
          value: null,
          order: '8',
          reverseScoring: '1',
        },
        {
          id: '153',
          label: 'Zdarza się, że odzywa się do dziecka w sposób, który narusza jego/jej godność. ',
          value: null,
          order: '9',
          reverseScoring: '1',
        },
        {
          id: '154',
          label: 'Często zostawia zbyt wiele swobody dziecku.  ',
          value: null,
          order: '10',
          reverseScoring: '1',
        },
        {
          id: '155',
          label: 'Stawia dziecku wymagania nieadekwatne do jego wieku.  ',
          value: null,
          order: '11',
          reverseScoring: '1',
        },
        {
          id: '156',
          label: 'Nie pozwala dziecku na samodzielność.  ',
          value: null,
          order: '12',
          reverseScoring: '1',
        },
        {
          id: '157',
          label: 'Dąży do tego, żeby we wszystkim decydować za dziecko. ',
          value: null,
          order: '13',
          reverseScoring: '1',
        },
        {
          id: '158',
          label: 'Do dziecka odnosi się życzliwie i z szacunkiem.  ',
          value: null,
          order: '14',
          reverseScoring: '0',
        },
        {
          id: '159',
          label: 'Pozwala dziecku na autonomię (swobodę) odpowiednią do jego wieku. ',
          value: null,
          order: '15',
          reverseScoring: '0',
        },
        {
          id: '160',
          label: 'W stosunku do dziecka jest cierpliwa/y.  ',
          value: null,
          order: '16',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '11',
      label:
        'Wersja dla rodzica/opiekuna dziecka w wieku 0-6 lat. Poczucie skuteczności w obszarze kompetencji rodzicielskich.',
      questions: [
        {
          id: '161',
          label: 'Czasem nie wiem, o co mojemu dziecku chodzi. ',
          value: null,
          order: '1',
          reverseScoring: '1',
        },
        {
          id: '162',
          label: 'Czasem trudno mi określić, czego moje dziecko potrzebuje.  ',
          value: null,
          order: '2',
          reverseScoring: '1',
        },
        {
          id: '163',
          label: 'Czasem trudno mi zdecydować, co robić z dzieckiem w wolnym czasie.   ',
          value: null,
          order: '3',
          reverseScoring: '1',
        },
        {
          id: '164',
          label: 'Gdy dziecko bardzo czegoś chce, zawsze się zgadzam. ',
          value: null,
          order: '4',
          reverseScoring: '1',
        },
        {
          id: '165',
          label: 'Często dla swojego spokoju zgadzam na to, co dziecko chce. ',
          value: null,
          order: '5',
          reverseScoring: '1',
        },
        {
          id: '166',
          label: 'Zdarza się, że denerwuje mnie zachowanie mojego dziecka. ',
          value: null,
          order: '6',
          reverseScoring: '1',
        },
        {
          id: '167',
          label: 'Często moje dziecko mnie męczy. ',
          value: null,
          order: '7',
          reverseScoring: '1',
        },
      ],
    },
  ],

  CHILD_EVALUATION_QUESTIONNAIRE_6_12: [
    {
      id: '1',
      label: 'Obszar rozwoju poznawczego – pamięć, koncentracja, myślenie.',
      questions: [
        {
          id: '1',
          label: 'Ma trudność z rozumieniem poleceń czy instrukcji nawet prostych zadań.',
          value: null,
          order: '1',
          reverseScoring: '1',
        },
        {
          id: '2',
          label: 'W przypadku trudniejszych zadań ma problem z rozumieniem poleceń czy instrukcji.',
          value: null,
          order: '2',
          reverseScoring: '1',
        },
        {
          id: '3',
          label: 'Powtarza w zadaniach te same błędy.',
          value: null,
          order: '3',
          reverseScoring: '1',
        },
        {
          id: '4',
          label: 'Potrafi uczyć się, obserwując innych.',
          value: null,
          order: '4',
          reverseScoring: '0',
        },
        {
          id: '5',
          label: 'Ma problem z zapamiętywaniem informacji.',
          value: null,
          order: '5',
          reverseScoring: '1',
        },
        {
          id: '6',
          label: 'Potrafi utrzymać uwagę na zadaniu, które lubi wykonywać.',
          value: null,
          order: '6',
          reverseScoring: '0',
        },
        {
          id: '7',
          label:
            'Potrafi utrzymywać uwagę na zadaniu nawet pomimo małej atrakcyjności/monotonii zadania.',
          value: null,
          order: '7',
          reverseScoring: '0',
        },
        {
          id: '8',
          label: 'Łatwo się rozprasza.',
          value: null,
          order: '8',
          reverseScoring: '1',
        },
        {
          id: '9',
          label: 'Często zajmuje się czymś innym niż powinien/powinna.',
          value: null,
          order: '9',
          reverseScoring: '1',
        },
        {
          id: '10',
          label: 'Często zapomina, o co był/a proszony/a.',
          value: null,
          order: '10',
          reverseScoring: '1',
        },
      ],
    },
    {
      id: '2',
      label: 'Obszar rozwoju poznawczego – wytrwałość, planowanie, motywacja wewnętrzna',
      questions: [
        {
          id: '11',
          label: 'Łatwo się zniechęca lub nudzi tym, co robi.',
          value: null,
          order: '11',
          reverseScoring: '1',
        },
        {
          id: '12',
          label: 'Często porzuca i nie kończy rozpoczętych zadań ',
          value: null,
          order: '12',
          reverseScoring: '1',
        },
        {
          id: '13',
          label: 'Żeby coś dokończył/a wymaga napominania.',
          value: null,
          order: '13',
          reverseScoring: '1',
        },
        {
          id: '14',
          label: 'W pracy jest niesamodzielny/a, czeka na dokładną instrukcję.',
          value: null,
          order: '14',
          reverseScoring: '1',
        },
        {
          id: '15',
          label: 'Powierzone zadania wykonuje mechanicznie, bez zaangażowania.',
          value: null,
          order: '15',
          reverseScoring: '1',
        },
        {
          id: '16',
          label: 'Realizuje zadania przy minimum wysiłku i zaangażowania.',
          value: null,
          order: '16',
          reverseScoring: '1',
        },
        {
          id: '17',
          label: 'Ma sprecyzowane plany na przyszłość.',
          value: null,
          order: '17',
          reverseScoring: '0',
        },
        {
          id: '18',
          label: 'Wie, co chciałby/chciałaby robić w życiu.',
          value: null,
          order: '18',
          reverseScoring: '0',
        },
        {
          id: '19',
          label: 'Potrafi określić, co lubi robić.',
          value: null,
          order: '19',
          reverseScoring: '0',
        },
        {
          id: '20',
          label: 'Ma jasno określone zainteresowania.',
          value: null,
          order: '20',
          reverseScoring: '0',
        },
        {
          id: '21',
          label: 'Brakuje mu cierpliwości w realizacji planów.',
          value: null,
          order: '21',
          reverseScoring: '1',
        },
        {
          id: '22',
          label: 'Nie potrafi zaczekać na nagrodę.',
          value: null,
          order: '22',
          reverseScoring: '0',
        },
        {
          id: '23',
          label: 'Brak szybkiej nagrody, frustruje go/ją i zniechęca do działania.',
          value: null,
          order: '23',
          reverseScoring: '1',
        },
      ],
    },
    {
      id: '3',
      label: 'Poczucie skuteczności własnego działania ',
      questions: [
        {
          id: '24',
          label: 'Sprawia wrażenie, że wierzy w siebie.',
          value: null,
          order: '24',
          reverseScoring: '0',
        },
        {
          id: '25',
          label: 'Z reguły ma optymistyczne nastawianie, gdy zaczyna jakąś aktywność.',
          value: null,
          order: '25',
          reverseScoring: '0',
        },
        {
          id: '26',
          label: 'Trudności mobilizują ją/go do działania.',
          value: null,
          order: '26',
          reverseScoring: '0',
        },
        {
          id: '27',
          label: 'Gdy pojawiają się trudności, szybko się zniechęca.',
          value: null,
          order: '27',
          reverseScoring: '1',
        },
        {
          id: '28',
          label: 'Z góry zakłada, że sobie nie da rady.',
          value: null,
          order: '28',
          reverseScoring: '1',
        },
        {
          id: '29',
          label: 'Swoje sukcesy tłumaczy jako przypadkowe albo spowodowane przez innych ludzi.',
          value: null,
          order: '29',
          reverseScoring: '1',
        },
        {
          id: '30',
          label: 'Sukcesy bardzo zachęcają go do dalszego działania.',
          value: null,
          order: '30',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '4',
      label: 'Obszar rozwoju emocjonalnego',
      questions: [
        {
          id: '31',
          label: 'Potrafi przeżywać radość i cieszyć się.',
          value: null,
          order: '31',
          reverseScoring: '0',
        },
        {
          id: '32',
          label: 'Potrafi nazywać to, co czuje.',
          value: null,
          order: '32',
          reverseScoring: '0',
        },
        {
          id: '33',
          label: 'Potrafi zakomunikować o tym, co czuje.',
          value: null,
          order: '33',
          reverseScoring: '0',
        },
        {
          id: '34',
          label: 'Często reaguje emocjonalnie nieadekwatnie (np. zbyt silnie) do sytuacji.',
          value: null,
          order: '34',
          reverseScoring: '1',
        },
        {
          id: '35',
          label: 'Często nie potrafi zapanować nad złością.',
          value: null,
          order: '35',
          reverseScoring: '1',
        },
        {
          id: '36',
          label: 'Słabo radzi sobie ze stresem/frustracją.',
          value: null,
          order: '36',
          reverseScoring: '1',
        },
        {
          id: '37',
          label: 'Często przeżywa huśtawkę emocji.',
          value: null,
          order: '37',
          reverseScoring: '1',
        },
        {
          id: '38',
          label: 'Sprawia wrażenie, że jego/jej emocje są zamrożone.',
          value: null,
          order: '38',
          reverseScoring: '1',
        },
        {
          id: '39',
          label: 'Najczęściej trudno jest ocenić, jakie w danej chwili przeżywa emocje.',
          value: null,
          order: '39',
          reverseScoring: '1',
        },
      ],
    },
    {
      id: '5',
      label: 'Obszar rozwoju społecznego – kooperacja i współpraca',
      questions: [
        {
          id: '40',
          label: 'Okazuje współczucie innym uczestnikom grupy/zajęć.',
          value: null,
          order: '40',
          reverseScoring: '0',
        },
        {
          id: '41',
          label: 'Przeżywa, gdy komuś dzieje się krzywda.',
          value: null,
          order: '41',
          reverseScoring: '0',
        },
        {
          id: '42',
          label: 'Stara się pomagać innym osobom w grupie.',
          value: null,
          order: '42',
          reverseScoring: '0',
        },
        {
          id: '43',
          label: 'Nawet poproszony/a niechętnie pomaga innym dzieciom w grupie.',
          value: null,
          order: '43',
          reverseScoring: '1',
        },
        {
          id: '44',
          label: 'Rówieśników trzyma na dystans.',
          value: null,
          order: '44',
          reverseScoring: '1',
        },
        {
          id: '45',
          label: 'Niechętnie angażuje się w działania grupy.',
          value: null,
          order: '45',
          reverseScoring: '1',
        },
        {
          id: '46',
          label: 'W grupie trzyma się na uboczu.',
          value: null,
          order: '46',
          reverseScoring: '1',
        },
        {
          id: '47',
          label: 'Dąży do spokojnego rozwiązywania konfliktów w grupie.',
          value: null,
          order: '47',
          reverseScoring: '0',
        },
        {
          id: '48',
          label: 'Często wszczyna kłótnie.',
          value: null,
          order: '48',
          reverseScoring: '1',
        },
        {
          id: '49',
          label: 'Jest odrzucany/a przez rówieśników i wyłączany z grupy.',
          value: null,
          order: '49',
          reverseScoring: '1',
        },
        {
          id: '50',
          label: 'W grupie chętnie staje na jej czele.',
          value: null,
          order: '50',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '6',
      label: 'Obszar zachowań problemowych.',
      questions: [
        {
          id: '51',
          label: 'Swoim zachowaniem dezorganizuje prace w grupie.',
          value: null,
          order: '51',
          reverseScoring: '0',
        },
        {
          id: '52',
          label: 'Biega po sali w trakcie zajęć.',
          value: null,
          order: '52',
          reverseScoring: '0',
        },
        {
          id: '53',
          label: 'Odwraca uwagę innych dzieci lub wychowawcy od zadań/celu spotkania.',
          value: null,
          order: '53',
          reverseScoring: '0',
        },
        {
          id: '54',
          label: 'Nadmiernie skupia na sobie uwagę grupy.',
          value: null,
          order: '54',
          reverseScoring: '0',
        },
        {
          id: '55',
          label: 'Zdarza się, że niszczy różne przedmioty.',
          value: null,
          order: '55',
          reverseScoring: '0',
        },
        {
          id: '56',
          label: 'Bywa, że traci panowanie nad sobą.',
          value: null,
          order: '56',
          reverseScoring: '0',
        },
        {
          id: '57',
          label: 'Stosuje wobec innych przemoc fizyczną.',
          value: null,
          order: '57',
          reverseScoring: '0',
        },
        {
          id: '58',
          label: 'Stosuje wobec innych przemoc inną niż fizyczną, np. obraża, wyzywa.',
          value: null,
          order: '58',
          reverseScoring: '0',
        },
        {
          id: '59',
          label: 'Zaczepia, prowokuje kłótnie z innymi osobami w grupie.',
          value: null,
          order: '59',
          reverseScoring: '0',
        },
        {
          id: '60',
          label: 'Często ignoruje lub kwestionuje polecenia i prośby.',
          value: null,
          order: '60',
          reverseScoring: '0',
        },
        {
          id: '61',
          label: 'Wyrządza sobie w zamierzony sposób krzywdę.',
          value: null,
          order: '61',
          reverseScoring: '0',
        },
        {
          id: '62',
          label: 'Najczęściej działa impulsywnie i pod wpływem chwili.',
          value: null,
          order: '62',
          reverseScoring: '0',
        },
        {
          id: '63',
          label: 'Bywa, że jego/jej zachowanie tworzy niebezpieczeństwo dla innych.',
          value: null,
          order: '63',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '7',
      label: 'Obszar rozwoju społecznego – komunikacja w grupie i rozumienie norm',
      questions: [
        {
          id: '64',
          label: 'Stara się być miły/a  w stosunku do rówieśników.',
          value: null,
          order: '64',
          reverseScoring: '0',
        },
        {
          id: '65',
          label: 'Jest niepopularny/a wśród rówieśników.',
          value: null,
          order: '65',
          reverseScoring: '1',
        },
        {
          id: '66',
          label: 'Jest lubiany/a przez większość rówieśników.',
          value: null,
          order: '66',
          reverseScoring: '0',
        },
        {
          id: '67',
          label: 'Dostosowuje swoje zachowanie do sytuacji.',
          value: null,
          order: '67',
          reverseScoring: '0',
        },
        {
          id: '68',
          label: 'W zrozumiały sposób potrafi formułować swoje zdanie.',
          value: null,
          order: '68',
          reverseScoring: '0',
        },
        {
          id: '69',
          label: 'Potrafi odczytywać niewerbalne sygnały płynące z otoczenia.',
          value: null,
          order: '69',
          reverseScoring: '0',
        },
        {
          id: '70',
          label: 'Z reguły rozumie żart czy ironię.',
          value: null,
          order: '70',
          reverseScoring: '0',
        },
        {
          id: '71',
          label: 'Potrafi odczytywać emocje innych osób.',
          value: null,
          order: '71',
          reverseScoring: '0',
        },
        {
          id: '72',
          label: 'Potrafi zrozumieć zasady panujące w grupie.',
          value: null,
          order: '72',
          reverseScoring: '0',
        },
        {
          id: '73',
          label: 'Potrafi dostosować się do zasad panujących w grupie rówieśniczej.',
          value: null,
          order: '73',
          reverseScoring: '0',
        },
        {
          id: '74',
          label: 'Myślę, że rozumie sens (źródło) norm panujących na zajęciach.',
          value: null,
          order: '74',
          reverseScoring: '0',
        },
      ],
    },
  ],

  CHILD_EVALUATION_QUESTIONNAIRE_13_18: [
    {
      id: '8',
      label: 'Obszar rozwoju poznawczego ',
      questions: [
        {
          id: '75',
          label: 'Łatwo się rozprasza.',
          value: null,
          order: '1',
          reverseScoring: '1',
        },
        {
          id: '76',
          label: 'Często zajmuje się czymś innym niż powinien/powinna.',
          value: null,
          order: '2',
          reverseScoring: '1',
        },
        {
          id: '77',
          label: 'Często zapomina, o co był/a proszony/a.',
          value: null,
          order: '3',
          reverseScoring: '1',
        },
        {
          id: '78',
          label: 'Łatwo się zniechęca lub nudzi tym, co robi.',
          value: null,
          order: '4',
          reverseScoring: '1',
        },
        {
          id: '79',
          label: 'Często porzuca i nie kończy rozpoczętych zadań ',
          value: null,
          order: '5',
          reverseScoring: '1',
        },
        {
          id: '80',
          label: 'Żeby coś dokończył/a wymaga napominania.',
          value: null,
          order: '6',
          reverseScoring: '1',
        },
        {
          id: '81',
          label: 'W pracy jest niesamodzielny/a, czeka na dokładną instrukcję.',
          value: null,
          order: '7',
          reverseScoring: '1',
        },
        {
          id: '82',
          label: 'Powierzone zadania wykonuje mechanicznie, bez zaangażowania.',
          value: null,
          order: '8',
          reverseScoring: '1',
        },
        {
          id: '83',
          label: 'Realizuje zadania przy minimum wysiłku i zaangażowania.',
          value: null,
          order: '9',
          reverseScoring: '1',
        },
        {
          id: '84',
          label: 'Ma sprecyzowane plany na przyszłość.',
          value: null,
          order: '10',
          reverseScoring: '0',
        },
        {
          id: '85',
          label: 'Wie, co chciałby/chciałaby robić w życiu.',
          value: null,
          order: '11',
          reverseScoring: '0',
        },
        {
          id: '86',
          label: 'Potrafi określić, co lubi robić.',
          value: null,
          order: '12',
          reverseScoring: '0',
        },
        {
          id: '87',
          label: 'Ma jasno określone zainteresowania.',
          value: null,
          order: '13',
          reverseScoring: '0',
        },
        {
          id: '88',
          label: 'Brakuje mu cierpliwości w realizacji planów.',
          value: null,
          order: '14',
          reverseScoring: '1',
        },
        {
          id: '89',
          label: 'Nie potrafi zaczekać na nagrodę.',
          value: null,
          order: '15',
          reverseScoring: '1',
        },
        {
          id: '90',
          label: 'Brak szybkiej nagrody, frustruje go/ją i zniechęca do działania.',
          value: null,
          order: '16',
          reverseScoring: '1',
        },
      ],
    },
    {
      id: '3',
      label: 'Poczucie skuteczności własnego działania ',
      questions: [
        {
          id: '91',
          label: 'Sprawia wrażenie, że wierzy w siebie.',
          value: null,
          order: '17',
          reverseScoring: '0',
        },
        {
          id: '92',
          label: 'Z reguły ma optymistyczne nastawianie, gdy zaczyna jakąś aktywność.',
          value: null,
          order: '18',
          reverseScoring: '0',
        },
        {
          id: '93',
          label: 'Trudności mobilizują ją/go do działania.',
          value: null,
          order: '19',
          reverseScoring: '0',
        },
        {
          id: '94',
          label: 'W większości sytuacji zachowuje się biernie.',
          value: null,
          order: '20',
          reverseScoring: '1',
        },
        {
          id: '95',
          label: 'Gdy pojawiają się trudności, szybko się zniechęca.',
          value: null,
          order: '21',
          reverseScoring: '1',
        },
        {
          id: '96',
          label: 'Z góry zakłada, że sobie nie da rady.',
          value: null,
          order: '22',
          reverseScoring: '1',
        },
        {
          id: '97',
          label: 'Swoje sukcesy tłumaczy jako przypadkowe albo spowodowane przez innych ludzi.',
          value: null,
          order: '23',
          reverseScoring: '1',
        },
        {
          id: '98',
          label: 'Sukcesy bardzo zachęcają go do dalszego działania.',
          value: null,
          order: '24',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '4',
      label: 'Obszar rozwoju emocjonalnego',
      questions: [
        {
          id: '99',
          label: 'Potrafi przeżywać radość i cieszyć się.',
          value: null,
          order: '25',
          reverseScoring: '0',
        },
        {
          id: '100',
          label: 'Potrafi nazywać to, co czuje.',
          value: null,
          order: '26',
          reverseScoring: '0',
        },
        {
          id: '101',
          label: 'Potrafi zakomunikować o tym, co czuje.',
          value: null,
          order: '27',
          reverseScoring: '0',
        },
        {
          id: '102',
          label: 'Często reaguje emocjonalnie nieadekwatnie (np. zbyt silnie) do sytuacji.',
          value: null,
          order: '28',
          reverseScoring: '1',
        },
        {
          id: '103',
          label: 'Często nie potrafi zapanować nad złością.',
          value: null,
          order: '29',
          reverseScoring: '1',
        },

        {
          id: '104',
          label: 'Słabo radzi sobie ze stresem/frustracją.',
          value: null,
          order: '30',
          reverseScoring: '1',
        },
        {
          id: '105',
          label: 'Często przeżywa huśtawkę emocji.',
          value: null,
          order: '31',
          reverseScoring: '1',
        },
        {
          id: '106',
          label: 'Sprawia wrażenie, że jego/jej emocje są zamrożone.',
          value: null,
          order: '32',
          reverseScoring: '1',
        },
        {
          id: '107',
          label: 'Najczęściej trudno jest ocenić, jakie w danej chwili przeżywa emocje.',
          value: null,
          order: '33',
          reverseScoring: '1',
        },
      ],
    },
    {
      id: '5',
      label: 'Obszar rozwoju społecznego – kooperacja i współpraca',
      questions: [
        {
          id: '108',
          label: 'Okazuje współczucie innym uczestnikom grupy/zajęć.',
          value: null,
          order: '34',
          reverseScoring: '0',
        },
        {
          id: '109',
          label: 'Przeżywa, gdy komuś dzieje się krzywda.',
          value: null,
          order: '35',
          reverseScoring: '0',
        },
        {
          id: '110',
          label: 'Stara się pomagać innym osobom w grupie.',
          value: null,
          order: '36',
          reverseScoring: '0',
        },
        {
          id: '111',
          label: 'Nawet poproszony/a niechętnie pomaga innym osobom w grupie.',
          value: null,
          order: '37',
          reverseScoring: '1',
        },
        {
          id: '112',
          label: 'Rówieśników trzyma na dystans.',
          value: null,
          order: '38',
          reverseScoring: '1',
        },
        {
          id: '113',
          label: 'Niechętnie angażuje się w działania grupy.',
          value: null,
          order: '39',
          reverseScoring: '1',
        },
        {
          id: '114',
          label: 'W grupie trzyma się na uboczu.',
          value: null,
          order: '40',
          reverseScoring: '1',
        },
        {
          id: '115',
          label: 'Dąży do spokojnego rozwiązywania konfliktów w grupie.',
          value: null,
          order: '41',
          reverseScoring: '0',
        },
        {
          id: '116',
          label: 'Często wszczyna kłótnie.',
          value: null,
          order: '42',
          reverseScoring: '1',
        },
        {
          id: '117',
          label: 'Jest odrzucany/a przez rówieśników i wyłączany z grupy.',
          value: null,
          order: '43',
          reverseScoring: '1',
        },
        {
          id: '118',
          label: 'W grupie chętnie staje na jej czele.',
          value: null,
          order: '44',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '9',
      label: 'Skala zachowań problemowych',
      questions: [
        {
          id: '119',
          label: 'Swoim zachowaniem dezorganizuje prace w grupie.',
          value: null,
          order: '45',
          reverseScoring: '0',
        },
        {
          id: '120',
          label: 'Biega po sali w trakcie zajęć.',
          value: null,
          order: '46',
          reverseScoring: '0',
        },
        {
          id: '121',
          label:
            'Odwraca uwagę innych dzieci/uczestników grupy lub wychowawcy od zadań/celu spotkania.',
          value: null,
          order: '47',
          reverseScoring: '0',
        },
        {
          id: '122',
          label: 'Nadmiernie skupia na sobie uwagę grupy.',
          value: null,
          order: '48',
          reverseScoring: '0',
        },
        {
          id: '123',
          label: 'Zdarza się, że niszczy różne przedmioty.',
          value: null,
          order: '49',
          reverseScoring: '0',
        },
        {
          id: '124',
          label: 'Bywa, że traci panowanie nad sobą.',
          value: null,
          order: '50',
          reverseScoring: '0',
        },
        {
          id: '125',
          label: 'Stosuje wobec innych przemoc fizyczną.',
          value: null,
          order: '51',
          reverseScoring: '0',
        },
        {
          id: '126',
          label: 'Stosuje wobec innych przemoc inną niż fizyczną, np. obraża, wyzywa.',
          value: null,
          order: '52',
          reverseScoring: '0',
        },
        {
          id: '127',
          label: 'Zaczepia, prowokuje kłótnie z innymi osobami w grupie.',
          value: null,
          order: '53',
          reverseScoring: '0',
        },
        {
          id: '128',
          label: 'Często ignoruje lub kwestionuje polecenia i prośby.',
          value: null,
          order: '54',
          reverseScoring: '0',
        },
        {
          id: '129',
          label: 'Wyrządza sobie w zamierzony sposób krzywdę.',
          value: null,
          order: '55',
          reverseScoring: '0',
        },
        {
          id: '130',
          label: 'Najczęściej działa impulsywnie i pod wpływem chwili.',
          value: null,
          order: '56',
          reverseScoring: '0',
        },
        {
          id: '131',
          label: 'Bywa, że jego/jej zachowanie tworzy niebezpieczeństwo dla innych.',
          value: null,
          order: '57',
          reverseScoring: '0',
        },
      ],
    },
    {
      id: '7',
      label: 'Obszar rozwoju społecznego – komunikacja w grupie i rozumienie norm',
      questions: [
        {
          id: '132',
          label: 'Stara się być miły/a  w stosunku do rówieśników.',
          value: null,
          order: '58',
          reverseScoring: '0',
        },
        {
          id: '133',
          label: 'Jest niepopularny/a wśród rówieśników.',
          value: null,
          order: '59',
          reverseScoring: '1',
        },
        {
          id: '134',
          label: 'Jest lubiany/a przez większość rówieśników.',
          value: null,
          order: '60',
          reverseScoring: '0',
        },
        {
          id: '135',
          label: 'Dostosowuje swoje zachowanie do sytuacji.',
          value: null,
          order: '61',
          reverseScoring: '0',
        },
        {
          id: '136',
          label: 'W zrozumiały sposób potrafi formułować swoje zdanie.',
          value: null,
          order: '62',
          reverseScoring: '0',
        },
        {
          id: '137',
          label: 'Potrafi odczytywać niewerbalne sygnały płynące z otoczenia.',
          value: null,
          order: '63',
          reverseScoring: '0',
        },
        {
          id: '138',
          label: 'Z reguły rozumie żart czy ironię.',
          value: null,
          order: '64',
          reverseScoring: '0',
        },
        {
          id: '139',
          label: 'Potrafi odczytywać emocje innych osób.',
          value: null,
          order: '65',
          reverseScoring: '0',
        },
        {
          id: '140',
          label: 'Potrafi zrozumieć zasady panujące w grupie.',
          value: null,
          order: '66',
          reverseScoring: '0',
        },
        {
          id: '141',
          label: 'Potrafi dostosować się do zasad panujących w grupie rówieśniczej.',
          value: null,
          order: '67',
          reverseScoring: '0',
        },
        {
          id: '142',
          label: 'Myślę, że rozumie sens (źródło) norm panujących na zajęciach.',
          value: null,
          order: '68',
          reverseScoring: '0',
        },
        {
          id: '143',
          label: 'Unika kontaktów z osobami płci przeciwnej.',
          value: null,
          order: '69',
          reverseScoring: '1',
        },
        {
          id: '144',
          label: 'W kontaktach z osobami płci przeciwnej nie stawia granic.',
          value: null,
          order: '70',
          reverseScoring: '1',
        },
        {
          id: '145',
          label: 'Wobec innych ludzi jest raczej bezkrytyczny.',
          value: null,
          order: '71',
          reverseScoring: '1',
        },
        {
          id: '146',
          label: 'Pozwala się traktować innym w sposób dla niego/dla niej krzywdzący.',
          value: null,
          order: '72',
          reverseScoring: '1',
        },
      ],
    },
  ],
};

export const surveysScales = {
  1: {
    male: [0, 2.79, 4.47],
    female: [0, 3.1, 4.78],
  },
  2: {
    male: [0, 2.6, 4.16],
    female: [0, 2.98, 4.45],
  },
  3: {
    male: [0, 2.73, 4.18],
    female: [0, 2.95, 4.42],
  },
  4: {
    male: [0, 3.16, 4.5],
    female: [0, 3.42, 4.76],
  },
  5: {
    male: [0, 3.43, 4.64],
    female: [0, 3.58, 5.04],
  },
  6: {
    male: [0, 1.93, 3.84],
    female: [0, 1.48, 3.22],
  },
  7: {
    male: [0, 3.32, 4.64],
    female: [0, 3.65, 4.86],
  },
};
