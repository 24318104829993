// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// Component
const AutocompleteWithInput = ({ id, label, name, value, variant, data, onChange, disabled }) => (
  <Autocomplete
    id={id}
    freeSolo
    disabled={disabled}
    name={name}
    value={value}
    onChange={(_, newValue) => onChange(name, newValue)}
    onInputChange={(_, newValue) => onChange(name, newValue)}
    options={data.map((option) => option.name)}
    renderInput={(params) => (
      <TextField {...params} disabled={disabled} label={label} variant={variant} />
    )}
  />
);

AutocompleteWithInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

AutocompleteWithInput.defaultProps = {
  id: '',
  label: '',
  name: '',
  value: null,
  variant: '',
  data: [],
  disabled: null,
  onChange: null,
};

export default AutocompleteWithInput;
