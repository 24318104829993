import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import Heading from '@components/atoms/Heading/Heading';
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import {
  fetchSubjectList as fetchSubjectListService,
  saveSubject as saveSubjectService,
  deleteSubject as deleteSubjectService,
} from '@services/selectServices';
import { clearSubjectList as clearSubjectListAction } from '@actions/selectActions';
import { StyledTitle, StyledWrapper } from './SubjectSettings.styles';
import './SubjectSettings.scss';

const headCellsSubjects = [{ type: 'name', label: 'Nazwa' }];

const SubjectSettings = ({
  clearSubjectList,
  fetchSubjectsList,
  subjectList,
  saveSubject,
  deleteSubject,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState(null);

  const triggerFetchSubjects = useCallback(() => fetchSubjectsList(), [fetchSubjectsList]);

  useEffect(() => {
    clearSubjectList();
    triggerFetchSubjects();
  }, [triggerFetchSubjects]);

  const handleDeleteClick = (subject) => {
    setSelectedSubject(subject);
    setDeleteDialogOpen(true);
  };

  const handleEditClick = (subject) => {
    setSelectedSubject(subject);
    setEditDialogOpen(true);
  };

  const confirmDelete = () => {
    setDeleteDialogOpen(false);
    deleteSubject(selectedSubject.value);
  };

  const handleEditSave = () => {
    setEditDialogOpen(false);
    saveSubject(selectedSubject.value, selectedSubject);
  };

  return (
    <StyledWrapper className="subjects" container spacing={2}>
      <Grid item xs={10}>
        <StyledTitle>
          <Heading.Subtitle1>Przedmioty</Heading.Subtitle1>
        </StyledTitle>
        <TableTemplate
          headCells={headCellsSubjects}
          data={subjectList}
          noFilter
          rowActions={[
            {
              name: 'edytuj',
              action: handleEditClick,
            },
            {
              name: 'usuń',
              action: handleDeleteClick,
            },
          ]}
        />
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć przedmiot `{selectedSubject?.name}`?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Usuń
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit/Add Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>
          {selectedSubject?.value === 'new' ? 'Dodaj Przedmiot' : 'Edytuj Przedmiot'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Nazwa"
            fullWidth
            margin="normal"
            value={selectedSubject?.name || ''}
            onChange={(e) => setSelectedSubject({ ...selectedSubject, name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>

      {/* Floating Button for Adding New Subject */}
      <FloatingButton
        onClick={() => {
          setSelectedSubject({ value: 'new' });
          setEditDialogOpen(true);
        }}
      />
    </StyledWrapper>
  );
};

SubjectSettings.propTypes = {
  clearSubjectList: PropTypes.func,
  fetchSubjectsList: PropTypes.func,
  subjectList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  saveSubject: PropTypes.func,
  deleteSubject: PropTypes.func,
};

SubjectSettings.defaultProps = {
  clearSubjectList: null,
  fetchSubjectsList: null,
  subjectList: [],
  saveSubject: null,
  deleteSubject: null,
};

const mapStateToProps = ({ selectReducer }) => ({
  subjectList: selectReducer.subjectList,
});

const mapDispatchToProps = (dispatch) => ({
  clearSubjectList: () => dispatch(clearSubjectListAction()),
  fetchSubjectsList: () => dispatch(fetchSubjectListService()),
  saveSubject: (id, data) => dispatch(saveSubjectService(id, data)),
  deleteSubject: (id) => dispatch(deleteSubjectService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubjectSettings);
