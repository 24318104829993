// Elements
import { Button as MaterialButton } from '@mui/material';

// Styles
import styled, { css } from 'styled-components';

export const StyledMaterialButton = styled(MaterialButton)`
  && {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.body2};
    background-color: ${({ bgColor, theme }) => (bgColor ? theme.color.lightGray : null)};
    text-transform: none;
    ${({ color, theme }) => {
      switch (color) {
        case 'secondary':
          return css`
            color: ${theme.color.red};
            border-color: ${theme.color.red};
          `;
        case 'primary':
          return css`
            color: ${theme.color.blue};
            border-color: ${theme.color.blue};
          `;

        default:
          return css`
            color: ${theme.color.darkGray};
            border-color: ${theme.color.darkGray};
          `;
      }
    }}

    &.MuiButton-contained {
      background-color: ${({ theme }) => theme.color.darkGray};
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
