// React
import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { StyledButton } from './AddReport.styles';

// Funkcja do eksportu danych do Excela
const exportToExcel = (data, columns, filename) => {
  // Przekształcanie danych do odpowiedniego formatu
  const formattedData = data.map((row) => {
    const formattedRow = {};
    columns.forEach((col) => {
      formattedRow[col.title] = row[col.name];
    });
    return formattedRow;
  });

  // Tworzenie arkusza
  const ws = XLSX.utils.json_to_sheet(formattedData);

  // Tworzenie skoroszytu
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Raport');

  // Eksportowanie pliku Excel
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const dataBlob = new Blob([excelBuffer], { type: fileType });
  saveAs(dataBlob, `${filename}.xlsx`);
};

// Component
const ReportExcel = ({ data, filename }) => {
  const handleDownload = () => {
    if (data && data.data && data.columns) {
      exportToExcel(data.data, data.columns, filename || 'Raport');
    }
  };

  return (
    <Grid item xs={12}>
      <StyledButton variant="outlined" onClick={handleDownload}>
        Pobierz raport
      </StyledButton>
    </Grid>
  );
};

ReportExcel.propTypes = {
  data: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  filename: PropTypes.string,
};

ReportExcel.defaultProps = {
  filename: 'Raport',
};

export default ReportExcel;

// // React
// import React from 'react';
// import PropTypes from 'prop-types';

// import ReactExport from 'react-data-export';

// import { Grid } from '@mui/material';
// import { StyledButton } from './AddReport.styles';

// // Component
// const ReportExcel = ({ data, filename }) => {
//   const { ExcelFile } = ReactExport;
//   const { ExcelSheet } = ReactExport.ExcelFile;
//   const { ExcelColumn } = ReactExport.ExcelFile;

//   return (
//     <ExcelFile
//       hideElement
//       filename={filename}
//       element={
//         <Grid item xs={12}>
//           <StyledButton variant="outlined">Pobierz raport</StyledButton>
//         </Grid>
//       }
//     >
//       <ExcelSheet data={data.data} name="Raport">
//         {data.columns.map((el) => (
//           <ExcelColumn label={el.title} value={el.name} key={el.name} />
//         ))}
//       </ExcelSheet>
//     </ExcelFile>
//   );
// };

// ReportExcel.propTypes = {
//   data: PropTypes.shape({
//     columns: PropTypes.arrayOf(PropTypes.any),
//     data: PropTypes.arrayOf(PropTypes.any),
//   }),
//   filename: PropTypes.string,
// };

// ReportExcel.defaultProps = {
//   data: null,
//   filename: null,
// };

// export default ReportExcel;
