// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { checkNewBeneficiary as checkNewBeneficiaryService } from '@services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Moment
import moment from 'moment';

// Data
import { gender as genderType } from '@constants/selectLists';

// Utility
import { ParsePesel } from '@utils/functions';

// Elements
import { Box, Grid } from '@mui/material';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import Calendar from '@components/atoms/Calendar/Calendar';
import Button from '@components/atoms/Button/Button';
import SwitchButton from '@components/atoms/SwitchButton/SwitchButton';

// Component
const AddNewBeneficiary = ({
  setOpenFn,
  setNewBeneficiary,
  checkNewBeneficiary,
  beneficiary_id,
  beneficiary,
}) => {
  const [nopesel, setNopesel] = useState(false);
  const [checkedBeneficiery, setCheckedBeneficiery] = useState(false);

  useEffect(() => {
    if (beneficiary_id && beneficiary) {
      setCheckedBeneficiery(true);
    }
  }, [beneficiary_id]);

  return (
    <>
      <Formik
        initialValues={{
          name: beneficiary_id && beneficiary ? beneficiary.name : '',
          surname: beneficiary_id && beneficiary ? beneficiary.surname : '',
          pesel: beneficiary_id && beneficiary ? beneficiary.pesel : '',
          passport: beneficiary_id && beneficiary ? beneficiary.passport : '',
          dob: beneficiary_id && beneficiary ? beneficiary.dob : '',
          gender: beneficiary_id && beneficiary ? beneficiary.gender : 'male',
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          surname: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          dob: Yup.date().required('pole wymagane!').nullable(),
          passport: Yup.string().test('passport', 'pole wymagane!', (passport) => {
            return !nopesel || passport;
          }),
          gender: Yup.string().test(
            'gender',
            'płeć nie pasuje do numeru pesel',
            (gender, schema) => {
              if (!schema.parent.pesel) return true;

              if (parseInt(schema.parent.pesel / 10, 10) % 2 === 0 && gender === 'female')
                return true;
              if (parseInt(schema.parent.pesel / 10, 10) % 2 === 1 && gender === 'male')
                return true;

              return false;
            },
          ),
          pesel: Yup.string()
            .typeError('nieprawidłowy pesel!')
            .test('pesel', 'Nieprawidłowy numer', (pesel) => {
              if (nopesel) {
                return true;
              }
              if (!/^[0-9]{11}$/.test(pesel)) {
                return false;
              }
              const parsedPesel = ParsePesel(pesel);
              if (parsedPesel.error) {
                return false;
              }
              return true;
            })
            .test('pesel', 'pole wymagane!', (pesel) => {
              return nopesel || pesel;
            }),
        })}
        onSubmit={(values) => {
          checkNewBeneficiary(values).then((result) => {
            if (!result || (result.data.isnew === false && result.data.beneficiary)) {
              if (result) {
                setNewBeneficiary(result.data.beneficiary);
                setCheckedBeneficiery(true);
              }
            } else {
              // console.log(values);
              setNewBeneficiary(values);
              setCheckedBeneficiery(true);
            }
          });
        }}
      >
        {({ values, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Input
                  disabled={nopesel}
                  label="Pesel"
                  variant="filled"
                  type="text"
                  name="pesel"
                  onChange={(e) => {
                    handleChange(e);

                    const parsedPesel = ParsePesel(e.target.value);

                    if (!parsedPesel.error) {
                      setFieldValue('gender', parsedPesel.plec);
                      setFieldValue('dob', moment(parsedPesel.data).format('YYYY-MM-DD'));
                    }
                  }}
                  value={values.pesel}
                  autoComplete="off"
                />
                <ErrorMessage name="pesel">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={4}>
                <SwitchButton
                  checked={nopesel}
                  name="nopesel"
                  label="Nie ma numeru PESEL"
                  place="end"
                  setChecked={(e) => {
                    setNopesel(e);
                    setFieldValue('pesel', '');
                    setFieldValue('passport', '');
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!nopesel}
                  label="Paszport"
                  variant="filled"
                  type="text"
                  name="passport"
                  onChange={handleChange}
                  value={values.passport}
                  autoComplete="off"
                />
                <ErrorMessage name="passport">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={4}>
                <Input
                  label="Imię"
                  variant="filled"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  value={values.name}
                  autoComplete="off"
                />
                <ErrorMessage name="name">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={4}>
                <Input
                  label="Nazwisko"
                  variant="filled"
                  type="text"
                  name="surname"
                  onChange={handleChange}
                  value={values.surname}
                  autoComplete="off"
                />
                <ErrorMessage name="surname">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>

              <Grid className="addBeneCalendar" item xs={4}>
                <Calendar
                  calendarToolbar={false}
                  disabled={values.pesel !== ''}
                  label="Data urodzenia"
                  variant="filled"
                  disableFuture
                  name="dob"
                  onChange={setFieldValue}
                  value={values.dob}
                  autoComplete="off"
                />
                <ErrorMessage name="dob">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={4}>
                <Select
                  disabled={values.pesel !== ''}
                  label="Płeć"
                  data={genderType}
                  variant="filled"
                  name="gender"
                  text="gender"
                  selectValue={values.gender}
                  onChange={setFieldValue}
                />
                <ErrorMessage name="gender">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={4} />
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  {setOpenFn && (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setOpenFn(false);
                      }}
                    >
                      Anuluj
                    </Button>
                  )}
                  <Button
                    variant="contained"
                    style={{ marginLeft: setOpenFn ? 15 : 0 }}
                    onClick={
                      checkedBeneficiery
                        ? () => {
                            setCheckedBeneficiery(false);
                            setNewBeneficiary(null);
                          }
                        : handleSubmit
                    }
                  >
                    {checkedBeneficiery ? 'Cofnij' : 'Przejdź dalej'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </>
  );
};

AddNewBeneficiary.propTypes = {
  checkNewBeneficiary: PropTypes.func,
  setOpenFn: PropTypes.func,
  setNewBeneficiary: PropTypes.func,
  beneficiary_id: PropTypes.number,
  beneficiary: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    pesel: PropTypes.string,
    passport: PropTypes.string,
    dob: PropTypes.string,
    gender: PropTypes.string,
  }),
};

AddNewBeneficiary.defaultProps = {
  checkNewBeneficiary: null,
  setOpenFn: null,
  setNewBeneficiary: null,
  beneficiary_id: null,
  beneficiary: null,
};

const mapDispatchToProps = (dispatch) => ({
  checkNewBeneficiary: (data) => dispatch(checkNewBeneficiaryService(data)),
});

export default connect(null, mapDispatchToProps)(AddNewBeneficiary);
