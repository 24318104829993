import axios from 'axios';

const baseURL = import.meta.env.VITE_API_URL;
const headers = {};

const axiosInstance = axios.create({
  baseURL,
  headers,
});

export default axiosInstance;
