// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Services
import {
  addBeneficiaryIndicatorPeriod as addBeneficiaryIndicatorPeriodService,
  saveBeneficiaryIndicatorValue as saveBeneficiaryIndicatorValueService,
  removeBeneficiaryIndicatorPeriod as removeBeneficiaryIndicatorPeriodService,
  setBeneficiaryIndicatorPeriodBase as setBeneficiaryIndicatorPeriodBaseService,
} from '@services/beneficiaryServices';
import { fetchBeneficiaryIndicatorPeriod as fetchBeneficiaryIndicatorPeriodService } from '@services/selectServices';

// Assets
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// Elements
import { Help } from '@mui/icons-material';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
} from '@mui/material';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import Heading from '@components/atoms/Heading/Heading';
import Button from '@components/atoms/Button/Button';
import Dialog from '@components/organisms/Dialog/Dialog';
import Select from '@components/atoms/Select/Select';

// Styles
import { StyledPaper } from '@components/styled/StyledComponents';

import {
  StyledButton,
  StyledTableCell,
  StyledDialogActions,
  StyledInput,
  StyledHeadingCaption2,
  StyledTooltip,
} from './EditIndicatorTable.styles';

// Component
const EditIndicatorTable = ({
  isEditable,
  indicator,
  beneficiaryId,
  indicatorPeriods,
  fetchBeneficiaryIndicatorPeriod,
  addBeneficiaryIndicatorPeriod,
  saveBeneficiaryIndicatorValue,
  removeBeneficiaryIndicatorPeriod,
  setBeneficiaryIndicatorPeriodBase,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { periodsColumns, periodsValues, indicatorId, indicatorMeasurementNumber, description } =
    indicator;

  useEffect(() => {
    if (openDialog) {
      fetchBeneficiaryIndicatorPeriod(indicatorId);
    }
  }, [openDialog]);
  return (
    <>
      <TableContainer className="tabela" component={StyledPaper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell title={1} column="name">
                <StyledHeadingCaption2>
                  Założona liczba pomiarów: {indicatorMeasurementNumber}
                </StyledHeadingCaption2>
              </StyledTableCell>
              {periodsColumns.length > 0 &&
                periodsColumns
                  .sort((col1, col2) => col1.order - col2.order)
                  .map((col) => (
                    <StyledTableCell key={col.id} number={1}>
                      <Checkbox
                        isEditable={isEditable}
                        label="pomiar bazowy"
                        name={`base_measurement_${col.id}`}
                        value={col.base_measurement}
                        onChange={() => {
                          setBeneficiaryIndicatorPeriodBase(beneficiaryId, col.id, {
                            base_measurement: !col.base_measurement,
                            cwpId: indicator.cwpId,
                          });
                        }}
                      />
                      <Heading.Overline>{col.name}</Heading.Overline>
                      {isEditable && (
                        <>
                          <br />
                          <StyledButton
                            className={isEditable && 'btn-indi active'}
                            startIcon={<RemoveCircleIcon />}
                            disabled={!isEditable || false}
                            onClick={() => {
                              removeBeneficiaryIndicatorPeriod(beneficiaryId, col.id, {
                                cwpId: indicator.cwpId,
                              });
                            }}
                          >
                            usuń
                          </StyledButton>
                        </>
                      )}
                    </StyledTableCell>
                  ))}

              <TableCell column="support" align="left" colSpan={2}>
                <StyledButton
                  className={isEditable ? 'btn-indi active' : 'btn-indi'}
                  disabled={!isEditable || false}
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => setOpenDialog(true)}
                >
                  Dodaj kolejne okresy
                </StyledButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell title={1} component="th" scope="row">
                <Heading.Body2>
                  {description && (
                    <Tooltip
                      title={
                        <StyledTooltip>
                          <Heading.Body1 color="white">{description}</Heading.Body1>
                        </StyledTooltip>
                      }
                    >
                      <IconButton>
                        <Help />
                      </IconButton>
                    </Tooltip>
                  )}
                  <br />
                  {periodsValues.name || '-'}
                </Heading.Body2>
              </StyledTableCell>
              {periodsValues.data &&
                periodsValues.data
                  .sort((col1, col2) => col1.order - col2.order)
                  .map((item) => (
                    <StyledTableCell number={1} key={item.id}>
                      {isEditable ? (
                        <Formik
                          initialValues={{ value: item.value, cwpId: indicator.cwpId }}
                          onSubmit={(values) => {
                            saveBeneficiaryIndicatorValue(beneficiaryId, item.id, values);
                          }}
                          validationSchema={
                            indicator.indicatorType === 4
                              ? Yup.object({
                                  value: Yup.number()
                                    .min(
                                      indicator.indicatorTypeDetails.numerical.from,
                                      `Mininalna wartość ${indicator.indicatorTypeDetails.numerical.from}`,
                                    )
                                    .max(
                                      indicator.indicatorTypeDetails.numerical.to,
                                      `Maksymalna wartość ${indicator.indicatorTypeDetails.numerical.to}`,
                                    ),
                                })
                              : null
                          }
                        >
                          {({
                            values,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                            touched,
                            errors,
                            setFieldTouched,
                          }) => (
                            <>
                              {indicator.indicatorType === 1 && (
                                <Select
                                  variant="outlined"
                                  label=""
                                  data={[
                                    { value: 'tak', name: 'tak' },
                                    { value: 'nie', name: 'nie' },
                                  ]}
                                  text="value"
                                  onChange={(name, val) => {
                                    setFieldValue(name, val);
                                    setFieldTouched('value', true);
                                  }}
                                  selectValue={values.value}
                                />
                              )}
                              {indicator.indicatorType === 2 && (
                                <Select
                                  variant="outlined"
                                  label=""
                                  data={indicator.indicatorTypeDetails.select}
                                  text="value"
                                  onChange={(name, val) => {
                                    setFieldValue(name, val);
                                    setFieldTouched('value', true);
                                  }}
                                  selectValue={values.value}
                                />
                              )}
                              {indicator.indicatorType === 3 && (
                                <StyledInput
                                  variant="outlined"
                                  name="value"
                                  type="text"
                                  defaultValue={values.value}
                                  onChange={handleChange}
                                  onKeyUp={() => setFieldTouched('value', true)}
                                />
                              )}
                              {indicator.indicatorType === 4 && (
                                <StyledInput
                                  variant="outlined"
                                  name="value"
                                  type="number"
                                  defaultValue={values.value}
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldTouched('value', true);
                                  }}
                                  InputProps={{
                                    inputProps: {
                                      min: indicator.indicatorTypeDetails.numerical.from,
                                      max: indicator.indicatorTypeDetails.numerical.to,
                                    },
                                  }}
                                  onKeyUp={() => setFieldTouched('value', true)}
                                />
                              )}
                              <ErrorMessage name="value">
                                {(msg) => <div className="error-txt">{msg}</div>}
                              </ErrorMessage>
                              {touched.value && !errors.value && (
                                <Button
                                  onClick={(e) => {
                                    handleSubmit(e);
                                    setFieldTouched('value', false);
                                  }}
                                >
                                  zapisz
                                </Button>
                              )}
                            </>
                          )}
                        </Formik>
                      ) : (
                        <>
                          {indicator.indicatorType === 2 && (
                            <Heading.Body2 key={item.id}>
                              {(indicator.indicatorTypeDetails.select.find(
                                (el) => el.value === item.value,
                              ) &&
                                indicator.indicatorTypeDetails.select.find(
                                  (el) => el.value === item.value,
                                ).name) ||
                                '-'}
                            </Heading.Body2>
                          )}
                          {indicator.indicatorType !== 2 && (
                            <Heading.Body2 key={item.id}>{item.value || '-'}</Heading.Body2>
                          )}
                        </>
                      )}
                    </StyledTableCell>
                  ))}
              <TableCell colSpan={2} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog size="xs" open={openDialog} setOpen={setOpenDialog} title="Wybierz okres pomiaru">
        <Formik
          initialValues={{ periodId: 0, cwpId: indicator.cwpId }}
          onSubmit={(values) => {
            addBeneficiaryIndicatorPeriod(beneficiaryId, indicatorId, values);
            setOpenDialog(false);
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <Select
                variant="filled"
                label="Czas pomiaru"
                data={indicatorPeriods}
                text="periodId"
                onChange={setFieldValue}
                selectValue={values.periodId}
              />
              <StyledDialogActions>
                <Button onClick={() => setOpenDialog(false)}>WRÓĆ</Button>
                <Button onClick={handleSubmit} autoFocus variant="outlined">
                  DALEJ
                </Button>
              </StyledDialogActions>
            </>
          )}
        </Formik>
        <></>
      </Dialog>
    </>
  );
};

EditIndicatorTable.propTypes = {
  fetchBeneficiaryIndicatorPeriod: PropTypes.func,
  addBeneficiaryIndicatorPeriod: PropTypes.func,
  saveBeneficiaryIndicatorValue: PropTypes.func,
  removeBeneficiaryIndicatorPeriod: PropTypes.func,
  setBeneficiaryIndicatorPeriodBase: PropTypes.func,
  isEditable: PropTypes.bool,
  indicator: PropTypes.shape({
    description: PropTypes.string,
    indicatorMeasurementNumber: PropTypes.number,
    periodsColumns: PropTypes.arrayOf(PropTypes.any),
    periodsValues: PropTypes.shape({
      name: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.any),
    }),
    indicatorType: PropTypes.number,
    indicatorTypeDetails: PropTypes.objectOf(PropTypes.any),
    indicatorId: PropTypes.number,
    name: PropTypes.string,
    cwpId: PropTypes.number,
  }),
  indicatorPeriods: PropTypes.arrayOf(PropTypes.any),
  beneficiaryId: PropTypes.number.isRequired,
};

EditIndicatorTable.defaultProps = {
  addBeneficiaryIndicatorPeriod: null,
  fetchBeneficiaryIndicatorPeriod: null,
  saveBeneficiaryIndicatorValue: null,
  removeBeneficiaryIndicatorPeriod: null,
  setBeneficiaryIndicatorPeriodBase: null,
  isEditable: false,
  indicator: null,
  indicatorPeriods: [],
};

const mapStateToProps = ({ selectReducer }) => ({
  indicatorPeriods: selectReducer.indicatorPeriods,
});

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryIndicatorValue: (beneficjentId, indicatorId, data) =>
    dispatch(saveBeneficiaryIndicatorValueService(beneficjentId, indicatorId, data)),
  addBeneficiaryIndicatorPeriod: (beneficjentId, indicatorId, data) =>
    dispatch(addBeneficiaryIndicatorPeriodService(beneficjentId, indicatorId, data)),
  removeBeneficiaryIndicatorPeriod: (beneficiaryId, indicatorId, data) =>
    dispatch(removeBeneficiaryIndicatorPeriodService(beneficiaryId, indicatorId, data)),
  setBeneficiaryIndicatorPeriodBase: (beneficiaryId, indicatorId, data) =>
    dispatch(setBeneficiaryIndicatorPeriodBaseService(beneficiaryId, indicatorId, data)),
  fetchBeneficiaryIndicatorPeriod: (id) => dispatch(fetchBeneficiaryIndicatorPeriodService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditIndicatorTable);
