// React
import React from 'react';
import PropTypes from 'prop-types';

// Data
import { surveysScales } from '@constants/surveys';

// Elements
import { Grid, Box } from '@mui/material';
import ChartSurvey from '@components/molecules/ChartSurvey/ChartSurvey';
import TableTemplate from '@templates/TableTemplate';
import Heading from '@components/atoms/Heading/Heading';

// Data
const headCells = [
  { type: 'name', label: 'nazwa grupy' },
  { type: 'score', label: 'wynik' },
];

// View
const ResultSurveys = ({ results, age, gender }) => {
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Box mt={6}>
            <Heading.H6>Wynik pomiaru ankietą diagnostyczno-ewaluacyjną</Heading.H6>
          </Box>
        </Grid>

        {results.map((el) => (
          <Grid item xs={6} key={el.id}>
            <ChartSurvey
              score={el.score}
              title={el.name}
              scale={
                (age === 'CHILD_EVALUATION_QUESTIONNAIRE_6_12' &&
                  (surveysScales[el.id] ? surveysScales[el.id][gender] || [] : [])) ||
                []
              }
            />
          </Grid>
        ))}
        <Grid item xs={12}>
          <TableTemplate headCells={headCells} data={results} noSort noPagination noFilter />
        </Grid>
      </Grid>
    </>
  );
};

ResultSurveys.propTypes = {
  results: PropTypes.arrayOf(PropTypes.any),
  age: PropTypes.string,
  gender: PropTypes.string,
};

ResultSurveys.defaultProps = {
  results: [],
  age: '',
  gender: '',
};

export default ResultSurveys;
