import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
import {
  fetchMessageAction,
  fetchMessageSingleAction,
  sendMessageAction,
} from '@actions/messageActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const fetchMyMessageList = () => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get(`/wiadomosci`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchMessageAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchMessageSingle = (id) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get(`/wiadomosci/wiadomosc/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchMessageSingleAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const sendNewMessage = (data) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .post(`/wiadomosci/wyslij`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(sendMessageAction(res.data));
      dispatch(fetchMyMessageList());
      return res.data.id;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const sendMessage = (id, data) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .post(`/wiadomosci/${id}/wyslij`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(sendMessageAction(res.data));
      dispatch(fetchMessageSingle(id));
      return res.data.id;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};
