// Elements
import { Grid } from '@mui/material';
import Button from '@components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '70%')};
    &.MuiGrid-spacing-xs-2 {
      margin-top: 4rem;
    }

    margin-top: ${({ margin }) => margin || '0rem'};
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1.5rem 2.5rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    padding-right: 1px;
  }
`;

export const StyledPodstawoweCont = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledKropki = styled.div`
  && {
    margin-left: 3rem !important;
  }
`;
