import { StyleSheet } from '@react-pdf/renderer';

// Create styles
export const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 10,
    paddingHorizontal: 50,
    paddingVertical: 25,
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    marginBottom: 8,
  },
  textFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  textBold: {
    fontWeight: 700,
  },
  textBoldMB: {
    fontWeight: 700,
    marginBottom: 4,
  },
  table: {
    width: '100%',
    display: 'table',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
  },
  tableRow: {
    flexDirection: 'row',
    margin: 0,
  },
  tableColHeader: {
    width: '33.3%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
  },
  tableCol: {
    width: '33.3%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#000000',
  },
  tableCellHeader: {
    fontSize: 10,
    fontWeight: 700,
    margin: 5,
  },
  tableCell: {
    fontSize: 8,
    margin: 5,
  },
});

export const stylesReport = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 9,
    paddingHorizontal: 5,
    paddingVertical: 15,
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  section: {
    margin: 3,
    padding: 3,
  },
  table: {
    display: 'table',
    width: 'auto',
    maxWidth: '100%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 0,
    flexDirection: 'row',
  },
  tableCol: (colWidth) => ({
    width: `${colWidth}%`,
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomWidth: 1,
    borderRightWidth: 1,
    padding: 5,
  }),
  tableCell: {
    margin: 'auto',
    fontSize: 8,
    flexWrap: 'wrap',
  },
  title: {
    fontSize: 12,
    fontWeight: 700,
    marginTop: 20,
    marginBottom: 20,
  },
  text: {
    marginBottom: 3,
  },
  textFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: 3,
  },
  textBold: {
    fontWeight: 700,
  },
  textBoldMB: {
    fontWeight: 700,
    marginBottom: 3,
  },
  tableHeader: {
    flexDirection: 'row',
  },
  headerCell: {
    margin: 'auto',
    fontSize: 8,
    fontWeight: 600,
    flexWrap: 'wrap',
  },
});
