// React
import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import moment from 'moment';
import * as Yup from 'yup';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';

// Services
import { fetchPWDList as fetchPWDListServiceService } from '@services/selectServices';
import {
  saveBeneficiaryPWD as saveBeneficiaryPWDService,
  saveBeneficiaryData as saveBeneficiaryDataService,
} from '@services/beneficiaryServices';
import {
  addBeneficiaries as addBeneficiariesService,
  fetchPossibleBeneficiaryActionList as fetchActionsList,
  endBeneficiaryParticipationInAction as endBeneficiaryParticipationInActionService,
  fetchBeneficiaryActionsList as fetchBeneficiaryActionsListService,
} from '@services/actionServices';

// Actions
import { clearPwdList as clearPwdListAction } from '@actions/selectActions';
import { clearActions as clearActionsAction } from '@actions/actionActions';

// Data
import { gender } from '@constants/selectLists';
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
} from '@constants/roles';

// Elements
import { Grid } from '@mui/material';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import MoreIcon from '@components/molecules/MoreIcon/MoreIcon';
import Calendar from '@components/atoms/Calendar/Calendar';
import Heading from '@components/atoms/Heading/Heading';
import EditButton from '@components/atoms/EditButton/EditButton';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import AutocompleteWithInput from '@components/atoms/AutocompleteWithInput/AutocompleteWithInput';
import Dialog from '@components/organisms/Dialog/Dialog';
import TableTemplate from '@templates/TableTemplate';
import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';
import SwitchButton from '@components/atoms/SwitchButton/SwitchButton';

// Utility
import { ParsePesel } from '@utils/functions';

// Styles
import { clearBeneficiaryAction } from '@actions/beneficiaryActions';
import {
  StyledGrid,
  StyledButton,
  StyledTitle,
  StyledPodstawoweCont,
  StyledKropki,
} from './BeneficiarySettings.styles';

// Component
const BeneficiarySettings = ({
  details,
  clearPwdList,
  fetchPWDList,
  PWDList,
  clearActions,
  fetchActions,
  actions,
  addBeneficiaries,
  saveBeneficiaryPWD,
  saveBeneficiaryData,
  endBeneficiaryParticipationInAction,
  fetchBeneficiaryActions,
  me,
}) => {
  const history = useHistory();

  const { id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDialogActions, setOpenDialogActions] = useState(false);
  const [openDialogEndActions, setOpenDialogEndActions] = useState(false);
  const [currentActionToEnd, setCurrentActionToEnd] = useState(null);
  const [openDialogConfirmEndAction, setOpenDialogConfirmEndAction] = useState(false);
  const [nopesel, setNopesel] = useState(false);

  const headCellsActions = [{ type: 'action_name', label: 'Nazwa działania', nav: true }];

  useEffect(() => {
    clearPwdList();
  }, []);

  useEffect(() => {
    fetchPWDList();
    if (me.user_role.includes(COORDINATOR)) {
      setIsAdmin(true);
    }
  }, [id, fetchPWDList]);

  // Data
  const options = [
    {
      name: 'dodaj do działania',
      action: () => {
        clearActions();
        fetchActions(id);
        setOpenDialogActions(true);
      },
    },
    {
      name: 'wypisz z działania',
      action: () => {
        fetchBeneficiaryActions(id);
        setOpenDialogEndActions(true);
      },
    },
    {
      name: 'wypełnij wskaźnik',
      action: () => history.push(`/beneficjenci/${id}/tab/4-wskazniki`),
    },
  ];

  const actionCellsActions = [
    {
      column: 'zapisz beneficjenta',
      action: (row) => {
        addBeneficiaries(row.id, {
          beneficiaries: [
            {
              cwp_id: row.cwp_id,
            },
          ],
        });
      },
      button: 'zapisz',
    },
  ];
  const actionCellsActionsEnd = [
    {
      column: 'wypisz beneficjenta',
      action: (row) => {
        setOpenDialogConfirmEndAction(true);
        setCurrentActionToEnd(row);
      },
      button: 'wypisz',
    },
  ];

  return (
    <>
      <StyledTitle>
        <StyledPodstawoweCont>
          <Heading.Subtitle1>Podstawowe dane osobowe</Heading.Subtitle1>
          {details.name !== 'anonimizacja' && (
            <GuardedComponent allowed_user_roles={[COORDINATOR, PROGRAM_COORDINATOR]}>
              <StyledKropki>
                <MoreIcon size="2.5rem" actions={options} />
              </StyledKropki>
            </GuardedComponent>
          )}
        </StyledPodstawoweCont>
        {details.name !== 'anonimizacja' && (
          <GuardedComponent
            allowed_user_roles={[
              COORDINATOR,
              PROGRAM_COORDINATOR,
              TASK_COORDINATOR,
              LECTURER_SENSITIVE_DATA,
            ]}
            // program_id={parseInt(id, 10)}
            // allowed_program_roles={[PROGRAM_COORDINATOR, TASK_COORDINATOR, LECTURER_SENSITIVE_DATA]}
          >
            <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
          </GuardedComponent>
        )}
      </StyledTitle>
      <StyledGrid container spacing={2}>
        <Formik
          initialValues={{
            name: details.name,
            surname: details.surname,
            pesel: details.pesel ? details.pesel : '',
            passport: details.passport ? details.passport : '',
            dateAdd: details.date_add,
            dateModified: details.date_modified ? details.date_modified : '',
            dob: details.dob ? details.dob : '',
            gender: details.gender,
            pwd: details.pwd,
          }}
          validationSchema={Yup.object({
            name: isAdmin
              ? Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!')
              : null,
            surname: isAdmin
              ? Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!')
              : null,
            dob: isAdmin ? Yup.date().required('pole wymagane!').nullable() : null,
            passport: isAdmin
              ? Yup.string().test('passport', 'pole wymagane!', (passport) => {
                  return !nopesel || passport;
                })
              : null,
            gender: isAdmin
              ? Yup.string().test('gender', 'płeć nie pasuje do numeru pesel', (gend, schema) => {
                  if (!schema.parent.pesel) return true;

                  if (parseInt(schema.parent.pesel / 10, 10) % 2 === 0 && gend === 'female')
                    return true;
                  if (parseInt(schema.parent.pesel / 10, 10) % 2 === 1 && gend === 'male')
                    return true;

                  return false;
                })
              : null,
            pesel: isAdmin
              ? Yup.string()
                  .typeError('nieprawidłowy pesel!')
                  .test('pesel', 'Nieprawidłowy numer', (pesel) => {
                    if (nopesel) {
                      return true;
                    }
                    if (!/^[0-9]{11}$/.test(pesel)) {
                      return false;
                    }
                    const parsedPesel = ParsePesel(pesel);
                    if (parsedPesel.error) {
                      return false;
                    }
                    return true;
                  })
                  .test('pesel', 'pole wymagane!', (pesel) => {
                    return nopesel || pesel;
                  })
              : null,
          })}
          enableReinitialize
          onSubmit={(values) => {
            if (isAdmin) {
              saveBeneficiaryData(id, values);
            } else {
              saveBeneficiaryPWD(id, { pwd: values.pwd });
            }
          }}
        >
          {({ values, setFieldValue, handleChange, handleSubmit }) => (
            <>
              <Grid item xs={4}>
                <Input
                  disabled={!isAdmin || !isEditable}
                  label="Imię"
                  variant="filled"
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isAdmin || !isEditable}
                  label="Nazwisko"
                  variant="filled"
                  type="text"
                  name="surname"
                  value={values.surname}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Calendar
                  disabled={!isAdmin || !isEditable}
                  label="Data urodzenia"
                  variant="filled"
                  disableFuture
                  name="dob"
                  value={values.dob}
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isAdmin || !isEditable}
                  label="Pesel"
                  variant="filled"
                  type="text"
                  name="pesel"
                  value={values.pesel}
                  onChange={(e) => {
                    handleChange(e);

                    const parsedPesel = ParsePesel(e.target.value);

                    if (!parsedPesel.error) {
                      setFieldValue('gender', parsedPesel.plec);
                      setFieldValue('dob', moment(parsedPesel.data).format('YYYY-MM-DD'));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled={!isAdmin || !isEditable}
                  label="Paszport"
                  variant="filled"
                  type="text"
                  name="passport"
                  value={values.passport}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <Select
                  disabled={!isAdmin || !isEditable}
                  label="Płeć"
                  data={gender}
                  variant="filled"
                  text="gender"
                  selectValue={values.gender}
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled
                  label="Data dodania"
                  variant="filled"
                  type="text"
                  value={values.dateAdd}
                />
              </Grid>
              <Grid item xs={4}>
                <Input
                  disabled
                  label="Data ostatniej modyfikacji"
                  variant="filled"
                  type="text"
                  value={values.dateModified}
                />
              </Grid>
              <GuardedComponent allowed_user_roles={[COORDINATOR]}>
                <Grid item xs={4}>
                  <SwitchButton
                    checked={nopesel}
                    name="nopesel"
                    label="Nie ma numeru PESEL"
                    place="end"
                    setChecked={(e) => {
                      setNopesel(e);
                      setFieldValue('pesel', '');
                      setFieldValue('passport', '');
                    }}
                  />
                </Grid>
              </GuardedComponent>
              <Grid item xs={8}>
                <AutocompleteWithInput
                  data={PWDList}
                  disabled={!isEditable || false}
                  label="Placówka Wsparcia Dziennego"
                  name="pwd"
                  value={values.pwd}
                  variant="filled"
                  onChange={setFieldValue}
                />
                <ErrorMessage name="pwd">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={4}>
                <GuardedComponent allowed_user_roles={[COORDINATOR, PROGRAM_COORDINATOR]}>
                  <StyledButton
                    disabled={!isEditable || false}
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    Zapisz
                  </StyledButton>
                </GuardedComponent>
              </Grid>
            </>
          )}
        </Formik>
      </StyledGrid>
      <Dialog
        size="md"
        open={openDialogActions}
        setOpen={setOpenDialogActions}
        title="Lista działań z programów beneficjenta"
      >
        <>
          <TableTemplate
            headCells={headCellsActions}
            data={actions}
            tableName="Lista działań"
            navCells
            link="/dzialania"
            actionCells={actionCellsActions}
          />
        </>
        <></>
      </Dialog>
      <Dialog
        size="md"
        open={openDialogEndActions}
        setOpen={setOpenDialogEndActions}
        title="Lista działań beneficjenta"
      >
        <>
          <TableTemplate
            headCells={headCellsActions}
            data={actions}
            tableName="Lista działań"
            navCells
            link="/dzialania"
            actionCells={actionCellsActionsEnd}
          />
        </>
        <></>
      </Dialog>
      {currentActionToEnd && (
        <DialogConfirm
          item={currentActionToEnd}
          removeFunction={() => {
            endBeneficiaryParticipationInAction(currentActionToEnd.id, currentActionToEnd.cwa_id);
            setCurrentActionToEnd(null);
          }}
          title={`Potwierdź wypisanie beneficjenta ${details.name} ${details.surname} z działania "${currentActionToEnd.action_name}"`}
          open={openDialogConfirmEndAction}
          setOpenFn={setOpenDialogConfirmEndAction}
        />
      )}
    </>
  );
};

BeneficiarySettings.propTypes = {
  clearActions: PropTypes.func,
  fetchActions: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    dob: PropTypes.string,
    pesel: PropTypes.string,
    passport: PropTypes.string,
    gender: PropTypes.string,
    date_add: PropTypes.string,
    date_modified: PropTypes.string,
    pwd: PropTypes.string,
  }),
  clearPwdList: PropTypes.func,
  fetchPWDList: PropTypes.func,
  PWDList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  addBeneficiaries: PropTypes.func,
  endBeneficiaryParticipationInAction: PropTypes.func,
  saveBeneficiaryPWD: PropTypes.func,
  saveBeneficiaryData: PropTypes.func,
  fetchBeneficiaryActions: PropTypes.func,
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
};

BeneficiarySettings.defaultProps = {
  details: {
    id: null,
    name: '',
    surname: '',
    dob: '',
    pesel: '',
    passport: '',
    gender: 'male',
    date_add: '',
    date_modified: '',
    pwd: '',
  },
  clearPwdList: null,
  fetchPWDList: null,
  PWDList: null,
  addBeneficiaries: null,
  endBeneficiaryParticipationInAction: null,
  saveBeneficiaryPWD: null,
  saveBeneficiaryData: null,
  clearActions: null,
  fetchActions: null,
  actions: [],
  fetchBeneficiaryActions: null,
  me: {
    user_role: [],
  },
};

const mapStateToProps = ({ beneficiaryReducer, selectReducer, actionReducer, userReducer }) => ({
  details: beneficiaryReducer.beneficiary,
  PWDList: selectReducer.PWD,
  PWDPending: selectReducer.PWDPending,
  actions: actionReducer.actions,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearActions: () => dispatch(clearActionsAction()),
  clearBeneficiary: () => dispatch(clearBeneficiaryAction()),
  fetchActions: (id) => dispatch(fetchActionsList(id)),
  fetchBeneficiaryActions: (id) => dispatch(fetchBeneficiaryActionsListService(id)),
  clearPwdList: () => dispatch(clearPwdListAction()),
  fetchPWDList: () => dispatch(fetchPWDListServiceService()),
  addBeneficiaries: (id, data) => dispatch(addBeneficiariesService(id, data)),
  saveBeneficiaryPWD: (id, data) => dispatch(saveBeneficiaryPWDService(id, data)),
  saveBeneficiaryData: (id, data) => dispatch(saveBeneficiaryDataService(id, data)),
  endBeneficiaryParticipationInAction: (action_id, cwa_id) =>
    dispatch(endBeneficiaryParticipationInActionService(action_id, cwa_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiarySettings);
