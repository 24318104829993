// Elements
import { Card, CardActions, CardMedia, Divider, IconButton } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  && {
    position: relative;
    background-color: ${({ theme }) => theme.color.white};
    transition: ${({ theme }) => theme.transition};

    &:hover {
      box-shadow: 0 0.3rem 0.2rem ${({ theme }) => theme.color.shadow},
        0 0.7rem 0.5rem ${({ theme }) => theme.color.shadow},
        0 1.3rem 1rem ${({ theme }) => theme.color.shadow},
        0 2.2rem 1.8rem ${({ theme }) => theme.color.shadow},
        0 4.2rem 3.3rem ${({ theme }) => theme.color.shadow};
    }

    .contrast-on & {
      border: 0.1rem solid ${({ theme }) => theme.color.darkGray};
      border-radius: ${({ theme }) => theme.borderRadius.normal};
    }
  }
`;

export const StyledCardMedia = styled(CardMedia)`
  && {
    height: 22rem;
    background-color: ${({ theme }) => theme.color.lightGray};
    transition: ${({ theme }) => theme.transition};
  }
`;

export const StyledDivider = styled(Divider)`
  && {
    width: 5rem;
    height: 3px;
    background-color: ${({ theme, color }) => (color ? theme.color[color] : '')};
    border-radius: ${({ theme }) => theme.borderRadius.normal};
    margin-left: 2rem;
    transition: ${({ theme }) => theme.transition};

    ${StyledCard}:hover & {
      width: 8rem;
    }
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;

    button {
      margin-left: 1rem;
    }
  }
`;

export const StyledBadge = styled.div`
  && {
    position: absolute;
    top: -1rem;
    right: 2rem;
    width: 5rem;
    height: 6rem;
    background-image: ${({ badge }) => `url(${badge})`};
    background-repeat: no-repeat;
  }
`;

export const StyledCardActions = styled(CardActions)`
  && {
    justify-content: flex-end;
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    height: 3.8rem;
    transition: ${({ theme }) => theme.transition};

    .MuiButtonBase-root {
      margin-bottom: 5rem;
    }
  }
`;
