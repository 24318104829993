// React
import { Link } from 'react-router-dom';

// Assets
import MoreVertIcon from '@mui/icons-material/MoreVert';

// Elements
import { Card, CardMedia, IconButton } from '@mui/material';
import Input from '@components/atoms/Input/Input';
import Button from '@components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.color.darkGray};
  }
`;
export const StyledBlueLink = styled(Link)`
  && {
    text-decoration: none;
    color: ${({ theme }) => theme.color.blue};
  }
`;

export const StyledCard = styled(Card)`
  && {
    background: ${({ theme }) => theme.color.white};
  }
`;

export const StyledCardMedia = styled(CardMedia)`
  && {
    min-width: 8rem;
    min-height: 8rem;
  }
`;

export const StyledMoreVertIcon = styled(MoreVertIcon)`
  && {
    width: 2rem;
    height: 2rem;
    color: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledIconButton = styled(IconButton)`
  && {
    &.MuiButtonBase-root {
      margin-left: auto;
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1rem 2.5rem;

    &:not(:last-child) {
      margin-right: 1.6rem;
    }
  }
`;

export const StyledInput = styled(Input)`
  && {
    input,
    textarea {
      &:disabled {
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }
  }
`;
