// Elements
import { Grid, DialogActions } from '@mui/material';
import Button from '@components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 1.5rem 2.5rem;
  }
`;

export const StyledTextWrapper = styled.div`
  && {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
`;

export const StyledDialogActions = styled(DialogActions)`
  && {
    width: 50%;
    margin-left: auto;
    margin-top: 2rem;
  }
`;

export const StyledEditWrapper = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

export const StyledBox = styled.div`
  && {
    margin-bottom: 2rem !important;
    border: 2px solid ${({ theme }) => theme.color.gray};
    border-radius: 10px;
    padding: 8px;
  }
`;
