// Styles
import styled from 'styled-components';

export const StyledChartSurvey = styled.div`
  && {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 24rem;
    border: 0.1rem solid ${({ theme }) => theme.color.darkGray};
    border-top: 0;
    border-right: 0;
  }
`;

export const StyledChartSurveyScale = styled.div`
  && {
    position: absolute;
    bottom: ${({ scale }) => (scale ? `${(scale / 6) * 100}%` : 0)};
    left: 0;
    width: 100%;
    height: 0.1rem;
    background-color: ${({ theme }) => theme.color.lightBlue};
    &::before {
      content: '${({ scale, txt }) => scale + txt || 0}';
      position: absolute;
      bottom: 100%;
      left: ${({ right }) => (right ? 'auto' : 0)};
      right: ${({ right }) => (right ? 0 : 'auto')};
      font-size: 1rem;
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;

export const StyledChartSurveyItem = styled.div`
  && {
    position: relative;
    width: calc(100% / 3);
    height: ${({ score }) => (score ? `${(score / 6) * 100}%` : 0)};
    background-color: ${({ theme }) => theme.color.blue};

    &::before {
      content: '${({ score }) => score || 0}';
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      font-size: 1.2rem;
      text-align: center;
    }
  }
`;
