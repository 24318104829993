// React
import React from 'react';

// Elements
import ActionDescription from '@views/Dashboard/Actions/ActionDescription/ActionDescription';

// Styles
import { StyledWrapper } from './ActionAdd.styles';

// Component
const ActionAdd = () => (
  <>
    <StyledWrapper container>
      <ActionDescription isNew />
    </StyledWrapper>
  </>
);

export default ActionAdd;
