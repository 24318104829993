// React
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { detachOrganization as detachOrganizationService } from '@services/organizationServices';

// Utils
import { isAdministratorInOrganization, isCoordinator } from '@utils/functions';

// Data
import { filterCoaliton } from '@constants/tableFilters';
import { ORGANIZATION_ADMINISTRATOR, COORDINATOR } from '@constants/roles';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Elements
import { Grid } from '@mui/material';
import TableTemplate from '@templates/TableTemplate';
import Button from '@components/atoms/Button/Button';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import OrganizationCurrent from '@views/Dashboard/Organizations/OrganizationCurrent/OrganizationCurrent';
import Dialog from '@components/organisms/Dialog/Dialog';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa organizacji' },
  { type: 'identificator_number', label: 'Numer Identyfikacyjny' },
];

// Component
const OrganizationCoalition = ({ detachOrganization, organizations, coallition, parentId, me }) => {
  const { id } = useParams();
  const [openDialogNewOrg, setOpenDialogNewOrg] = useState(false);

  const tableData = parentId ? coallition : organizations;

  return (
    <>
      {tableData && (
        <TableTemplate
          headCells={headCells}
          data={tableData}
          navCells
          link="/organizacje"
          filters={filterCoaliton}
          tableName="Koalicje"
          rowActions={
            isCoordinator(me.user_role) || isAdministratorInOrganization(me, id)
              ? [
                  {
                    name: 'odepnij z koalicji',
                    action: (row) => detachOrganization(row.id, id),
                  },
                ]
              : []
          }
        />
      )}
      {parentId === 0 && (
        <GuardedComponent
          allowed_user_roles={[COORDINATOR]}
          allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
          organization_id={parseInt(id, 10)}
        >
          <Grid item xs={12}>
            <Button
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={() => setOpenDialogNewOrg(!openDialogNewOrg)}
              style={{ fontSize: '1.5rem' }}
            >
              Dodaj organizację koalicyjną
            </Button>
          </Grid>

          <Dialog
            size="md"
            open={openDialogNewOrg}
            setOpen={setOpenDialogNewOrg}
            title="Dodaj organizację koalicyjną"
          >
            <>
              <OrganizationCurrent isNew dont_clear sub_organization parentId={parseInt(id, 10)} />
            </>
            <></>
          </Dialog>
        </GuardedComponent>
      )}
    </>
  );
};

OrganizationCoalition.propTypes = {
  detachOrganization: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.any),
  coallition: PropTypes.arrayOf(PropTypes.any),
  parentId: PropTypes.number,
  me: PropTypes.shape({ user_role: PropTypes.arrayOf(PropTypes.string) }),
};

OrganizationCoalition.defaultProps = {
  detachOrganization: null,
  organizations: [],
  coallition: [],
  parentId: 0,
  me: {
    user_role: [],
  },
};

const mapStateToProps = ({ userReducer, organizationReducer }) => ({
  organizations: organizationReducer.organization.organization_children,
  coallition: organizationReducer.organization.parent
    ? organizationReducer.organization.parent.organization_children
    : [],
  parentId: organizationReducer.organization.parent
    ? organizationReducer.organization.parent.id
    : 0,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  detachOrganization: (id, parent_id) => dispatch(detachOrganizationService(id, parent_id)),
});

// ??? CZY TUTAJ NIE POWINNO POBIERAĆ ORGANIZACJI?

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationCoalition);
