import {
  FETCH_SUBJECT_LIST,
  CLEAR_SUBJECT_LIST,
  FETCH_SUBJECT_PENDING,
  FETCH_PWD_LIST,
  CLEAR_PWD_LIST,
  FETCH_PWD_PENDING,
  FETCH_EDUCATIONALFACILITY_LIST,
  CLEAR_EDUCATIONALFACILITY_LIST,
  FETCH_EDUCATIONALFACILITY_PENDING,
  FETCH_PERIODS_SEMESTER,
  CLEAR_PERIODS_SEMESTER,
  FETCH_PERIODS,
  FETCH_INDICATORPERIODS_LIST,
} from '@constants/actionTypes';

const initialState = {
  subjectList: [],
  subjectListPending: false,
  PWD: [],
  PWDPending: false,
  educationalFacilities: [],
  educationalFacilitiesPending: false,
  periodsSemester: [],
};

const selectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_SUBJECT_LIST:
      return { ...state, subjectList: payload.data };
    case CLEAR_SUBJECT_LIST:
      return { ...state, subjectList: initialState.subjectList };
    case FETCH_SUBJECT_PENDING:
      return { ...state, subjectListPending: payload };
    case FETCH_PWD_LIST:
      return { ...state, PWD: payload.data };
    case CLEAR_PWD_LIST:
      return { ...state, PWD: initialState.PWD };
    case FETCH_PWD_PENDING:
      return { ...state, PWDPending: payload };
    case FETCH_EDUCATIONALFACILITY_LIST:
      return { ...state, educationalFacilities: payload.data };
    case CLEAR_EDUCATIONALFACILITY_LIST:
      return { ...state, educationalFacilities: initialState.educationalFacilities };
    case FETCH_EDUCATIONALFACILITY_PENDING:
      return { ...state, educationalFacilitiesPending: payload };
    case FETCH_PERIODS:
      return { ...state, periods: payload.data };
    case FETCH_PERIODS_SEMESTER:
      return { ...state, periodsSemester: payload.data };
    case CLEAR_PERIODS_SEMESTER:
      return { ...state, periodsSemester: initialState.periodsSemester };
    case FETCH_INDICATORPERIODS_LIST:
      return { ...state, indicatorPeriods: payload.data };
    default:
      return state;
  }
};

export default selectReducer;
