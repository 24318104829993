// React
import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramIndicators } from '@services/programServices';

// Actions
import { clearProgramIndicators as clearProgramIndicatorsAction } from '@actions/programActions';

// Elements
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_GUARDIAN,
} from '@constants/roles';

// Data
const headCells = [
  { type: 'indicator_name', label: 'Nazwa wskaźnika' },
  { type: 'indicator_kind', label: 'Rodzaj wskaźnika' },
  { type: 'indicator_type', label: 'Rodzaj pomiaru' },
  { type: 'indicator_time_period', label: 'Przedział czasowy' },
  { type: 'aggregation', label: 'Przypisanie' },
  { type: 'task', label: 'Zadanie' },
  { type: 'action', label: 'Działanie' },
  { type: 'planned_number_of_measurements', label: 'Planowana liczba pomiarów' },
];

// Component
const ProgramIndicators = ({ clearProgramIndicators, fetchIndicators, data }) => {
  const history = useHistory();
  const { id } = useParams();
  const { indicators } = data;

  useEffect(() => {
    clearProgramIndicators();
  }, []);

  const triggerFetchIndicators = useCallback((myid) => fetchIndicators(myid), [fetchIndicators]);

  useEffect(() => {
    triggerFetchIndicators(id);
  }, [id, triggerFetchIndicators]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={indicators}
        isnav
        link="/wskazniki"
        tableName="Wskaźniki"
      />
      <GuardedComponent
        allowed_user_roles={[
          COORDINATOR,
          ORGANIZATION_ADMINISTRATOR,
          PROGRAM_GUARDIAN,
          PROGRAM_COORDINATOR,
          TASK_COORDINATOR,
        ]}
      >
        <FloatingButton onClick={() => history.push(`/wskaznik-dodaj/program/${id}`)} />
      </GuardedComponent>
    </>
  );
};

ProgramIndicators.propTypes = {
  clearProgramIndicators: PropTypes.func,
  fetchIndicators: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    program_name: PropTypes.string,
    indicators: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        indicator_name: PropTypes.string,
        indicator_kind: PropTypes.string,
        indicator_type: PropTypes.string,
        indicator_time_period: PropTypes.string,
        planned_number_of_measurements: PropTypes.number,
      }),
    ),
  }),
};

ProgramIndicators.defaultProps = {
  clearProgramIndicators: null,
  fetchIndicators: null,
  data: {
    id: null,
    program_name: '',
    indicators: [],
  },
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.indicators,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramIndicators: () => dispatch(clearProgramIndicatorsAction()),
  fetchIndicators: (id) => dispatch(fetchProgramIndicators(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramIndicators);
