// React
import React from 'react';

// Assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import OutdoorGrillRoundedIcon from '@mui/icons-material/OutdoorGrillRounded';

// Elements
import { SvgIcon } from '@mui/material';

// Icons
function PeopleGroupIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect fill="none" height="24" width="24" />
      <g>
        <path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" />
      </g>
    </SvgIcon>
  );
}

function FamilyIcon(props) {
  return (
    <SvgIcon {...props}>
      <g>
        <rect fill="none" height="24" width="24" />
        <path d="M16,4c0-1.11,0.89-2,2-2s2,0.89,2,2s-0.89,2-2,2S16,5.11,16,4z M20,22v-6h2.5l-2.54-7.63C19.68,7.55,18.92,7,18.06,7h-0.12 c-0.86,0-1.63,0.55-1.9,1.37l-0.86,2.58C16.26,11.55,17,12.68,17,14v8H20z M12.5,11.5c0.83,0,1.5-0.67,1.5-1.5s-0.67-1.5-1.5-1.5 S11,9.17,11,10S11.67,11.5,12.5,11.5z M5.5,6c1.11,0,2-0.89,2-2s-0.89-2-2-2s-2,0.89-2,2S4.39,6,5.5,6z M7.5,22v-7H9V9 c0-1.1-0.9-2-2-2H4C2.9,7,2,7.9,2,9v6h1.5v7H7.5z M14,22v-4h1v-4c0-0.82-0.68-1.5-1.5-1.5h-2c-0.82,0-1.5,0.68-1.5,1.5v4h1v4H14z" />
      </g>
    </SvgIcon>
  );
}

function CogIcon(props) {
  return (
    <SvgIcon {...props}>
      <g transform="translate(-2.002 -2)">
        <path d="M14.17,13.71l1.4-2.42a.353.353,0,0,0-.08-.45L14.01,9.68A5.061,5.061,0,0,0,14.06,9a5.337,5.337,0,0,0-.05-.69l1.48-1.16a.353.353,0,0,0,.08-.45l-1.4-2.42a.354.354,0,0,0-.43-.15L12,4.83a5.142,5.142,0,0,0-1.18-.69l-.26-1.85A.364.364,0,0,0,10.21,2H7.41a.363.363,0,0,0-.35.3L6.8,4.15a5.363,5.363,0,0,0-1.18.69l-1.74-.7a.354.354,0,0,0-.43.15L2.05,6.71a.353.353,0,0,0,.08.45L3.61,8.32A5.061,5.061,0,0,0,3.56,9a5.337,5.337,0,0,0,.05.69L2.13,10.85a.353.353,0,0,0-.08.45l1.4,2.42a.354.354,0,0,0,.43.15l1.74-.7a5.142,5.142,0,0,0,1.18.69l.26,1.85a.364.364,0,0,0,.35.29h2.8a.363.363,0,0,0,.35-.3l.26-1.85A5.363,5.363,0,0,0,12,13.16l1.74.7A.354.354,0,0,0,14.17,13.71ZM8.81,11a2,2,0,1,1,2-2A2,2,0,0,1,8.81,11Z" />
        <path d="M21.92,18.67l-.96-.74a3.128,3.128,0,0,0,.04-.44,2.119,2.119,0,0,0-.04-.44l.95-.74a.229.229,0,0,0,.05-.29l-.9-1.55a.233.233,0,0,0-.28-.1l-1.11.45a3.018,3.018,0,0,0-.76-.44l-.17-1.18a.216.216,0,0,0-.21-.2H16.74a.217.217,0,0,0-.22.19l-.17,1.18a3.585,3.585,0,0,0-.76.44l-1.11-.45a.23.23,0,0,0-.28.1l-.9,1.55a.229.229,0,0,0,.05.29l.95.74a3.242,3.242,0,0,0,0,.88l-.95.74a.229.229,0,0,0-.05.29l.9,1.55a.233.233,0,0,0,.28.1l1.11-.45a3.018,3.018,0,0,0,.76.44l.17,1.18a.225.225,0,0,0,.22.19h1.79a.217.217,0,0,0,.22-.19l.17-1.18a3.285,3.285,0,0,0,.75-.44l1.12.45a.23.23,0,0,0,.28-.1l.9-1.55a.217.217,0,0,0-.05-.28Zm-4.29.16a1.35,1.35,0,1,1,1.35-1.35,1.35,1.35,0,0,1-1.35,1.35Z" />
      </g>
    </SvgIcon>
  );
}

export const actionBuildInType = [
  {
    value: 1,
    key: 'GIVING_LECTURE',
    name: 'Prowadzenie zajęć',
    route: 'prowadzenie-zajec',
  },
  {
    value: 2,
    key: 'ATTENDANCE',
    name: 'Obecność w ognisku / PWD',
    route: 'obecnosc',
  },
  {
    value: 3,
    key: 'WORKING_WITH_FAMILY',
    name: 'Praca z rodziną',
    route: 'rodzina',
  },
  {
    value: 4,
    key: 'COOPERATION_IN_SYSTEM_ASSISTANCE',
    name: 'Współpraca w systemie wsparcia',
    route: 'wsparcie',
  },
  {
    value: 6,
    key: 'OPEN_PARTY',
    name: 'Wydarzenia otwarte',
    route: 'wydarzenie-otwarte',
  },
];

export const getActionsList = [
  {
    link: '/dzialania-dodaj/prowadzenie-zajec',
    name: 'Prowadzenie zajęć',
    icon: <PeopleGroupIcon />,
    color: 'yellow',
    root: true,
  },
  {
    link: '/dzialania-dodaj/obecnosc',
    name: 'Obecność w ognisku / PWD',
    icon: <CheckCircleIcon />,
    root: true,
  },
  {
    link: '/dzialania-dodaj/rodzina',
    name: 'Praca z rodziną',
    icon: <FamilyIcon />,
    color: 'blue',
    root: true,
  },
  {
    link: '/dzialania-dodaj/wsparcie',
    name: 'Współpraca w systemie wsparcia',
    icon: <CogIcon />,
    root: true,
  },
  {
    link: '/dzialania-dodaj/wydarzenie-otwarte',
    name: 'Wydarzenia otwarte',
    icon: <OutdoorGrillRoundedIcon />,
    root: true,
  },
];

export const getDiagnosisList = () => [
  {
    link: '/ankieta-dodaj/',
    name: 'Ankieta',
    color: 'blue',
    icon: <DescriptionIcon style={{ color: 'white' }} />,
    root: true,
  },
  {
    link: '/diagnoza-dodaj/',
    name: 'Diagnoza',
    color: 'red',
    icon: <AssignmentIndIcon style={{ color: 'white' }} />,
    root: true,
  },
];

export const ippPlan = [
  {
    id: 'plan_goal',
    label: 'Cel planu',
    charCounter: 500,
  },
  {
    id: 'plan_child_family',
    label: 'Planowane działania z dzieckiem i rodziną',
    charCounter: 1000,
  },
  {
    id: 'implementation_plan',
    label: 'Przebieg i realizacja planu',
    charCounter: 1000,
  },
  {
    id: 'deviations_plan',
    label: 'Odstępstwa od planu / korekta planu',
    charCounter: 1000,
    optional: true,
  },
  {
    id: 'concept_evaluation',
    label: 'Ocena koncepcji',
    charCounter: 500,
    optional: true,
  },
];

export const ippSummary = [
  {
    id: 'implementation_assessment',
    label: 'Ocena realizacji',
    charCounter: 500,
  },
  {
    id: 'result_child_family',
    label: 'Ocena współpracy z dzieckiem i z jego rodziną',
    charCounter: 500,
  },
  {
    id: 'difficulties_child_family',
    label: 'Własne trudności w pracy z dzieckiem i z jego rodziną',
    charCounter: 500,
  },
  {
    id: 'conclusions_plan',
    label: 'Efekty i wnioski z realizacji planu',
    charCounter: 500,
  },
];
