// Elements
import { Accordion, AccordionSummary } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import styled from 'styled-components';

// Assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const StyledAccordion = styled(Accordion)`
  && {
    display: ${({ hide }) => (hide === 'true' ? 'none' : 'block')};
    border-radius: 0.5rem !important;

    &::before {
      display: none;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  && {
    color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    border-radius: 0.5rem !important;
    background-color: ${({ theme }) => theme.color.blue} !important;
  }
`;

export const StyledAccordionWrapper = styled.div`
  && {
    padding: 1.5rem 1rem 1.5rem;
    background: ${({ theme }) => theme.color.white};
  }
`;

export const StyledHeadingSubtitle1 = styled(Heading.Subtitle1)`
  && {
    color: ${({ theme }) => theme.color.white};
  }
`;
