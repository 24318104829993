// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  importLibrusOceny as importLibrusOcenyService,
  importLibrusFrekwencja as importLibrusFrekwencjaService,
} from '@services/librusServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Grid, DialogActions } from '@mui/material';
import Button from '@components/atoms/Button/Button';
import Dialog from '@components/organisms/Dialog/Dialog';

// Styles
import { StyledWrapper } from './DialogBeneficiaryLibrus.styles';

// Component
const DialogBeneficiaryLibrus = ({
  importLibrusOceny,
  importLibrusFrekwencja,
  open,
  setOpenFn,
  item,
}) => {
  return (
    <Dialog size="xs" open={open} setOpen={setOpenFn} title="Import z librusa">
      {item && item.id > 0 && (
        <p>
          <small>
            Dodana {item.date_added} przez {item.who_added}
          </small>
          <br />
          {item.who_edited && (
            <small>
              Edytowana {item.date_edited} przez {item.who_edited}
            </small>
          )}
        </p>
      )}
      <>
        <Formik
          initialValues={{
            file: null,
          }}
          validationSchema={Yup.object({
            file: Yup.mixed().required('pole wymagane!'),
          })}
          enableReinitialize
          onSubmit={(values) => {
            // eslint-disable-next-line no-undef
            const data = new FormData();
            data.append('file', values.file);
            // console.log(values.file);
            // console.log(data);
            importLibrusOceny(data);
            setOpenFn(false);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <>
              <StyledWrapper container spacing={2} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                  <Button variant="contained" component="label">
                    Import ocen
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => {
                        // console.log(e.target.files[0]);
                        setFieldValue('file', e.target.files[0]);
                      }}
                      hidden
                    />
                  </Button>
                  <br />
                  <span>{values.file && values.file.name}</span>
                  <ErrorMessage name="file">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              </StyledWrapper>
              <DialogActions>
                <Button onClick={() => setOpenFn(false)} color="primary">
                  Wróć
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="outlined">
                  importuj oceny
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
        <hr />
        <Formik
          initialValues={{
            file: null,
          }}
          validationSchema={Yup.object({
            file: Yup.mixed().required('pole wymagane!'),
          })}
          enableReinitialize
          onSubmit={(values) => {
            // eslint-disable-next-line no-undef
            const data = new FormData();
            data.append('file', values.file);

            importLibrusFrekwencja(data);
            setOpenFn(false);
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <>
              <StyledWrapper container spacing={2} style={{ marginTop: 0 }}>
                <Grid item xs={12}>
                  <Button variant="contained" component="label">
                    Import frekwencji
                    <input
                      type="file"
                      name="file"
                      onChange={(e) => {
                        setFieldValue('file', e.target.files[0]);
                      }}
                      hidden
                    />
                  </Button>
                  <br />
                  <span>{values.file && values.file.name}</span>
                  <ErrorMessage name="file">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              </StyledWrapper>
              <DialogActions>
                <Button onClick={() => setOpenFn(false)} color="primary">
                  Wróć
                </Button>
                <Button onClick={handleSubmit} color="primary" variant="outlined">
                  importuj frekwencję
                </Button>
              </DialogActions>
            </>
          )}
        </Formik>
      </>
    </Dialog>
  );
};

DialogBeneficiaryLibrus.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,

  item: PropTypes.shape({
    id: PropTypes.number,
    cwa_id: PropTypes.number,
    date: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    date_added: PropTypes.string,
    type_value: PropTypes.string,
    who_added: PropTypes.string,
    who_edited: PropTypes.string,
    date_edited: PropTypes.string,
  }),
  importLibrusOceny: PropTypes.func,
  importLibrusFrekwencja: PropTypes.func,
};

DialogBeneficiaryLibrus.defaultProps = {
  item: {},
  importLibrusOceny: null,
  importLibrusFrekwencja: null,
};

const mapDispatchToProps = (dispatch) => ({
  importLibrusOceny: (data) => dispatch(importLibrusOcenyService(data)),
  importLibrusFrekwencja: (data) => dispatch(importLibrusFrekwencjaService(data)),
});

export default connect(null, mapDispatchToProps)(DialogBeneficiaryLibrus);
