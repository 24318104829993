// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { saveBeneficiaryDocument as saveBeneficiaryDocumentService } from '@services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Grid, DialogActions } from '@mui/material';
import Button from '@components/atoms/Button/Button';
import Input from '@components/atoms/Input/Input';
import Dialog from '@components/organisms/Dialog/Dialog';

// Styles
import { StyledWrapper } from './DialogBeneficiaryDocuments.styles';

// Component
const DialogBeneficiaryDocuments = ({
  beneficiaryId,
  cwpid,
  open,
  setOpenFn,
  onlyRead,
  item,
  saveBeneficiaryDocument,
  fetchDocumentsAfterSave,
}) => {
  return (
    <Dialog size="xs" open={open} setOpen={setOpenFn} title="Dodaj dokument">
      {item && item.id > 0 && (
        <p>
          <small>
            Dodana {item.date_added} przez {item.who_added}
          </small>
          <br />
          {item.who_edited && (
            <small>
              Edytowana {item.date_edited} przez {item.who_edited}
            </small>
          )}
        </p>
      )}
      <Formik
        initialValues={{
          cwp_id: cwpid,
          name: '',
          file: null,
        }}
        validationSchema={Yup.object({
          name: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          file: Yup.mixed().required('pole wymagane!'),
        })}
        enableReinitialize
        onSubmit={(values) => {
          // eslint-disable-next-line no-undef
          const data = new FormData();
          data.append('file', values.file);
          data.append('values', JSON.stringify(values));

          saveBeneficiaryDocument(beneficiaryId, data, fetchDocumentsAfterSave);
          setOpenFn(false);
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange, values }) => (
          <>
            <StyledWrapper container spacing={2} style={{ marginTop: 0 }}>
              <Grid item xs={12}>
                <Input
                  disabled={onlyRead}
                  label="Tytuł dokumentu"
                  name="name"
                  value={values.name}
                  variant="filled"
                  onChange={handleChange}
                />
                <ErrorMessage name="name">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" component="label">
                  Załącz plik
                  <input
                    type="file"
                    name="file"
                    onChange={(e) => {
                      setFieldValue('file', e.target.files[0]);
                    }}
                    hidden
                  />
                </Button>
                <br />
                <span>{values.file && values.file.name}</span>
                <ErrorMessage name="file">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
            </StyledWrapper>
            <DialogActions>
              <Button onClick={() => setOpenFn(false)} color="primary">
                Wróć
              </Button>
              <Button onClick={handleSubmit} color="primary" variant="outlined">
                Dodaj
              </Button>
            </DialogActions>
          </>
        )}
      </Formik>
      <></>
    </Dialog>
  );
};

DialogBeneficiaryDocuments.propTypes = {
  beneficiaryId: PropTypes.number,
  cwpid: PropTypes.number,
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  onlyRead: PropTypes.bool,
  fetchDocumentsAfterSave: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    cwa_id: PropTypes.number,
    date: PropTypes.string,
    name: PropTypes.string,
    content: PropTypes.string,
    date_added: PropTypes.string,
    type_value: PropTypes.string,
    who_added: PropTypes.string,
    who_edited: PropTypes.string,
    date_edited: PropTypes.string,
  }),
  saveBeneficiaryDocument: PropTypes.func,
};

DialogBeneficiaryDocuments.defaultProps = {
  cwpid: null,
  beneficiaryId: null,
  fetchDocumentsAfterSave: true,
  item: {},
  onlyRead: false,
  saveBeneficiaryDocument: null,
};

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryDocument: (id, data, edit, fetchDocumentsAfterSave) =>
    dispatch(saveBeneficiaryDocumentService(id, data, edit, fetchDocumentsAfterSave)),
});

export default connect(null, mapDispatchToProps)(DialogBeneficiaryDocuments);
