// React
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

// Redux
import { connect } from 'react-redux';

// Elements
import Heading from '@components/atoms/Heading/Heading';
import TableTemplate from '@templates/TableTemplate';

import { showAlert as showAlertAction } from '@actions/appActions';
import { JUZ_ZANIMIZOWANO } from '@constants/alerts';

import {
  fetchBeneficiaryRodo as fetchBeneficiaryRodoService,
  anonymizeCWO as anonymizeCWOService,
  anonymizeCWP as anonymizeCWPService,
} from '@services/beneficiaryServices';
import { clearBeneficiaryRodo as clearBeneficiaryRodoAction } from '@actions/beneficiaryActions';

// Styles
import { StyledTitle, StyledWrapper } from './BeneficiaryRodo.styles';
import './BeneficiaryRodo.scss';

// Data
const headCellsAdministratorsOrgs = [
  { type: 'organization', label: 'Administrator danych' },
  { type: 'date_start', label: 'Data dodania do organizacji' },
  { type: 'date_end', label: 'Data zakończenia administrowania' },
  { type: 'was_anonymized', label: 'Zanonimizowano' },
];

// Data
const headCellsAdministrators = [
  { type: 'program', label: 'Program' },
  { type: 'organization', label: 'Administrator danych' },
  { type: 'date_start', label: 'Data dodania do programu' },
  { type: 'date_end', label: 'Data zakończenia udziału w programie' },
  { type: 'program_end', label: 'Data zakończenia programu' },
  { type: 'was_anonymized', label: 'Zanonimizowano' },
];
const headCellsProcessors = [
  { type: 'program', label: 'Program' },
  { type: 'organization_entrusting', label: 'Administrator powierzający' },
  { type: 'organization_processing', label: 'Podmiot przetwarzający' },
  { type: 'custom_data', label: 'Zakres przetwarzania' },
  { type: 'date_start', label: 'Data rozpoczęcia udziału w programie' },
  { type: 'date_end', label: 'Data zakończenia udziału w programie' },
  { type: 'processing_date_end', label: 'Data zakończenia przetwarzania danych osobowych' },
  { type: 'program_end', label: 'Data zakończenia programu' },
  { type: 'was_anonymized', label: 'Zanonimizowano' },
];

// Component
const BeneficiaryRodo = ({
  clearBeneficiaryRodo,
  fetchBeneficiaryRodo,
  anonymizeCWO,
  anonymizeCWP,
  rodo,
  showAlert,
  beneficiary,
}) => {
  const { id } = useParams();
  const { administrators_orgs, administrators, coadministrators, processors } = rodo;

  useEffect(() => {
    clearBeneficiaryRodo();
  }, []);

  const triggerFetchRodo = useCallback(
    (myid) => fetchBeneficiaryRodo(myid),
    [fetchBeneficiaryRodo],
  );

  useEffect(() => {
    triggerFetchRodo(id);
  }, [id]);
  return (
    <div className="rodo">
      {beneficiary && beneficiary.name === 'anonimizacja' ? (
        <StyledTitle>
          <Heading.Subtitle1>Beneficjent został zanonimizwany</Heading.Subtitle1>
        </StyledTitle>
      ) : (
        <StyledWrapper className="rodo" container spacing={2}>
          <Grid item xs={10}>
            <StyledTitle>
              <Heading.Subtitle1>Administratorzy danych osobowych (organizacje)</Heading.Subtitle1>
            </StyledTitle>
            <TableTemplate
              headCells={headCellsAdministratorsOrgs}
              data={administrators_orgs}
              isNav={false}
              tableTools
              noFilter
              disableNav
              rowActions={[
                {
                  name: 'anonimizuj',
                  action: (row) => {
                    if (row.was_anonymized === 'tak') {
                      showAlert(JUZ_ZANIMIZOWANO);
                    } else {
                      anonymizeCWO(row.cwo_id);
                    }
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={10}>
            <StyledTitle>
              <Heading.Subtitle1>Administratorzy danych osobowych</Heading.Subtitle1>
            </StyledTitle>
            <TableTemplate
              headCells={headCellsAdministrators}
              data={administrators}
              isNav={false}
              tableTools
              noFilter
              disableNav
              rowActions={[
                {
                  name: 'anonimizuj',
                  action: (row) => {
                    if (row.was_anonymized === 'tak') {
                      showAlert(JUZ_ZANIMIZOWANO);
                    } else {
                      anonymizeCWP(row.cwp_id);
                    }
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={10}>
            <StyledTitle>
              <Heading.Subtitle1>Współadministratorzy danych osobowych</Heading.Subtitle1>
            </StyledTitle>
            <TableTemplate
              headCells={headCellsAdministrators}
              data={coadministrators}
              isNav={false}
              tableTools
              noFilter
              disableNav
              rowActions={[
                {
                  name: 'anonimizuj',
                  action: (row) => {
                    if (row.was_anonymized === 'tak') {
                      showAlert(JUZ_ZANIMIZOWANO);
                    } else {
                      anonymizeCWP(row.cwp_id);
                    }
                  },
                },
              ]}
            />
          </Grid>
          <Grid item xs={10}>
            <StyledTitle>
              <Heading.Subtitle1>Podmioty przetwarzające dane osobowe</Heading.Subtitle1>
            </StyledTitle>
            <TableTemplate
              headCells={headCellsProcessors}
              data={processors}
              isNav={false}
              tableTools
              noFilter
              disableNav
              rowActions={[
                {
                  name: 'anonimizuj',
                  action: (row) => {
                    if (row.was_anonymized === 'tak') {
                      showAlert(JUZ_ZANIMIZOWANO);
                    } else {
                      anonymizeCWP(row.cwp_id);
                    }
                  },
                },
              ]}
            />
          </Grid>
        </StyledWrapper>
      )}
    </div>
  );
};

BeneficiaryRodo.propTypes = {
  clearBeneficiaryRodo: PropTypes.func,
  fetchBeneficiaryRodo: PropTypes.func,
  anonymizeCWO: PropTypes.func,
  anonymizeCWP: PropTypes.func,
  showAlert: PropTypes.func,
  rodo: PropTypes.shape({
    administrators: PropTypes.arrayOf(PropTypes.any),
    administrators_orgs: PropTypes.arrayOf(PropTypes.any),
    coadministrators: PropTypes.arrayOf(PropTypes.any),
    processors: PropTypes.arrayOf(PropTypes.any),
  }),
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryRodo.defaultProps = {
  clearBeneficiaryRodo: null,
  fetchBeneficiaryRodo: null,
  anonymizeCWO: null,
  anonymizeCWP: null,
  showAlert: null,
  rodo: { administrators: [], administrators_orgs: [], coadministrators: [], processors: [] },
  beneficiary: null,
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  beneficiary: beneficiaryReducer.beneficiary,
  rodo: beneficiaryReducer.rodo,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryRodo: () => dispatch(clearBeneficiaryRodoAction()),
  fetchBeneficiaryRodo: (id) => dispatch(fetchBeneficiaryRodoService(id)),
  anonymizeCWO: (cwo_id) => dispatch(anonymizeCWOService(cwo_id)),
  anonymizeCWP: (cwo_id) => dispatch(anonymizeCWPService(cwo_id)),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryRodo);
