import {
  CHANGE_FONT_SIZE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  CLOSE_SNACKBAR,
  CHANGE_CONTRAST,
  REDIRECT,
  CLEAR_REDIRECT,
  SHOW_ALERT,
} from '@constants/actionTypes';

const initialState = {
  fontSize: 10,
  contrast: 'false',
  isPending: false,
  activeRequests: 0,
  snack: { type: '', message: '', isActive: false, code: 0 },
  redirectUrl: null,
};

const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_FONT_SIZE:
      return { ...state, fontSize: payload };
    case CHANGE_CONTRAST:
      return { ...state, contrast: payload };
    case CLOSE_SNACKBAR:
      return { ...state, snack: { ...state.snack, isActive: payload.isActive } };
    case FETCH_REQUEST:
      return {
        ...state,
        isPending: true,
        activeRequests: state.activeRequests + 1, // Zwiększ licznik przy każdym nowym żądaniu
      };
    case FETCH_SUCCESS:
    case FETCH_FAILURE:
      return {
        ...state,
        isPending: state.activeRequests - 1 > 0, // Ustaw isPending na true, jeśli są aktywne żądania
        activeRequests: state.activeRequests - 1,
        snack: type === FETCH_SUCCESS && payload.status ? payload.status : payload.error,
      };
    // case FETCH_REQUEST:
    // case FETCH_SUCCESS:
    //   if (payload.status) {
    //     return { ...state, isPending: payload.isPending, snack: payload.status };
    //   }
    //   return { ...state, isPending: payload.isPending };
    // case FETCH_FAILURE:
    //   return { ...state, isPending: payload.isPending, snack: payload.error };
    case REDIRECT:
      return {
        ...state,
        redirectUrl: payload.url,
        ...(payload.snack ? { snack: payload.snack } : null),
      };
    case SHOW_ALERT:
      return {
        ...state,
        ...(payload.snack ? { snack: payload.snack } : null),
      };
    case CLEAR_REDIRECT:
      return { ...state, redirectUrl: null };
    default:
      return state;
  }
};

export default appReducer;
