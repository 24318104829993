// React
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Service
import { fetchUserSimpleList as fetchUserSimpleListService } from '@services/userServices';
import { sendNewMessage as sendMessageService } from '@services/messageServices';

// Actions
import { clearUsers as clearUsersAction } from '@actions/userActions';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Box, Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Input from '@components/atoms/Input/Input';
import MultiSelectChips from '@components/molecules/MultiSelectChips/MultiSelectChips';

// Styles
import { StyledGrid, StyledTitle, StyledButton } from './AddMessages.styles';

// Component
const AddMessages = ({ clearUsers, fetchUserSimpleList, users, sendMessage }) => {
  const history = useHistory();
  const [dataUsers, setDataUsers] = useState([]);

  const { user_id } = useParams();

  useEffect(() => {
    clearUsers();
  }, []);

  const triggerFetchUserSimpleList = useCallback(
    () => fetchUserSimpleList(),
    [fetchUserSimpleList],
  );

  useEffect(() => {
    triggerFetchUserSimpleList();
  }, [triggerFetchUserSimpleList]);

  useEffect(() => {
    const newData = [];

    users.map((el) => newData.push({ id: el.id, value: el.id, name: `${el.name} ${el.surname}` }));

    newData.sort((a, b) => (a.name < b.name ? -1 : 1));

    setDataUsers(newData);
  }, [users]);

  return (
    <>
      <StyledTitle>
        <Heading.Subtitle1>Stwórz wątek</Heading.Subtitle1>
      </StyledTitle>
      <StyledGrid container spacing={2}>
        <Grid item xs={6}>
          <Formik
            initialValues={{
              subject: '',
              recipients: user_id ? dataUsers.filter((u) => u.id === parseInt(user_id, 10)) : [],
              content: '',
            }}
            enableReinitialize
            validationSchema={Yup.object({
              subject: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
              recipients: Yup.array().min(1, 'Minimum 1 odbiorca'),
              content: Yup.string().min(2, 'Minimum 2 znaków').required('pole wymagane!'),
            })}
            onSubmit={(values) => {
              sendMessage(values);
              history.push('/wiadomosci');
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => {
              return (
                <>
                  <Box mb={2}>
                    <Input
                      label="Temat wątku"
                      variant="filled"
                      name="subject"
                      onChange={handleChange}
                      value={values.subject}
                    />
                    <ErrorMessage name="subject">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Box>
                  <Box mb={2}>
                    <MultiSelectChips
                      isEditable={dataUsers.length > 0 || false}
                      data={dataUsers}
                      label="Odbiorcy"
                      fieldName="recipients"
                      values_data={values.recipients}
                      setFieldValue={setFieldValue}
                    />
                    <ErrorMessage name="recipients">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Box>
                  <Box>
                    <Input
                      label="Wiadomość"
                      variant="filled"
                      name="content"
                      rows={10}
                      multiline
                      charCounter={2000}
                      setField={setFieldValue}
                      value={values.content}
                    />
                    <ErrorMessage name="content">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Box>
                  <Box>
                    <StyledButton variant="outlined" onClick={handleSubmit}>
                      Wyślij
                    </StyledButton>
                  </Box>
                </>
              );
            }}
          </Formik>
        </Grid>
      </StyledGrid>
    </>
  );
};

AddMessages.propTypes = {
  clearUsers: PropTypes.func,
  fetchUserSimpleList: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.any),
  sendMessage: PropTypes.func,
};

AddMessages.defaultProps = {
  clearUsers: null,
  fetchUserSimpleList: null,
  users: [],
  sendMessage: null,
};

const mapStateToProps = ({ userReducer }) => ({
  users: userReducer.users,
});

const mapDispatchToProps = (dispatch) => ({
  clearUsers: () => dispatch(clearUsersAction()),
  fetchUserSimpleList: () => dispatch(fetchUserSimpleListService()),
  sendMessage: (data) => dispatch(sendMessageService(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddMessages);
