// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik } from 'formik';

// Services
import { saveMySettings as saveMySettingsService } from '@services/userServices';

// Data
import {
  userDesktopSettings,
  userDesktopSettingInstructor,
  userDesktopSettingGuardian,
} from '@constants/selectLists';

// Utils
import { isInstructorOnly, isGuardianOnly } from '@utils/functions';

// Elements
import { Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import EditButton from '@components/atoms/EditButton/EditButton';
import Checkbox from '@components/atoms/Checkbox/Checkbox';

// Styles
import { StyledGrid, StyledTitle, StyledButton, StyledActions } from './UserDesktop.styles';

// Component
const UserDesktop = ({ saveMySettings, me }) => {
  const [isEditable, setIsEditable] = useState(false);
  const { user_settings } = me;

  const calculateDestop = () => {
    if (isInstructorOnly(me.user_role)) {
      return userDesktopSettingInstructor;
    }
    if (isGuardianOnly(me.user_role)) {
      return userDesktopSettingGuardian;
    }
    return userDesktopSettings;
  };
  const desktopSettings = calculateDestop();

  return (
    <>
      <StyledTitle>
        <Heading.Subtitle1>WŁĄCZ LUB WYŁĄCZ WIDGET</Heading.Subtitle1>
        <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
      </StyledTitle>
      <Formik
        initialValues={{
          user_settings: desktopSettings.map((item) => {
            return user_settings && user_settings[item.value] ? user_settings[item.value] : false;
          }),
        }}
        enableReinitialize
        onSubmit={(values) => {
          const results = {};
          desktopSettings.forEach((item, index) => {
            results[item.value] = values.user_settings[index];
          });
          saveMySettings(results);
        }}
      >
        {({ values, handleChange, handleSubmit }) => {
          return (
            <StyledGrid container spacing={2}>
              {desktopSettings.map((item, index) => (
                <Grid key={item.value} item xs={6}>
                  <Checkbox
                    isEditable={isEditable || false}
                    label={item.name}
                    name={`user_settings[${index}]`}
                    value={values.user_settings[index]}
                    onChange={handleChange}
                  />
                </Grid>
              ))}

              <StyledActions item xs={12}>
                <StyledButton disabled={!isEditable} variant="outlined" onClick={handleSubmit}>
                  ZAPISZ
                </StyledButton>
              </StyledActions>
            </StyledGrid>
          );
        }}
      </Formik>
    </>
  );
};

UserDesktop.propTypes = {
  saveMySettings: PropTypes.func,
  me: PropTypes.shape({
    user_settings: PropTypes.any || PropTypes.shape(PropTypes.any),
    user_role: PropTypes.arrayOf(PropTypes.any),
  }),
};

UserDesktop.defaultProps = {
  saveMySettings: null,
  me: null,
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  saveMySettings: (values) => dispatch(saveMySettingsService(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDesktop);
