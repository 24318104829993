// React
import React from 'react';
import PropTypes from 'prop-types';

// Date FNS
import 'date-fns';

// Elements
import { TextField } from '@mui/material';

// Styles
import {
  StyledSelect,
  StyledFormHelperText,
  StyledFormControl,
  StyledMenuItem,
  StyledInputLabel,
  StyledChip,
  StyledAutocomplete,
} from './Select.styles';

// Component
const Select = ({
  label,
  data,
  variant,
  disabled,
  helperText,
  onChange,
  selectValue,
  noborder,
  text,
  multiple,
  setProgramIDFn,
  allowNull,
}) => (
  <StyledFormControl disabled={disabled || false} variant={variant} noborder={noborder ? 1 : 0}>
    {data && data.length <= 5 && label && !allowNull && (
      <StyledInputLabel>{label}</StyledInputLabel>
    )}
    {data && (data.length > 5 || allowNull) ? (
      <StyledAutocomplete
        multiple={multiple || false}
        id={text}
        name={text}
        options={data}
        disabled={disabled}
        getOptionLabel={(option) => (option.name ? option.name : '')}
        value={(data.length > 0 && data.find((el) => el.value === selectValue)) || null}
        onChange={(e, newValue) => {
          if (newValue) {
            onChange(text, newValue.value);

            if (setProgramIDFn) {
              setProgramIDFn(newValue.value);
            }
          } else {
            onChange(text, null);
          }
        }}
        renderInput={(params) => <TextField {...params} label={label} variant={variant} />}
      />
    ) : (
      <StyledSelect
        multiple={multiple || false}
        name={text}
        onChange={(e) => {
          onChange(text, e.target.value);

          if (setProgramIDFn) {
            setProgramIDFn(e.target.value);
          }
        }}
        value={selectValue || ''}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
        renderValue={
          multiple
            ? (selected) => (
                <div>
                  {selected.map((val) => (
                    <StyledChip key={val} label={val} />
                  ))}
                </div>
              )
            : null
        }
      >
        {data &&
          data.map(({ value, name }) => (
            <StyledMenuItem key={value} value={value}>
              {name}
            </StyledMenuItem>
          ))}
      </StyledSelect>
    )}
    <StyledFormHelperText>{helperText || null}</StyledFormHelperText>
  </StyledFormControl>
);

Select.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
        }),
      ]),
      name: PropTypes.string,
    }),
  ),
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  allowNull: PropTypes.bool,
  selectValue: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.string,
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  noborder: PropTypes.bool,
  text: PropTypes.string,
  multiple: PropTypes.bool,
  setProgramIDFn: PropTypes.func,
};

Select.defaultProps = {
  label: '',
  variant: 'standard',
  disabled: false,
  data: [],
  helperText: '',
  onChange: null,
  selectValue: null,
  allowNull: false,
  noborder: false,
  text: '',
  multiple: false,
  setProgramIDFn: null,
};

export default Select;
