// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Breadcrumb
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  WORKING_WITH_FAMILY,
  COOPERATION_IN_SYSTEM_ASSISTANCE,
} from '@constants/ActionBuildInType';

// Actions
import { clearActionAction } from '@actions/actionActions';
import { clearProgramAction } from '@actions/programActions';
import { clearTaskAction } from '@actions/taskActions';

//  Data
import { actionsTabs as renderViewArray } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const Action = ({ details, clearAction, clearProgram, clearTask }) => {
  const [filteredRenderViewArray, setFilteredRenderViewArray] = useState([renderViewArray[0]]);

  useEffect(() => {
    clearTask();
    clearProgram();
    clearAction();

    return () => {
      clearTask();
      clearProgram();
      clearAction();
    };
  }, [clearTask, clearProgram, clearAction]);

  useEffect(() => {
    if (details) {
      if (details.first_card_only) {
        setFilteredRenderViewArray([renderViewArray[0]]);
      } else if (details.build_in_type) {
        if (
          details.build_in_type === WORKING_WITH_FAMILY ||
          details.build_in_type === COOPERATION_IN_SYSTEM_ASSISTANCE
        ) {
          setFilteredRenderViewArray(
            renderViewArray.filter((v) => {
              return v.key !== 'dziennik-obecnosci';
            }),
          );
        } else {
          setFilteredRenderViewArray(
            renderViewArray.filter((v) => {
              return v.key !== 'spotkania';
            }),
          );
        }
      } else {
        setFilteredRenderViewArray(renderViewArray);
      }
    } else {
      setFilteredRenderViewArray(renderViewArray);
    }
  }, [details, renderViewArray]);

  return (
    <>
      {details && details.program && (
        <BreadcrumbsItem to={`/programy/${details.program.id}`} order={2}>
          Program: {details.program.name}
        </BreadcrumbsItem>
      )}
      {details && details.task && (
        <BreadcrumbsItem to={`/zadania/${details.task.id}`} order={3}>
          Zadanie: {details.task.name}
        </BreadcrumbsItem>
      )}
      {details && details.id && (
        <BreadcrumbsItem to={`/dzialania/${details.id}`} order={4}>
          Działanie: {details.name}
        </BreadcrumbsItem>
      )}
      <Tabs
        renderViewArray={filteredRenderViewArray}
        program_id={details && details.program && details.program.id}
      />
    </>
  );
};

Action.propTypes = {
  details: PropTypes.objectOf(PropTypes.any),
  clearAction: PropTypes.func,
  clearTask: PropTypes.func,
  clearProgram: PropTypes.func,
};

Action.defaultProps = {
  details: null,
  clearAction: null,
  clearTask: null,
  clearProgram: null,
};
const mapDispatchToProps = (dispatch) => ({
  clearAction: () => dispatch(clearActionAction()),
  clearTask: () => dispatch(clearTaskAction()),
  clearProgram: () => dispatch(clearProgramAction()),
});
const mapStateToProps = ({ actionReducer }) => ({
  details: actionReducer.action,
});

export default connect(mapStateToProps, mapDispatchToProps)(Action);
