// React
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
// Services
import {
  saveBeneficiaryGuardianDetails as saveBeneficiaryGuardianDetailsService,
  fetchBeneficiaryList as fetchBeneficiaryListService,
} from '@services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import { guardianTypeAdm, guardianTypeFamily } from '@constants/selectLists';

// Elements
import { DialogActions, Grid } from '@mui/material';
import Dialog from '@components/organisms/Dialog/Dialog';
import Heading from '@components/atoms/Heading/Heading';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import AddNewBeneficiary from '@components/molecules/AddNewBeneficiary/AddNewBeneficiary';
import AddToProgram from '@components/molecules/AddToProgram/AddToProgram';
import AddToOrganization from '@components/molecules/AddToOrganization/AddToOrganization';

// Styles
import { StyledButton, StyledWrapper } from './BeneficiaryGuardianForm.styles';

// Component
const BeneficiaryGuardianForm = ({
  openDialog,
  setOpenDialog,
  osoba,
  programId,
  programName,
  organizationId,
  organizationName,
  family,
  osoba_org_pro_name,
  connectionWithProgram,
  connectionWithOrganization,
  saveBeneficiaryGuardianDetails,
  dataBeneficiaries,
  fetchBeneficiaryList,
}) => {
  const { id } = useParams();
  const [newBeneficiary, setNewBeneficiary] = useState(null);
  const [wybierzZBazy, setWybierzZBazy] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  return (
    <>
      <Dialog open={openDialog} setOpen={setOpenDialog} size="md">
        <>
          <Formik
            initialValues={{
              id: osoba && osoba.id ? osoba.id : null,
              isBeneficiaryId: osoba && osoba.isBeneficiaryId ? osoba.isBeneficiaryId : null,
              name: osoba && osoba.name ? osoba.name : '',
              surname: osoba && osoba.surname ? osoba.surname : '',
              type: osoba && osoba.type ? osoba.type.value : '',
              email: osoba && osoba.email ? osoba.email : '',
              phone: osoba && osoba.phone ? osoba.phone : '',
              legalGuardian: osoba && osoba.legalGuardian ? osoba.legalGuardian : false,
              connectionWithProgram,
              connectionWithOrganization,
              addToProgram: false,
              addToOrganization: false,
            }}
            enableReinitialize
            validationSchema={Yup.object({
              name: wybierzZBazy
                ? null
                : Yup.string()
                    .min(2, 'Minimum 2 znaki')
                    .max(30, 'Maksymalnie 30 znaków')
                    .required('pole wymagane!'),
              surname: wybierzZBazy
                ? null
                : Yup.string()
                    .min(2, 'Minimum 2 znaki')
                    .max(30, 'Maksymalnie 30 znaków')
                    .required('pole wymagane!'),
              type: Yup.string().required('pole wymagane!'),
              email: Yup.string().email(),
              phone: Yup.string().matches(phoneRegExp, 'Numer telefonu jest nieprawidłowy'),
              isBeneficiaryId: wybierzZBazy ? Yup.number().required() : null,
            })}
            onSubmit={(values) => {
              setOpenDialog();
              saveBeneficiaryGuardianDetails(id, { ...values, newBeneficiary });
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
              <StyledWrapper container spacing={2}>
                <Grid item xs={12}>
                  <Heading.H6>
                    {osoba ? 'Edycja kontaktu: ' : 'Dodanie kontaktu '}
                    {osoba && `${osoba.name} ${osoba.surname}`}{' '}
                    {osoba_org_pro_name && ` w ${osoba_org_pro_name}`}
                  </Heading.H6>
                </Grid>
                <Grid item xs={6}>
                  <Select
                    label="Wybierz typ opiekuna"
                    data={family ? guardianTypeFamily : guardianTypeAdm}
                    variant="filled"
                    name="type"
                    text="type"
                    selectValue={values.type}
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="type">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                {family && (
                  <Grid item xs={6}>
                    <Checkbox
                      label="Opiekun prawny"
                      name="legalGuardian"
                      value={values.legalGuardian}
                      onChange={handleChange}
                      isEditable
                    />
                    <ErrorMessage name="legalGuardian">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                )}
                {!family && <Grid item xs={6} />}
                {!osoba && family && (
                  <>
                    <Grid item xs={12}>
                      <Checkbox
                        label="Wybierz beneficjenta z bazy lub dodaj jako nowego beneficjenta"
                        name="wybierzZBazy"
                        value={wybierzZBazy}
                        onChange={(e, v) => setWybierzZBazy(v)}
                        isEditable
                      />
                    </Grid>
                    {wybierzZBazy && (
                      <Grid item xs={12}>
                        <Select
                          label="Wybierz beneficjenta z bazy"
                          data={[{ name: 'Dodaj nowego', value: 0 }, ...dataBeneficiaries]}
                          variant="filled"
                          name="isBeneficiaryId"
                          text="isBeneficiaryId"
                          selectValue={values.isBeneficiaryId}
                          onChange={setFieldValue}
                        />
                        <ErrorMessage name="isBeneficiaryId">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                    )}
                    {wybierzZBazy &&
                      values.isBeneficiaryId !== null &&
                      (newBeneficiary || values.isBeneficiaryId > 0) &&
                      programId > 0 && (
                        <Grid item xs={12}>
                          <Checkbox
                            label={`Dodaj opiekuna jako beneficjenta programu ${programName}`}
                            name="addToProgram"
                            value={values.addToProgram}
                            onChange={handleChange}
                            isEditable
                          />
                        </Grid>
                      )}
                    {values.isBeneficiaryId === 0 && (
                      <Grid item xs={12}>
                        <AddNewBeneficiary
                          // setOpenFn={setOpenFn}

                          setNewBeneficiary={(v) => setNewBeneficiary(v)}
                        />
                      </Grid>
                    )}

                    {values.addToProgram && (
                      <Grid item xs={12}>
                        <AddToProgram
                          beneficiaryId={values.isBeneficiaryId}
                          newBeneficiary={newBeneficiary}
                          addToProgramId={programId}
                          updateBeneficiaryId={(newBenId) => {
                            setFieldValue('isBeneficiaryId', newBenId);
                            fetchBeneficiaryList();
                          }}
                        />
                      </Grid>
                    )}

                    {wybierzZBazy &&
                      values.isBeneficiaryId !== null &&
                      (newBeneficiary || values.isBeneficiaryId > 0) &&
                      organizationId > 0 && (
                        <Grid item xs={12}>
                          <Checkbox
                            label="Dodaj opiekuna jako beneficjenta organizacji"
                            name="addToOrganization"
                            value={values.addToOrganization}
                            onChange={handleChange}
                            isEditable
                          />
                        </Grid>
                      )}
                    {wybierzZBazy && values.addToOrganization && (
                      <Grid item xs={12}>
                        <AddToOrganization
                          beneficiaryId={values.isBeneficiaryId}
                          newBeneficiary={newBeneficiary}
                          organization={{ id: organizationId, name: organizationName }}
                          updateBeneficiaryId={(newBenId) => {
                            setFieldValue('isBeneficiaryId', newBenId);
                            fetchBeneficiaryList();
                          }}
                        />
                      </Grid>
                    )}

                    {!wybierzZBazy && (
                      <Grid item xs={12}>
                        <Heading.Body1>Wprowadź dane opiekuna</Heading.Body1>
                      </Grid>
                    )}
                  </>
                )}
                {!wybierzZBazy && (
                  <>
                    <Grid item xs={6}>
                      <Input
                        label="Imię"
                        variant="filled"
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        disabled={values.isBeneficiaryId !== null && values.isBeneficiaryId >= 0}
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        label="Nazwisko"
                        variant="filled"
                        type="text"
                        name="surname"
                        value={values.surname}
                        onChange={handleChange}
                        disabled={values.isBeneficiaryId !== null && values.isBeneficiaryId >= 0}
                      />
                      <ErrorMessage name="surname">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  </>
                )}
                <Grid item xs={6}>
                  <Input
                    label="Email"
                    variant="filled"
                    type="text"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="email">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6}>
                  <Input
                    label="Telefon kontaktowy"
                    variant="filled"
                    name="phone"
                    type="text"
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="phone">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                  <StyledButton variant="outlined" onClick={() => setOpenDialog()}>
                    Anuluj
                  </StyledButton>
                  <StyledButton
                    disabled={
                      !(newBeneficiary || values.isBeneficiaryId > 0) &&
                      (values.isBeneficiaryId === null || values.isBeneficiaryId === 0) &&
                      wybierzZBazy
                    }
                    variant="outlined"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Zapisz
                  </StyledButton>
                </Grid>
              </StyledWrapper>
            )}
          </Formik>
        </>
        <DialogActions />
      </Dialog>
    </>
  );
};

BeneficiaryGuardianForm.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  osoba: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    type: PropTypes.shape({ value: PropTypes.number }),
    phone: PropTypes.string,
    email: PropTypes.string,
    legalGuardian: PropTypes.bool,
    isBeneficiaryId: PropTypes.number,
  }),
  family: PropTypes.bool,
  programId: PropTypes.number,
  programName: PropTypes.string,
  organizationId: PropTypes.number,
  organizationName: PropTypes.string,
  osoba_org_pro_name: PropTypes.string,
  connectionWithProgram: PropTypes.number,
  connectionWithOrganization: PropTypes.number,
  saveBeneficiaryGuardianDetails: PropTypes.func,
  dataBeneficiaries: PropTypes.arrayOf(PropTypes.any),
  fetchBeneficiaryList: PropTypes.func,
};

BeneficiaryGuardianForm.defaultProps = {
  openDialog: false,
  setOpenDialog: null,
  programId: null,
  programName: null,
  organizationId: null,
  organizationName: null,
  osoba: null,
  family: null,
  osoba_org_pro_name: null,
  connectionWithProgram: null,
  connectionWithOrganization: null,
  saveBeneficiaryGuardianDetails: null,
  dataBeneficiaries: [],
  fetchBeneficiaryList: null,
};

const mapDispatchToProps = (dispatch) => ({
  fetchBeneficiaryList: () => dispatch(fetchBeneficiaryListService()),
  saveBeneficiaryGuardianDetails: (id, data) =>
    dispatch(saveBeneficiaryGuardianDetailsService(id, data)),
});

export default connect(null, mapDispatchToProps)(BeneficiaryGuardianForm);
