// Elements
import { FormControl, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

// Styles
import styled, { css } from 'styled-components';

export const StyledAutocomplete = styled(Autocomplete)`
  && {
    color: ${({ theme }) => theme.color.darkGray};

    font-size: ${({ theme }) => theme.fontSize.body2};
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
    .MuiFormLabel-root {
      color: ${({ theme }) => theme.color.darkGray};
    }
    .MuiFormLabel-root.Mui-disabled {
      font-size: ${({ theme }) => theme.fontSize.body2};
      font-family: 'rubik', sans-serif;
    }

    .MuiInputLabel-formControl {
      font-size: ${({ theme }) => theme.fontSize.body2};
      font-family: 'rubik', sans-serif !important;
    }

    .MuiFilledInput-root {
      background-color: ${({ theme }) => theme.color.lightGray};

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.color.white};
      }

      &.Mui-focused {
        background-color: ${({ theme }) => theme.color.gray};
      }
    }

    .MuiInputBase-root {
      border-bottom: 1px solid rgba(0, 0, 0, 0.42);

      &::after,
      &::before {
        display: none;
      }

      &.Mui-disabled {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
      }
    }
  }
`;

export const StyledFormHelperText = styled(FormHelperText)`
  && {
    ${({ theme }) => css`
      font-size: ${theme.fontSize.caption};
    `}
  }
`;
