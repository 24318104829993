// React
import React from 'react';

// Styles
import { StyledAssignmentIcon, Audyt, Rimage } from './Reports.styles';

export const reportTypes = [
  {
    id: 1,
    label: 'Raporty podsumowujące',
    color: 'blue',
    icon: <StyledAssignmentIcon />,
    items: [
      {
        id: 1,
        label: 'Promocja do następnej klasy',
        filename: 'promocja-do-nastepnej-klasy',
        tooltip: 'Należy wybrać wskaźnik który mierzy promocję do następnej klasy.',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'indicator',
          'school',
          'pwd',
        ],
        filteringRequired: ['indicator', 'program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
        indicator_type: 'tak/nie',
      },
      {
        id: 2,
        label: 'Frekwencja - liczba godzin nieusprawiedliwionych',
        filename: 'frekwencja-l-godz-nieusprawiedliwionych',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
          'metric_1',
          'metric_2',
        ],
        filteringRequired: ['program', 'metric_1', 'metric_2'],
        metricType: 'semester',
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 3,
        label: 'Frekwencja %',
        filename: 'frekwencja-procentowo',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
          'metric_1',
          'metric_2',
        ],
        metricType: 'semester',
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 4,
        label: 'Średnia ocen',
        filename: 'srednia-ocen',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
          'metric_1',
          'metric_2',
        ],
        filteringRequired: ['program', 'metric_1', 'metric_2'],
        metricType: 'semester',
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 5,
        label: 'Ocena z zachowania',
        filename: 'ocena-z-zachowania',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
          'metric_1',
          'metric_2',
        ],
        filteringRequired: ['program', 'metric_1', 'metric_2'],
        metricType: 'semester',
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 16,
        label: 'Raport uczestnictwa – uczestników wytypowanych przez szkoły',
        filename: 'raport-uczestnictwa-uczestnikow-wytypowanych-przez-szkoly',
        tooltip: null,
        hideDates: false,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 6,
        label: 'Raport podsumowujący wskaźniki własne - dla typu TAK/ NIE',
        filename: 'raport-podsumowujacy-wskazniki-wlasne-dla-typu-tak-nie',
        tooltip:
          'Na pomiar składa się pomiar bazowy oraz ostatni wpisany pomiar do BM2 w danym okresie.',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'indicator',
          'school',
          'pwd',
          'indicator',
        ],
        filteringRequired: ['program', 'indicator'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
        indicator_type: 'tak/nie',
      },
      {
        id: 7,
        label: 'Raport podsumowujący wskaźniki własne dla typu SELECT',
        filename: 'raport-podsumowujacy-wskazniki-wlasne-dla-typu-select',
        tooltip:
          'Na pomiar składa się pomiar bazowy oraz ostatni wpisany pomiar do BM2 w danym okresie.',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'indicator',
          'school',
          'pwd',
          'indicator',
        ],
        filteringRequired: ['program', 'indicator'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
        indicator_type: 'pole wyboru',
      },
      {
        id: 8,
        label: 'Raport podsumowujący wskaźniki własne dla typu LICZBOWY',
        filename: 'raport-podsumowujacy-wskazniki-wlasne-dla-typu-liczbowy',
        tooltip:
          'Na pomiar składa się pomiar bazowy oraz ostatni wpisany pomiar do BM2 w danym okresie.',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'indicator',
          'school',
          'pwd',
          'indicator',
        ],
        filteringRequired: ['program', 'indicator'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
        indicator_type: 'liczbowy',
      },
      {
        id: 9,
        label: 'Raport podsumowujący wskaźniki własne dla typu OPISOWY.',
        filename: 'raport-podsumowujacy-wskazniki-wlasne-dla-typu-opisowy',
        tooltip:
          'Na pomiar składa się pomiar bazowy oraz ostatni wpisany pomiar do BM2 w danym okresie.',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'indicator',
          'school',
          'pwd',
          'indicator',
        ],
        filteringRequired: ['program', 'indicator'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
        indicator_type: 'tekstowy',
      },
      {
        id: 10,
        label: 'Raport podsumowujący ankieta diagnostyczno-ewaluacyjna',
        filename: 'raport-podsumowujacy-ankieta-diagnostyczno-ewaluacyjna',
        tooltip:
          'Na pomiar składa się pomiar bazowy oraz ostatni wpisany pomiar do BM2 w danym okresie.',
        hideDates: false,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 11,
        label: 'Raport podsumowujący wspierane przedmioty',
        filename: 'raport-podsumowujacy-wspierane-przedmioty',
        tooltip: null,
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
      },
      {
        id: 12,
        label: 'Raport podsumowujący współpraca z rodzinami',
        filename: 'raport-podsumowujacy-wspolpraca-z-rodzinami',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 13,
        label: 'Raport podsumowujący współpraca w systemie wsparcia',
        filename: 'raport-podsumowujacy-wspolpraca-w-systemie-wsparcia',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: true,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 14,
        label: 'Raport podsumowujący frekwencja PWD',
        filename: 'raport-podsumowujacy-frekwencja-pwd',
        hideDates: false,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 15,
        label: 'Raport podsumowujący uczestnictwo w zajęciach',
        filename: 'raport-podsumowujacy-uczestnictwo-w-zajeciach',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 18,
        label: 'Raport podsumowujący intensywność wsparcia',
        filename: 'raport-podsumowujacy-intensywnosc-wsparcia',
        tootlip:
          'Rok podzielony jest na 2 semestry. Pierwszy trwa 01.01. - 31.08 oraz drugi trwa 1.09 - 31.12',
        hideDates: false,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
    ],
  },
  {
    id: 2,
    label: 'Raporty indywidualne',
    color: 'red',
    icon: <Rimage className="search-person-dark" src="img/search-person.svg" />,
    items: [
      {
        id: 27,
        label: 'Przetwarzane dane RODO',
        filename: 'przetwarzane-dane-rodo',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: true,
        filtering: ['beneficiaries'],
        filteringRequired: ['beneficiaries'],
      },
      {
        id: 28,
        label: 'Historia edycji danych',
        filename: 'historia-edycji-danych',
        hideDates: false,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: true,
        filtering: ['beneficiaries'],
        filteringRequired: ['beneficiaries'],
      },
    ],
  },
  {
    id: 3,
    label: 'Raporty audytowe',
    color: 'yellow',
    icon: <Audyt src="img/report-audit.svg" />,
    items: [
      {
        id: 20,
        label: 'Raport braków danych',
        tooltip: null,
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filename: 'raport-brakow-danych',
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
        types: [
          { value: 'single', name: 'indywidualny' },
          { value: 'group', name: 'grupowy' },
        ],
      },
      {
        id: 21,
        label: 'Raport braków w sekcji klauzul',
        tooltip: null,
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filename: 'raport-brakow-w-sekcji-klauzul',
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
      },
      {
        id: 22,
        label: 'Raport uczestników z niską frekwencją',
        filename: 'raport-uczestnikow-z-niska-frekwencja',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program', 'task', 'action_name'],
      },
      {
        id: 23,
        label: 'Raport uczestników nieprzypisanych do zajęć',
        tooltip: null,
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filename: 'raport-uczestnikow-nieprzypisanych-do-zajec',
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
      },
      {
        id: 24,
        label: 'Raport nieuzupełnionych wskaźników',
        tooltip:
          'Raport nieuzupełnionych wskaźników zakłada, że w przypadku nieuzupełnionego jednego lub więcej pomiarów wskaźnik jest nieuzupełniony. Sprawdź czy uzupełnione są wszystkie pomiary.',
        hideDates: true,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: false,
        filename: 'raport-nieuzupelnionych-wskaznikow',
        filtering: [
          'program',
          'organization',
          'task',
          'action_organization',
          'action_buildintype',
          'action_group',
          'action_name',
          'school',
          'pwd',
        ],
        filteringRequired: ['program'],
      },
      {
        id: 25,
        label: 'Raport aktywności użytkowników',
        tooltip: null,
        hideDates: false,
        hideGrouping: true,
        hideFiltering: false,
        hideSwitchOptions: true,
        filtering: ['program', 'organization'],
        filteringRequired: ['program'],
        filename: 'raport-aktywnosci-uzytkownikow',
      },
      {
        id: 19,
        label: 'Raport terytorialny organizacje',
        tooltip: 'zestawienie danych organizacji do których użytkownik ma dostęp',
        hideOptions: true,
        filename: 'raport-terytorialny-organizacje',
      },
    ],
  },
];
