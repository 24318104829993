// Styles
import styled from 'styled-components';

export const StyledBox = styled.div`
  && {
    max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '70%')};

    &:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
    padding-right: 1px;
  }
`;
export const StyledWrapper = styled.div`
  && {
    margin-top: 4rem;
    label .MuiTypography-body1 {
      line-height: 1;
      font-size: 11px;
    }
  }
`;
