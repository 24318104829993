// Assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Styles
import styled from 'styled-components';

export const StyledAddCircleIcon = styled(AddCircleIcon)`
  && {
    width: 2rem;
    height: 2rem;
    font-size: 5rem !important;
    transform: rotate(45deg);
    fill: ${({ theme }) => theme.color.red};
  }
`;

export const StyledCheckCircleIcon = styled(CheckCircleIcon)`
  && {
    width: 2rem;
    height: 2rem;
    font-size: 5rem !important;
    fill: ${({ theme }) => theme.color.green};
  }
`;
