// React
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Context
import { Context } from '@context';

// Tooltip
import ReactTooltip from 'react-tooltip';

import { COORDINATOR } from '@constants/roles';

// Services
import { fetchMe as fetchMeService, clearMe as clearMeService } from '@services/userServices';

// Elements
import { IconButton, Tooltip, MenuItem, Divider } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// Styles
import { StyledUserIcon, StyledMenu, StyledTooltip } from './UserIcon.styles';
import './UserIcon.scss';

const baseURL = import.meta.env.VITE_API_URL;

// Component
const UserIcon = ({ me, fetchMe, clearMe }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [applicationEl, setApplicationEl] = useState(null);
  const history = useHistory();
  const { setWhichItem } = useContext(Context);
  const { id, name, surname, user_programs, email } = me;
  const navigateTo = (path) => {
    setAnchorEl(null);
    setApplicationEl(null);
    history.push(path);
  };
  const triggerFetchMe = useCallback(() => {
    return fetchMe();
  }, [fetchMe]);

  useEffect(() => {
    triggerFetchMe();
  }, [id, triggerFetchMe]);

  return (
    <>
      <Tooltip
        title={
          <StyledTooltip>
            <Heading.Body1 color="white">
              {name} {surname} {email}
            </Heading.Body1>
            {user_programs &&
              user_programs.map((p) => {
                return (
                  <Heading.Body2 key={p.id} color="white">
                    {p.name}
                  </Heading.Body2>
                );
              })}
          </StyledTooltip>
        }
        onClick={(e) => {
          setWhichItem('');
          setAnchorEl(e.currentTarget);
        }}
        aria-label="add"
      >
        <IconButton edge="start" aria-label="menu">
          <StyledUserIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => navigateTo('/moje-role')}>
          <Heading.Body2>Moje role</Heading.Body2>
        </MenuItem>
        <MenuItem onClick={() => navigateTo('/ustawienia')}>
          <Heading.Body2>Ustawienia konta</Heading.Body2>
        </MenuItem>
        <MenuItem onClick={(e) => setApplicationEl(e.currentTarget)}>
          <Heading.Body2>Aplikacja</Heading.Body2>
        </MenuItem>
        <GuardedComponent allowed_user_roles={[COORDINATOR]}>
          <MenuItem onClick={() => navigateTo('/konfiguracja')}>
            <Heading.Body2>Konfiguracja</Heading.Body2>
          </MenuItem>
        </GuardedComponent>
        <Divider />
        <MenuItem
          onClick={() => {
            clearMe();
            window.location.assign(`${baseURL}/logout`);
          }}
        >
          <Heading.Body2>Wyloguj się</Heading.Body2>
        </MenuItem>
      </StyledMenu>
      <StyledMenu
        id="simple-menu2"
        anchorEl={applicationEl}
        keepMounted
        open={Boolean(applicationEl)}
        onClose={() => setApplicationEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={() => navigateTo('/przewodnik')}>
          <Heading.Body2>FAQ</Heading.Body2>
        </MenuItem>
        <MenuItem onClick={() => window.open('/files/polityka_prywatnosci.pdf', '_blank')}>
          <Heading.Body2>Polityka prywatności</Heading.Body2>
        </MenuItem>
        <MenuItem onClick={() => window.open('/files/Regulamin_dla_uzytkownika.pdf', '_blank')}>
          <Heading.Body2>Regulamin użytkownika</Heading.Body2>
        </MenuItem>
        <MenuItem
          onClick={() =>
            window.open('/files/Regulamin_korzystania_z_platformy_Baza_Monitorujaca.pdf', '_blank')
          }
        >
          <Heading.Body2>Regulamin korzystania z platformy</Heading.Body2>
        </MenuItem>

        <MenuItem onClick={() => setApplicationEl(null)}>
          <div data-tip="React-tooltip">
            <Heading.Body2>Wersja</Heading.Body2>
          </div>
          <ReactTooltip className="tool" place="left" type="light" effect="solid">
            <Heading.Body2>Baza Monitorująca 2 </Heading.Body2>
            <Heading.Body2>Wersja 2.1</Heading.Body2>
            <Heading.Body2>wykonana przez czek.it</Heading.Body2>
            <Heading.Body2>dla Centrum Wspierania Rodzin </Heading.Body2>
            <Heading.Body2>Rodzinna Warszawa</Heading.Body2>
          </ReactTooltip>
        </MenuItem>
        <MenuItem onClick={() => navigateTo('/kontakt-z-administratorem')}>
          <Heading.Body2>Kontakt z administratorem</Heading.Body2>
        </MenuItem>
      </StyledMenu>
    </>
  );
};

UserIcon.propTypes = {
  me: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    email: PropTypes.string,
    user_programs: PropTypes.arrayOf(
      PropTypes.shape({
        program_id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  fetchMe: PropTypes.func.isRequired,
  clearMe: PropTypes.func.isRequired,
};

UserIcon.defaultProps = {
  me: {
    id: null,
    name: '',
    surname: '',
    user_programs: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMeService()),
  clearMe: () => dispatch(clearMeService()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserIcon);
