// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import { Switch } from '@mui/material';

// Styles
import { StyledOption, StyledFormControlLabel } from './EditButton.styles';

// Component
const EditButton = ({ isEditable, setIsEditable, label, place, disabled, name }) => (
  <StyledOption>
    <StyledFormControlLabel
      control={<Switch checked={isEditable} color="primary" />}
      onChange={() => {
        setIsEditable(!isEditable);
      }}
      name={name}
      label={label || 'Edycja'}
      labelPlacement={place || 'start'}
      disabled={disabled || false}
    />
  </StyledOption>
);

EditButton.propTypes = {
  isEditable: PropTypes.bool,
  setIsEditable: PropTypes.func,
  label: PropTypes.string,
  place: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
};

EditButton.defaultProps = {
  label: '',
  place: '',
  disabled: false,
  isEditable: false,
  setIsEditable: null,
  name: '',
};

export default EditButton;
