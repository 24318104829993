// React
import React from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { pl } from 'date-fns/locale';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// Styles
import { StyledDatePicker, StyledTextField } from './Calendar.styles';
import './Calendar.scss';

// Component
const Calendar = ({ label, disabled, disableFuture, onChange, value, name, minDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
      <StyledDatePicker
        label={label}
        minDate={minDate ? new Date(minDate) : null}
        value={value ? new Date(value) : null}
        onChange={(e) => {
          if (e) {
            onChange(name, format(e, 'yyyy-MM-dd'));
          }
        }}
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        renderInput={(params) => <StyledTextField {...params} disabled={disabled || false} />}
        disableFuture={disableFuture}
        disabled={disabled}
      />
    </LocalizationProvider>
  );
};
// const Calendar = ({ label, disabled, disableFuture, onChange, value, name, minDate }) => {
//   return (
//     <LocalizationProvider dateAdapter={AdapterDateFns} locale={pl}>
//       <StyledDatePicker
//         label={label}
//         minDate={minDate ? new Date(minDate) : null}
//         value={new Date(value) || null}
//         onChange={(e) => {
//           onChange(name, format(e, 'yyyy-MM-dd'));
//         }}
//         inputFormat="yyyy-MM-dd"
//         renderInput={(params) => <StyledTextField {...params} disabled={disabled || false} />}
//         disableFuture={disableFuture}
//         disabled={disabled}
//       />
//     </LocalizationProvider>
//   );
// };

Calendar.propTypes = {
  label: PropTypes.string,
  calendarToolbar: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  autoOk: PropTypes.bool,
  clearable: PropTypes.bool,
  placeholder: PropTypes.string,
  minDate: PropTypes.string,
};

Calendar.defaultProps = {
  label: 'Data',
  calendarToolbar: true,
  variant: 'standard',
  disabled: false,
  disableFuture: false,
  onChange: null,
  value: '',
  minDate: null,
  autoOk: true,
  name: '',
  clearable: false,
  placeholder: '',
};

export default Calendar;
