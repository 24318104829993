import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledCheckbox, StyledRadio, StyledFormControlLabel } from './Checkbox.styles';

// Component
const Checkbox = ({
  removemargin,
  label,
  isEditable,
  color,
  radio,
  value,
  onChange,
  name,
  fieldValue,
}) => (
  <>
    {radio ? (
      <StyledFormControlLabel
        control={<StyledRadio />}
        disabled={!isEditable || false}
        color={color || 'default'}
        label={label}
        onChange={onChange}
        checked={value}
        value={fieldValue}
      />
    ) : (
      <StyledFormControlLabel
        control={<StyledCheckbox />}
        disabled={!isEditable || false}
        label={label}
        checked={value}
        onChange={onChange}
        color={color || 'default'}
        name={name}
        removemargin={removemargin}
      />
    )}
  </>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  isEditable: PropTypes.bool,
  color: PropTypes.string,
  radio: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  removemargin: PropTypes.string,
  fieldValue: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  isEditable: false,
  color: '',
  radio: false,
  value: false,
  onChange: null,
  name: '',
  removemargin: null,
  fieldValue: '',
};

export default Checkbox;
