// React
import React from 'react';
import PropTypes from 'prop-types';

// Moment
import moment from 'moment';

// PDF
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

// Fonts
import robotoRegularSRC from '@font/Roboto-Regular.ttf';
import robotoBoldSRC from '@font/Roboto-Bold.ttf';

// Data
import { ageRange, answers, surveysQuestions } from '@constants/surveys';

// Styles
import { styles } from '@utils/pdfStyles';

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegularSRC }, { src: robotoBoldSRC, fontWeight: 700 }],
});

const BeneficiarySurveysPDF = ({ age, data, empty }) => {
  return data && Object.keys(data).length > 0 ? (
    <Document language="pl">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Informacje podstawowe</Text>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Tytuł: </Text>
            <Text>{data.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Data dodania: </Text>
            <Text>{moment(data.date_added).format('DD-MM-YYYY')}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Przedział wiekowy: </Text>
            <Text>{ageRange.find((el) => el.value === data.age)?.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko beneficjenta: </Text>
            <Text>{data.beneficiary.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Nazwa programu: </Text>
            <Text>{data.program.name}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko przeprowadzającego: </Text>
            <Text>{data.author.name}</Text>
          </View>
          {data.answers.map(({ id, label, questions }) => (
            <React.Fragment key={id}>
              <Text style={styles.title}>{label}</Text>
              {questions.map((el, index) => {
                const currentAnswer = answers.find((it) => el.value === it.value);

                if (questions.length - 1 === index) {
                  return (
                    <React.Fragment key={el.id}>
                      <Text style={styles.textBold}>{el.label}</Text>
                      <Text style={styles.text}>{currentAnswer && currentAnswer.odp}</Text>
                      <Text break />
                    </React.Fragment>
                  );
                }

                return (
                  <React.Fragment key={el.id}>
                    <Text style={styles.textBold}>{el.label}</Text>
                    <Text style={styles.text}>{currentAnswer && currentAnswer.odp}</Text>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        </View>
      </Page>
    </Document>
  ) : (
    <Document language="pl">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Informacje podstawowe</Text>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Tytuł:</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Data dodania:</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Przedział wiekowy: </Text>
            <Text>{ageRange[age]}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko beneficjenta:</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Nazwa programu:</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko przeprowadzającego:</Text>
          </View>
          {surveysQuestions[age].map(({ id, label, questions }) => (
            <React.Fragment key={id}>
              <Text style={styles.title}>{label}</Text>
              {questions.map((el, index) => {
                if (questions.length - 1 === index) {
                  return (
                    <React.Fragment key={el.id}>
                      <Text style={styles.textBold}>{el.label}</Text>
                      {empty && (
                        <Text style={styles.text}>
                          [ ] zdecydowanie nie [ ] nie [ ] raczej nie [ ] raczej tak [ ] tak [ ]
                          zdecydowanie tak
                        </Text>
                      )}
                      <Text style={styles.text} />
                      <Text style={styles.text} />
                      <Text break />
                    </React.Fragment>
                  );
                }

                return (
                  <React.Fragment key={el.id}>
                    <Text style={styles.textBold}>{el.label}</Text>
                    {empty && (
                      <Text style={styles.text}>
                        [ ] zdecydowanie nie [ ] nie [ ] raczej nie [ ] raczej tak [ ] tak [ ]
                        zdecydowanie tak
                      </Text>
                    )}
                    <Text style={styles.text} />
                    <Text style={styles.text} />
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          ))}
        </View>
      </Page>
    </Document>
  );
};

BeneficiarySurveysPDF.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  age: PropTypes.string,
  empty: PropTypes.bool,
};

BeneficiarySurveysPDF.defaultProps = {
  data: {},
  age: '',
  empty: false,
};

export default BeneficiarySurveysPDF;
