// React
import React, { createContext, useState } from 'react';

export const Context = createContext({});

// eslint-disable-next-line react/prop-types
const ContextProvider = ({ children }) => {
  const [whichItem, setWhichItem] = useState();
  const [search2, setSearch2] = useState();
  const [autoValue, setAutoValue] = useState([]);
  const [typeValue, setTypeValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [tabPage, setTabPage] = useState(0);

  return (
    <Context.Provider
      value={{
        whichItem,
        setWhichItem,
        search2,
        setSearch2,
        autoValue,
        setAutoValue,
        typeValue,
        setTypeValue,
        statusValue,
        setStatusValue,
        tabPage,
        setTabPage,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
