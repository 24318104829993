// Elements
import { Grid } from '@mui/material';
import Input from '@components/atoms/Input/Input';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: 50%;
    &.MuiGrid-spacing-xs-2 {
      margin-top: 3rem;
    }
  }
`;

export const StyledWrapper = styled.div`
  && {
    margin-top: 4rem;
  }
`;

export const StyledGridItem = styled(Grid)`
  && {
    display: flex;
    align-items: center;
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3rem;
  }
`;

export const StyledText = styled(Heading.Body2)`
  && {
    margin-left: 1rem;
    flex-basis: 100%;
  }
`;

export const StyledInput = styled(Input)`
  && {
    width: auto;
  }
`;
