// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import ChartItem from '@components/atoms/ChartItem/ChartItem';

// Styles
import { StyledBox } from './Chart.styles';

// Component
const Chart = ({ chartdata }) => {
  const allValues = Object.values(chartdata);
  const max = Math.max(...allValues);

  return (
    <StyledBox>
      {Object.keys(chartdata).map((el) => (
        <ChartItem
          key={el}
          title={el}
          data={chartdata[el]}
          percentage={(chartdata[el] / max) * 100}
        />
      ))}
    </StyledBox>
  );
};

Chart.propTypes = {
  chartdata: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default Chart;
