// React
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Axios
import axiosInstance from '@utils/axios';

// Services
import { fetchActionList } from '@services/actionServices';

// Actions
import { showAlert as showAlertAction } from '@actions/appActions';
import { clearActions } from '@actions/actionActions';

// Data
import { getActionsList } from '@constants/other';
import { filterActions } from '@constants/tableFilters';
import { COORDINATOR, PROGRAM_COORDINATOR, TASK_COORDINATOR } from '@constants/roles';
import { NIE_MASZ_UPRAWNIEN } from '@constants/alerts';

// Utils
import { exportPresenceToExcel, isGuardianInProgram } from '@utils/functions';

// Assets
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

// Elements
import { SvgIcon } from '@mui/material';
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// Custom icon
function RuleIcon(props) {
  return (
    <SvgIcon {...props}>
      <rect fill="none" height="24" width="24" />
      <path d="M15.83,10.29l-2.12-2.12c-0.39-0.39-0.39-1.02,0-1.41l0,0c0.39-0.39,1.02-0.39,1.41,0l1.41,1.41l3.54-3.54 c0.39-0.39,1.02-0.39,1.41,0l0,0c0.39,0.39,0.39,1.02,0,1.41l-4.24,4.24C16.85,10.68,16.22,10.68,15.83,10.29z M10,7H3 C2.45,7,2,7.45,2,8v0c0,0.55,0.45,1,1,1h7c0.55,0,1-0.45,1-1v0C11,7.45,10.55,7,10,7z M20.29,12.71L20.29,12.71 c-0.39-0.39-1.02-0.39-1.41,0L17,14.59l-1.88-1.88c-0.39-0.39-1.02-0.39-1.41,0l0,0c-0.39,0.39-0.39,1.02,0,1.41L15.59,16 l-1.88,1.88c-0.39,0.39-0.39,1.02,0,1.41l0,0c0.39,0.39,1.02,0.39,1.41,0L17,17.41l1.88,1.88c0.39,0.39,1.02,0.39,1.41,0l0,0 c0.39-0.39,0.39-1.02,0-1.41L18.41,16l1.88-1.88C20.68,13.73,20.68,13.1,20.29,12.71z M10,15H3c-0.55,0-1,0.45-1,1v0 c0,0.55,0.45,1,1,1h7c0.55,0,1-0.45,1-1v0C11,15.45,10.55,15,10,15z" />
    </SvgIcon>
  );
}

// Data
const headCells = [
  { type: 'action_name', label: 'Nazwa działania', nav: true },
  { type: 'action_type', label: 'Typ działania' },
  { type: 'task_name', label: 'Zadanie' },
  { type: 'description', label: 'Opis działania', truncate_text: 160 },
  { type: 'lecturer', label: 'Prowadzący' },
  { type: 'beneficiaries_number', label: 'Liczba beneficjentów' },
  { type: 'hours_number', label: 'Liczba godzin' },
  { type: 'start_date', label: 'Pierwsze zajęcia' },
  { type: 'end_date', label: 'Ostatnie zajęcia' },
  { type: 'created_by', label: 'Twórca działania' },
];

// Component
const Actions = ({ clearAct, fetchList, actions, showAlert, me }) => {
  const history = useHistory();

  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  useEffect(() => {
    console.log('clearAct', clearAct);
    clearAct();
  }, []);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  const actionCells = [
    {
      column: 'Sprawdź obecność',
      action: (row) =>
        isGuardianInProgram(me, row.program_id)
          ? showAlert(NIE_MASZ_UPRAWNIEN)
          : history.push(`/dzialania/${row.id}/tab/2-dziennik`),
      button: 'Obecność',
      buttonType: (row) => (row.buttonType ? row.buttonType : 'empty'),
      buttonIcon: (row) => {
        if (row.buttonType === 'full') {
          return <CheckCircleRoundedIcon fontSize="large" style={{ marginRight: 5 }} />;
        }

        if (row.buttonType === 'error' || row.buttonType === 'warning') {
          return <RemoveCircleRoundedIcon fontSize="large" style={{ marginRight: 5 }} />;
        }

        return <RuleIcon fontSize="large" style={{ marginRight: 5 }} />;
      },
    },
  ];

  return (
    <>
      <TableTemplate
        headCells={headCells}
        filters={filterActions}
        data={actions}
        navCells
        actionCells={actionCells}
        tableName="Działania"
        rowActions={[
          {
            name: 'zobacz szczegóły',
            action: (row) => history.push(`/dzialania/${row.id}`),
          },
          {
            name: 'sprawdź obecność',
            action: (row) =>
              isGuardianInProgram(me, row.program_id)
                ? showAlert(NIE_MASZ_UPRAWNIEN)
                : history.push(`/dzialania/${row.id}/tab/2-dziennik`),
          },
          {
            name: 'wypisz beneficjenta/ów',
            action: (row) =>
              isGuardianInProgram(me, row.program_id)
                ? showAlert(NIE_MASZ_UPRAWNIEN)
                : history.push(`/dzialania/${row.id}/tab/1-uczestnicy`),
          },
          {
            name: 'eksportuj Dziennik Obecności',
            action: (row) => {
              axiosInstance.get(`/dzialania/${row.id}/dziennik`).then(({ data: { data } }) => {
                exportPresenceToExcel(data, row.action_name);
              });
            },
          },
        ]}
      />
      <GuardedComponent allowed_user_roles={[COORDINATOR, PROGRAM_COORDINATOR, TASK_COORDINATOR]}>
        <FloatingButton actions={getActionsList} />
      </GuardedComponent>
    </>
  );
};

Actions.propTypes = {
  clearAct: PropTypes.func,
  fetchList: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  showAlert: PropTypes.func,
  me: PropTypes.shape({
    program_roles: PropTypes.arrayOf(PropTypes.any),
  }),
};

Actions.defaultProps = {
  clearAct: null,
  fetchList: null,
  actions: [],
  showAlert: null,
  me: null,
};

const mapStateToProps = ({ userReducer, actionReducer, programReducer }) => ({
  actions: actionReducer.actions,
  programs: programReducer.programs,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearAct: () => dispatch(clearActions()),
  fetchList: () => dispatch(fetchActionList()),
  showAlert: (data) => dispatch(showAlertAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
