// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik } from 'formik';

// Services
import {
  fetchBeneficiarySupportedSubjects as fetchBeneficiarySupportedSubjectsService,
  addBeneficiarySupportedSubjects as addBeneficiarySupportedSubjectsService,
  addBeneficiarySupportedSubjectsPeriod as addBeneficiarySupportedSubjectsPeriodService,
  removeBeneficiarySupportedSubjectPeriod as removeBeneficiarySSPeriodService,
  removeBeneficiarySupportedSubject as removeBeneficiarySupportedSubjectService,
} from '@services/beneficiaryServices';
import { fetchPeriodsSemester as fetchPeriodsSemesterService } from '@services/indicatorServices';
import { fetchSubjectList as fetchSubjectListService } from '@services/selectServices';

// Actions
import { clearPeriodsSemester as clearPeriodsSemesterAction } from '@actions/indicatorActions';
import { clearSubjectList as clearSubjectListAction } from '@actions/selectActions';
import { clearBeneficiarySupport as clearBeneficiarySupportAction } from '@actions/beneficiaryActions';

// Assets
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Elements
import { Grid, Box } from '@mui/material';
import MyAccordion from '@components/atoms/Accordion/Accordion';
import Heading from '@components/atoms/Heading/Heading';
import Button from '@components/atoms/Button/Button';
import Dialog from '@components/organisms/Dialog/Dialog';
import Select from '@components/atoms/Select/Select';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

// Styles
import {
  StyledWrapper,
  StyledGrid,
  StyledBox,
  StyledItem,
  StyledDialogActions,
  StyledChip,
  StyledButton,
} from './BeneficiarySupportedSubjects.styles';
import './Supported.css';

// Component
const BeneficiaryPrograms = ({
  clearBeneficiarySupport,
  fetchSubjects,
  details,
  clearSubjectList,
  fetchSubjectsSelect,
  subjectsList,
  clearPeriodsSemester,
  fetchPeriodsSemester,
  periodsSemester,
  subjectLoading,
  addBeneficiarySupportedSubjects,
  addBeneficiarySupportedSubjectsPeriod,
  removeBeneficiarySupportedSubject,
  removeBeneficiarySSPeriod,
  beneficiary,
}) => {
  const { id } = useParams();
  const { supported_subjects } = details;
  const [openDialog, setOpenDialog] = useState();
  const [openSubjectDialog, setOpenSubjectDialog] = useState();
  const [currentCWPId, setCurrentCWPId] = useState();
  const [currentSupportedSubject, setCurrentSupportedSubject] = useState();
  const [isActiveProgram, setActiveProgram] = useState(false);
  const [isEndProgram, setEndProgram] = useState(false);

  useEffect(() => {
    clearBeneficiarySupport();
    clearSubjectList();
    clearPeriodsSemester();
  }, []);

  const triggerFetchSubjects = useCallback(
    (myid) => {
      fetchSubjects(myid);
      fetchSubjectsSelect();
      fetchPeriodsSemester();
    },
    [fetchSubjects, fetchSubjectsSelect, fetchPeriodsSemester],
  );

  useEffect(() => {
    triggerFetchSubjects(id);
  }, [id, triggerFetchSubjects]);

  const showAccordion = (ended) =>
    !((!isActiveProgram && !isEndProgram) || (isActiveProgram && !ended) || (isEndProgram && ended))
      ? 'true'
      : 'false';

  return (
    <>
      <StyledWrapper className="supported">
        <StyledGrid container>
          <Grid item xs={2}>
            <Checkbox
              label="Aktualne"
              isEditable={!!true}
              value={isActiveProgram}
              onChange={() => setActiveProgram(!isActiveProgram)}
            />
          </Grid>
          <Grid item xs={2}>
            <Checkbox
              label="Zakończone"
              isEditable={!!true}
              value={isEndProgram}
              onChange={() => setEndProgram(!isEndProgram)}
            />
          </Grid>
        </StyledGrid>
        {supported_subjects.length ? (
          supported_subjects.map((program) => (
            <MyAccordion
              key={program.cwp_id}
              title={program.name}
              hide={showAccordion(program.ended)}
            >
              {program.subjects.map((subject) => (
                <StyledBox key={subject.id} container>
                  <Heading.Body2>
                    {subject.name}{' '}
                    <StyledButton
                      className="btn-indi active"
                      startIcon={<RemoveCircleIcon />}
                      onClick={() => {
                        removeBeneficiarySupportedSubject(id, subject.id, program.cwp_id);
                      }}
                    >
                      usuń
                    </StyledButton>
                  </Heading.Body2>
                  <StyledItem>
                    {subject.periods
                      .sort((el1, el2) => el1.order - el2.order)
                      .map(({ supp_subject_period_id, time_period_name }) => (
                        <StyledChip
                          key={supp_subject_period_id}
                          subject={supp_subject_period_id}
                          label={time_period_name}
                          onDelete={() =>
                            removeBeneficiarySSPeriod(id, program.cwp_id, supp_subject_period_id)
                          }
                        />
                      ))}
                    {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                      <Button
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                          setCurrentSupportedSubject(subject.id);
                          setOpenDialog(true);
                        }}
                      >
                        Dodaj kolejne okresy
                      </Button>
                    )}
                  </StyledItem>
                </StyledBox>
              ))}
              {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
                <StyledBox>
                  <Button
                    startIcon={<AddCircleIcon />}
                    onClick={() => {
                      setOpenSubjectDialog(true);
                      setCurrentCWPId(program.cwp_id);
                    }}
                  >
                    Dodaj przedmiot
                  </Button>
                </StyledBox>
              )}
            </MyAccordion>
          ))
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
          </Grid>
        )}
      </StyledWrapper>
      <Dialog open={openDialog} setOpen={setOpenDialog} title="Wybierz okres pomiaru" size="xs">
        <Formik
          initialValues={{ periodId: 0, currentSupportedSubject }}
          onSubmit={(values) => {
            addBeneficiarySupportedSubjectsPeriod(id, values);
            setCurrentSupportedSubject(null);
            setOpenDialog(false);
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <>
              <Select
                variant="filled"
                label="Czas pomiaru"
                data={periodsSemester}
                text="periodId"
                onChange={setFieldValue}
                selectValue={values.periodId}
              />
              <StyledDialogActions>
                <Button onClick={() => setOpenDialog(false)}>WRÓĆ</Button>
                <Button onClick={handleSubmit} autoFocus variant="outlined">
                  DALEJ
                </Button>
              </StyledDialogActions>
            </>
          )}
        </Formik>
        <></>
      </Dialog>
      {subjectsList && subjectsList.length > 0 && (
        <Dialog
          open={openSubjectDialog}
          setOpen={setOpenSubjectDialog}
          title="Wybierz przedmiot z listy"
          size="xs"
        >
          <Formik
            initialValues={{
              subjectId: 0,
              cwp_id: currentCWPId,
              periodId: 0,
              currentSupportedSubject,
            }}
            onSubmit={(values) => {
              addBeneficiarySupportedSubjects(id, {
                subjectId: values.subjectId,
                cwp_id: values.cwp_id,
              }).then((res) => {
                addBeneficiarySupportedSubjectsPeriod(id, {
                  periodId: values.periodId,
                  currentSupportedSubject: res,
                });

                setCurrentSupportedSubject(null);
                setCurrentCWPId(null);
                setOpenSubjectDialog(false);
              });
            }}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <>
                <Select
                  variant="filled"
                  label="Wybierz"
                  text="subjectId"
                  data={subjectsList}
                  onChange={setFieldValue}
                  loading={subjectLoading}
                  selectValue={values.subjectId}
                />
                <Box mt={1}>
                  <Select
                    variant="filled"
                    label="Czas pomiaru"
                    data={periodsSemester}
                    text="periodId"
                    onChange={setFieldValue}
                    selectValue={values.periodId}
                  />
                </Box>
                <StyledDialogActions>
                  <Button onClick={() => setOpenSubjectDialog(false)}>WRÓĆ</Button>
                  <Button onClick={handleSubmit} autoFocus variant="outlined">
                    DALEJ
                  </Button>
                </StyledDialogActions>
              </>
            )}
          </Formik>
          <></>
        </Dialog>
      )}
    </>
  );
};

BeneficiaryPrograms.propTypes = {
  clearBeneficiarySupport: PropTypes.func,
  fetchSubjects: PropTypes.func,
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    supported_subjects: PropTypes.arrayOf(
      PropTypes.shape({
        program_id: PropTypes.number,
        program_name: PropTypes.string,
        subjects: PropTypes.arrayOf(
          PropTypes.shape({
            subject_id: PropTypes.number,
            subject_name: PropTypes.string,
            periods: PropTypes.arrayOf(
              PropTypes.shape({
                order: PropTypes.number,
                supp_subject_period_id: PropTypes.number,
                time_period_id: PropTypes.number,
                time_period_name: PropTypes.string,
              }),
            ),
          }),
        ),
      }),
    ),
  }),
  clearSubjectList: PropTypes.func,
  fetchSubjectsSelect: PropTypes.func,
  subjectsList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  clearPeriodsSemester: PropTypes.func,
  fetchPeriodsSemester: PropTypes.func,
  periodsSemester: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
  subjectLoading: PropTypes.bool,
  addBeneficiarySupportedSubjects: PropTypes.func,
  addBeneficiarySupportedSubjectsPeriod: PropTypes.func,
  removeBeneficiarySupportedSubject: PropTypes.func,
  removeBeneficiarySSPeriod: PropTypes.func,
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryPrograms.defaultProps = {
  clearBeneficiarySupport: null,
  fetchSubjects: null,
  beneficiary: null,
  details: {
    id: null,
    name: '',
    supported_subjects: [],
  },
  clearSubjectList: null,
  fetchSubjectsSelect: null,
  subjectsList: [],
  clearPeriodsSemester: null,
  fetchPeriodsSemester: null,
  periodsSemester: [],
  subjectLoading: false,
  addBeneficiarySupportedSubjects: null,
  addBeneficiarySupportedSubjectsPeriod: null,
  removeBeneficiarySupportedSubject: null,
  removeBeneficiarySSPeriod: null,
};

const mapStateToProps = ({ beneficiaryReducer, selectReducer }) => ({
  details: beneficiaryReducer.supportedSubjects,
  subjectLoading: selectReducer.subjectListPending,
  subjectsList: selectReducer.subjectList,
  periodsSemester: selectReducer.periodsSemester,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiarySupport: () => dispatch(clearBeneficiarySupportAction()),
  fetchSubjects: (id) => dispatch(fetchBeneficiarySupportedSubjectsService(id)),
  clearSubjectList: () => dispatch(clearSubjectListAction()),
  fetchSubjectsSelect: () => dispatch(fetchSubjectListService()),
  clearPeriodsSemester: () => dispatch(clearPeriodsSemesterAction()),
  fetchPeriodsSemester: () => dispatch(fetchPeriodsSemesterService()),
  removeBeneficiarySupportedSubject: (id, subject_id, cwp_id) =>
    dispatch(removeBeneficiarySupportedSubjectService(id, subject_id, { cwpId: cwp_id })),
  removeBeneficiarySSPeriod: (id, cwp_id, period_id) =>
    dispatch(removeBeneficiarySSPeriodService(id, period_id, { cwpId: cwp_id })),
  addBeneficiarySupportedSubjectsPeriod: (id, data) =>
    dispatch(addBeneficiarySupportedSubjectsPeriodService(id, data)),
  addBeneficiarySupportedSubjects: (id, data) =>
    dispatch(addBeneficiarySupportedSubjectsService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryPrograms);
