import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
// import { fetchReportAction } from '@actions/reportActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const generateReport = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/raporty/${id}/generuj`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );

      if (
        result &&
        result.data &&
        result.data.data &&
        (result.data.data.format === 'pdf' ||
          (!Array.isArray(result.data.data) && result.data.data.data.length > 0))
      ) {
        return result;
      }
      dispatch(
        fetchFailure({
          type: 'error',
          message:
            'Nie znaleziono danych dla podanych parametrów! Nie udało się wygenerować raportu.',
          isActive: true,
        }),
      );
      return null;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się wygenerować raportu.'),
          isActive: true,
        }),
      );
    });
};
