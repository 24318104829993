// Elements
import { Grid } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
`;

export const StyledBorder = styled(Grid)`
  && {
    margin-top: 3rem !important;
    margin-bottom: 1rem !important;
    border: 2px solid ${({ theme }) => theme.color.gray};
    border-radius: 10px;
    padding: 1.5rem 2.5rem;
  }
`;
