// React
import React from 'react';

// Data
import { tasksTabs } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const Tasks = () => {
  return <Tabs renderViewArray={tasksTabs} tabsUrl />;
};

export default Tasks;
