import {
  FETCH_MESSAGE,
  CLEAR_MESSAGE,
  FETCH_MESSAGE_SINGLE,
  CLEAR_MESSAGE_SINGLE,
} from '@constants/actionTypes';

const initialState = {
  messages: [],
  message: {},
};

const messageReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_MESSAGE:
      return { ...state, messages: payload.data };
    case CLEAR_MESSAGE:
      return { ...state, messages: initialState.messages };
    case FETCH_MESSAGE_SINGLE:
      return { ...state, message: payload.data };
    case CLEAR_MESSAGE_SINGLE:
      return { ...state, message: initialState.message };
    default:
      return state;
  }
};

export default messageReducer;
