// React
import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Routes
import routes from '@routes/routes';

// Context
import { DashboardContext } from '@contexts/DashboardContext';

// Actions
import { redirect as redirectService } from '@actions/appActions';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Elements
import DashboardTemplate from '@templates/DashboardTemplate';
import RouterBreadcrumbs from '@components/molecules/RouterBreadcrumbs/RouterBreadcrumbs';

// Styles
import { StyledWrapper, StyledHomeIcon } from './Dashboard.styles';

// Component
const Dashboard = ({ redirect, me }) => {
  const [isSidebarHide, setIsSidebarHide] = useState(false);

  return (
    <DashboardContext.Provider value={{ isSidebarHide, setIsSidebarHide }}>
      <StyledWrapper>
        <DashboardTemplate>
          {routes.map(({ path, Component, roles, strict, breadcrumbs, name }) => {
            return (
              <Route
                exact
                strinct={!!strict}
                path={path}
                key={path}
                render={(props) => {
                  if (
                    !roles ||
                    (roles && me.user_role && roles.some((r) => me.user_role.includes(r)))
                  ) {
                    return (
                      <Suspense fallback={<div>Ładowanie...</div>}>
                        <RouterBreadcrumbs />
                        <BreadcrumbsItem to="/dashboard" order={0}>
                          <StyledHomeIcon />
                        </BreadcrumbsItem>
                        {breadcrumbs &&
                          breadcrumbs.map((b, index) => {
                            return b.self ? (
                              <BreadcrumbsItem to={path} order={index + 1} key={path}>
                                {name}
                              </BreadcrumbsItem>
                            ) : (
                              <BreadcrumbsItem to={b.path} order={index + 1} key={b.path}>
                                {b.name}
                              </BreadcrumbsItem>
                            );
                          })}
                        <Component {...props} />
                      </Suspense>
                    );
                  }

                  redirect({
                    url: '/dashboard',
                    snack: {
                      code: 405,
                      type: 'warning',
                      isActive: true,
                      message: 'Nie masz uprawnień do odwiedzenia tej części serwisu!',
                    },
                  });

                  return <></>;
                }}
              />
            );
          })}
        </DashboardTemplate>
      </StyledWrapper>
    </DashboardContext.Provider>
  );
};

Dashboard.propTypes = {
  redirect: PropTypes.func,
  me: PropTypes.shape({
    user_role: PropTypes.arrayOf(PropTypes.string),
    user_settings: PropTypes.objectOf(PropTypes.any),
  }),
};

Dashboard.defaultProps = {
  redirect: null,
  me: {
    user_role: [],
    user_settings: null,
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  redirect: (snack) => dispatch(redirectService(snack)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
