import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    font-size: 62.5%;
  }

  * {
    font-family: "rubik", sans-serif !important;
  }

  body {
    font-family: "rubik", sans-serif !important;
    font-size: 1.6rem;
    min-height: calc(100% - 10rem);
    padding: 0;
    margin: 0;
  }

  .MuiPopover-root .MuiPaper-root {
    color: ${({ theme }) => theme.color.darkGray}; 
    background: ${({ theme }) => theme.color.white};
  }
  .MuiAutocomplete-popper .MuiPaper-root {
    color: ${({ theme }) => theme.color.darkGray}; 
    background: ${({ theme }) => theme.color.white};
  }
  
  .mainCol .MuiSvgIcon-root  {
    color: ${({ theme }) => theme.color.darkGray}; 
  }
`;

export default GlobalStyle;
