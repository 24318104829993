export const CHANGE_FONT_SIZE = 'CHANGE_FONT_SIZE';
export const CHANGE_CONTRAST = 'CHANGE_CONTRAST';
export const FETCH_REQUEST = 'FETCH_REQUEST';
export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_FAILURE = 'FETCH_FAILURE';
export const SHOW_APPLIACTIONS = 'SHOW_APPLIACTIONS';
export const CLEAR_APPLIACTIONS = 'CLEAR_APPLIACTIONS';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REDIRECT = 'REDIRECT';
export const CLEAR_REDIRECT = 'CLEAR_REDIRECT';
export const SHOW_ALERT = 'SHOW_ALERT';

// Reports
export const FETCH_REPORT = 'FETCH_REPORT';

// Program types
export const SHOW_PROGRAMS = 'SHOW_PROGRAMS';
export const CLEAR_PROGRAMS = 'CLEAR_PROGRAMS';
export const FETCH_PROGRAM = 'FETCH_PROGRAM';
export const FETCH_PROGRAM_CONSORTIUM = 'FETCH_PROGRAM_CONSORTIUM';
export const CLEAR_PROGRAM_CONSORTIUM = 'CLEAR_PROGRAM_CONSORTIUM';
export const FETCH_PROGRAM_ORGANIZATIONS = 'FETCH_PROGRAM_ORGANIZATIONS';
export const FETCH_PROGRAM_TASKS = 'FETCH_PROGRAM_TASKS';
export const CLEAR_PROGRAM_TASKS = 'CLEAR_PROGRAM_TASKS';
export const FETCH_PROGRAM_IPP = 'FETCH_PROGRAM_IPP';
export const CLEAR_PROGRAM_IPP = 'CLEAR_PROGRAM_IPP';
export const FETCH_PROGRAM_ACTIONS = 'FETCH_PROGRAM_ACTIONS';
export const CLEAR_PROGRAM_ACTIONS = 'CLEAR_PROGRAM_ACTIONS';
export const FETCH_PROGRAM_USERS = 'FETCH_PROGRAM_USERS';
export const CLEAR_PROGRAM_USERS = 'CLEAR_PROGRAM_USERS';
export const FETCH_PROGRAM_BENEFICIARES = 'FETCH_PROGRAM_BENEFICIARES';
export const CLEAR_PROGRAM_BENEFICIARES = 'CLEAR_PROGRAM_BENEFICIARES';
export const FETCH_PROGRAM_INDICATORS = 'FETCH_PROGRAM_INDICATORS';
export const CLEAR_PROGRAM_INDICATORS = 'CLEAR_PROGRAM_INDICATORS';
export const FETCH_PROGRAM_DIAGNOSIS = 'FETCH_PROGRAM_DIAGNOSIS';
export const CLEAR_PROGRAM_DIAGNOSIS = 'CLEAR_PROGRAM_DIAGNOSIS';
export const FETCH_PROGRAM_COORDINATORS = 'FETCH_PROGRAM_COORDINATORS';
export const CLEAR_PROGRAM_COORDINATORS = 'CLEAR_PROGRAM_COORDINATORS';
export const FETCH_PROGRAM_TASK_COORDINATORS = 'FETCH_PROGRAM_TASK_COORDINATORS';
export const FETCH_PROGRAM_PWD_PRESENCE = 'FETCH_PROGRAM_PWD_PRESENCE';
export const CLEAR_PROGRAM = 'CLEAR_PROGRAM';
export const CLEAR_PWD_PRESENCE = 'CLEAR_PWD_PRESENCE';
export const FETCH_PROGRAM_DATA_ADMINISTRATORS = 'FETCH_PROGRAM_DATA_ADMINISTRATORS';

// Organization types
export const SHOW_ORGANIZATIONS = 'SHOW_ORGANIZATIONS';
export const CLEAR_ORGANIZATIONS = 'CLEAR_ORGANIZATIONS';
export const FETCH_ORGANIZATION = 'FETCH_ORGANIZATION';
export const CLEAR_ORGANIZATION = 'CLEAR_ORGANIZATION';
export const FETCH_REPRESENTATIVE_LIST = 'FETCH_REPRESENTATIVE_LIST';
export const CLEAR_REPRESENTATIVE_LIST = 'CLEAR_REPRESENTATIVE_LIST';
export const FETCH_ORGANIZATION_FOR_PROGRAM = 'FETCH_ORGANIZATION_FOR_PROGRAM';
export const FETCH_ORGANIZATION_BENEFICIARIES_LIST = 'FETCH_ORGANIZATION_BENEFICIARIES_LIST';
export const CLEAR_ORGANIZATION_BENEFICIARIES_LIST = 'CLEAR_ORGANIZATION_BENEFICIARIES_LIST';
export const FETCH_ORGANIZATION_PROGRAMS_LIST = 'FETCH_ORGANIZATION_PROGRAMS_LIST';
export const CLEAR_ORGANIZATION_PROGRAMS_LIST = 'CLEAR_ORGANIZATION_PROGRAMS_LIST';
export const FETCH_ORGANIZATION_COORDINATORS_LIST = 'FETCH_ORGANIZATION_COORDINATORS_LIST';
export const CLEAR_ORGANIZATION_COORDINATORS_LIST = 'CLEAR_ORGANIZATION_COORDINATORS_LIST';

// Action types
export const SHOW_ACTIONS = 'SHOW_ACTIONS';
export const CLEAR_ACTIONS = 'CLEAR_ACTIONS';
export const FETCH_ACTION = 'FETCH_ACTION';
export const CLEAR_SINGLE_ACTION = 'CLEAR_SINGLE_ACTION';
export const FETCH_ACTION_MEMBERS = 'FETCH_ACTION_MEMBERS';
export const CLEAR_ACTION_MEMBERS = 'CLEAR_ACTION_MEMBERS';
export const FETCH_ACTION_PRESENCE = 'FETCH_ACTION_PRESENCE';
export const FETCH_LECTURERS_LIST = 'FETCH_LECTURERS_LIST';
export const FETCH_MEETINGS_LIST = 'FETCH_MEETINGS_LIST';
export const CLEAR_MEETINGS_LIST = 'CLEAR_MEETINGS_LIST';
export const CLEAR_ACTION = 'CLEAR_ACTION';
export const CLEAR_PRESENCE = 'CLEAR_PRESENCE';
export const FETCH_ACTION_GROUPS_SUCCESS = 'FETCH_ACTION_GROUPS_SUCCESS';
export const ADD_ACTION_GROUPS_SUCCESS = 'ADD_ACTION_GROUPS_SUCCESS';

// Task types
export const SHOW_TASKS = 'SHOW_TASKS';
export const CLEAR_TASKS = 'CLEAR_TASKS';
export const SHOW_TASK_DIAGNOSIS = 'SHOW_TASK_DIAGNOSIS';
export const CLEAR_TASK_DIAGNOSIS = 'CLEAR_TASK_DIAGNOSIS';
export const SHOW_TASK_SURVEYS = 'SHOW_TASK_SURVEYS';
export const CLEAR_TASK_SURVEYS = 'CLEAR_TASK_SURVEYS';
export const SHOW_TASK_IPP = 'SHOW_TASK_IPP';
export const CLEAR_TASK_IPP = 'CLEAR_TASK_IPP';
export const FETCH_TASK = 'FETCH_TASK';
export const FETCH_TASK_ACTION = 'FETCH_TASK_ACTION';
export const CLEAR_TASK_ACTION = 'CLEAR_TASK_ACTION';
export const FETCH_TASK_BENEFICIARY = 'FETCH_TASK_BENEFICIARY';
export const CLEAR_TASK_BENEFICIARY = 'CLEAR_TASK_BENEFICIARY';
export const FETCH_TASK_USERS = 'FETCH_TASK_USERS';
export const CLEAR_TASK_USERS = 'CLEAR_TASK_USERS';
export const FETCH_TASK_INDICATORS = 'FETCH_TASK_INDICATORS';
export const CLEAR_TASK_INDICATORS = 'CLEAR_TASK_INDICATORS';
export const FETCH_TASK_PROGRAM_ID = 'FETCH_TASK_PROGRAM_ID';
export const CLEAR_TASK_PROGRAM_ID = 'CLEAR_TASK_PROGRAM_ID';
export const FETCH_TASK_COORDINATORS = 'FETCH_TASK_COORDINATORS';
export const CLEAR_TASK = 'CLEAR_TASK';

// Indicator types
export const SHOW_INDICATORS = 'SHOW_INDICATORS';
export const CLEAR_INDICATORS = 'CLEAR_INDICATORS';
export const FETCH_INDICATOR = 'FETCH_INDICATOR';
export const FETCH_PERIODS = 'FETCH_PERIODS';
export const FETCH_PERIODS_SEMESTER = 'FETCH_PERIODS_SEMESTER';
export const CLEAR_PERIODS_SEMESTER = 'CLEAR_PERIODS_SEMESTER';
export const CLEAR_INDICATOR = 'CLEAR_INDICATOR';

// Beneficiary types
export const SHOW_BENEFICIARIES = 'SHOW_BENEFICIARIES';
export const CLEAR_BENEFICIARIES = 'CLEAR_BENEFICIARIES';
export const FETCH_BENEFICIARY = 'FETCH_BENEFICIARY';
export const CLEAR_BENEFICIARY = 'CLEAR_BENEFICIARY';
export const FETCH_BENEFICIARY_ADDED = 'FETCH_BENEFICIARY_ADDED';
export const FETCH_BENEFICIARY_INDICATORS = 'FETCH_BENEFICIARY_INDICATORS';
export const CLEAR_BENEFICIARY_INDICATORS = 'CLEAR_BENEFICIARY_INDICATORS';
export const FETCH_BENEFICIARY_BINDINGS = 'FETCH_BENEFICIARY_BINDINGS';
export const CLEAR_BENEFICIARY_BINDINGS = 'CLEAR_BENEFICIARY_BINDINGS';
export const FETCH_BENEFICIARY_RODO = 'FETCH_BENEFICIARY_RODO';
export const CLEAR_BENEFICIARY_RODO = 'CLEAR_BENEFICIARY_RODO';
export const FETCH_BENEFICIARY_PROGRAMS = 'FETCH_BENEFICIARY_PROGRAMS';
export const CLEAR_BENEFICIARY_PROGRAMS = 'CLEAR_BENEFICIARY_PROGRAMS';
export const FETCH_BENEFICIARY_PROGRAMS_SIMPLE = 'FETCH_BENEFICIARY_PROGRAMS_SIMPLE';
export const CLEAR_BENEFICIARY_PROGRAMS_SIMPLE = 'CLEAR_BENEFICIARY_PROGRAMS_SIMPLE';
export const FETCH_BENEFICIARY_ORGANIZATIONS = 'FETCH_BENEFICIARY_ORGANIZATIONS';
export const CLEAR_BENEFICIARY_ORGANIZATIONS = 'CLEAR_BENEFICIARY_ORGANIZATIONS';
export const FETCH_BENEFICIARY_SUPPORT = 'FETCH_BENEFICIARY_SUPPORT';
export const CLEAR_BENEFICIARY_SUPPORT = 'CLEAR_BENEFICIARY_SUPPORT';
export const FETCH_BENEFICIARY_DIAGNOSIS = 'FETCH_BENEFICIARY_DIAGNOSIS';
export const CLEAR_BENEFICIARY_DIAGNOSIS = 'CLEAR_BENEFICIARY_DIAGNOSIS';
export const FETCH_BENEFICIARY_IPP = 'FETCH_BENEFICIARY_IPP';
export const CLEAR_BENEFICIARY_IPP = 'CLEAR_BENEFICIARY_IPP';
export const FETCH_BENEFICIARY_DOCUMENTS = 'FETCH_BENEFICIARY_DOCUMENTS';
export const CLEAR_BENEFICIARY_DOCUMENTS = 'CLEAR_BENEFICIARY_DOCUMENTS';
export const FETCH_BENEFICIARY_NOTES = 'FETCH_BENEFICIARY_NOTES';
export const CLEAR_BENEFICIARY_NOTES = 'CLEAR_BENEFICIARY_NOTES';
export const FETCH_NEW_BENEFICIARY_PROGRAMS = 'FETCH_NEW_BENEFICIARY_PROGRAMS';
export const FETCH_CWP_INFO = 'FETCH_CWP_INFO';
export const CLEAR_CWP_INFO = 'CLEAR_CWP_INFO';
export const FETCH_SINGLE_DIAGNOSE = 'FETCH_SINGLE_DIAGNOSE';
export const CLEAR_SINGLE_DIAGNOSE = 'CLEAR_SINGLE_DIAGNOSE';
export const FETCH_SINGLE_SURVEY = 'FETCH_SINGLE_SURVEY';
export const CLEAR_SINGLE_SURVEY = 'CLEAR_SINGLE_SURVEY';
export const FETCH_CWP_LIST = 'FETCH_CWP_LIST';
export const CLEAR_CWP_LIST = 'CLEAR_CWP_LIST';
export const FETCH_SINGLE_IPP = 'FETCH_SINGLE_IPP';
export const REMOVE_SINGLE_NOTE = 'REMOVE_SINGLE_NOTE';
export const CLEAR_SINGLE_IPP = 'CLEAR_SINGLE_IPP';

// User types
export const SHOW_USERS = 'SHOW_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const FETCH_USERS_LOGIN_STATISTICS = 'FETCH_USERS_LOGIN_STATISTICS';
export const CLEAR_USERS_LOGIN_STATISTICS = 'CLEAR_USERS_LOGIN_STATISTICS';
export const FETCH_USER = 'FETCH_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const FETCH_ME = 'FETCH_ME';
export const CLEAR_ME = 'CLEAR_ME';
export const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS';
export const CLEAR_USER_PERMISSIONS = 'CLEAR_USER_PERMISSIONS';
export const FETCH_USER_NOTIFICATIONS = 'FETCH_USER_NOTIFICATIONS';

// Select types
export const FETCH_SUBJECT_LIST = 'FETCH_SUBJECT_LIST';
export const CLEAR_SUBJECT_LIST = 'CLEAR_SUBJECT_LIST';
export const FETCH_SUBJECT_PENDING = 'FETCH_SUBJECT_PENDING';
export const FETCH_PWD_LIST = 'FETCH_PWD_LIST';
export const CLEAR_PWD_LIST = 'CLEAR_PWD_LIST';
export const FETCH_PWD_PENDING = 'FETCH_PWD_PENDING';
export const FETCH_EDUCATIONALFACILITY_LIST = 'FETCH_EDUCATIONALFACILITY_LIST';
export const CLEAR_EDUCATIONALFACILITY_LIST = 'CLEAR_EDUCATIONALFACILITY_LIST';
export const FETCH_EDUCATIONALFACILITY_PENDING = 'FETCH_EDUCATIONALFACILITY_PENDING';
export const FETCH_INDICATORPERIODS_LIST = 'FETCH_INDICATORPERIODS_LIST';

// Config Types
export const FETCH_CONFIGS = 'FETCH_CONFIGS';
export const CLEAR_CONFIGS = 'CLEAR_CONFIGS';

// Message Types
export const FETCH_MESSAGE = 'FETCH_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const FETCH_MESSAGE_SINGLE = 'FETCH_MESSAGE_SINGLE';
export const CLEAR_MESSAGE_SINGLE = 'CLEAR_MESSAGE_SINGLE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
