// Assets
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

// Elements
import { Menu } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledMenu = styled(Menu)`
  && {
    & .MuiPaper-root {
      background-color: ${({ theme }) => theme.color.white};
      z-index: 9999999;
    }
  }
`;

export const StyledTooltip = styled.div`
  && {
    padding: 1.5rem 1rem;
    line-height: 1.1;

    > * {
      margin-bottom: 1rem;
    }

    p {
      &:first-child {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
`;

export const StyledUserIcon = styled(AccountCircleIcon)`
  && {
    fill: ${({ theme }) => theme.color.darkGray};
    width: 3.2rem;
    height: 3.2rem;
  }
`;
