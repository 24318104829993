// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchBeneficiaryIndicators as fetchBeneficiaryIndicatorsService } from '@services/beneficiaryServices';

// Actions
import { clearBeneficiaryIndicators as clearBeneficiaryIndicatorsAction } from '@actions/beneficiaryActions';

// Data
import {
  indicatorKind as indicatorKindSelect,
  indicatorCategory as indicatorCategorySelect,
} from '@constants/selectLists';

import { StyledPaper } from '@components/styled/StyledComponents';

// Elements
import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import MyAccordion from '@components/atoms/Accordion/Accordion';
import Heading from '@components/atoms/Heading/Heading';
import EditButton from '@components/atoms/EditButton/EditButton';
import EditIndicatorTable from '@components/organisms/EditIndicatorTable/EditIndicatorTable';
import EditDegreeTable from '@components/organisms/EditDegreeTable/EditDegreeTable';

// Styles
import { StyledBox, StyledTitle, StyledWrapper } from './BeneficiaryIndicators.styles';

// Component
const BeneficiaryIndicators = ({
  clearBeneficiaryIndicators,
  fetchBeneficiary,
  indicators,
  beneficiary,
}) => {
  const { id } = useParams();
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    clearBeneficiaryIndicators();
  }, []);

  const triggerFetchBeneficiary = useCallback((myid) => fetchBeneficiary(myid), [fetchBeneficiary]);

  useEffect(() => {
    triggerFetchBeneficiary(id);
  }, [id, triggerFetchBeneficiary]);

  return (
    <>
      <StyledTitle>
        <Heading.H6>Wskaźniki i oceny beneficjenta</Heading.H6>
        {(!beneficiary || beneficiary.name !== 'anonimizacja') && (
          <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
        )}
      </StyledTitle>
      <StyledWrapper className="supported">
        {indicators.indicators.length ? (
          indicators.indicators.map((program) => (
            <MyAccordion key={program.programId} title={program.programName}>
              {program.indicators &&
                Object.keys(program.indicators).map((indicatorKind) => (
                  <div key={indicatorKind}>
                    <StyledTitle>
                      <Heading.H6>
                        Wskaźnik {indicatorKindSelect.find((el) => el.value === indicatorKind).name}
                      </Heading.H6>
                    </StyledTitle>
                    {program.indicators[indicatorKind] &&
                      Object.keys(program.indicators[indicatorKind]).map((indicatorCategory) => (
                        <StyledBox key={indicatorCategory}>
                          <Box mb={1}>
                            <Heading.Subtitle2>
                              Wskaźnik{' '}
                              {
                                indicatorCategorySelect.find((el) => el.value === indicatorCategory)
                                  .name
                              }
                            </Heading.Subtitle2>
                          </Box>
                          {program.indicators[indicatorKind][indicatorCategory].map((indicator) => (
                            <StyledBox key={indicator.indicatorId}>
                              {indicator.organization && (
                                <Box mb={1}>
                                  <Heading.Body2>
                                    Organizacja: {indicator.organization}
                                  </Heading.Body2>
                                </Box>
                              )}
                              <EditIndicatorTable
                                indicator={indicator}
                                beneficiaryId={parseInt(id, 10)}
                                isEditable={isEditable}
                              />
                            </StyledBox>
                          ))}
                        </StyledBox>
                      ))}
                  </div>
                ))}
              {program.supported_subjects && program.supported_subjects.length > 0 && (
                <>
                  <StyledTitle>
                    <Heading.H6>Przedmioty wspierane </Heading.H6>
                  </StyledTitle>
                  {program.supported_subjects.map((s) => (
                    <StyledBox key={s.subject_id}>
                      {s && (
                        <EditDegreeTable
                          subject={s}
                          beneficiaryId={parseInt(id, 10)}
                          isEditable={isEditable}
                        />
                      )}
                    </StyledBox>
                  ))}
                </>
              )}
            </MyAccordion>
          ))
        ) : (
          <Grid item xs={12} container justifyContent="center">
            <Heading.Subtitle1>Brak danych</Heading.Subtitle1>
          </Grid>
        )}
      </StyledWrapper>
      <StyledTitle>
        <Heading.H6>Dane importowane z Librusa</Heading.H6>
      </StyledTitle>
      <StyledWrapper>
        {indicators.degrees && indicators.degrees.librus && indicators.degrees.librus.length > 0 ? (
          indicators.degrees.librus.map((classRegister) => (
            <MyAccordion key={classRegister.class_register_id} title={classRegister.period}>
              {classRegister.frequency && (
                <>
                  <StyledBox>
                    <StyledTitle>
                      <Heading.H6>Frekwencja</Heading.H6>
                    </StyledTitle>
                  </StyledBox>
                  <StyledBox>
                    <TableContainer className="tabela" component={StyledPaper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Heading.Body2>Liczba godzin w pierwszym semestrze</Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                Liczba godzin usprawiedliwionych I semestr
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                Liczba godzin nieusprawiedliwionych I semestr
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                Liczba godzin usprawiedliwionych koniec roku
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                Liczba godzin nieusprawiedliwionych koniec roku
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                Liczba godzin zajęć w semestrze I (łącznie)
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                Liczba godzin zajęć w semestrze (łącznie) koniec roku
                              </Heading.Body2>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.hoursInSemesterFirst}
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.justifiedHoursSemesterFirst}
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.unexcusedHoursSemesterFirst}
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.justifiedHoursEndYear}
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.unexcusedHoursEndYear}
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.hoursInSemesterFirst}
                              </Heading.Body2>
                            </TableCell>
                            <TableCell>
                              <Heading.Body2>
                                {classRegister.frequency.totalNumberHours}
                              </Heading.Body2>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </StyledBox>
                </>
              )}
              <StyledBox>
                <StyledTitle>
                  <Heading.H6>Oceny</Heading.H6>
                </StyledTitle>
              </StyledBox>
              <StyledBox>
                <TableContainer className="tabela" component={StyledPaper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Heading.Body2>Przedmiot</Heading.Body2>
                        </TableCell>
                        <TableCell>
                          <Heading.Body2>Oceny I semestr</Heading.Body2>
                        </TableCell>
                        <TableCell>
                          <Heading.Body2>Ocena końcowa I semestr</Heading.Body2>
                        </TableCell>
                        <TableCell>
                          <Heading.Body2>Oceny II semestr</Heading.Body2>
                        </TableCell>
                        <TableCell>
                          <Heading.Body2>Ocena końcowa II semestr</Heading.Body2>
                        </TableCell>
                        <TableCell>
                          <Heading.Body2> Ocena końcowa</Heading.Body2>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {classRegister.class_register_subjects.map((s) => (
                        <TableRow key={s.subject.id}>
                          <TableCell>
                            <Heading.Body2>{s.subject.name}</Heading.Body2>
                          </TableCell>
                          <TableCell>
                            <Heading.Body2>
                              {s.ratings.semesterFirst.partial &&
                                s.ratings.semesterFirst.partial.map((deg, index) =>
                                  index > 0 ? `, ${deg.name}` : `${deg.name}`,
                                )}
                            </Heading.Body2>
                          </TableCell>
                          <TableCell>
                            <Heading.Body2>
                              {s.ratings.semesterFirst.final
                                ? s.ratings.semesterFirst.final.name
                                : ''}
                            </Heading.Body2>
                          </TableCell>
                          <TableCell>
                            <Heading.Body2>
                              {s.ratings.semesterSecond.partial &&
                                s.ratings.semesterSecond.partial.map((deg, index) =>
                                  index > 0 ? ',' : `${deg.name}`,
                                )}
                            </Heading.Body2>
                          </TableCell>
                          <TableCell>
                            <Heading.Body2>
                              {s.ratings.semesterSecond.final
                                ? s.ratings.semesterSecond.final.name
                                : ''}
                            </Heading.Body2>
                          </TableCell>
                          <TableCell>
                            <Heading.Body2>
                              {s.ratings.annual ? s.ratings.annual.name : ''}
                            </Heading.Body2>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </StyledBox>
            </MyAccordion>
          ))
        ) : (
          <Heading.Body2>Brak importowanych danych</Heading.Body2>
        )}
      </StyledWrapper>
    </>
  );
};

BeneficiaryIndicators.propTypes = {
  clearBeneficiaryIndicators: PropTypes.func,
  fetchBeneficiary: PropTypes.func,
  indicators: PropTypes.shape({
    indicators: PropTypes.arrayOf(PropTypes.any),
    degrees: PropTypes.shape({
      librus: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
  school: PropTypes.shape({
    beneficiary_id: PropTypes.number,
    beneficiary_full_name: PropTypes.string,
    frequencies: PropTypes.shape({
      time_periods: PropTypes.arrayOf(
        PropTypes.shape({
          frequency_id: PropTypes.number,
          measurement_period: PropTypes.string,
        }),
      ),
      values: PropTypes.arrayOf(PropTypes.string),
    }),
    degrees: PropTypes.shape({
      time_periods: PropTypes.arrayOf(
        PropTypes.shape({
          frequency_id: PropTypes.number,
          measurement_period: PropTypes.string,
        }),
      ),
      subjects: PropTypes.arrayOf(PropTypes.any),
    }),
  }),
  beneficiary: PropTypes.shape({ name: PropTypes.string, surname: PropTypes.string }),
};

BeneficiaryIndicators.defaultProps = {
  clearBeneficiaryIndicators: null,
  fetchBeneficiary: null,
  indicators: [],
  beneficiary: null,
  school: {
    beneficiary_id: null,
    beneficiary_full_name: '',
    frequencies: {
      time_periods: [],
      values: [],
    },
    degrees: {
      time_periods: [],
      subjects: [],
    },
  },
};

const mapStateToProps = ({ beneficiaryReducer }) => ({
  indicators: beneficiaryReducer.indicators,
  school: beneficiaryReducer.indicators.degrees,
  beneficiary: beneficiaryReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearBeneficiaryIndicators: () => dispatch(clearBeneficiaryIndicatorsAction()),
  fetchBeneficiary: (id) => dispatch(fetchBeneficiaryIndicatorsService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BeneficiaryIndicators);
