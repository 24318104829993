import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
import {
  showProgramsAction,
  fetchProgramAction,
  fetchProgramConsortiumAction,
  fetchProgramTasksAction,
  fetchProgramIPPAction,
  fetchProgramActionsAction,
  fetchProgramUsersAction,
  fetchProgramBeneficiariesAction,
  fetchProgramIndicatorsAction,
  fetchProgramDiegnosisAction,
  fetchProgramCoordinatorsListAction,
  fetchProgramOrganizationsAction,
  fetchProgramTaskCoordinatorsAction,
  fetchPWDPresenceAction,
  fetchProgramDataAdministratorsAction,
  fetchActionGroupsSuccess,
  addActionGroupSuccess,
} from '@actions/programActions';
import { checkStatusCode } from '@utils/checkStatusCode';

// export const fetchActionGroups = (programId) => async (dispatch) => {
//   try {
//     const response = await fetch(`/programy/${programId}/action-groups`);
//     const data = await response.json();
//     dispatch(fetchActionGroupsSuccess(data));
//   } catch (error) {
//     console.error('Failed to fetch action groups', error);
//   }
// };

export const fetchActionGroups = (programId) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${programId}/action-groups`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchActionGroupsSuccess(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const addActionGroup = (programId, actionGroup) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${programId}/action-groups/add`, { name: actionGroup })
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(addActionGroupSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};
export const removeActionGroup = (programId, actionGroup) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${programId}/action-groups/remove`, { groupId: actionGroup })
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(addActionGroupSuccess(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramsList =
  (roles = []) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post('/programy/nowa_lista', { roles })
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch(showProgramsAction(res.data));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err),
            isActive: true,
          }),
        );
      });
  };

export const fetchProgramsListSelect =
  (roles = []) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post('/programy/nowa_lista_select', { roles })
      .then((res) => {
        dispatch(fetchSuccess());
        dispatch(showProgramsAction(res.data));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err),
            isActive: true,
          }),
        );
      });
  };

export const saveConsortiumLeader = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/program/${id}/zapisz_lidera`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać obecności'),
          isActive: true,
        }),
      );
    });
};

export const savePWDAttendanceList = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/program/${id}/zapisz_obecnosc`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać obecności'),
          isActive: true,
        }),
      );
    });
};

export const fetchPWDPresence = (id, startDate, endDate) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/program/${id}/dziennik`, { params: { date_start: startDate, date_end: endDate } })
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchPWDPresenceAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgram = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/program/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramAction(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
      if (err && err.response) {
        return err.response.status;
      }
      return err;
    });
};

export const editProgram = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/edytuj_program`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );

      if (id !== 'new') {
        dispatch(fetchProgram(id));
      }
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować program'),
          isActive: true,
        }),
      );
    });
};

export const editProgramPWD = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/edytuj_pwd_program`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );

      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować program'),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramConsortium = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/konsorcjum`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramConsortiumAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramOrganizations = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/organizacje`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramOrganizationsAction(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramDataAdministrators = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/administratorzy_danych`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramDataAdministratorsAction(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramTaskCoordinators = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/koordynatorzy_zadan`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramTaskCoordinatorsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const setAdvancedrodoProgram = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/konsorcjum_rodo`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramConsortium(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować programu'),
          isActive: true,
        }),
      );
    });
};

export const saveAdvancedrodoConfigProgram = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/konsorcjum_rodo_konfiguracja`, { data })
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramConsortium(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować programu'),
          isActive: true,
        }),
      );
    });
};
export const removeEntrusting = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/usun_powierzenie`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramConsortium(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować programu'),
          isActive: true,
        }),
      );
    });
};

export const inviteProgram = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/zapros_organizacje`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramConsortium(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować programu'),
          isActive: true,
        }),
      );
    });
};

export const allInvitedProgram = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/all_invited`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramConsortium(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować programu'),
          isActive: true,
        }),
      );
    });
};

export const cancelInvitationToProgram = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/programy/${id}/cofnij_zaproszenie`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramConsortium(id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować programu'),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramCoordinatorsList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/koordynatorzy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramCoordinatorsListAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramTasks =
  (id, updateReducer = true) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .get(`/programy/${id}/zadania`)
      .then((res) => {
        dispatch(fetchSuccess());

        if (updateReducer) {
          dispatch(fetchProgramTasksAction(res.data));
        }

        return res.data;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err),
            isActive: true,
          }),
        );
      });
  };

export const fetchProgramIPP = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/ipp`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramIPPAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramActions = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/dzialania`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramActionsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramUsersSimple = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/uzytkownicy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramUsersAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramUsers = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/uzytkownicy_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramUsersAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramBeneficiaries = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/beneficjenci`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramBeneficiariesAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramIndicators = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/wskazniki`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramIndicatorsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchProgramDiagnosis = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/programy/${id}/diagnozy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchProgramDiegnosisAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};
