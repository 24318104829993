// Elements
import { Grid, RadioGroup, TableCell, TextField } from '@mui/material';
import Button from '@components/atoms/Button/Button';
import Input from '@components/atoms/Input/Input';

// Styles
import styled, { css } from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
    margin-top: ${({ margin }) => margin || '0rem'};

    &.MuiGrid-spacing-xs-2 {
      margin-top: ${({ margin }) => margin || '6rem'};
    }
  }
`;

export const StyledTextareaWrapper = styled(Grid)`
  && {
    margin-top: 2rem;
    padding: 8px;
  }
`;

export const StyledTextarea = styled(Input)`
  && {
    margin-top: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    margin-left: ${({ margin }) => margin || '0rem'};
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.base};
  font-size: ${({ theme }) => theme.fontSize.body};
  color: ${({ theme, color }) => color || theme.color.darkGray};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: ${({ align }) => align || 'left'};
  margin: 0;
`;

export const StyledTableCell = styled(TableCell)`
  && {
    ${({ noPadding }) =>
      noPadding &&
      css`
        && {
          padding: 0;
        }
      `}
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  && {
    padding: 8px;
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;

    textarea,
    label {
      font-size: ${({ theme }) => theme.fontSize.body2};
      padding: 0rem 1.5rem;
      color: ${({ theme }) => theme.color.darkGray};
    }

    textarea {
      line-height: 1.4;
    }
    .MuiInputBase-root {
      &::after {
        display: none;
      }
      &::before {
        border-bottom: 0px !important;
      }
    }
  }
`;

export const StyledEditWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;
