// Elements
import { Grid, Accordion } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    background-color: ${({ theme }) => theme.color.white};
    svg {
      fill: ${({ theme }) => theme.color.darkGray};
    }
  }
`;
