// React
import React from 'react';

// Elements
import { Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import ProgramSettings from '@views/Dashboard/Programs/ProgramSettings/ProgramSettings';

// Styles
import { StyledWrapper } from './ProgramAdd.styles';

// Component
const ProgramAdd = () => (
  <StyledWrapper container>
    <Grid item xs={12}>
      <Heading.Subtitle2>Nowy program</Heading.Subtitle2>
    </Grid>
    <ProgramSettings isNew />
  </StyledWrapper>
);

export default ProgramAdd;
