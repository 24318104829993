// Elements
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

// Styles
import styled from 'styled-components';

export const Wrapper = styled.div`
  && {
    position: relative;
    margin: ${({ removemargin }) => (removemargin === 'true' ? '0' : '0 3rem')};
  }
`;

export const StyledDateRangePicker = styled(DateRangePicker)`
  && {
    z-index: 5;

    .react-calendar {
      border: 0;
    }

    .react-calendar__month-view__weekdays {
      font-size: 1.2rem;
      color: rgba(0, 0, 0, 0.38);
      font-weight: 300;
      text-transform: none;
      abbr {
        text-decoration: none;
      }
    }

    .react-calendar__tile:disabled {
      background: none;
      color: rgba(0, 0, 0, 0.5);

      &.react-calendar__tile--hover,
      &.react-calendar__tile--hoverEnd,
      &:focus,
      &:hover {
        abbr {
          background: none;
        }
      }
    }

    .react-calendar__tile--hover,
    .react-calendar__tile--hoverEnd,
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background: none;

      abbr {
        background: #e6e6e6;
      }
    }

    .react-calendar__tile,
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5rem;
    }

    .react-calendar__tile--now {
      background: none;

      abbr {
        background: #ffff76;
      }
    }

    .react-calendar button {
      abbr {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        margin-left: auto;
        margin-right: auto;
      }

      &.react-calendar__tile--active,
      &.react-calendar__tile--hasActive {
        background: transparent;

        abbr {
          color: #ffffff;
          background: #3f51b5;
        }

        &:focus,
        &:hover {
          abbr {
            background: #3f51b5;
          }
        }
      }
    }
  }
`;
