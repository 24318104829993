// React
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledTextField } from './Input.styles';

// Component
const Input = ({ charCounter, setField, dispatch, min, max, step, ...rest }) => {
  const [chars, setChars] = useState(0);

  return (
    <>
      {charCounter > 0 ? (
        <StyledTextField
          {...rest}
          onChange={(e) => {
            setField(e.target.name, e.target.value);
            setChars(e.target.value.length);
          }}
          helperText={`${chars} / ${charCounter}`}
          right={1}
          inputProps={{
            maxLength: charCounter,
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
      ) : (
        <StyledTextField
          {...rest}
          inputProps={{
            min,
            max,
            step,
          }}
        />
      )}
    </>
  );
};

Input.propTypes = {
  charCounter: PropTypes.number,
  setField: PropTypes.func,
  dispatch: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
};

Input.defaultProps = {
  charCounter: 0,
  min: null,
  max: null,
  step: null,
  setField: null,
  dispatch: null,
};

export default Input;
