// React
import React from 'react';

// Blob
import { Blob } from 'blob-polyfill';

// Saver
import { saveAs } from 'file-saver';

// PDF
import { pdf } from '@react-pdf/renderer';
import BeneficiaryIPPPDF from '@views/Dashboard/Beneficiary/BeneficiaryIPP/BenificiaryIPPPDF';
import BeneficiaryDiagnosisPDF from '@views/Dashboard/Beneficiary/BeneficiaryDiagnosis/BeneficiaryDiagnosisPDF';
import BeneficiarySurveysPDF from '@views/Dashboard/Beneficiary/BeneficiaryDiagnosis/BeneficiarySurveysPDF';
import BeneficiaryInstructionSurveysPDF from '@views/Dashboard/Beneficiary/BeneficiaryDiagnosis/BeneficiaryInstructionSurveysPDF';
import BeneficiaryNotesPDF from '@views/Dashboard/Beneficiary/BeneficiaryNotes/BeneficiaryNotesPDF';
import Report27PDF from '@views/Dashboard/Reports/AddReport/Report27PDF';

// Data
import { ageRange } from '@constants/surveys';
import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_GUARDIAN,
  INSTRUCTOR,
} from '@constants/roles';

// Excel
import * as XLSX from 'xlsx';

export const isInstructorOnly = (roles) =>
  ![
    COORDINATOR,
    PROGRAM_GUARDIAN,
    PROGRAM_COORDINATOR,
    TASK_COORDINATOR,
    LECTURER_SENSITIVE_DATA,
    LECTURER,
  ].some((r) => roles.includes(r));

export const isGuardianOnly = (roles) =>
  ![
    COORDINATOR,
    PROGRAM_COORDINATOR,
    TASK_COORDINATOR,
    LECTURER_SENSITIVE_DATA,
    LECTURER,
    INSTRUCTOR,
  ].some((r) => roles.includes(r));

export const isCoordinator = (roles) => [COORDINATOR].some((r) => roles.includes(r));
export const isGuardianInProgram = (me, programId) => {
  return me.user_programs.some((p) => p.id === programId && p.role === PROGRAM_GUARDIAN);
};
export const isAdministratorInOrganization = (me, organizationId) => {
  return me.user_organizations.some(
    (o) => o.id === organizationId && o.role === ORGANIZATION_ADMINISTRATOR,
  );
};

export const generateBeneficiaryIPPPDF = async (data) => {
  const blob = await pdf(<BeneficiaryIPPPDF data={data} />).toBlob();

  saveAs(blob, `${data.title}.pdf`);
};

export const generateBeneficiaryDiagnosisPDF = async (data) => {
  console.log(data);
  const blob = await pdf(<BeneficiaryDiagnosisPDF data={data} />).toBlob();

  saveAs(blob, `${data.name}.pdf`);
};

export const generateBeneficiarySurveysPDF = async (data) => {
  const blob = await pdf(<BeneficiarySurveysPDF data={data} />).toBlob();

  saveAs(blob, `${data.name}.pdf`);
};

export const generateReport27PDF = async (data) => {
  if (data.data) {
    const blob = await pdf(<Report27PDF data={data.data} />).toBlob();

    saveAs(blob, `przetwarzane_dane_rodo_${data.data.name}_${data.data.surname}.pdf`);
  }
};

export const generateBeneficiaryEmptySurveyPDF = async (age) => {
  const blob = await pdf(<BeneficiarySurveysPDF age={age} empty />).toBlob();
  const getLabel = ageRange.find((el) => el.value === age);
  let fileName = 'ankieta.pdf';

  if (getLabel) {
    fileName = `ankieta_${getLabel.name}.pdf`;
  }

  saveAs(blob, fileName);
};

export const generateBeneficiaryInstructionSurveyPDF = async () => {
  const blob = await pdf(<BeneficiaryInstructionSurveysPDF />).toBlob();

  saveAs(blob, 'instrukcja_do_ankiety.pdf');
};

export const generateBeneficiaryNotesPDF = async (data) => {
  const blob = await pdf(<BeneficiaryNotesPDF data={data} />).toBlob();

  saveAs(blob, `${data.name}.pdf`);
};

export const exportToExcel = (dataTable, headTable, fileName) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const newData = [];
  const colKeys = {};

  headTable.map((el) => {
    colKeys[el.type] = el.label;

    return true;
  });

  dataTable.map((el, index) => {
    const newObj = {};
    newObj['l.p.'] = `${index + 1}.`;
    Object.keys(el).map((item) => {
      if (colKeys[item]) {
        newObj[colKeys[item]] = el[item];
      }

      return true;
    });
    newData.push(newObj);

    return true;
  });

  const ws = XLSX.utils.json_to_sheet(newData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  saveAs(data, `${fileName}.xlsx`);
};

export const exportPresenceToExcel = (dataTable, fileName) => {
  const { dates, beneficiaries } = dataTable;
  const presenceChars = {
    '-1': 'x',
    1: '✓',
    2: '-',
  };

  const newData = [];
  const cols = ['Imię i nazwisko'];
  const colsEst = [`Szacunkowa liczba`];

  dates.map((el) => {
    cols.push(el.date);
    colsEst.push(el.estimatedParticipants ? el.estimatedParticipantsNumber : '-');
    return true;
  });

  newData.push(cols);

  beneficiaries.map(({ beneficiary, attendance }) => {
    const singleAttendance = [];

    Object.keys(attendance).map((it) => {
      if (attendance[it]) {
        singleAttendance.push(presenceChars[attendance[it]]);
      } else {
        singleAttendance.push(' ');
      }

      return true;
    });

    newData.push([`${beneficiary.name} ${beneficiary.surname}`, ...singleAttendance]);

    return true;
  });

  newData.push(colsEst);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const ws = XLSX.utils.json_to_sheet(newData, { skipHeader: 1 });
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });

  saveAs(data, `${fileName}.xlsx`);
};

export function ParsePesel(s) {
  if (s.length !== 11) return { error: 'nieprawidłowa długość' };

  const aInt = [];
  for (let i = 0; i < 11; i) {
    aInt[i] = parseInt(s.substring(i, i + 1), 10);
    if (Number.isNaN(aInt[i])) {
      return { error: 'nieprawidłowe znaki' };
    }
    i += 1;
  }

  const wagi = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 1];
  let sum = 0;
  for (let i = 0; i < 11; i) {
    sum += wagi[i] * aInt[i];
    i += 1;
  }
  if (sum % 10 !== 0) return { error: 'nieprawidłowa suma kontrolna' };

  let rok = 1900 + aInt[0] * 10 + aInt[1];
  if (aInt[2] >= 2 && aInt[2] < 8) rok += Math.floor(aInt[2] / 2) * 100;
  if (aInt[2] >= 8) rok -= 100;

  const miesiac = (aInt[2] % 2) * 10 + aInt[3];
  const dzien = aInt[4] * 10 + aInt[5];

  const dt = new Date(rok, miesiac - 1, dzien);
  if (dt.getDate() === dzien && dt.getMonth() === miesiac && dt.getFullYear() === rok)
    return { error: 'nieprawidłowa data' };

  const plec = aInt[9] % 2 === 1 ? 'male' : 'female';

  return { data: dt, plec };
}
