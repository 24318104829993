export const standardfontSize = {
  h1: '9.6rem',
  h2: '6rem',
  h3: '4.8rem',
  h4: '3rem',
  h5: '2.4rem',
  h6: '2rem',
  body: '1.6rem',
  body2: '1.4rem',
  caption: '1.2rem',
  overline: '1rem',
};

export const normalColor = {
  white: '#FFFFFF',
  lightGray: '#F3F3F3',
  gray: '#DEDEDE',
  grayToBlack: '#DEDEDE',
  mediumGray: '#BFBFBF',
  darkGray: '#707070',
  darkGreyToBlack: '#707070',
  yellow: '#F8B227',
  red: '#E3191B',
  lightBlue: '#ADD8E6',
  blue: '#076BB3',
  black: '#000000',
  green: '#00BC06',
  float: '#000000',
  shadow: 'rgba(0, 0, 0, 0.07)',
  icon: '#ffffff',
  bgIcon: '#F3F3F3',
};

export const contrastColor = {
  white: '#000000',
  lightGray: '#444444',
  gray: '#F8B227',
  grayToBlack: '#000000',
  mediumGray: '#F8B227',
  darkGray: '#F8B227',
  darkGreyToBlack: '#000000',
  lightBlue: '#F8B227',
  yellow: '#F8B227',
  red: '#F8B227',
  blue: '#F8B227',
  black: '#ffffff',
  green: '#F8B227',
  float: '#000000',
  shadow: 'rgba(#f8b227, 0.15)',
  icon: '#F8B227',
  bgIcon: '#000000',
};

const size = {
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '1920px',
};

export const device = {
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
};

export const theme = {
  fontFamily: {
    head: '"rubik", sans-serif',
    base: '"rubik", sans-serif',
  },
  color: {
    white: '#FFFFFF',
    lightGray: '#F3F3F3',
    gray: '#DEDEDE',
    mediumGray: '#BFBFBF',
    darkGray: '#707070',
    yellow: '#F8B227',
    red: '#E3191B',
    blue: '#076BB3',
    black: '#000000',
    green: '#00BC06',
  },
  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
  },
  transition: '0.5s all ease',
  borderRadius: {
    small: '0.2rem',
    normal: '0.4rem',
    big: '0.6rem',
  },
};
