// React
import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramsList } from '@services/programServices';

// Actions
import { clearPrograms as clearProgramsAction } from '@actions/programActions';

// Data
import { filterPrograms } from '@constants/tableFilters';
import { COORDINATOR } from '@constants/roles';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// Data
const headCells = [
  { type: 'name', label: 'Nazwa programu' },
  { type: 'type', id: 'type', label: 'Typ programu' },
  { type: 'is_active', id: 'is_active', label: 'Trwający' },
  { type: 'organizations_number', label: 'Organizacje' },
  { type: 'tasks_number', label: 'Zadania' },
  { type: 'beneficiaries_number', label: 'Beneficjenci' },
  { type: 'date_start', label: 'Data rozpoczęcia' },
  { type: 'date_end', label: 'Data zakończenia' },
  { type: 'planned_meeting_hours', label: 'Godziny w ramach działań' },
  { type: 'coordinator', label: 'Koordynator' },
];

// Constant
const Programs = ({ clearPrograms, fetchList, programs }) => {
  const history = useHistory();

  useEffect(() => {
    clearPrograms();
  }, []);

  const triggerFetchList = useCallback(() => fetchList(), [fetchList]);

  useEffect(() => {
    triggerFetchList();
  }, [triggerFetchList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={programs}
        filters={filterPrograms}
        tableType
        navCells
        link="/programy"
        tableName="Programy"
      />
      <GuardedComponent allowed_user_roles={[COORDINATOR]}>
        <FloatingButton onClick={() => history.push('/programy-dodaj')} />
      </GuardedComponent>
    </>
  );
};

Programs.propTypes = {
  clearPrograms: PropTypes.func,
  fetchList: PropTypes.func,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

Programs.defaultProps = {
  clearPrograms: null,
  fetchList: null,
  programs: [],
};

const mapStateToProps = ({ programReducer }) => ({
  programs: programReducer.programs,
});

const mapDispatchToProps = (dispatch) => ({
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchList: () => dispatch(fetchProgramsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Programs);
