// React
import { Link } from 'react-router-dom';

// React SVG
import { ReactSVG } from 'react-svg';

// Assets
import AssignmentIcon from '@mui/icons-material/Assignment';

// Elements
import { Grid, ListItem, Card } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import styled, { css } from 'styled-components';

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    padding: 2rem;
    display: flex;
    align-items: center;
    ${({ color, theme }) => {
      switch (color) {
        case 'blue':
          return css`
            background-color: ${theme.color.blue};
          `;
        case 'red':
          return css`
            background-color: ${theme.color.red};
          `;
        case 'yellow':
          return css`
            background-color: ${theme.color.yellow};
          `;
        default:
          return null;
      }
    }}
  }
`;

export const StyledLink = styled(Link)`
  && {
    color: inherit;
    text-decoration: none;
  }
`;

export const StyledListItem = styled(ListItem)`
  && {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 1.5rem;
      left: 0;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      ${({ color, theme }) => {
        switch (color) {
          case 'blue':
            return css`
              background-color: ${theme.color.blue};
            `;
          case 'red':
            return css`
              background-color: ${theme.color.red};
            `;
          case 'yellow':
            return css`
              background-color: ${theme.color.yellow};
            `;
          default:
            return null;
        }
      }}
    }
  }
`;

export const StyledTitleText = styled(Heading.Subtitle1)`
  && {
    margin-left: 1rem;
    color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledCard = styled(Card)`
  && {
    background-color: ${({ theme }) => theme.color.white};
  }
`;

export const StyledAssignmentIcon = styled(AssignmentIcon)`
  && {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.color.blue};
  }
`;

export const Audyt = styled(ReactSVG)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.color.blue};
  }
`;

export const Rimage = styled(ReactSVG)`
  && {
    display: flex;
    width: 2rem;
    height: 2rem;
    fill: #f8b227;
    align-items: center;
    justify-content: center;
  }
`;

export const StyledIconWrapper = styled.div`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 50%;
    padding: 1rem;
  }
`;
