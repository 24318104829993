// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Elements
import { Card } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  && {
    position: relative;
    background-color: ${({ theme }) => theme.color.white};
    min-height: 30rem;
    min-height: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      box-shadow: 0 2.8px 2.2px ${({ shadows }) => shadows},
        0 6.7px 5.3px ${({ shadows }) => shadows}, 0 12.5px 10px ${({ shadows }) => shadows},
        0 22.3px 17.9px ${({ shadows }) => shadows}, 0 41.8px 33.4px ${({ shadows }) => shadows};
      transition: 0.3s;
    }
  }
`;

export const StyledPlus = styled(AddCircleIcon)`
  && {
    fill: ${({ theme }) => theme.color.darkGray};
    font-size: 10rem;
    cursor: pointer;
  }
`;
