// React
import React from 'react';
import PropTypes from 'prop-types';

// Assets
// import DashboardIcon from '@mui/icons-material/Dashboard';
import ReorderIcon from '@mui/icons-material/Reorder';

// Elements
import { IconButton } from '@mui/material';
import MoreIcon from '@components/molecules/MoreIcon/MoreIcon';
import SearchInput from '@components/atoms/SearchInput/SearchInput';

// Styles
import { StyledOption, StyledFilterListIcon, StyledButton } from './TableOptions.styles';

// Component
const TableOptions = ({
  gridView,
  setGridView,
  tableType,
  isFilterHide,
  setIsFilterHide,
  actions,
  tableBene,
  noFilter,
}) => (
  <>
    {tableType ? (
      <StyledOption>
        {gridView ? (
          <StyledButton onClick={() => setGridView(!gridView)} startIcon={<ReorderIcon />}>
            Widok listy
          </StyledButton>
        ) : (
          <>
            <SearchInput />
            {!noFilter && (
              <IconButton onClick={() => setIsFilterHide(!isFilterHide)}>
                <StyledFilterListIcon />
              </IconButton>
            )}
            {(tableBene || actions.length > 0) && <MoreIcon size="2.5rem" actions={actions} />}
            {/* <StyledButton onClick={() => setGridView(!gridView)} startIcon={<DashboardIcon />}>
              Widok kafelek
            </StyledButton> */}
          </>
        )}
      </StyledOption>
    ) : (
      <StyledOption>
        <SearchInput />
        {!noFilter && (
          <IconButton onClick={() => setIsFilterHide(!isFilterHide)}>
            <StyledFilterListIcon />
          </IconButton>
        )}
        {(tableBene || actions.length > 0) && <MoreIcon size="2.5rem" actions={actions} />}
      </StyledOption>
    )}
  </>
);

TableOptions.propTypes = {
  tableBene: PropTypes.bool,
  setGridView: PropTypes.func,
  gridView: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  tableType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isFilterHide: PropTypes.bool,
  setIsFilterHide: PropTypes.func,
  actions: PropTypes.arrayOf(PropTypes.any),
  noFilter: PropTypes.bool,
};

TableOptions.defaultProps = {
  tableBene: false,
  actions: [],
  setGridView: null,
  gridView: false,
  tableType: '',
  isFilterHide: true,
  setIsFilterHide: null,
  noFilter: false,
};

export default TableOptions;
