export const ROLE_SONATA_COORDINATOR = 'ROLE_SONATA_COORDINATOR';

export const ROLE_SONATA_ORGANIZATION_ADMINISTRATOR = 'ROLE_SONATA_ORGANIZATION_ADMINISTRATOR';
export const ROLE_SONATA_ORGANIZATION_EMPLOYEE = 'ROLE_SONATA_ORGANIZATION_EMPLOYEE';
export const ROLE_SONATA_ORGANIZATION_EXTERNAL_COLLABORATOR =
  'ROLE_SONATA_ORGANIZATION_EXTERNAL_COLLABORATOR';

export const ROLE_SONATA_PROGRAM_GUARDIAN = 'ROLE_SONATA_PROGRAM_GUARDIAN';

export const ROLE_SONATA_PROGRAM_COORDINATOR = 'ROLE_SONATA_PROGRAM_COORDINATOR';
export const ROLE_SONATA_TASK_COORDINATOR = 'ROLE_SONATA_TASK_COORDINATOR';
export const ROLE_SONATA_LECTURER_SENSITIVE_DATA = 'ROLE_SONATA_LECTURER_SENSITIVE_DATA';
export const ROLE_SONATA_LECTURER = 'ROLE_SONATA_LECTURER';
export const ROLE_SONATA_INSTRUCTOR = 'ROLE_SONATA_INSTRUCTOR';

export const NO_ACCESS = 'NO_ACCESS';

// short
export const COORDINATOR = 'ROLE_SONATA_COORDINATOR';

export const ORGANIZATION_ADMINISTRATOR = 'ROLE_SONATA_ORGANIZATION_ADMINISTRATOR';
export const ORGANIZATION_EMPLOYEE = 'ROLE_SONATA_ORGANIZATION_EMPLOYEE';
export const ORGANIZATION_EXTERNAL_COLLABORATOR = 'ROLE_SONATA_ORGANIZATION_EXTERNAL_COLLABORATOR';

export const PROGRAM_GUARDIAN = 'ROLE_SONATA_PROGRAM_GUARDIAN';

export const PROGRAM_COORDINATOR = 'ROLE_SONATA_PROGRAM_COORDINATOR';
export const TASK_COORDINATOR = 'ROLE_SONATA_TASK_COORDINATOR';
export const LECTURER_SENSITIVE_DATA = 'ROLE_SONATA_LECTURER_SENSITIVE_DATA';
export const LECTURER = 'ROLE_SONATA_LECTURER';
export const INSTRUCTOR = 'ROLE_SONATA_INSTRUCTOR';

export const rolesTranslator = (role) => {
  switch (role) {
    case ROLE_SONATA_COORDINATOR:
      return 'Administrator systemu';
    case ROLE_SONATA_ORGANIZATION_ADMINISTRATOR:
      return 'Administrator organizacji';
    case ROLE_SONATA_ORGANIZATION_EMPLOYEE:
      return 'Pracownik organizacji';
    case ROLE_SONATA_ORGANIZATION_EXTERNAL_COLLABORATOR:
      return 'Współpracownik zewnętrzny';
    case ROLE_SONATA_PROGRAM_COORDINATOR:
      return 'Koordynator programu';
    case ROLE_SONATA_PROGRAM_GUARDIAN:
      return 'Opiekun programu';
    case ROLE_SONATA_TASK_COORDINATOR:
      return 'Koordynator zadania';
    case ROLE_SONATA_LECTURER_SENSITIVE_DATA:
      return 'Prowadzący - z wglądem w dane wrażliwe';
    case ROLE_SONATA_LECTURER:
      return 'Prowadzący bez wglądu w dane wrażliwe';
    case ROLE_SONATA_INSTRUCTOR:
      return 'Instruktor';
    default:
      return '';
  }
};
