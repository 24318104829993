// Styles
import styled from 'styled-components';

export const StyledBox = styled.div`
  && {
    height: 12rem;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    align-items: flex-end;
    gap: 1rem;
  }
`;
