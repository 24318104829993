// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Services
import {
  updateBeneficiaryProgramStartDate as updateBeneficiaryProgramStartDateService,
  updateBeneficiaryActionStartDate as updateBeneficiaryActionStartDateService,
} from '@services/beneficiaryServices';

// Elements
import { Box, Button } from '@mui/material';
import Dialog from '@components/organisms/Dialog/Dialog';
import Calendar from '@components/atoms/Calendar/Calendar';

// Component
const DialogEditDate = ({
  updateBeneficiaryProgramStartDate,
  updateBeneficiaryActionStartDate,
  open,
  setOpenFn,
  dialogBeneficiary,
  typeAction,
}) => {
  const fullName = !typeAction
    ? `${dialogBeneficiary?.beneficiary_name} ${dialogBeneficiary?.beneficiary_last_name}`
    : `${dialogBeneficiary?.name} ${dialogBeneficiary?.surname}`;
  return (
    dialogBeneficiary &&
    Object.keys(dialogBeneficiary).length > 0 && (
      <Dialog
        size="xs"
        open={open}
        setOpen={setOpenFn}
        title={`Edytuj datę ${typeAction ? 'rozpoczęcia' : 'dodania'} dla ${fullName}`}
        onClose={() => setOpenFn(false)}
      >
        <>
          <Box mb={3}>
            <Formik
              initialValues={{
                dateAddedToProgram: !typeAction ? dialogBeneficiary.date_added_to_program : null,
                date_start: typeAction ? dialogBeneficiary.date_start : null,
              }}
              validationSchema={Yup.object().shape({
                dateAddedToProgram: Yup.string()
                  .nullable()
                  .test(
                    'dateAddedToProgram',
                    'Data nie może być wcześniejsza niż data rozpoczęcia programu!',
                    (val) => val >= dialogBeneficiary.program_start || typeAction,
                  ),
                date_start: Yup.string()
                  .nullable()
                  .test(
                    'date_start',
                    'Data nie może być wcześniejsza niż data rozpoczęcia działania!',
                    (val) => val >= dialogBeneficiary.action_start || !typeAction,
                  ),
              })}
              enableReinitialize
              onSubmit={(values) => {
                if (!typeAction) {
                  updateBeneficiaryProgramStartDate(
                    dialogBeneficiary.cwp_id,
                    values,
                    dialogBeneficiary.program_id,
                  ).then(() => {
                    setOpenFn(false);
                  });
                } else {
                  updateBeneficiaryActionStartDate(
                    dialogBeneficiary.cwa_id,
                    values,
                    dialogBeneficiary.action_id,
                  ).then(() => {
                    setOpenFn(false);
                  });
                }
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <>
                  <Calendar
                    label={!typeAction ? 'Data dodania' : 'Data rozpoczęcia'}
                    variant="filled"
                    disableFuture
                    name={!typeAction ? 'dateAddedToProgram' : 'date_start'}
                    onChange={setFieldValue}
                    value={!typeAction ? values.dateAddedToProgram : values.date_start}
                    minDate={
                      !typeAction ? dialogBeneficiary.program_start : dialogBeneficiary.action_start
                    }
                  />
                  <ErrorMessage name={!typeAction ? 'dateAddedToProgram' : 'date_start'}>
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                  <Box display="flex" justifyContent="flex-end" mt={3}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => {
                        setOpenFn(false);
                      }}
                    >
                      Anuluj
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      style={{ marginLeft: setOpenFn ? 15 : 0 }}
                      onClick={handleSubmit}
                    >
                      Zapisz
                    </Button>
                  </Box>
                </>
              )}
            </Formik>
          </Box>
        </>
        <></>
      </Dialog>
    )
  );
};

DialogEditDate.propTypes = {
  updateBeneficiaryProgramStartDate: PropTypes.func,
  updateBeneficiaryActionStartDate: PropTypes.func,
  dialogBeneficiary: PropTypes.shape({
    id: PropTypes.number,
    action_id: PropTypes.number,
    program_id: PropTypes.number,
    action_start: PropTypes.string,
    program_start: PropTypes.string,
    beneficiary_gender: PropTypes.string,
    surname: PropTypes.string,
    name: PropTypes.string,
    beneficiary_last_name: PropTypes.string,
    beneficiary_name: PropTypes.string,
    cwa_id: PropTypes.number,
    cwp_id: PropTypes.number,
    date_added_to_program: PropTypes.string,
    date_start: PropTypes.string,
    date_modified: PropTypes.string,
    educational_facility_id: PropTypes.number,
    educational_facility_name: PropTypes.string,
    modified_by: PropTypes.string,
    pwd: PropTypes.string,
  }),
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  typeAction: PropTypes.bool,
};

DialogEditDate.defaultProps = {
  updateBeneficiaryProgramStartDate: null,
  updateBeneficiaryActionStartDate: null,
  dialogBeneficiary: null,
  typeAction: false,
};

const mapDispatchToProps = (dispatch) => ({
  updateBeneficiaryProgramStartDate: (cwp_id, data, program_id) =>
    dispatch(updateBeneficiaryProgramStartDateService(cwp_id, data, program_id)),
  updateBeneficiaryActionStartDate: (cwa_id, data, action_id) =>
    dispatch(updateBeneficiaryActionStartDateService(cwa_id, data, action_id)),
});

export default connect(null, mapDispatchToProps)(DialogEditDate);
