// React
import React from 'react';

// Breadcrumbs
import { Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic';

// Styles
import { StyledLink, StyledSpan, StyledWrapper } from './RouterBreadcrumbs.styles';
import './RouterBreadcrumbs.scss';

// Component
const RouterBreadcrumbs = () => (
  <>
    <StyledWrapper aria-label="breadcrumb">
      <DynamicBreadcrumbs
        separator=" / "
        item={StyledLink}
        finalItem={StyledSpan}
        finalProps={{}}
        compare={(a, b) => {
          if (Number.isInteger(a.order) && Number.isInteger(b.order)) {
            return a.order - b.order;
          }

          if (Number.isInteger(b.order)) {
            return 100 - b.order;
          }

          return a.to.length - b.to.length;
        }}
      />
    </StyledWrapper>
  </>
);

export default RouterBreadcrumbs;
