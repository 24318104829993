// React
import { Link } from 'react-router-dom';

// Elements
import { Breadcrumbs } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  && {
    text-decoration: none;
    margin: 0 5px;
    color: ${({ theme }) => theme.color.darkGray};

    &:hover {
      color: ${({ theme }) => theme.color.black};
    }
  }
`;

export const StyledSpan = styled.span`
  && {
    text-decoration: none;
    margin: 0 5px;
    color: ${({ theme }) => theme.color.darkGray};

    &:hover {
      color: ${({ theme }) => theme.color.black};
    }
  }
`;

export const StyledWrapper = styled(Breadcrumbs)`
  && {
    display: flex;
    height: 5.5rem;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 2;

    li {
      color: ${({ theme }) => theme.color.darkGray};
    }
  }
`;
