// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import Button from '@components/atoms/Button/Button';
import Dialog from '@components/organisms/Dialog/Dialog';

// Styles
import { StyledDialogActions } from './DialogConfirm.styles';

// Component
const DialogConfirm = ({ open, body, title, setOpenFn, removeFunction }) => {
  return (
    <Dialog open={open} setOpen={setOpenFn} title={title} size="xs">
      <>{body}</>
      <StyledDialogActions>
        <Button onClick={() => setOpenFn(false)} color="primary">
          Anuluj
        </Button>
        <Button
          onClick={() => {
            removeFunction();
            setOpenFn(false);
          }}
          color="primary"
          autoFocus
          variant="outlined"
        >
          Zatwierdź
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

DialogConfirm.propTypes = {
  body: PropTypes.element,
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  title: PropTypes.string,
  removeFunction: PropTypes.func,
};

DialogConfirm.defaultProps = {
  body: null,
  title: 'Potwierdź usunięcie, dane zostaną utracone!',
  removeFunction: null,
};

export default DialogConfirm;
