// React
import React, { useState, useContext, useRef } from 'react';

// Context
import { Context } from '@context';
import { TableContext } from '@contexts/TableContext';

// Elements
import { IconButton, Grid } from '@mui/material';

// Styles
import {
  StyledTextSearch,
  StyledGridWrapper,
  StyledTextField,
  StyledSearchIcon,
} from './SearchInput.styles';

// Component
const SearchInput = () => {
  const [isSearchShow, setIsSearchShow] = useState(false);
  const { search, setSearch } = useContext(TableContext);
  const { setSearch2 } = useContext(Context);
  const textInput = useRef(null);

  return (
    <StyledGridWrapper container spacing={1} alignItems="flex-end">
      <StyledTextSearch item show={isSearchShow ? 1 : 0}>
        <StyledTextField
          variant="outlined"
          value={search}
          inputRef={textInput}
          onChange={(e) => {
            setSearch(e.target.value);
            setSearch2(e.target.value);
          }}
        />
      </StyledTextSearch>
      <Grid item>
        <IconButton
          onClick={() => {
            setIsSearchShow(!isSearchShow);
            textInput.current.focus();
          }}
        >
          <StyledSearchIcon />
        </IconButton>
      </Grid>
    </StyledGridWrapper>
  );
};

export default SearchInput;
