// Elements
import Button from '@components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  && {
    margin-right: 1.5rem;
    margin-top: 1rem;
  }
`;
