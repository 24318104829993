// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Breadcrumbs
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Data
import { programTabs as renderViewArray } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const Program = ({ details }) => {
  const { id } = useParams();
  const [filteredRenderViewArray, setFilteredRenderViewArray] = useState(renderViewArray);

  useEffect(() => {
    let newArray = renderViewArray;

    if (details) {
      if (details.type === 2) {
        newArray = newArray.filter((v) => {
          return v.key !== 'konsorcjum';
        });
      }

      if (!details.is_pwd) {
        newArray = newArray.filter((v) => {
          return v.key !== 'obecnosc' && v.key !== 'pwd';
        });
      }
    }

    setFilteredRenderViewArray(newArray);
  }, [details, renderViewArray]);

  // console.log('DETAILS', details);

  return (
    <>
      {details.id && (
        <BreadcrumbsItem
          to={`/programy/${details.id}`}
          order={4}
        >{`Program: ${details.name}`}</BreadcrumbsItem>
      )}

      <Tabs renderViewArray={filteredRenderViewArray} program_id={parseInt(id, 10)} />
    </>
  );
};

Program.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    date_start: PropTypes.string,
    date_end: PropTypes.string,
    contract_number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    is_pwd: PropTypes.bool,
    type: PropTypes.number,
  }),
};

Program.defaultProps = {
  details: {
    id: null,
    name: '',
    description: '',
    date_start: '',
    date_end: '',
    contract_number: null,
    is_pwd: false,
    type: null,
  },
};

const mapStateToProps = ({ programReducer }) => ({
  details: programReducer.program,
});

export default connect(mapStateToProps)(Program);
