// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import { StyledMaterialButton } from './Button.styles';

// Component
const Button = ({ dispatch, ...other }) => {
  return <StyledMaterialButton {...other} />;
};

Button.propTypes = {
  dispatch: PropTypes.func,
};

Button.defaultProps = {
  dispatch: null,
};

export default Button;
