// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Moment
import moment from 'moment';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { Grid, Box } from '@mui/material';
import Dialog from '@components/organisms/Dialog/Dialog';
import Button from '@components/atoms/Button/Button';
import Input from '@components/atoms/Input/Input';
import AutocompleteWithInput from '@components/atoms/AutocompleteWithInput/AutocompleteWithInput';

import { saveMeetingsParticipants as saveMeetingsParticipantsService } from '@services/actionServices';

// Component
const DialogBeneficiaryMeeting = ({
  open,
  setOpenFn,
  currentDate,
  saveMeetingsParticipants,
  currentParticipantId,
  currentOrganizations,
  hideOrganizations,
}) => {
  const [currentParticipants, setCurrentParticipants] = useState([]);
  const [addNext, setAddNext] = useState(false);

  useEffect(() => {
    if (currentDate && currentDate.participants && currentDate.participants.length > 0) {
      setCurrentParticipants([...currentDate.participants]);
    }
  }, [currentDate]);

  const initVal = {
    name:
      currentParticipantId || currentParticipantId === 0
        ? currentDate?.participants[currentParticipantId].name
        : '',
    surname:
      currentParticipantId || currentParticipantId === 0
        ? currentDate?.participants[currentParticipantId].surname
        : '',
    organization:
      currentParticipantId || currentParticipantId === 0
        ? currentDate?.participants[currentParticipantId].organization
        : '',
  };

  return (
    <Dialog
      size="lg"
      open={open}
      setOpen={setOpenFn}
      title={`Przypisz uczestnika do spotkania ${
        currentDate && moment(currentDate?.date).format('DD/MM/YYYY')
      }`}
    >
      <Formik
        initialValues={initVal}
        validationSchema={Yup.object({
          name: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          surname: Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
          organization: hideOrganizations
            ? Yup.string().min(2, 'Minimum 2 znaki')
            : Yup.string().min(2, 'Minimum 2 znaki').required('pole wymagane!'),
        })}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          if (currentParticipantId || currentParticipantId === 0) {
            const { participants } = currentDate;

            participants[currentParticipantId] = values;

            saveMeetingsParticipants(currentDate.attendance_list_id, participants);
          } else {
            saveMeetingsParticipants(
              currentDate.attendance_list_id,
              currentParticipants ? [...currentParticipants, values] : [values],
            );

            setCurrentParticipants((prevState) => [...prevState, values]);
          }

          if (!addNext) {
            setOpenFn(false);
          } else {
            resetForm(initVal);
          }
        }}
      >
        {({ handleSubmit, setFieldValue, handleChange, values }) => (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input
                  label="Imię"
                  name="name"
                  value={values.name}
                  variant="filled"
                  onChange={handleChange}
                />
                <ErrorMessage name="name">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Nazwisko"
                  name="surname"
                  value={values.surname}
                  variant="filled"
                  onChange={handleChange}
                />
                <ErrorMessage name="surname">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              {!hideOrganizations && (
                <Grid item xs={12}>
                  <AutocompleteWithInput
                    data={currentOrganizations}
                    label="Instytucja / organizacja"
                    name="organization"
                    value={values.organization}
                    variant="filled"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="organization">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              )}
            </Grid>
            <Box display="flex" justifyContent="flex-end" mt={3}>
              {setOpenFn && (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setOpenFn(false);
                    setAddNext(false);
                  }}
                >
                  Anuluj
                </Button>
              )}
              <Button
                variant="contained"
                style={{ marginLeft: setOpenFn ? 15 : 0 }}
                onClick={() => {
                  handleSubmit();
                  setAddNext(true);
                }}
              >
                Zapisz i dodaj kolejnego
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: setOpenFn ? 15 : 0 }}
                onClick={() => {
                  handleSubmit();
                  setAddNext(false);
                }}
              >
                {`${currentParticipantId || currentParticipantId === 0 ? 'Zapisz' : 'Dodaj'}`}
              </Button>
            </Box>
          </>
        )}
      </Formik>
      <></>
    </Dialog>
  );
};

DialogBeneficiaryMeeting.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenFn: PropTypes.func.isRequired,
  currentDate: PropTypes.objectOf(PropTypes.any),
  saveMeetingsParticipants: PropTypes.func,
  currentParticipantId: PropTypes.number,
  currentOrganizations: PropTypes.arrayOf(PropTypes.any),
  hideOrganizations: PropTypes.bool,
};

DialogBeneficiaryMeeting.defaultProps = {
  currentDate: null,
  saveMeetingsParticipants: null,
  currentParticipantId: null,
  currentOrganizations: [],
  hideOrganizations: false,
};

const mapDispatchToProps = (dispatch) => ({
  saveMeetingsParticipants: (id, participants) =>
    dispatch(saveMeetingsParticipantsService(id, participants)),
});

export default connect(null, mapDispatchToProps)(DialogBeneficiaryMeeting);
