// React
import React from 'react';

// Elements
import TaskDescription from '@views/Dashboard/Tasks/TaskDescription/TaskDescription';

// Styles
import { StyledWrapper } from './TaskAdd.styles';

// Component
const TaskAdd = () => (
  <StyledWrapper container>
    <TaskDescription isNew />
  </StyledWrapper>
);

export default TaskAdd;
