// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Services
import {
  fetchConfigSettings as fetchConfigSettingsService,
  saveConfigSettings as saveConfigSettingsService,
} from '@services/configServices';

// Actions
import { clearConfigs as clearConfigsAction } from '@actions/configActions';

// Elements
import Heading from '@components/atoms/Heading/Heading';
import Button from '@components/atoms/Button/Button';

// Styles
import {
  StyledGrid,
  StyledWrapper,
  StyledGridItem,
  StyledTitle,
  StyledText,
  StyledInput,
} from './SystemSettings.styles';

// Data
const tranlator = {
  PASSWORD_RESTART: 'Link umożliwiający restart hasła',
  INVITATION_TO_THE_COALITION: 'Link z zaproszeniem do koalicji',
  INVITATION_TO_THE_ORGANIZATION: 'Link z zaproszeniem do organizacji',
  INVITATION_TO_THE_PROGRAM: 'Link z zaproszeniem do programu',
  INVITATION_TO_MONTHLY_CHALLENGE: 'Link z zaproszeniem do wyzwania miesiąca',
  APPLICATION_ACCOUNT_CONFIRMATION: 'Link potwierdzający utworzenie konta',
  unsuccessful_login_blocking_time: 'Czas tymczasowej blokady konta',
  unsuccessful_login: 'Ilości niedanych logowań po którym ma nastąpić tymczasowa blokada konta',
};

// Component
const SystemSettings = ({ clearConfigs, fetchConfigSettings, settings, saveConfigSettings }) => {
  const { login_settings, token_settings } = settings;

  useEffect(() => {
    clearConfigs();
    fetchConfigSettings();
  }, []);

  return (
    <Formik
      initialValues={{
        token_settings,
        login_settings,
      }}
      validationSchema={Yup.object().shape({
        token_settings: Yup.array().of(
          Yup.object().shape({
            bareValue: Yup.string()
              .typeError('podaj liczbę inną niż 0')
              .required('pole wymagane!')
              .matches(/^(?<=\s|^)\d+(?=\s|$)/, 'wymagana liczba naturalna')
              .test('Is positive?', 'wymagana wartość większa od 0', (value) => value > 0),
          }),
        ),
        login_settings: Yup.array().of(
          Yup.object().shape({
            bareValue: Yup.string()
              .typeError('podaj liczbę inną niż 0')
              .required('pole wymagane!')
              .matches(/^(?<=\s|^)\d+(?=\s|$)/, 'wymagana liczba naturalna')
              .test('Is positive?', 'wymagana wartość większa od 0', (value) => value > 0),
          }),
        ),
      })}
      enableReinitialize
      onSubmit={(values) => {
        saveConfigSettings(values);
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <StyledWrapper>
          <StyledTitle>
            <Heading.Subtitle1>Czas ważności jednorazowych tokenów</Heading.Subtitle1>
          </StyledTitle>
          <StyledGrid container spacing={2}>
            {values.token_settings.map((item, index) => (
              <StyledGridItem key={item.key} item xs={6}>
                <div>
                  <StyledInput
                    type="number"
                    min={0}
                    step={1}
                    name={`token_settings[${index}].bareValue`}
                    value={values.token_settings[index].bareValue}
                    label={
                      values.token_settings[index].type &&
                      values.token_settings[index].type[1] === 'D'
                        ? 'Liczba dni'
                        : 'Liczba minut' || 'Liczba prób'
                    }
                    variant="filled"
                    onChange={handleChange}
                  />
                  <ErrorMessage name={`token_settings[${index}].bareValue`}>
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </div>
                <StyledText>{tranlator[item.key]}</StyledText>
              </StyledGridItem>
            ))}
          </StyledGrid>
          <StyledTitle>
            <Heading.Subtitle1>Ustawienia logowania</Heading.Subtitle1>
          </StyledTitle>
          <StyledGrid container spacing={2}>
            {values.login_settings.map((item, index) => (
              <StyledGridItem key={item.key} item xs={6}>
                <div>
                  <StyledInput
                    label={
                      values.login_settings[index].type &&
                      values.login_settings[index].type[1] === 'D'
                        ? 'Liczba dni'
                        : (values.login_settings[index].type && 'Liczba minut') || 'Liczba prób'
                    }
                    name={`login_settings[${index}].bareValue`}
                    value={values.login_settings[index].bareValue}
                    variant="filled"
                    type="number"
                    min={0}
                    step={1}
                    onChange={handleChange}
                  />
                  <ErrorMessage name={`login_settings[${index}].bareValue`}>
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </div>
                <StyledText>{tranlator[item.key]}</StyledText>
              </StyledGridItem>
            ))}
          </StyledGrid>
          <StyledGrid container spacing={2}>
            <StyledGridItem item xs={6}>
              <Button onClick={handleSubmit} variant="outlined">
                Zapisz
              </Button>
            </StyledGridItem>
          </StyledGrid>
        </StyledWrapper>
      )}
    </Formik>
  );
};

SystemSettings.propTypes = {
  clearConfigs: PropTypes.func,
  fetchConfigSettings: PropTypes.func,
  settings: PropTypes.shape({
    token_settings: PropTypes.arrayOf(PropTypes.any),
    login_settings: PropTypes.arrayOf(PropTypes.any),
  }),
  saveConfigSettings: PropTypes.func,
};

SystemSettings.defaultProps = {
  clearConfigs: null,
  fetchConfigSettings: null,
  settings: {
    token_settings: [],
    login_settings: [],
  },
  saveConfigSettings: null,
};

const mapStateToProps = ({ configReducer }) => ({
  settings: configReducer.settings,
});

const mapDispatchToProps = (dispatch) => ({
  clearConfigs: () => dispatch(clearConfigsAction()),
  fetchConfigSettings: () => dispatch(fetchConfigSettingsService()),
  saveConfigSettings: (data) => dispatch(saveConfigSettingsService(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemSettings);
