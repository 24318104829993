// React
import React from 'react';

// Data
import { userSettingsTabs } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const UserSettings = () => <Tabs renderViewArray={userSettingsTabs} />;

export default UserSettings;
