import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
import { fetchConfigs } from '@actions/configActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const fetchConfigSettings = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/konfiguracja/biznesowa/lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchConfigs(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const saveConfigSettings = (data) => (dispatch) => {
  return axiosInstance
    .post(`/konfiguracja/biznesowa/zapisz`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      // dispatch(fetchApplicationList());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać ustawień.'),
          isActive: true,
        }),
      );
    });
};
