export const diagnosisBasicData = [
  {
    id: 'participant_observation',
    label: 'Obserwacja uczestnika',
    value: false,
  },
  {
    id: 'document_analysis',
    label: 'Analiza dokumentów',
    value: false,
  },
  {
    id: 'interview_caregivers',
    label: 'Wywiad z opiekunami',
    value: false,
  },
  {
    id: 'interview_participants',
    label: 'Wywiad z uczestnikami',
    value: false,
  },
  {
    id: 'interview_school_employee',
    label: 'Wywiad z pracownikiem szkoły',
    value: false,
  },
  {
    id: 'interview_curator',
    label: 'Wywiad z kuratorem',
    value: false,
  },
  {
    id: 'others',
    label: 'Inne',
    value: false,
    textField: true,
    textValue: '',
  },
];

export const diagnosisRecruitmentSource = [
  {
    id: 'last_year',
    label: 'Dziecko uczęszczało do PWD w ubiegłym roku',
    value: false,
  },
  {
    id: 'other_activities',
    label: 'Dziecko uczestniczyło w innych działaniach naszej organizacji / instytucji',
    value: false,
  },
  {
    id: 'peers_siblings',
    label: 'Zachęcone przez rówieśników / rodzeństwo',
    value: false,
  },
  {
    id: 'parent_reporting',
    label: 'Zgłoszenie się rodzica',
    value: false,
  },
  {
    id: 'street_work_activities',
    label: 'Działania streetworkingowe',
    value: false,
  },
  {
    id: 'recruitment_activities',
    label:
      'Działania rekrutacyjne organizacji / instytucji (np. warsztaty w szkołach, spotkania informacyjne, pikniki promujące)',
    value: false,
  },
  {
    id: 'referral_from_ops',
    label: 'Skierowanie z OPS',
    value: false,
  },
  {
    id: 'referral_school',
    label: 'Skierowanie ze Szkoły',
    value: false,
  },
  {
    id: 'referral_probation_officer',
    label: 'Skierowanie przez kuratora',
    value: false,
  },
  {
    id: 'referral_wcpr',
    label: 'Skierowanie z WCPR',
    value: false,
  },
  {
    id: 'others',
    label: 'Inne',
    value: false,
    textField: true,
    textValue: '',
  },
];

export const diagnosisChildSituation = [
  {
    id: 'social_maladjustment',
    label: 'Niedostosowanie społeczne uczestnika',
    value: false,
  },
  {
    id: 'cognitive_and_developmental_deficits',
    label: 'Deficyty poznawcze i rozwojowe',
    value: false,
  },
  {
    id: 'threat_of_social_maladjustment',
    label: 'Zagrożenie niedostosowaniem społecznym',
    value: false,
  },
  {
    id: 'language_communication_disorders',
    label: 'Zaburzenia komunikacji językowej',
    value: false,
  },
  {
    id: 'difficulty_interacting_peers',
    label: 'Trudności w kontaktach z rówieśnikami',
    value: false,
  },
  {
    id: 'mental_disorders',
    label: 'Zaburzenia psychiczne',
    value: false,
  },
  {
    id: 'pervasive_development_disorders',
    label: 'Całościowe zaburzenia rozwoju',
    value: false,
  },
  {
    id: 'Motor_sensory_disorders',
    label: 'Zaburzenia ruchowo-sensoryczne',
    value: false,
  },
  {
    id: 'learning_difficulties',
    label: 'Długotrwałe trudności w nauce i niepowodzenia edukacyjne',
    value: false,
  },
  {
    id: 'emotional_and_social_disorders',
    label: 'Zaburzenia emocjonalno-społeczne',
    value: false,
  },
  {
    id: 'threat_demoralization',
    label: 'Zagrożenie demoralizacją',
    value: false,
  },
  {
    id: 'environmental_negligence',
    label: 'Zaniedbania środowiskowe',
    value: false,
  },
  {
    id: 'demoralization',
    label: 'Demoralizacja',
    value: false,
  },
  {
    id: 'gifted_child',
    label: 'Dziecko zdolne',
    value: false,
  },
  {
    id: 'home_escapes',
    label: 'Ucieczki z domu',
    value: false,
  },
  {
    id: 'adaptation_difficulties',
    label: 'Trudności adaptacyjne',
    value: false,
  },
  {
    id: 'truancy',
    label: 'Wagarowanie',
    value: false,
  },
  {
    id: 'adaptation_difficulties_cultural_differences',
    label: 'Trudności adaptacyjne wynikające z różnic kulturowych',
    value: false,
  },
  {
    id: 'witness_violence',
    label: 'Bycie świadkiem przemocy',
    value: false,
  },
  {
    id: 'conflicts_law',
    label: 'Konflikty z prawem',
    value: false,
  },
  {
    id: 'victim_violence',
    label: 'Bycie ofiarą przemocy',
    value: false,
  },
  {
    id: 'health_problems',
    label: 'Problemy zdrowotne',
    value: false,
  },
  {
    id: 'perpetrator_violence',
    label: 'Bycie sprawcą przemocy',
    value: false,
  },
  {
    id: 'mobility_disability',
    label: 'Niepełnosprawność ruchowa uczestnika',
    value: false,
  },
  {
    id: 'addiction',
    label: 'Uzależnienie',
    value: false,
  },
  {
    id: 'intellectual_disability',
    label: 'Niepełnosprawność intelektualna uczestnika',
    value: false,
  },
  {
    id: 'risk_addiction',
    label: 'Zagrożenie uzależnieniem',
    value: false,
  },
  {
    id: 'crisis_traumatic_situations',
    label: 'Sytuacje kryzysowe lub traumatyczne osobiste lub w środowisku rodzinnym',
    value: false,
  },
];

export const diagnosisProgramsData = [
  {
    id: 'intellectual_disability',
    label: 'Niepełnosprawność intelektualna',
    certificate: false,
    opinion: false,
  },
  {
    id: 'adhd',
    label: 'ADHD',
    certificate: false,
    opinion: false,
  },
  {
    id: 'autism',
    label: 'Autyzm',
    certificate: false,
    opinion: false,
  },
  {
    id: 'asperger_syndrome',
    label: 'Zespół Aspergera',
    certificate: false,
    opinion: false,
  },
  {
    id: 'dyslexia',
    label: 'Dysleksja',
    certificate: false,
    opinion: false,
  },
  {
    id: 'dysgraphy',
    label: 'Dysgrafia',
    certificate: false,
    opinion: false,
  },
  {
    id: 'aggressive_behavior',
    label: 'Zachowanie agresywne',
    certificate: false,
    opinion: false,
  },
  {
    id: 'dyscalculia',
    label: 'Dyskalkulia',
    certificate: false,
    opinion: false,
  },
  {
    id: 'epilepsy',
    label: 'Epilepsja',
    certificate: false,
    opinion: false,
  },
  {
    id: 'individual_teaching',
    label: 'O potrzebie indywidualnego nauczania',
    certificate: false,
    opinion: false,
  },
  {
    id: 'special_education',
    label: 'O potrzebie kształcenia specjalnego',
    certificate: false,
    opinion: false,
  },
  {
    id: 'early_development_support',
    label: 'O potrzebie wczesnego wspomagania rozwoju',
    certificate: false,
    opinion: false,
  },
  {
    id: 'others',
    label: 'Inne',
    certificate: false,
    opinion: false,
    textField: true,
    textValue: '',
  },
];

export const diagnosisFamilySituation = [
  {
    id: 'family_material_and_living_difficulties',
    label: 'Trudności materialno-bytowe rodziny',
    value: false,
  },
  {
    id: 'long_term_unemployment_parents',
    label: 'Długotrwałe bezrobocie rodziców',
    value: false,
  },
  {
    id: 'temporary_prolonged_care_difficulties_parents',
    label: 'Przejściowe lub długotrwałe trudności w sprawowaniu opieki przez rodziców / opiekunów',
    value: false,
  },
  {
    id: 'mental_illness_parent',
    label: 'Choroba psychiczna rodzica / opiekuna',
    value: false,
  },
  {
    id: 'family_many_children',
    label: 'Rodzina wielodzietna',
    value: false,
  },
  {
    id: 'chronic_parent_disease',
    label: 'Choroby przewlekłe rodzica / opiekuna',
    value: false,
  },
  {
    id: 'incomplete_family',
    label: 'Rodzina niepełna',
    value: false,
  },
  {
    id: 'educational_failure_parent',
    label: 'Niewydolność wychowawcza rodzica / rodziców / opiekunów',
    value: false,
  },
  {
    id: 'patchwork_family',
    label: 'Rodzina patchworkowa',
    value: false,
  },
  {
    id: 'parent_disability',
    label: 'Niepełnosprawność rodzica',
    value: false,
  },
  {
    id: 'parents_migration_experience',
    label: 'Rodzice z doświadczeniem migracji',
    value: false,
  },
  {
    id: 'limitation_freedom_family_member',
    label: 'Ograniczenie wolności członka / członków rodziny',
    value: false,
  },
  {
    id: 'family_alcohol_problem',
    label: 'Problem alkoholowy w rodzinie',
    value: false,
  },
  {
    id: 'crisis_situations_family_environment',
    label: 'Sytuacje kryzysowe w środowisku rodzinnym',
    value: false,
  },
  {
    id: 'family_addiction_problem',
    label: 'Problem uzależnień w rodzinie (inny niż uzależnienie od alkoholu)',
    value: false,
  },
];

export const diagnosisInstitutionCare = [
  {
    id: 'social_curator',
    label: 'Kurator społeczny (Kto z członków rodziny jest pod opieką?)',
    value: false,
    textField: true,
    textValue: '',
  },
  {
    id: 'social_welfare_center',
    label: 'Ośrodek Pomocy Społecznej',
    value: false,
  },
  {
    id: 'foster_care',
    label: 'Piecza zastępcza',
    value: false,
  },
  {
    id: 'another_specialist_institution',
    label: 'Inna instytucja specjalistyczna (Jaka?)',
    value: false,
    textField: true,
    textValue: '',
  },
  {
    id: 'no_specialist_institution',
    label: 'Nie jest pod opieką instytucji wspierającej',
    value: false,
    disableRest: true,
  },
];

export const diagnosisFamilyType = [
  {
    id: 'full',
    label: 'Pełna',
    value: false,
  },
  {
    id: 'foster_family',
    label: 'Rodzina zastępcza',
    value: false,
  },
  {
    id: 'parents_divorced',
    label: 'Rodzice rozwiedzeni',
    value: false,
  },
  {
    id: 'single_mother_single_father',
    label: 'Samotna matka / samotny ojciec',
    value: false,
  },
  {
    id: 'separation',
    label: 'W separacji',
    value: false,
  },
  {
    id: 'reconstructed',
    label: 'Zrekonstruowana',
    value: false,
  },
  {
    id: 'informal_relationship',
    label: 'Związek nieformalny',
    value: false,
  },
  {
    id: 'child_institutional_foster_care',
    label: 'Dziecko w instytucjonalnej pieczy zastępczej',
    value: false,
  },
  {
    id: 'others',
    label: 'Inne',
    value: false,
  },
];

export const diagnosisTextareaFields = [
  {
    id: 'resources',
    label:
      'Zasoby (Przystosowanie społeczne, radzenie sobie ze zmianą, samodzielność, inicjatywa, zainteresowania, motywacja do zmiany, rokowania (wymiary, zakresy, stopień świadomości), odpowiedzialność, pracowitość, odporność, odwaga, otwartość.)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'motivation',
    label: 'Motywacja uczestnika',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'problem_resolve',
    label: 'Sposoby radzenia sobie z problemami',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'current_diagnosis',
    label: 'Dotychczasowe diagnozy/terapie/doświadczenia z PWD / inne',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'health_and_skills',
    label:
      'Rozwój i stan zdrowia uczestnika (Ciąża/poród, funkcjonowanie w niemowlęctwie, dysfunkcje rozwojowe, przeciwwskazania do udziału w zajęciach i aktywnościach.)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'difficulties_and_deficits',
    label:
      'Trudności i deficyty (Braki poznawcze, braki w zachowaniu, braki w umiejętnościach społecznych, braki rozwojowe)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'support',
    label:
      'Obszary, wymagające wsparcia w rodzinie, zgłoszone przez rodzica/opiekuna oraz oczekiwania',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'child_in_family',
    label:
      'Sposób traktowania dziecka w rodzinie (Rola w rodzinie, pozycja, więzi, wymagania, samodzielność.)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'emotional_description',
    label:
      'Opis funkcjonowania emocjonalnego (Dominujące potrzeby i stopień ich zaspokojenia, sposoby radzenia sobie z trudnymi sytuacjami i emocjami, konflikty wewnętrzne, ograniczające przekonania, objawy psychosomatyczne i przejawy w zachowaniu, osiowe konflikty i zahamowania.)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'inadequate_perceptions',
    label: 'Nieadekwatne wyobrażenia (ambicje, oczekiwania, złudzenia)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'significant_events_in_life',
    label:
      'Znaczące wydarzenia w życiu uczestnika i jego rodziny (Rozwód, separacja, wypadek, pobyt w zakładzie karnym/poprawczym/zakładzie psychiatrycznym/domu dziecka lub członka rodziny, choroby, śmierć, uzależnienia, długotrwała nieobecność, procesy)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'functioning_social_roles',
    label:
      'Funkcjonowanie w rolach społecznych (W rolach społecznych w rodzinie; grupie rówieśniczej; w szkole; w placówce)',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'description_family',
    label: 'Skład rodziny',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'law_situation',
    label: 'Sytuacja prawna',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'financial_situation',
    label: 'Sytuacja finansowa',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'house_situation',
    label: 'Sytuacja mieszkaniowa',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'health_situation',
    label: 'Sytuacja zdrowotna członków rodziny',
    charCounter: 1000,
    value: '',
  },
  {
    id: 'others',
    label: 'Inne',
    charCounter: 1000,
    value: '',
  },
];
