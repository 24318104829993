// React
import React from 'react';
import PropTypes from 'prop-types';

// Elements
import { Box } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';

// Styles
import {
  StyledChartSurvey,
  StyledChartSurveyScale,
  StyledChartSurveyItem,
} from './ChartSurvey.styles';

// Component
const ChartSurvey = ({ score, scale, title }) => {
  const lowScore = scale && scale[0];
  const mediumScore = scale && scale[1];
  const highScore = scale && scale[2];

  return (
    <>
      <Box display="flex" justifyContent="center" mb={2} mt={4}>
        <Heading.Subtitle2>{title}</Heading.Subtitle2>
      </Box>
      <StyledChartSurvey>
        <StyledChartSurveyScale scale={0} txt=" min" right />
        {lowScore ? (
          <StyledChartSurveyScale scale={lowScore} txt=" próg dolny przedziału typowego" />
        ) : null}
        {mediumScore ? (
          <StyledChartSurveyScale scale={mediumScore} txt=" próg dolny przedziału typowego" />
        ) : null}
        {highScore ? (
          <StyledChartSurveyScale scale={highScore} txt=" próg górny przedziału typowego" />
        ) : null}
        <StyledChartSurveyItem score={score} />
        <StyledChartSurveyScale scale={6} txt=" max" right />
      </StyledChartSurvey>
      <Box display="flex" justifyContent="center" mb={4} mt={2}>
        <Heading.Subtitle2>wynik beneficjenta</Heading.Subtitle2>
      </Box>
    </>
  );
};

ChartSurvey.propTypes = {
  score: PropTypes.number.isRequired,
  scale: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string,
};

ChartSurvey.defaultProps = {
  title: '',
};

export default ChartSurvey;
