import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import Heading from '@components/atoms/Heading/Heading';
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import {
  fetchEducationalFacilitiesList as fetchEducationalFacilitiesListService,
  saveEducationalFacility as saveEducationalFacilityService,
  deleteEducationalFacility as deleteEducationalFacilityService,
} from '@services/selectServices';
import { StyledTitle, StyledWrapper } from './SchoolSettings.styles';
import './SchoolSettings.scss';

const headCellsSubjects = [
  { type: 'name', label: 'Nazwa' },
  { type: 'district', label: 'Dzielnica' },
  { type: 'rspo', label: 'RSPO' },
];

const SchoolSettings = ({
  fetchEducationalFacilitiesList,
  educationalFacilitiesList,
  saveEducationalFacility,
  deleteEducationalFacility,
}) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const triggerFetchEducationalFacilities = useCallback(
    () => fetchEducationalFacilitiesList(),
    [fetchEducationalFacilitiesList],
  );

  useEffect(() => {
    triggerFetchEducationalFacilities();
  }, [triggerFetchEducationalFacilities]);

  const handleDeleteClick = (school) => {
    setSelectedSchool(school);
    setDeleteDialogOpen(true);
  };

  const handleEditClick = (school) => {
    setSelectedSchool(school);
    setEditDialogOpen(true);
  };

  const confirmDelete = () => {
    console.log('Deleting school:', selectedSchool);
    setDeleteDialogOpen(false);
    deleteEducationalFacility(selectedSchool.value);
  };

  const handleEditSave = () => {
    console.log('Editing school:', selectedSchool);
    setEditDialogOpen(false);
    saveEducationalFacility(selectedSchool.value, selectedSchool);
  };

  return (
    <StyledWrapper className="subjects" container spacing={2}>
      <Grid item xs={10}>
        <StyledTitle>
          <Heading.Subtitle1>Szkoły</Heading.Subtitle1>
        </StyledTitle>
        <TableTemplate
          headCells={headCellsSubjects}
          data={educationalFacilitiesList}
          noFilter
          rowActions={[
            {
              name: 'edytuj',
              action: (row) => handleEditClick(row),
            },
            {
              name: 'usuń',
              action: (row) => handleDeleteClick(row),
            },
          ]}
        />
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć szkołę `{selectedSchool?.name}`?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Usuń
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>Edycja Szkoły</DialogTitle>
        <DialogContent>
          <TextField
            label="Nazwa"
            fullWidth
            margin="normal"
            value={selectedSchool?.name || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, name: e.target.value })}
          />
          <TextField
            label="Dzielnica"
            fullWidth
            margin="normal"
            value={selectedSchool?.district || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, district: e.target.value })}
          />
          <TextField
            label="Miasto"
            fullWidth
            margin="normal"
            value={selectedSchool?.city || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, city: e.target.value })}
          />
          <TextField
            label="Ulica"
            fullWidth
            margin="normal"
            value={selectedSchool?.street || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, street: e.target.value })}
          />
          <TextField
            label="Numer domu"
            fullWidth
            margin="normal"
            value={selectedSchool?.house_number || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, house_number: e.target.value })}
          />
          <TextField
            label="Kod pocztowy"
            fullWidth
            margin="normal"
            value={selectedSchool?.zip_code || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, zip_code: e.target.value })}
          />
          <TextField
            label="Telefon"
            fullWidth
            margin="normal"
            value={selectedSchool?.phone || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, phone: e.target.value })}
          />
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={selectedSchool?.email || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, email: e.target.value })}
          />
          <TextField
            label="RSPO"
            fullWidth
            margin="normal"
            value={selectedSchool?.rspo || ''}
            onChange={(e) => setSelectedSchool({ ...selectedSchool, rspo: e.target.value })}
          />

          <TextField
            label="Nazwa placówki"
            fullWidth
            margin="normal"
            value={selectedSchool?.facility_name || ''}
            onChange={(e) =>
              setSelectedSchool({ ...selectedSchool, facility_name: e.target.value })
            }
          />
          <TextField
            label="RSPO Placówki"
            fullWidth
            margin="normal"
            value={selectedSchool?.facility_rspo || ''}
            onChange={(e) =>
              setSelectedSchool({ ...selectedSchool, facility_rspo: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>
      <FloatingButton
        onClick={() => {
          setSelectedSchool({ value: 'new' });
          setEditDialogOpen(true);
        }}
      />
    </StyledWrapper>
  );
};

SchoolSettings.propTypes = {
  fetchEducationalFacilitiesList: PropTypes.func,
  saveEducationalFacility: PropTypes.func,
  deleteEducationalFacility: PropTypes.func,
  educationalFacilitiesList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
};

SchoolSettings.defaultProps = {
  fetchEducationalFacilitiesList: null,
  saveEducationalFacility: null,
  deleteEducationalFacility: null,
  educationalFacilitiesList: [],
};

const mapStateToProps = ({ selectReducer }) => ({
  educationalFacilitiesList: selectReducer.educationalFacilities,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEducationalFacilitiesList: () => dispatch(fetchEducationalFacilitiesListService()),
  saveEducationalFacility: (id, data) => dispatch(saveEducationalFacilityService(id, data)),
  deleteEducationalFacility: (id) => dispatch(deleteEducationalFacilityService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SchoolSettings);
