// React
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { saveBeneficiaryEducationalFacility as saveBeneficiaryEducationalFacilityService } from '@services/beneficiaryServices';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Elements
import { DialogActions, Grid } from '@mui/material';
import Dialog from '@components/organisms/Dialog/Dialog';
import Heading from '@components/atoms/Heading/Heading';
import Select from '@components/atoms/Select/Select';

// Styles
import { StyledButton, StyledWrapper } from './BeneficiaryEducationalFacilityForm.styles';

// Component
const BeneficiaryEducationalFacilityCopy = ({
  openDialog,
  setOpenDialog,
  szkola,
  program_organization,
  dataOrgProg,
  organizations,
  saveBeneficiaryEducationalFacility,
}) => {
  const { id } = useParams();
  const [dataOrganizations, setDataOrganizations] = useState([]);
  const [dataPrograms, setDataPrograms] = useState([]);

  useEffect(() => {
    const newDataOrg = [];
    const newDataProg = [];

    dataOrgProg.map((el) => {
      if (
        el.connectionWithOrganization &&
        el.connectionWithOrganization !== program_organization.connectionWithOrganization
      ) {
        newDataOrg.push({
          value: el.organizationId,
          name: el.name,
        });
      }

      if (
        el.connectionWithProgram &&
        el.connectionWithProgram !== program_organization.connectionWithProgram
      ) {
        newDataProg.push({
          value: el.programId,
          name: el.name,
        });
      }

      return true;
    });

    setDataOrganizations(newDataOrg);
    setDataPrograms(newDataProg);
  }, [dataOrgProg, program_organization, organizations]);

  return (
    <>
      <Dialog open={openDialog} setOpen={setOpenDialog} size="md">
        <Formik
          initialValues={{
            educationalFacilityId:
              (szkola && szkola.educationalFacilty && szkola.educationalFacilty.id) || null,
            current_class: szkola && szkola.class ? szkola.class : null,
            reasonOfUpdate: szkola && szkola.reasonOfUpdate ? szkola.reasonOfUpdate : 4,
            typeOfChange: szkola && szkola.typeOfChange ? szkola.typeOfChange : null,
            connectionWithProgram: null,
            connectionWithOrganization: null,
          }}
          enableReinitialize
          validationSchema={Yup.object().shape(
            {
              programId: Yup.string()
                .nullable()
                .when('organizationId', {
                  is: null,
                  then: Yup.string().nullable().required('jedno z pól jest wymagane!'),
                  otherwise: Yup.string().nullable(),
                }),
              organizationId: Yup.string()
                .nullable()
                .when('programId', {
                  is: null,
                  then: Yup.string().nullable().required('jedno z pól jest wymagane!'),
                  otherwise: Yup.string().nullable(),
                }),
            },
            [['programId', 'organizationId']],
          )}
          onSubmit={(values) => {
            saveBeneficiaryEducationalFacility(id, values);
            setOpenDialog(false);
          }}
        >
          {({ values, setFieldValue, handleSubmit }) => (
            <StyledWrapper container spacing={2}>
              <Grid item xs={12}>
                <Heading.H6>
                  Kopiowanie placówki edukcyjnej:{' '}
                  {program_organization && program_organization.name}
                </Heading.H6>
              </Grid>
              {dataOrganizations && dataOrganizations.length > 0 && (
                <>
                  <Grid item xs={8}>
                    <Select
                      label="Kopiuj do organizacji"
                      text="organizationId"
                      selectValue={values.organizationId}
                      name="organizationId"
                      data={dataOrganizations}
                      variant="filled"
                      onChange={(_, newValue) => {
                        const connectionId = dataOrgProg.find(
                          (el) => el.organizationId === newValue,
                        );

                        setFieldValue('organizationId', newValue);
                        setFieldValue(
                          'connectionWithOrganization',
                          connectionId.connectionWithOrganization,
                        );
                      }}
                    />
                    <ErrorMessage name="organizationId">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton variant="contained" onClick={handleSubmit}>
                      Kopiuj
                    </StyledButton>
                  </Grid>
                </>
              )}
              {dataPrograms && dataPrograms.length > 0 && (
                <>
                  <Grid item xs={8}>
                    <Select
                      label="Kopiuj do programu"
                      text="programId"
                      selectValue={values.programId}
                      name="programId"
                      data={dataPrograms}
                      variant="filled"
                      onChange={(_, newValue) => {
                        const connectionId = dataOrgProg.find((el) => el.programId === newValue);

                        setFieldValue('programId', newValue);
                        setFieldValue('connectionWithProgram', connectionId.connectionWithProgram);
                      }}
                    />
                    <ErrorMessage name="programId">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={4}>
                    <StyledButton variant="contained" onClick={handleSubmit}>
                      Kopiuj
                    </StyledButton>
                  </Grid>
                </>
              )}
              <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <StyledButton variant="outlined" onClick={() => setOpenDialog(false)}>
                  Anuluj
                </StyledButton>
              </Grid>
            </StyledWrapper>
          )}
        </Formik>
        <DialogActions />
      </Dialog>
    </>
  );
};

BeneficiaryEducationalFacilityCopy.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  szkola: PropTypes.shape({
    class: PropTypes.string,
    educationalFacilty: PropTypes.shape({
      id: PropTypes.number,
      facilityName: PropTypes.string,
    }),
    typeOfChange: PropTypes.string,
    reasonOfUpdate: PropTypes.number,
  }),
  saveBeneficiaryEducationalFacility: PropTypes.func,
  program_organization: PropTypes.shape({
    contactDetailsId: PropTypes.number,
    connectionWithProgram: PropTypes.number,
    connectionWithOrganization: PropTypes.number,
    programId: PropTypes.number,
    organizationId: PropTypes.number,
    houseNumber: PropTypes.string,
    zipCode: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    apartmentNumber: PropTypes.string,
    name: PropTypes.string,
  }),
  organizations: PropTypes.arrayOf(PropTypes.any),
  dataOrgProg: PropTypes.arrayOf(PropTypes.any),
};

BeneficiaryEducationalFacilityCopy.defaultProps = {
  openDialog: false,
  program_organization: null,
  setOpenDialog: null,
  szkola: null,
  dataOrgProg: [],
  organizations: [],
  saveBeneficiaryEducationalFacility: null,
};

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryEducationalFacility: (id, data) =>
    dispatch(saveBeneficiaryEducationalFacilityService(id, data)),
});

export default connect(null, mapDispatchToProps)(BeneficiaryEducationalFacilityCopy);
