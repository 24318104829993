import {
  CHANGE_FONT_SIZE,
  FETCH_REQUEST,
  FETCH_SUCCESS,
  FETCH_FAILURE,
  CLOSE_SNACKBAR,
  CHANGE_CONTRAST,
  REDIRECT,
  CLEAR_REDIRECT,
  SHOW_ALERT,
} from '@constants/actionTypes';

export function redirect(payload) {
  return { type: REDIRECT, payload };
}

export function showAlert(payload) {
  return { type: SHOW_ALERT, payload };
}

export function clearRedirect() {
  return { type: CLEAR_REDIRECT };
}

export function changeFontSize(payload) {
  return { type: CHANGE_FONT_SIZE, payload };
}

export function changeContrast(payload) {
  return { type: CHANGE_CONTRAST, payload };
}

export function closeSnackbar() {
  return { type: CLOSE_SNACKBAR, payload: { isActive: false } };
}

export function fetchRequest() {
  return { type: FETCH_REQUEST, payload: { isPending: true } };
}

export function fetchSuccess(status = '') {
  return { type: FETCH_SUCCESS, payload: { isPending: false, status } };
}

export function fetchFailure(error) {
  return { type: FETCH_FAILURE, payload: { isPending: false, error } };
}
