// React
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// Elements
import {
  Grid,
  Table as AppTable,
  TableHead,
  TableRow,
  TableBody,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import MoreIcon from '@components/molecules/MoreIcon/MoreIcon';
import Checkbox from '@components/atoms/Checkbox/Checkbox';

import { theme } from '@theme/mainTheme';
// Styles
import { TableVirtuoso } from 'react-virtuoso';
import {
  StyledTableRow,
  StyledTableCell,
  StyledTableContainer,
  CheckboxCell,
  StyledHeading,
  StyledButton,
  StyledTableSortLabel,
  StyledTooltip,
} from './Table.styles';

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <StyledTableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <AppTable {...props} stickyHeader aria-label="simple table" />,
  TableHead,
  TableRow: (props) => <StyledTableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  TableCell: (props) => <StyledTableCell {...props} />,
};

const truncateText = (text, maxLength) => {
  if (!text) return '-';
  if (maxLength > 0 && text.length > maxLength) {
    return (
      <Tooltip title={<StyledTooltip>{text}</StyledTooltip>}>
        {text.substring(0, maxLength)}...
      </Tooltip>
    );
  }
  return text;
};

// Komponent
const Table = ({
  headCells,
  data,
  isnav,
  noSort,
  rowLink,
  rowActions,
  navCells,
  disableNav,
  actionCells,
  select,
  possibleOptions,
  updateSelected,
  selectedRows,
  defaultSortColumn,
  defaultSortDirection,
}) => {
  const [order, setOrder] = useState(defaultSortDirection || 'asc');
  const [orderBy, setOrderBy] = useState(defaultSortColumn || 'id');
  const [selected, setSelected] = useState(selectedRows || []);
  const [tableHeight, setTableHeight] = useState(600);
  const [displayedData, setDisplayedData] = useState(data);
  const history = useHistory();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  // const navigateTo = (id) => {
  //   if (String(id).startsWith('anonim_')) {
  //     return false;
  //   }

  //   const { pathname } = history.location;
  //   if (isnav) {
  //     if (rowLink) {
  //       history.push(`${rowLink}/${id}`);
  //     } else {
  //       history.push(`${pathname}/${id}`);
  //     }
  //   }
  //   return true;
  // };

  const navigateToCell = (id) => {
    if (String(id).startsWith('anonim_')) {
      return false;
    }
    const { pathname } = history.location;
    if (rowLink) {
      history.push(`${rowLink}/${id}`);
    } else {
      history.push(`${pathname}/${id}`);
    }
    return true;
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data; //
      setSelected(newSelecteds);
      if (updateSelected) {
        updateSelected(newSelecteds);
      }
      return;
    }
    setSelected([]);
    if (updateSelected) {
      updateSelected([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    if (updateSelected) {
      updateSelected(newSelected);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  useEffect(() => {
    setSelected(selectedRows);
  }, [selectedRows]);

  function descendingComparator(a, b, orderByTmp) {
    if (b[orderByTmp] < a[orderByTmp]) {
      return -1;
    }
    if (b[orderByTmp] > a[orderByTmp]) {
      return 1;
    }
    return 0;
  }

  function getComparator(orderTmp, orderByTmp) {
    return orderTmp === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByTmp)
      : (a, b) => -descendingComparator(a, b, orderByTmp);
  }

  function stableSort(array, comparator) {
    if (array) {
      const stabilizedThis = array.map((el, index) => [el, index]);

      stabilizedThis.sort((a, b) => {
        const order_tmp = comparator(a[0], b[0]);
        if (order_tmp !== 0) return order_tmp;
        return a[1] - b[1];
      });

      return stabilizedThis.map((el) => el[0]);
    }
    return false;
  }

  useEffect(() => {
    const newData = stableSort(data, getComparator(order, orderBy));
    setDisplayedData(newData);
    if (newData.length === 0) {
      setTableHeight(80);
    } else {
      setTableHeight(newData.length > 7 ? 630 : (newData.length + 1) * 72);
    }
  }, [data, order, orderBy]);

  return (
    <Grid className="table-cont" item xs={12}>
      <Paper
        style={{
          height: tableHeight,
          width: '100%',
        }}
      >
        <TableVirtuoso
          style={{ height: tableHeight }}
          data={displayedData}
          components={VirtuosoTableComponents}
          overscan={200}
          fixedHeaderContent={() => (
            <TableRow>
              {select && (
                <CheckboxCell padding="checkbox" align="center">
                  <Checkbox
                    isEditable
                    value={data.length > 0 && selected.length === data.length}
                    onChange={handleSelectAllClick}
                  />
                </CheckboxCell>
              )}
              <StyledTableCell header="true" key="lp2" align="left">
                <Heading.Body2>l.p.</Heading.Body2>
              </StyledTableCell>
              {headCells.length &&
                headCells.map(({ label, type, cellNoSort, align }) => (
                  <StyledTableCell
                    header="true"
                    key={label}
                    sortDirection={orderBy === type ? order : false}
                    align={align || 'left'}
                  >
                    {noSort || cellNoSort ? (
                      <Heading.Body2>{label}</Heading.Body2>
                    ) : (
                      <StyledTableSortLabel
                        onClick={createSortHandler(type)}
                        active={orderBy === type}
                        direction={orderBy === type ? order : 'asc'}
                      >
                        <Heading.Body2>{label}</Heading.Body2>
                      </StyledTableSortLabel>
                    )}
                  </StyledTableCell>
                ))}
              {actionCells.length
                ? actionCells.map((cell) => (
                    <StyledTableCell header="true" key={cell.column} align="center">
                      <StyledHeading>{cell.column}</StyledHeading>
                    </StyledTableCell>
                  ))
                : null}
              {rowActions.length ? <StyledTableCell header="true" /> : null}
            </TableRow>
          )}
          itemContent={(index, row) => {
            const isItemSelected = isSelected(row);
            const cells = headCells.map(({ type }) => type);

            // Tworzymy tablicę komórek
            const rowCells = [];

            if (select) {
              rowCells.push(
                <CheckboxCell padding="checkbox" align="center" key={`select_${index}`}>
                  <Checkbox
                    isEditable
                    value={isItemSelected}
                    onChange={(e) => handleClick(e, row)}
                  />
                </CheckboxCell>,
              );
            }

            rowCells.push(
              <StyledTableCell align="center" key="lp2">
                <Heading.Body2>{index + 1}.</Heading.Body2>
              </StyledTableCell>,
            );

            Object.keys(row).forEach((item) => {
              if (cells && cells.includes(item)) {
                rowCells.push(
                  <StyledTableCell
                    align="center"
                    key={item}
                    pointercursor={
                      !disableNav && (rowLink || isnav || navCells) ? 'true' : undefined
                    }
                    onClick={() => {
                      if (
                        !disableNav &&
                        (rowLink || isnav || navCells)
                        // true ||
                        // navCells ||
                        // headCells.find((element) => element.type === item).nav
                      ) {
                        navigateToCell(row.id);
                      }
                    }}
                  >
                    {typeof row[item] === 'boolean' ? (
                      <Checkbox value={row[item] || false} />
                    ) : (
                      <Heading.Body2>
                        {row[item] && typeof row[item] === 'object' ? (
                          <>
                            {row[item] !== null &&
                              row[item].length > 0 &&
                              row[item].map((obj) => {
                                return (
                                  <span key={obj.id}>
                                    {obj.name}
                                    <br />
                                  </span>
                                );
                              })}
                          </>
                        ) : (
                          <>
                            {truncateText(
                              possibleOptions[item]?.translator
                                ? possibleOptions[item].translator(row[item])
                                : row[item],
                              headCells.find((element) => element.type === item)?.truncate_text ||
                                0,
                            )}
                          </>
                        )}
                      </Heading.Body2>
                    )}
                  </StyledTableCell>,
                );
              }
            });

            // Dodajemy komórki akcji, jeśli istnieją
            if (actionCells.length && (!row.id || !String(row.id).startsWith('anonim_'))) {
              actionCells.forEach((cell) => {
                rowCells.push(
                  <StyledTableCell key={cell.column} align="center">
                    {cell.icon ? (
                      <IconButton
                        disabled={row.disableActions}
                        style={row.disableActions && cell.hideIfDisabled ? { display: 'none' } : {}}
                        edge="start"
                        aria-label="menu"
                        onClick={() =>
                          cell.action ? cell.action(row, cell.param ? cell.param : null) : null
                        }
                      >
                        {cell.icon}
                      </IconButton>
                    ) : (
                      <StyledButton
                        onClick={() =>
                          cell.action ? cell.action(row, cell.param ? cell.param : null) : null
                        }
                        button_type={
                          cell.buttonType && cell.buttonType(row) ? cell.buttonType(row) : ''
                        }
                      >
                        {cell.buttonIcon && cell.buttonIcon(row)}
                        {cell.button}
                      </StyledButton>
                    )}
                  </StyledTableCell>,
                );
              });
            }

            if (rowActions.length && (!row.id || !String(row.id).startsWith('anonim_'))) {
              rowCells.push(
                <StyledTableCell align="right" key="rowActions">
                  <MoreIcon actions={rowActions} row={row} index={index} />
                </StyledTableCell>,
              );
            }

            return rowCells;
          }}
        />
      </Paper>

      <div style={{ marginTop: '5px', color: theme.color.mediumGray }}>
        <small>Liczba rekordów: {displayedData.length}</small>
      </div>
    </Grid>
  );
};

Table.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.any),
  updateSelected: PropTypes.func,
  headCells: PropTypes.arrayOf(PropTypes.any),
  data: PropTypes.arrayOf(PropTypes.any),
  isnav: PropTypes.bool,
  noSort: PropTypes.bool,
  rowActions: PropTypes.arrayOf(PropTypes.any),
  actionCells: PropTypes.arrayOf(PropTypes.any),
  select: PropTypes.bool,
  navCells: PropTypes.bool,
  disableNav: PropTypes.bool,
  rowLink: PropTypes.string,
  possibleOptions: PropTypes.arrayOf(PropTypes.any),
  defaultSortColumn: PropTypes.string,
  defaultSortDirection: PropTypes.oneOf(['asc', 'desc']),
};

Table.defaultProps = {
  selectedRows: [],
  updateSelected: null,
  headCells: [''],
  data: [],
  navCells: false,
  disableNav: false,
  isnav: false,
  noSort: false,
  rowActions: [],
  actionCells: [],
  select: false,
  rowLink: null,
  possibleOptions: [],
  defaultSortColumn: '',
  defaultSortDirection: 'asc',
};

export default Table;
