// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTask as fetchTaskService } from '@services/taskServices';
import {
  fetchProgramsList as fetchProgramsService,
  fetchProgramTasks as fetchProgramTasksService,
  fetchProgramOrganizations as fetchProgramOrganizationsService,
  fetchProgramUsersSimple as fetchProgramUsersService,
  fetchProgram as fetchProgramService,
  fetchActionGroups as fetchActionGroupsService,
} from '@services/programServices';
import {
  fetchAction as fetchActionService,
  editAction as editActionService,
  fetchLecturersList as fetchLecturersListService,
  removeAction as removeActionService,
} from '@services/actionServices';

import {
  COORDINATOR,
  PROGRAM_COORDINATOR,
  ORGANIZATION_ADMINISTRATOR,
  TASK_COORDINATOR,
  LECTURER_SENSITIVE_DATA,
  LECTURER,
} from '@constants/roles';

import {
  ATTENDANCE,
  WORKING_WITH_FAMILY,
  OPEN_PARTY,
  COOPERATION_IN_SYSTEM_ASSISTANCE,
} from '@constants/ActionBuildInType';

// Datepicker
import MultipleDatesPicker from '@ambiot/material-ui-multiple-dates-picker';

// Moment
import moment from 'moment';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data

import {
  actionCharacter,
  actionKind,
  actionRecipients,
  actionFrequency,
} from '@constants/selectLists';
import { actionBuildInType } from '@constants/other';

// Elements
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import { Grid, Switch } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Input from '@components/atoms/Input/Input';
import EditButton from '@components/atoms/EditButton/EditButton';
import Button from '@components/atoms/Button/Button';
import Select from '@components/atoms/Select/Select';
import MultiSelectChips from '@components/molecules/MultiSelectChips/MultiSelectChips';
import Calendar from '@components/atoms/Calendar/Calendar';
import DialogConfirm from '@components/templates/DialogConfirm/DialogConfirm';

// Styles
import { StyledFormControlLabel } from '@components/styled/StyledComponents';
import { StyledWrapper, StyledButton, StyledLink } from './ActionDescription.styles';

// Component
const ActionDescription = ({
  fetchAction,
  details,
  fetchLecturersList,
  lecturers,
  fetchProgramOrganizations,
  program_organizations,
  fetchProgramUsers,
  program_lecturers,
  // actionGroups,
  fetchProgram,
  program,
  fetchProgramsList,
  programs,
  fetchTask,
  task,
  fetchProgramTasks,
  tasks,
  isNew,
  removeAction,
  editAction,
  fetchActionGroups,
  me,
}) => {
  const [openMultiDates, setOpenMultiDates] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { id, type, program_id, task_id } = useParams();
  const history = useHistory();
  const [openDialogConfirmRemove, setOpenDialogConfirmRemove] = useState(false);

  const [actionGroups, setActionGroups] = useState([]);

  const triggerFetchAction = useCallback(
    (myid) => {
      fetchLecturersList(myid);
      return fetchAction(myid);
    },
    [fetchAction, fetchLecturersList],
  );

  useEffect(() => {
    if (isNew) {
      setIsEditable(true);

      if (!task_id) {
        fetchProgramsList([
          PROGRAM_COORDINATOR,
          TASK_COORDINATOR,
          LECTURER_SENSITIVE_DATA,
          LECTURER,
        ]);
      } else {
        fetchTask(task_id);
      }

      if (program_id > 0) {
        fetchProgram(program_id);
        fetchProgramOrganizations(program_id);
        fetchProgramUsers(program_id);
        fetchActionGroups(program_id).then((res) => {
          setActionGroups(res.data);
        });
      }

      return;
    }

    setIsEditable(false);
    if (program_id > 0) {
      fetchActionGroups(program_id).then((res) => {
        setActionGroups(res.data);
      });
    }

    triggerFetchAction(id);
  }, [
    id,
    task_id,
    program_id,
    isNew,
    fetchProgramsList,
    fetchTask,
    fetchProgram,
    fetchProgramOrganizations,
    fetchProgramUsers,
    fetchActionGroups,
    triggerFetchAction,
  ]);

  useEffect(() => {
    if (task && task.program && task.program.id > 0) {
      fetchProgramUsers(task.program.id);
      fetchActionGroups(task.program.id).then((res) => {
        setActionGroups(res.data);
      });
    }
  }, [task, fetchProgramUsers, fetchActionGroups]);

  useEffect(() => {
    const programId =
      program_id ||
      (task && task.program && task.program.id) ||
      (details && details.program && details.program.id);
    if (tasks.length === 0 && programId) {
      fetchProgramTasks(programId);
      fetchActionGroups(programId).then((res) => {
        setActionGroups(res.data);
      });
    }
  }, [tasks.length, program_id, task, details, fetchProgramTasks, fetchActionGroups]);

  const programOrganizationsFinal =
    task && task.id ? task.program_organizations : program_organizations;

  const orgarnizations_list = isNew ? programOrganizationsFinal : details.program_organizations;
  const lecturers_list = isNew ? program_lecturers : lecturers;

  const build_in_type = isNew
    ? actionBuildInType.find((item) => item.route === type).key
    : details.build_in_type;

  const build_in_type_value = isNew
    ? actionBuildInType.find((item) => item.route === type).name
    : details.build_in_type_value;

  const getProgramIdfromUrl = () => {
    if (isNew && !task_id && !program_id) return null;

    return task && task.id ? task.program.id : parseInt(program_id, 10);
  };

  const getProgramObjectFromUrl = () => {
    if (isNew && !task_id && !program_id) return null;

    if (program || task) return program && program.id ? program : task.program;
    return null;
  };

  const taskIdFromUrl = program_id && task ? task.id : parseInt(task_id, 10);
  const taskObjectFromUrl = task && task.id ? task : null;

  const definedProgram = getProgramIdfromUrl() ? getProgramObjectFromUrl() : details.program;
  const definedTask = details.id ? details.task : taskObjectFromUrl;

  // console.log(details);

  return (
    <>
      <Formik
        initialValues={{
          name: details.name && !isNew ? details.name : '',
          actionKind: details.action_kind && !isNew ? details.action_kind : 0,
          actionCharacter: details.action_character && !isNew ? details.action_character : 0,
          organizations: details.organizations && !isNew ? details.organizations : [],
          keyAction: details.is_key_action && !isNew ? details.is_key_action > 0 : false,
          isOnline: details.is_online && !isNew ? details.is_online > 0 : false,
          description: details.description && !isNew ? details.description || '' : '',
          actionTime: details.action_time && !isNew ? details.action_time || 0 : 0,
          owner: details.owner_organization_id && !isNew ? details.owner_organization_id || 0 : 0,
          actionGroup: details.actionGroup && !isNew ? details.actionGroup : null,
          dateStart:
            details.start_date && !isNew ? details.start_date : moment().format('YYYY-MM-DD'),
          dateEnd: details.end_date && !isNew ? details.end_date : moment().format('YYYY-MM-DD'),
          dates: details.dates && !isNew ? details.dates : [moment().format('YYYY-MM-DD')],
          actionType: details.action_type && !isNew ? details.action_type : 2,
          actionRecipients: details.action_recipients && !isNew ? details.action_recipients : [],
          actionFrequency: details.action_frequency && !isNew ? details.action_frequency : 0,
          lecturers: details.lecturers && !isNew ? details.lecturers : [],
          program: details.program && !isNew ? details.program.id : getProgramIdfromUrl(),
          task: details.task && !isNew ? details.task.id : taskIdFromUrl,
          build_in_type,
          free_days: details.free_days && !isNew ? details.free_days : [],
          additional_days: details.additional_days && !isNew ? details.additional_days : [],
          // motivationalSystem:
          //   details.motivational_system && !isNew ? details.motivational_system : 0,
        }}
        enableReinitialize={!isNew}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(255, 'Maksymalnie 255 znaków')
            .required('pole wymagane!'),
          actionKind:
            build_in_type !== ATTENDANCE
              ? Yup.number()
                  .nullable()
                  .when('actionCharacter', {
                    is: 2,
                    then: Yup.number(),
                    otherwise: Yup.number()
                      .typeError('pole wymagane!')
                      .required('pole wymagane!')
                      .test('Is positive?', 'pole wymagane!', (value) => value > 0),
                  })
              : null,
          actionCharacter:
            build_in_type === COOPERATION_IN_SYSTEM_ASSISTANCE
              ? Yup.number()
                  .nullable()
                  .typeError('pole wymagane!')
                  .required('pole wymagane!')
                  .test('Is positive?', 'pole wymagane!', (value) => value > 0)
              : null,
          actionType: ![ATTENDANCE, OPEN_PARTY, COOPERATION_IN_SYSTEM_ASSISTANCE].includes(
            build_in_type,
          )
            ? Yup.number()
            : null,
          actionRecipients: ![
            ATTENDANCE,
            WORKING_WITH_FAMILY,
            COOPERATION_IN_SYSTEM_ASSISTANCE,
          ].includes(build_in_type)
            ? Yup.array().required('pole wymagane!')
            : null,
          description: Yup.string().max(1000, 'Maksymalnie 1000 znaków'),
          actionTime:
            build_in_type !== ATTENDANCE
              ? Yup.number().when('actionCharacter', {
                  is: 2,
                  then: Yup.number(),
                  otherwise: Yup.number()
                    .typeError('podaj liczbę inną niż 0')
                    .required('pole wymagane!')
                    .test('Is positive?', 'pole wymagane!', (value) => value > 0),
                })
              : null,
          actionFrequency:
            build_in_type !== ATTENDANCE && build_in_type !== COOPERATION_IN_SYSTEM_ASSISTANCE
              ? Yup.number()
                  .typeError('pole wymagane!')
                  .required('pole wymagane!')
                  .test('Is positive?', 'pole wymagane!', (value) => value > 0)
              : null,
          program: !definedProgram
            ? Yup.number()
                .required('pole wymagane!')
                .test('Is positive?', 'pole wymagane!', (value) => value > 0)
            : null,
          task: !definedTask
            ? Yup.number()
                .required('pole wymagane!')
                .test('Is positive?', 'pole wymagane!', (value) => value > 0)
            : null,
          dates:
            build_in_type !== ATTENDANCE
              ? Yup.array().of(Yup.date().required('pole wymagane!'))
              : null,
          participants: Yup.number(),
          owner: Yup.number()
            .required()
            .test('Is positive?', 'pole wymagane!', (value) => value > 0),
        })}
        onSubmit={(values) => {
          editAction(id === undefined || isNew ? 'new' : id, values).then((data) => {
            if (data && (id === undefined || isNew) && data.data.id) {
              triggerFetchAction(data.data.id);

              history.push(`/dzialania/${data.data.id}`);
            }
          });
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit }) => (
          <StyledWrapper container spacing={2} alignItems="flex-start">
            <Grid item xs={12} container justifyContent="flex-end">
              {!isNew && id !== undefined && !details.first_card_only && (
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR]}
                  program_id={
                    (details.program && details.program.id) ||
                    getProgramIdfromUrl() ||
                    values.program
                  }
                  allowed_program_roles={[
                    PROGRAM_COORDINATOR,

                    TASK_COORDINATOR,
                    LECTURER_SENSITIVE_DATA,
                    LECTURER,
                  ]}
                >
                  <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                </GuardedComponent>
              )}
            </Grid>
            <Grid item xs={6} container spacing={2}>
              <Grid item xs={12}>
                <Heading.Subtitle2>Dane podstawowe</Heading.Subtitle2>
              </Grid>
              <Grid item xs={12}>
                <Input
                  type="text"
                  disabled={!isEditable || false}
                  label="Nazwa działania"
                  variant="filled"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  className="pogrubiona_nazwa"
                />
                <ErrorMessage name="name">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>

              <Grid item xs={12} container justifyContent="flex-start">
                <Heading.Subtitle1>Działanie typu: {build_in_type_value}</Heading.Subtitle1>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-start">
                {definedProgram && (
                  <Heading.Subtitle1>
                    Zadanie jest cześcią programu{' '}
                    <StyledLink to={`/programy/${definedProgram.id}`}>
                      {definedProgram.name}
                    </StyledLink>
                  </Heading.Subtitle1>
                )}
              </Grid>
              <Grid item xs={12} container justifyContent="flex-start">
                {definedTask && (
                  <GuardedComponent
                    allowed_user_roles={[COORDINATOR]}
                    program_id={
                      (details.program && details.program.id) ||
                      getProgramIdfromUrl() ||
                      values.program
                    }
                    allowed_program_roles={[
                      PROGRAM_COORDINATOR,
                      TASK_COORDINATOR,
                      LECTURER_SENSITIVE_DATA,
                      LECTURER,
                    ]}
                  >
                    <Heading.Subtitle1>
                      Działanie jest cześcią zadania{' '}
                      <StyledLink to={`/zadania/${definedTask.id}`}>{definedTask.name}</StyledLink>
                    </Heading.Subtitle1>
                  </GuardedComponent>
                )}
              </Grid>
              {build_in_type !== ATTENDANCE && (
                <>
                  {[COOPERATION_IN_SYSTEM_ASSISTANCE].includes(build_in_type) && (
                    <>
                      <Grid item xs={6}>
                        <Select
                          name="actionCharacter"
                          disabled={!isEditable || false}
                          label="Charakter działania"
                          data={actionCharacter}
                          selectValue={values.actionCharacter}
                          variant="filled"
                          text="actionCharacter"
                          onChange={setFieldValue}
                        />
                        <ErrorMessage name="actionCharacter">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={6} />
                    </>
                  )}
                  {values.actionCharacter !== 2 && (
                    <Grid item xs={6}>
                      <Select
                        name="actionKind"
                        disabled={!isEditable || false}
                        label="Rodzaj działania"
                        data={actionKind}
                        selectValue={values.actionKind}
                        variant="filled"
                        text="actionKind"
                        onChange={setFieldValue}
                      />
                      <ErrorMessage name="actionKind">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                  {![OPEN_PARTY].includes(build_in_type) && values.actionCharacter !== 2 && (
                    <Grid item xs={6} container justifyContent="center">
                      <StyledFormControlLabel
                        name="keyAction"
                        control={
                          <Switch
                            checked={values.keyAction}
                            onChange={handleChange}
                            color="primary"
                            name="keyAction"
                          />
                        }
                        labelPlacement="start"
                        label="Działanie kluczowe"
                        disabled={!isEditable || false}
                      />
                      <ErrorMessage name="keyAction">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                  {![OPEN_PARTY, COOPERATION_IN_SYSTEM_ASSISTANCE].includes(build_in_type) && (
                    <Grid item xs={6} container justifyContent="center">
                      <StyledFormControlLabel
                        name="actionType"
                        control={
                          <Switch
                            checked={values.actionType === 1}
                            onChange={(e, newValue) => {
                              setFieldValue('actionType', newValue ? 1 : 2);
                            }}
                            color="primary"
                            name="actionType"
                          />
                        }
                        labelPlacement="start"
                        label="Zajęcia grupowe"
                        disabled={!isEditable || false}
                      />
                      <ErrorMessage name="actionType">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                  {[WORKING_WITH_FAMILY].includes(build_in_type) && <Grid item xs={6} />}
                  {![COOPERATION_IN_SYSTEM_ASSISTANCE, WORKING_WITH_FAMILY].includes(
                    build_in_type,
                  ) ? (
                    <Grid item xs={6}>
                      <MultiSelectChips
                        data={actionRecipients}
                        isEditable={isEditable}
                        values_data={values.actionRecipients}
                        fieldName="actionRecipients"
                        label="Dodaj odbiorcę"
                        labelChosen="Odbiorcy"
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage name="actionRecipients">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  ) : null}
                </>
              )}

              <Grid item xs={12}>
                <Input
                  label="Opis działania"
                  disabled={!isEditable || false}
                  type="text"
                  multiline
                  rows={10}
                  charCounter={1000}
                  setField={setFieldValue}
                  value={values.description}
                  variant="filled"
                  name="description"
                  onChange={handleChange}
                />
                <ErrorMessage name="description">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>

              {build_in_type !== ATTENDANCE && (
                <>
                  {values.actionCharacter !== 2 && (
                    <Grid item xs={6}>
                      <Input
                        type="number"
                        name="actionTime"
                        disabled={!isEditable || false}
                        label="Czas trwania zajęć (w minutach)"
                        variant="filled"
                        onChange={handleChange}
                        min={0}
                        max={600}
                        value={values.actionTime}
                      />
                      <ErrorMessage name="actionTime">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                  {values.actionCharacter !== 2 && (
                    <Grid item xs={6} container justifyContent="center">
                      <StyledFormControlLabel
                        name="isOnline"
                        control={
                          <Switch
                            checked={values.isOnline}
                            onChange={handleChange}
                            color="primary"
                            name="isOnline"
                          />
                        }
                        labelPlacement="start"
                        label="Działanie online"
                        disabled={!isEditable || false}
                      />
                      <ErrorMessage name="isOnline">
                        {(msg) => <div className="error-txt">{msg}</div>}
                      </ErrorMessage>
                    </Grid>
                  )}
                  {values.actionCharacter !== 2 && (
                    <>
                      <Grid item xs={6}>
                        <Select
                          name="actionFrequency"
                          disabled={!isEditable || false}
                          label="Częstotliwość"
                          data={actionFrequency}
                          selectValue={values.actionFrequency}
                          variant="filled"
                          text="actionFrequency"
                          onChange={(_, newValue) => {
                            setFieldValue('dates', [moment().format('YYYY-MM-DD')]);
                            setFieldValue('dateStart', moment().format('YYYY-MM-DD'));
                            setFieldValue('dateEnd', moment().format('YYYY-MM-DD'));
                            setFieldValue('actionFrequency', newValue);
                          }}
                        />
                        <ErrorMessage name="actionFrequency">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={6}>
                        {values.actionFrequency === 1 ? (
                          <Calendar
                            label="Data"
                            variant="filled"
                            name="dates[0]"
                            onChange={(e, v) => {
                              setFieldValue(e, v);
                              setFieldValue('dateStart', v);
                              setFieldValue('dateEnd', v);
                            }}
                            value={values.dates[0]}
                            disabled={!isEditable || false}
                          />
                        ) : (
                          <>
                            {values.actionFrequency > 0 && (
                              <Button
                                onClick={() => setOpenMultiDates(!openMultiDates)}
                                disabled={!isEditable || false}
                                variant="outlined"
                              >
                                Wybierz daty
                              </Button>
                            )}
                            {values.dates && (
                              // <div>
                              //   {values.dates.map((item, index) => {
                              //     return `${index > 0 ? ' / ' : ''}${moment(
                              //       item,
                              //       'YYYY-MM-DD',
                              //     ).format('DD-MM-YYYY')}`;
                              //   })}
                              // </div>
                              <MultipleDatesPicker
                                open={openMultiDates}
                                selectedDates={values.dates.map((d) => {
                                  return new Date(d);
                                })}
                                cancelButtonText="Anuluj"
                                submitButtonText="Zatwierdź"
                                selectedDatesTitle="Wybrane daty"
                                onCancel={() => setOpenMultiDates(false)}
                                onSubmit={(dates) => {
                                  const sortedDates = dates.sort((a, b) => {
                                    return a - b;
                                  });
                                  sortedDates.map((d) => {
                                    if (d.getHours() < 2) {
                                      return d.setTime(d.getTime() + 2 * 60 * 60 * 1000);
                                    }
                                    return d;
                                  });
                                  console.log(sortedDates);
                                  setFieldValue('dates', sortedDates);
                                  setFieldValue(
                                    'dateStart',
                                    moment(sortedDates[0]).format('YYYY-MM-DD'),
                                  );
                                  setFieldValue(
                                    'dateEnd',
                                    moment(sortedDates.slice(-1)[0]).format('YYYY-MM-DD'),
                                  );
                                  setOpenMultiDates(false);
                                }}
                              />
                            )}
                          </>
                        )}
                        <ErrorMessage name="dates">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </Grid>
                      <Grid item xs={12}>
                        <Heading.Subtitle2>
                          {values.actionFrequency === 2 &&
                            values.dates &&
                            values.dates.map((item, index) => {
                              return `${index > 0 ? ' / ' : ''}${moment(item, 'YYYY-MM-DD').format(
                                'DD-MM-YYYY',
                              )}`;
                            })}
                        </Heading.Subtitle2>
                      </Grid>
                    </>
                  )}
                </>
              )}

              {(build_in_type !== ATTENDANCE || !isNew) && (
                <>
                  <Grid item xs={6}>
                    <Calendar
                      disabled={build_in_type === ATTENDANCE || !isEditable}
                      label="Data rozpoczęcia"
                      onChange={setFieldValue}
                      value={values.dateStart}
                      name="dateStart"
                      variant="filled"
                    />
                    <ErrorMessage name="dateStart">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <Calendar
                      disabled={build_in_type === ATTENDANCE || !isEditable}
                      label="Data zakończenia"
                      onChange={setFieldValue}
                      value={values.dateEnd}
                      name="dateEnd"
                      variant="filled"
                    />
                    <ErrorMessage name="dateEnd">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item container spacing={2} xs={6}>
              {isNew && !definedProgram && (
                <>
                  <Grid item xs={12}>
                    <Heading.Subtitle2>Program</Heading.Subtitle2>
                  </Grid>
                  <Grid item xs={8}>
                    {programs.length > 0 ? (
                      <Select
                        label="Program"
                        data={programs.map((p) => {
                          return { value: p.id, name: p.name };
                        })}
                        text="program"
                        selectValue={values.program}
                        variant="filled"
                        onChange={(field, value) => {
                          setFieldValue(field, value);
                          setFieldValue('organizations', []);
                          setFieldValue('task', 0);
                          if (value > 0) {
                            fetchProgramTasks(value).then((res) => {
                              if (res.data && res.data.length === 1) {
                                setFieldValue('task', res.data[0].id);
                              }
                            });
                            fetchProgramOrganizations(value).then((res) => {
                              if (res.data && res.data.length === 1) {
                                setFieldValue('owner', res.data[0].id);
                              } else {
                                const foundOrg = res.data.find((el) => {
                                  if (me.user_organizations.find((o) => o.id === el.id)) {
                                    return true;
                                  }
                                  return false;
                                });
                                setFieldValue('owner', foundOrg.id);
                              }
                            });
                            fetchProgramUsers(value);
                          }
                        }}
                      />
                    ) : (
                      <Select
                        label="Program"
                        data={[{ value: '-1', name: 'Trwa ładowanie' }]}
                        text="program"
                        variant="filled"
                      />
                    )}
                    <ErrorMessage name="program">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Heading.Subtitle2>Zadanie</Heading.Subtitle2>
              </Grid>
              <Grid item xs={8}>
                {tasks.length > 0 ? (
                  <Select
                    label="Zadanie"
                    disabled={!isEditable || false}
                    data={tasks.map((t) => {
                      return { value: t.id, name: t.task_name };
                    })}
                    text="task"
                    selectValue={values.task}
                    variant="filled"
                    onChange={setFieldValue}
                  />
                ) : (
                  <Select
                    label="Zadanie"
                    disabled={!isEditable || false}
                    data={[{ value: '-1', name: 'Trwa ładowanie' }]}
                    text="task"
                    variant="filled"
                    onChange={setFieldValue}
                  />
                )}
                <ErrorMessage name="task">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12} container justifyContent="flex-start">
                <Heading.Subtitle1>Organizacja: {details.owner_organization}</Heading.Subtitle1>
              </Grid>
              {isNew && (
                <Grid item xs={8}>
                  <Select
                    label="Organizacja"
                    data={orgarnizations_list.map((org) => ({ value: org.id, name: org.name }))}
                    selectValue={values.owner}
                    text="owner"
                    variant="filled"
                    onChange={setFieldValue}
                  />
                  <ErrorMessage name="owner">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Grid>
              )}

              {/* ((definedProgram && definedProgram.type === 1) ||
                (values.program &&
                  programs.find((p) => p.id === values.program && p.type === 'Konsorcjum'))) && (
                <Grid item xs={8}>
                  <MultiSelectChips
                    data={orgarnizations_list}
                    isEditable={isEditable}
                    values_data={values.organizations}
                    fieldName="organizations"
                    label="Dodaj organizację do zadania"
                    labelChosen="Organizacje przypisane"
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                  ) */}

              <Grid item xs={12} container justifyContent="flex-start">
                <Heading.Subtitle1>Grupa działań</Heading.Subtitle1>
              </Grid>
              <Grid item xs={8}>
                {actionGroups && actionGroups.length > 0 ? (
                  <Select
                    label="Grupa działań"
                    disabled={!isEditable || false}
                    data={actionGroups.map((ag) => {
                      return { value: ag.id, name: ag.name };
                    })}
                    text="actionGroup"
                    selectValue={values.actionGroup}
                    variant="filled"
                    onChange={setFieldValue}
                    allowNull
                  />
                ) : (
                  <Select
                    label="Grupa działań"
                    disabled={!isEditable || false}
                    data={[{ value: '', name: 'Wybierz' }]}
                    text="actionGroup"
                    variant="filled"
                    onChange={setFieldValue}
                  />
                )}
                <ErrorMessage name="actionGroup">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12}>
                <Heading.Subtitle2>Prowadzący</Heading.Subtitle2>
              </Grid>
              <Grid item xs={8}>
                <MultiSelectChips
                  data={lecturers_list}
                  isEditable={isEditable}
                  values_data={values.lecturers}
                  fieldName="lecturers"
                  label="Wybierz prowadzącego"
                  labelChosen="Osoby przypisane"
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <GuardedComponent
                allowed_user_roles={[COORDINATOR]}
                program_id={
                  (details.program && details.program.id) || getProgramIdfromUrl() || values.program
                }
                allowed_program_roles={[
                  PROGRAM_COORDINATOR,
                  TASK_COORDINATOR,
                  LECTURER_SENSITIVE_DATA,
                  LECTURER,
                ]}
              >
                <StyledButton
                  disabled={!isEditable || false}
                  variant="outlined"
                  onClick={handleSubmit}
                >
                  ZAPISZ
                </StyledButton>
              </GuardedComponent>
              {!isNew && (
                <GuardedComponent
                  allowed_user_roles={[COORDINATOR]}
                  program_id={
                    (details.program && details.program.id) ||
                    (definedProgram && definedProgram.id) ||
                    values.program
                  }
                  allowed_program_roles={[PROGRAM_COORDINATOR]}
                  organization_id={details.owner_organization_id}
                  allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR]}
                >
                  <StyledButton
                    disabled={!isEditable || false}
                    variant="outlined"
                    color="secondary"
                    onClick={() => setOpenDialogConfirmRemove(true)}
                  >
                    USUŃ DZIAŁANIE
                  </StyledButton>
                </GuardedComponent>
              )}
            </Grid>
            <DialogConfirm
              removeFunction={() =>
                removeAction(id).then(() => {
                  history.push(`/dzialania`);
                })
              }
              title="Działanie zawiera dane! Usunięcie działania spowoduje również usunięcie wszystkich danych powiązanych z tym działaniem!"
              open={openDialogConfirmRemove}
              setOpenFn={setOpenDialogConfirmRemove}
            />
          </StyledWrapper>
        )}
      </Formik>
    </>
  );
};

ActionDescription.propTypes = {
  fetchAction: PropTypes.func,
  editAction: PropTypes.func,
  fetchLecturersList: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  fetchProgramTasks: PropTypes.func,
  fetchProgramOrganizations: PropTypes.func,
  fetchProgramUsers: PropTypes.func,
  fetchActionGroups: PropTypes.func,
  lecturers: PropTypes.arrayOf(PropTypes.any),
  program_lecturers: PropTypes.arrayOf(PropTypes.any),
  isNew: PropTypes.bool,
  programs: PropTypes.arrayOf(PropTypes.any),
  tasks: PropTypes.arrayOf(PropTypes.any),
  program_organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),

  program: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  task: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    program_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    program: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    owner_organization: PropTypes.string,
    owner_organization_id: PropTypes.number,
    organizations: PropTypes.arrayOf(PropTypes.any),
    kind: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    build_in_type: PropTypes.string,
    build_in_type_value: PropTypes.string,
    action_character: PropTypes.number,
    action_kind: PropTypes.number,
    is_key_action: PropTypes.bool,
    is_online: PropTypes.bool,
    action_type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    action_recipients: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    lecturers: PropTypes.arrayOf(PropTypes.any),
    description: PropTypes.string,
    action_time: PropTypes.number,
    action_frequency: PropTypes.number,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    dates: PropTypes.arrayOf(PropTypes.string),
    is_open_action: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.bool]),
    program_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    program: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      type: PropTypes.number,
    }),
    task: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    plannedParticipants: PropTypes.number,
    is_estimation: PropTypes.bool,
    first_card_only: PropTypes.bool,
    actionGroup: PropTypes.number,
    free_days: PropTypes.arrayOf(PropTypes.any),
    additional_days: PropTypes.arrayOf(PropTypes.any),
    motivational_system: PropTypes.number,
  }),
  me: PropTypes.shape({
    id: PropTypes.number,
    user_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
  }),
  fetchTask: PropTypes.func,
  fetchProgram: PropTypes.func,
  removeAction: PropTypes.func,
  // actionGroups: PropTypes.arrayOf(PropTypes.any),
};

ActionDescription.defaultProps = {
  fetchAction: null,
  editAction: null,
  fetchProgramsList: null,
  fetchProgramTasks: null,
  fetchLecturersList: null,
  fetchProgramOrganizations: null,
  fetchProgramUsers: null,
  fetchActionGroups: null,
  isNew: false,
  program_lecturers: [],
  program_organizations: [],
  task: null,
  program: null,
  details: {
    id: null,
    name: '',
    owner_organization: '',
    owner_organization_id: null,
    organizations: [],
    kind: '',
    build_in_type: null,
    build_in_type_value: null,
    action_character: null,
    action_kind: null,
    is_key_action: false,
    is_online: false,
    action_type: '',
    action_recipients: null,
    lecturers: [],
    description: '',
    action_time: null,
    action_frequency: null,
    dates: [],
    start_date: '',
    end_date: '',
    is_open_action: false,
    program_organizations: [],
    program: null,
    task: null,
    plannedParticipants: null,
    is_estimation: null,
    first_card_only: false,
    actionGroup: null,
    free_days: [],
    motivational_system: 0,
    additional_days: [],
  },
  me: {
    id: null,
    user_organizations: [],
  },
  programs: [],
  tasks: [],
  lecturers: [],
  fetchTask: null,
  fetchProgram: null,
  removeAction: null,
  // actionGroups: [],
};

const mapStateToProps = ({
  actionReducer,
  beneficiaryReducer,
  programReducer,
  taskReducer,
  userReducer,
}) => ({
  details: actionReducer.action,
  task: taskReducer.task,
  program: programReducer.program,
  lecturers: actionReducer.lecturers,
  tasks: programReducer.tasks,
  program_lecturers: programReducer.users,
  // actionGroups: programReducer.actionGroups,
  programs: programReducer.programs,
  program_organizations: programReducer.program_organizations,
  beneficiaries: beneficiaryReducer.beneficiaries,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAction: (id) => dispatch(fetchActionService(id)),
  fetchTask: (id) => dispatch(fetchTaskService(id)),
  fetchProgram: (id) => dispatch(fetchProgramService(id)),
  fetchLecturersList: (id) => dispatch(fetchLecturersListService(id)),
  fetchProgramTasks: (id) => dispatch(fetchProgramTasksService(id)),
  fetchProgramsList: (roles) => dispatch(fetchProgramsService(roles)),
  fetchProgramOrganizations: (id) => dispatch(fetchProgramOrganizationsService(id)),
  fetchProgramUsers: (id) => dispatch(fetchProgramUsersService(id)),
  fetchActionGroups: (id) => dispatch(fetchActionGroupsService(id)),
  editAction: (id, data) => dispatch(editActionService(id, data)),
  removeAction: (id) => dispatch(removeActionService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActionDescription);
