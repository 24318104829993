// React
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Breadcrumb
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

// Service
import {
  fetchCWPInfo as fetchCWPInfoService,
  fetchSingleSurvey as fetchSingleSurveyService,
  saveBeneficiarySurvey as saveBeneficiarySurveyService,
} from '@services/beneficiaryServices';
import {
  fetchProgramsList as fetchProgramsListService,
  fetchProgramBeneficiaries as fetchProgramBeneficiariesService,
  fetchProgramUsersSimple as fetchProgramUsersSimpleService,
} from '@services/programServices';

// Actions
import {
  clearProgramUsers as clearProgramUsersAction,
  clearPrograms as clearProgramsAction,
  clearProgramBeneficiaries as clearProgramBeneficiariesAction,
} from '@actions/programActions';
import {
  clearCWPInfo as clearCWPInfoAction,
  clearSingleSurvey as clearSingleSurveyAction,
} from '@actions/beneficiaryActions';

// Formik
import { Formik, ErrorMessage } from 'formik';

import { surveyMeasurementNumbers } from '@constants/selectLists';

// Data
import { ageRange, answers, surveysQuestions } from '@constants/surveys';

// Elements
import { Box, Grid, FormLabel, FormControl } from '@mui/material';
import MyAccordion from '@components/atoms/Accordion/Accordion';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import Checkbox from '@components/atoms/Checkbox/Checkbox';
import EditButton from '@components/atoms/EditButton/EditButton';
import ResultSurveys from '@views/Dashboard/Beneficiary/ResultSurveys/ResultSurveys';
import DialogBeneficiarySurveys from '@components/templates/DialogBeneficiarySurveys/DialogBeneficiarySurveys';

// Styles
import {
  StyledGrid,
  StyledAccordionDetails,
  StyledButton,
  StyledRadioGroup,
  StyledEditWrapper,
  StyledText,
} from './AddBeneficiarySurveys.styles';

// View
const AddBeneficiarySurveys = ({
  clearSingleSurvey,
  fetchSingleSurvey,
  single_survey,
  clearCWPInfo,
  fetchCWPInfo,
  cwp_info,
  clearProgramBeneficiaries,
  fetchProgramBeneficiaries,
  beneficiaries,
  clearPrograms,
  fetchProgramsList,
  programs,
  clearProgramUsers,
  fetchProgramUsersSimple,
  authors,
  saveBeneficiarySurvey,
  me,
}) => {
  const history = useHistory();
  const { id, id_survey, edit } = useParams();
  const [openDialog, setOpenDialog] = useState(
    false, /// window.localStorage.getItem('showSurveyInstruction') !== 'false',
  );
  const [isEditable, setIsEditable] = useState(false);
  const [programAuthorData, setProgramAuthorData] = useState([]);
  const [redirectView, setRedirectView] = useState(true);
  const [currentItem, setCurrentItem] = useState({});
  const [programID, setProgramID] = useState(null);
  const [programSelectData, setProgramSelectData] = useState([]);
  const [programBeneficiariesData, setProgramBeneficiariesData] = useState([]);

  useEffect(() => {
    clearPrograms();
    clearCWPInfo();
    clearProgramUsers();
    clearProgramBeneficiaries();
    clearSingleSurvey();
  }, []);

  const triggerFetchCWPInfo = useCallback((myid) => fetchCWPInfo(myid), [fetchCWPInfo]);

  useEffect(() => {
    const newData = [];

    authors.map((el) => {
      newData.push({ name: el.name, value: el.id });

      return true;
    });

    setProgramAuthorData(newData);
  }, [authors]);

  useEffect(() => {
    if ((id_survey && edit) || !id_survey) {
      setIsEditable(true);
    }
  }, [id_survey, edit]);

  const triggerFetchProgramsList = useCallback(() => fetchProgramsList(), [fetchProgramsList]);

  const triggerFetchProgramBeneficiaries = useCallback(
    (myid) => fetchProgramBeneficiaries(myid),
    [fetchProgramBeneficiaries],
  );

  const triggerFetchProgramUsersSimple = useCallback(
    (myid) => fetchProgramUsersSimple(myid),
    [fetchProgramUsersSimple],
  );

  useEffect(() => {
    triggerFetchProgramsList();

    if (id) {
      triggerFetchCWPInfo(id);
    }
  }, [id, triggerFetchCWPInfo, triggerFetchProgramsList]);

  useEffect(() => {
    if (Object.keys(cwp_info).length > 0) {
      setProgramID(cwp_info.program_id);
    }
  }, [cwp_info.program_id]);

  useEffect(() => {
    if (programID) {
      triggerFetchProgramBeneficiaries(programID);
      triggerFetchProgramUsersSimple(programID);
    }
  }, [
    programID,
    cwp_info.program_id,
    triggerFetchProgramBeneficiaries,
    triggerFetchProgramUsersSimple,
  ]);

  useEffect(() => {
    const newData = [];

    programs.map((el) => {
      if (el.beneficiaries_number > 0) {
        newData.push({ name: el.name, value: el.id });
      }

      return true;
    });

    setProgramSelectData(newData);
  }, [programs]);

  useEffect(() => {
    const newData = [];

    beneficiaries.map((el) => {
      newData.push({
        name: `${el.beneficiary_name} ${el.beneficiary_last_name}`,
        value: el.id,
        cwp_id: el.cwp_id,
      });

      return true;
    });

    setProgramBeneficiariesData(newData);
  }, [beneficiaries]);

  const triggerFetchSingleSurvey = useCallback(
    (myid) => fetchSingleSurvey(myid),
    [fetchSingleSurvey],
  );

  useEffect(() => {
    if (id_survey || currentItem.id) {
      triggerFetchSingleSurvey(id_survey || currentItem.id);
    }
  }, [id_survey, currentItem.id]);

  useEffect(() => {
    if (!id_survey || (id_survey && (single_survey ? single_survey.status !== 2 : false))) {
      setOpenDialog(true);
    }
  }, []);

  const getGroupsInitialValues = (localGroups) => {
    return localGroups.length > 0
      ? localGroups.map((item) => {
          return {
            id: item.id,
            label: item.label,
            questions: item.questions
              .sort(() => 0.5 - Math.random())
              .map((q) => {
                return { ...q, value: null };
              }),
          };
        })
      : [];
  };

  const initalVal = {
    id: currentItem?.id || single_survey?.id || null,
    title: currentItem?.title || single_survey?.name || '',
    cwp_id: currentItem?.cwp_id || single_survey?.cwp_id || cwp_info.connection_id || null,
    program: currentItem?.program || single_survey?.program?.name || cwp_info.program_name || '',
    program_id:
      currentItem?.program_id || single_survey?.program?.id || cwp_info.program_id || null,

    author: (me && `${me.name} ${me.surname}`) || single_survey?.author?.name || null,
    author_id: (me && me.id) || single_survey?.author?.id || currentItem.author_id || null,
    measurement_number: currentItem?.measurement_number || 1,
    beneficiary:
      currentItem?.beneficiary || single_survey?.beneficiary?.name || cwp_info.beneficiary || '',
    beneficiary_id:
      currentItem?.beneficiary_id ||
      single_survey?.beneficiary?.id ||
      cwp_info.beneficiary_id ||
      null,
    age: currentItem?.age || single_survey?.age || null,
    groups: currentItem?.groups || single_survey?.answers || [],
    status: currentItem?.status || single_survey?.status || 0,
  };

  return (
    <>
      <DialogBeneficiarySurveys
        open={openDialog}
        setOpenFn={setOpenDialog}
        title="Instrukcja wypełniania ankiety"
      />

      <Grid container>
        <Grid item xs={12}>
          <Box mt={6}>
            {(!id_survey ||
              (id_survey && (single_survey ? single_survey.status !== 2 : false))) && (
              <StyledText>
                <p>Wypełniając narzędzie, należy kierować się następującymi ogólnymi zasadami:</p>

                <ol>
                  <li>
                    Należy starać się udzielać szczerych odpowiedzi, opisujących swoje prawdziwe
                    sądy, oceny zachowań czy postaw.
                  </li>
                  <li>
                    Należy starać się odnosić do swoich własnych doświadczeń w kontakcie z
                    beneficjentem lub jego rodzicami.
                  </li>
                  <li>
                    Odpowiadając na poszczególne pytania, należy wziąć pod uwagę ogół swoich
                    doświadczeń z beneficjentem z ostatniego czasu współpracy z nim/nią (ostatni
                    miesiąc). Uprawnione jest kierowanie się swoim wrażeniem. Nie należy poprzedzać
                    wypełniania ankiety próbami pozyskania jakichś dodatkowych informacji o
                    beneficjencie niż te, które pozyskuje się w trakcie standardowej pracy z
                    nim/nią.
                  </li>
                  <li>
                    Należy starać się odpowiedzieć na wszystkie pytania. W przypadku trudności z
                    odpowiedzą można wybrać odpowiedź bliską tej ze środka skali.
                  </li>
                  <li>
                    Opisując daną osobę należy odnieść się do porównania z innymi osobami, które są
                    podobne do tej opisywanej, np. są w podobnym wieku i sytuacji życiowej.
                    Optymalną grupą odniesienia są inni beneficjenci danego projektu w zbliżonym
                    wieku.
                  </li>
                </ol>
              </StyledText>
            )}
          </Box>
        </Grid>

        <Grid item xs={8}>
          <Formik
            initialValues={initalVal}
            enableReinitialize
            onSubmit={(values) => {
              let isEditView = false;

              if ((Object.keys(currentItem).length > 0 || id_survey) && isEditable) {
                isEditView = true;
              }

              setCurrentItem((prevState) => ({ ...prevState, ...values }));

              const data = saveBeneficiarySurvey(
                values.beneficiary_id,
                values,
                isEditView,
                redirectView,
              );

              if (!redirectView) {
                data.then((res) => {
                  if (res) {
                    setCurrentItem((prevState) => ({
                      ...prevState,
                      id: res.id,
                      result: res.result,
                    }));
                  }
                  if (values.status === 2 && values.id) {
                    history.push(`/ankieta-wynik/${values.id}`);
                  }
                });
              } else {
                history.push(`/beneficjenci/${values.beneficiary_id}/tab/5-Diagnoza`);
              }
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => (
              <>
                {values.program && values.program_id && (
                  <BreadcrumbsItem to={`/programy/${values.program_id}`} order={0}>
                    Program: {values.program}
                  </BreadcrumbsItem>
                )}
                {values.beneficiary && values.beneficiary_id && (
                  <BreadcrumbsItem to={`/beneficjenci/${values.beneficiary_id}`} order={0}>
                    Beneficjent: {values.beneficiary}
                  </BreadcrumbsItem>
                )}
                {id_survey && single_survey?.status !== 2 && values.status !== 2 && (
                  <StyledEditWrapper>
                    <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
                  </StyledEditWrapper>
                )}
                <StyledGrid container spacing={2} margin="1rem">
                  <Grid item xs={12}>
                    <Input
                      disabled={!isEditable || values.status === 2}
                      label="Tytuł"
                      variant="filled"
                      name="title"
                      id="title"
                      onChange={handleChange}
                      value={values.title}
                    />
                    <ErrorMessage name="title">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={12}>
                    {id_survey && single_survey?.status === 2 && values.status === 2 ? (
                      <Input
                        disabled
                        label="Numer pomiar"
                        variant="filled"
                        name="measurement_number"
                        id="measurement_number"
                        onChange={handleChange}
                        value={
                          surveyMeasurementNumbers.find(
                            (el) => el.value === values.measurement_number,
                          )?.name
                        }
                      />
                    ) : (
                      <>
                        <Select
                          label="Numer pomiaru"
                          data={surveyMeasurementNumbers}
                          variant="filled"
                          selectValue={values.measurement_number}
                          text="measurement_number"
                          id="measurement_number"
                          onChange={setFieldValue}
                        />
                        <ErrorMessage name="measurement_number">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {(id_survey && values.program) ||
                    (values.id && values.program) ||
                    (id && values.program) ? (
                      <Input
                        disabled
                        label="Nazwa programu"
                        variant="filled"
                        name="program"
                        id="program"
                        onChange={handleChange}
                        value={values.program}
                      />
                    ) : (
                      <>
                        <Select
                          disabled={programSelectData.length === 0 ? true : null}
                          label="Nazwa programu"
                          data={programSelectData}
                          variant="filled"
                          selectValue={programSelectData.length > 0 ? values.program_id : null}
                          text="program_id"
                          id="program_id"
                          onChange={(_, newValue) => {
                            const foundProgram = programSelectData.find(
                              (el) => el.value === newValue,
                            );

                            setFieldValue('program_id', newValue);

                            if (foundProgram) {
                              setFieldValue('program', foundProgram.name);
                            }
                          }}
                          setProgramIDFn={setProgramID}
                        />
                        <ErrorMessage name="program_id">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {me ||
                    (id_survey && values.author) ||
                    (values.id && values.author) ||
                    (id && values.author) ? (
                      <Input
                        disabled
                        label="Imię i nazwisko przeprowadzającego"
                        variant="filled"
                        name="author"
                        id="author"
                        onChange={handleChange}
                        value={values.author}
                      />
                    ) : (
                      <>
                        <Select
                          disabled={programAuthorData.length === 0 ? true : null}
                          label="Imię i nazwisko przeprowadzającego"
                          data={programAuthorData}
                          variant="filled"
                          selectValue={programAuthorData.length > 0 ? values.author_id : null}
                          text="author_id"
                          id="author_id"
                          onChange={setFieldValue}
                        />
                        <ErrorMessage name="author_id">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {(id_survey && values.beneficiary) ||
                    (values.id && values.beneficiary) ||
                    (id && values.beneficiary) ? (
                      <Input
                        disabled
                        label="Imię i nazwisko beneficjenta"
                        variant="filled"
                        name="beneficiary"
                        id="beneficiary"
                        onChange={handleChange}
                        value={values.beneficiary}
                      />
                    ) : (
                      <>
                        <Select
                          disabled={programBeneficiariesData.length === 0 ? true : null}
                          label="Imię i nazwisko beneficjenta"
                          data={programBeneficiariesData}
                          variant="filled"
                          selectValue={
                            programBeneficiariesData.length > 0 ? values.beneficiary_id : null
                          }
                          text="beneficiary_id"
                          id="beneficiary_id"
                          onChange={(e, newValue) => {
                            const foundBeneficiary = programBeneficiariesData.find(
                              (el) => el.value === newValue,
                            );

                            setFieldValue(e, newValue);

                            if (foundBeneficiary) {
                              setFieldValue('cwp_id', foundBeneficiary.cwp_id);
                              setFieldValue('beneficiary', foundBeneficiary.name);
                            }
                          }}
                        />
                        <ErrorMessage name="beneficiary_id">
                          {(msg) => <div className="error-txt">{msg}</div>}
                        </ErrorMessage>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Select
                      disabled={
                        // !values.program_id ||
                        !values.author_id ||
                        !values.beneficiary_id ||
                        !isEditable ||
                        !!id_survey ||
                        !!values.id
                      }
                      label="Przedział wiekowy"
                      data={ageRange}
                      variant="filled"
                      selectValue={values.age}
                      text="age"
                      id="age"
                      onChange={(e, newValue) => {
                        setFieldValue('groups', getGroupsInitialValues(surveysQuestions[newValue]));
                        setFieldValue('age', newValue);
                      }}
                    />
                    <ErrorMessage name="age">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                </StyledGrid>
                {values.status !== 2 ||
                (single_survey && Object.keys(single_survey.result).length === 0) ? (
                  <Box my={3}>
                    {values.groups.length > 0 &&
                      values.groups.map((group, index_group) => {
                        return (
                          <MyAccordion key={group.id} title={group.label}>
                            <StyledAccordionDetails>
                              {group.questions.map((question, index_question) => (
                                <div key={question.id}>
                                  <p>{`${index_question + 1}. ${question.label}`}</p>
                                  <div>
                                    <FormControl component="fieldset">
                                      <FormLabel component="legend" />
                                      <StyledRadioGroup
                                        aria-label={question.id.toString()}
                                        name={`groups[${index_group}].questions[${index_question}].value`}
                                        // value={question.value ? question.value.toString() : null}
                                      >
                                        {answers.map((item) => (
                                          <Checkbox
                                            key={item.id}
                                            radio
                                            isEditable={isEditable && values.status !== 2}
                                            name={question.id.toString()}
                                            value={question.value === parseInt(item.value, 10)}
                                            fieldValue={item.value.toString()}
                                            label={item.odp}
                                            onChange={(e) => {
                                              setFieldValue(
                                                `groups[${index_group}].questions[${index_question}].value`,
                                                parseInt(e.target.value, 10),
                                              );
                                              if (values.status !== 1) {
                                                setFieldValue('status', 1);
                                              }
                                              setRedirectView(false);
                                              handleSubmit();
                                            }}
                                          />
                                        ))}
                                      </StyledRadioGroup>
                                    </FormControl>
                                  </div>
                                  <hr />
                                </div>
                              ))}
                            </StyledAccordionDetails>
                          </MyAccordion>
                        );
                      })}
                  </Box>
                ) : (
                  single_survey && (
                    <ResultSurveys
                      results={single_survey?.answers.map((a) => ({
                        id: a.id,
                        name: a.label,
                        score: single_survey?.result[parseInt(a.id, 10)]?.avg
                          ? single_survey?.result[parseInt(a.id, 10)].avg
                          : 0,
                      }))}
                      age={values.age}
                      gender={
                        values.beneficiary?.gender ||
                        currentItem?.beneficiary?.gender ||
                        single_survey?.beneficiary?.gender ||
                        'female'
                      }
                    />
                  )
                )}
                {isEditable &&
                  (values.status !== 2 ||
                    (single_survey && Object.keys(single_survey.result).length === 0)) && (
                    <Grid item xs={12}>
                      <StyledButton
                        variant="outlined"
                        onClick={() => {
                          setFieldValue('status', 1);
                          setRedirectView(true);
                          handleSubmit();
                        }}
                        disabled={!values.id}
                      >
                        ZAPISZ, ABY WRÓCIĆ DO EDYCJI PÓŹNIEJ
                      </StyledButton>
                      <StyledButton
                        margin="2rem"
                        padding="1rem 1.5rem"
                        variant="contained"
                        onClick={() => {
                          setFieldValue('status', 2);
                          setRedirectView(false);
                          handleSubmit();
                        }}
                        disabled={!values.id}
                      >
                        ZAPISZ I PODSUMUJ
                      </StyledButton>
                    </Grid>
                  )}
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
};

AddBeneficiarySurveys.propTypes = {
  clearSingleSurvey: PropTypes.func,
  fetchSingleSurvey: PropTypes.func,
  single_survey: PropTypes.objectOf(PropTypes.any),
  clearCWPInfo: PropTypes.func,
  fetchCWPInfo: PropTypes.func,
  cwp_info: PropTypes.shape({
    approvals: PropTypes.shape({
      approvalToIndependentReturnHome: PropTypes.bool,
      approvalToTransferOfInformation: PropTypes.bool,
      approvalToUseImage: PropTypes.bool,
      consortiumInformationClause: PropTypes.bool,
      presidentsInformationClause: PropTypes.bool,
    }),
    assigned_by_school: PropTypes.bool,
    beneficiary: PropTypes.string,
    beneficiaryType: PropTypes.number,
    beneficiary_id: PropTypes.number,
    connection_id: PropTypes.number,
    date_added: PropTypes.string,
    date_added_by_school_to_program: PropTypes.string,
    program_date_end: PropTypes.string,
    program_id: PropTypes.number,
    program_name: PropTypes.string,
    program_date_start: PropTypes.string,
    who_assigned: PropTypes.shape({
      city: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.number,
    }),
  }),
  clearProgramBeneficiaries: PropTypes.func,
  fetchProgramBeneficiaries: PropTypes.func,
  beneficiaries: PropTypes.arrayOf(PropTypes.any),
  clearPrograms: PropTypes.func,
  fetchProgramsList: PropTypes.func,
  programs: PropTypes.arrayOf(PropTypes.any),
  clearProgramUsers: PropTypes.func,
  fetchProgramUsersSimple: PropTypes.func,
  authors: PropTypes.arrayOf(PropTypes.any),
  saveBeneficiarySurvey: PropTypes.func,
  me: PropTypes.objectOf(PropTypes.any),
};

AddBeneficiarySurveys.defaultProps = {
  clearSingleSurvey: null,
  fetchSingleSurvey: null,
  single_survey: null,
  clearCWPInfo: null,
  fetchCWPInfo: null,
  cwp_info: {},
  clearProgramBeneficiaries: null,
  fetchProgramBeneficiaries: null,
  beneficiaries: [],
  clearPrograms: null,
  fetchProgramsList: null,
  programs: [],
  clearProgramUsers: null,
  fetchProgramUsersSimple: null,
  authors: [],
  saveBeneficiarySurvey: null,
  me: {},
};

const mapStateToProps = ({ beneficiaryReducer, programReducer, userReducer }) => ({
  cwp_info: beneficiaryReducer.cwp_info,
  programs: programReducer.programs,
  beneficiaries: programReducer.beneficiaries.program_beneficiaries,
  authors: programReducer.users,
  single_survey: beneficiaryReducer.single_survey,
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  clearSingleSurvey: () => dispatch(clearSingleSurveyAction()),
  fetchSingleSurvey: (id) => dispatch(fetchSingleSurveyService(id)),
  clearCWPInfo: () => dispatch(clearCWPInfoAction()),
  fetchCWPInfo: (id) => dispatch(fetchCWPInfoService(id)),
  clearProgramBeneficiaries: () => dispatch(clearProgramBeneficiariesAction()),
  fetchProgramBeneficiaries: (id) => dispatch(fetchProgramBeneficiariesService(id)),
  clearPrograms: () => dispatch(clearProgramsAction()),
  fetchProgramsList: () => dispatch(fetchProgramsListService()),
  clearProgramUsers: () => dispatch(clearProgramUsersAction()),
  fetchProgramUsersSimple: (id) => dispatch(fetchProgramUsersSimpleService(id)),
  saveBeneficiarySurvey: (id, data, edit, redirectView) =>
    dispatch(saveBeneficiarySurveyService(id, data, edit, redirectView)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBeneficiarySurveys);
