// React
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Context
import { DashboardContext } from '@contexts/DashboardContext';

// Elements
import Navbar from '@components/organisms/Navbar/Navbar';
import Snackbar from '@components/atoms/Snackbar/Snackbar';
import Sidebar from '@components/organisms/Sidebar/Sidebar';

// Styles
import { StyledWrapper } from './DashboardTemplate.styles';

// Component
const DashboardTemplate = ({ children }) => {
  const { isSidebarHide } = useContext(DashboardContext);

  return (
    <>
      <Snackbar />
      <Navbar />
      <Sidebar />
      <StyledWrapper hide={isSidebarHide}>{children}</StyledWrapper>
    </>
  );
};

DashboardTemplate.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default DashboardTemplate;
