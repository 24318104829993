// React
import React, { useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchProgramIPP } from '@services/programServices';

// Actions
import { clearProgramIPP as clearProgramIPPAction } from '@actions/programActions';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

// Data
import { filterIPP } from '@constants/tableFilters';

const headCells = [
  { type: 'beneficiary_name', label: 'Imię' },
  { type: 'beneficiary_surname', label: 'Nazwisko' },
  { type: 'title', label: 'Nazwa' },
  { type: 'status', label: 'Status' },
  { type: 'execution_date', label: 'Data przeprowadzenia' },
  { type: 'modified_on', label: 'Data ostatniej edycji' },
  { type: 'last_modified_by', label: 'Ostatnia osoba wprowadzająca' },
];

// Component
const ProgramIPP = ({ clearProgramIPP, fetchData, data }) => {
  const { id } = useParams();
  const history = useHistory();
  const { ipps } = data;

  useEffect(() => {
    clearProgramIPP();
  }, []);

  const triggerFetchData = useCallback((myid) => fetchData(myid), [fetchData]);

  useEffect(() => {
    triggerFetchData(id);
  }, [id, triggerFetchData]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={ipps}
        filters={filterIPP.filter((item) => item.key !== 'program')}
        isnav
        link="/indywidualny-plan-pracy"
        tableName="Indywidualne plany pracy"
      />
      <FloatingButton
        onClick={() => history.push(`/indywidualny-plan-pracy-dodaj/program/${id}`)}
      />
    </>
  );
};

ProgramIPP.propTypes = {
  clearProgramIPP: PropTypes.func,
  fetchData: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    ipps: PropTypes.arrayOf(
      PropTypes.shape({
        beneficiary_first_name: PropTypes.string,
        beneficiary_last_name: PropTypes.string,
        is_prepared: PropTypes.bool,
        date_start: PropTypes.string,
        modified_on: PropTypes.string,
        ipp_author: PropTypes.string,
      }),
    ),
  }),
};

ProgramIPP.defaultProps = {
  clearProgramIPP: null,
  fetchData: null,
  data: {
    id: null,
    name: '',
    ipps: [],
  },
};

const mapStateToProps = ({ programReducer }) => ({
  data: programReducer.ipp,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramIPP: () => dispatch(clearProgramIPPAction()),
  fetchData: (id) => dispatch(fetchProgramIPP(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramIPP);
