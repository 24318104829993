// Elements
import { TextField } from '@mui/material';

// Styles
import styled, { css } from 'styled-components';

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    input,
    label,
    textarea {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.color.darkGray};
    }

    .MuiFilledInput-root {
      background-color: ${({ theme }) => theme.color.lightGray};
      color: ${({ theme }) => theme.color.black};

      input {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }

      &.Mui-disabled {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.darkGray};
      }

      &.Mui-focused {
        background-color: ${({ theme }) => theme.color.grayToBlack};
        color: ${({ theme }) => theme.color.black};
      }
    }

    textarea {
      line-height: 1.4;
    }

    ${({ right }) =>
      right &&
      css`
        .MuiFormHelperText-root {
          text-align: right;
        }
      `}

    input, textarea {
      background-color: ${({ theme }) => theme.color.lightGray};
      border: 1px solid ${({ theme }) => theme.color.lightGray};
      &:disabled {
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.darkGray};
      }

      &:focus {
        background-color: ${({ theme }) => theme.color.grayToBlack};
      }
    }

    p {
      font-size: ${({ theme }) => theme.fontSize.caption};
    }
  }
`;
