// React
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';

// Service
import { fetchMyMessageList as fetchMyMessageListService } from '@services/messageServices';

// Actions
import { clearMessage as clearMessageAction } from '@actions/messageActions';

// Elements
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';

// Data
const headCells = [
  { type: 'subject', label: 'Temat' },
  { type: 'created_by', label: 'Nadawca' },
  { type: 'last_message_date', label: 'Data ostatnia wiadomości' },
  { type: 'last_recipient', label: 'Ostatnia wiadomość od' },
  { type: 'is_read', label: 'Przeczytane', align: 'center' },
];

// Component
const MessagesList = ({ clearMessage, fetchMyMessageList, messages }) => {
  const history = useHistory();

  useEffect(() => {
    clearMessage();
  }, []);

  useEffect(() => {
    fetchMyMessageList();
  }, [fetchMyMessageList]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={messages}
        navCells
        rowActions={[
          {
            name: 'zobacz',
            action: (row) => history.push(`/wiadomosci/${row.id}`),
          },
        ]}
      />
      <FloatingButton onClick={() => history.push('/wiadomosci-dodaj')} />
    </>
  );
};

MessagesList.propTypes = {
  clearMessage: PropTypes.func,
  fetchMyMessageList: PropTypes.func,
  messages: PropTypes.arrayOf(PropTypes.any),
};

MessagesList.defaultProps = {
  clearMessage: null,
  fetchMyMessageList: null,
  messages: [],
};

const mapStateToProps = ({ messageReducer }) => ({
  messages: messageReducer.messages,
});

const mapDispatchToProps = (dispatch) => ({
  clearMessage: () => dispatch(clearMessageAction()),
  fetchMyMessageList: (id) => dispatch(fetchMyMessageListService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MessagesList);
