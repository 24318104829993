// React
import React from 'react';

// Data
import { ConfigTabs } from '@constants/tabs';

// Elements
import Tabs from '@components/molecules/Tabs/Tabs';

// Component
const Config = () => <Tabs renderViewArray={ConfigTabs} />;

export default Config;
