// React
import React, { useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import { fetchTaskBeneficiary as fetchTaskBeneficiaryService } from '@services/taskServices';

// Actions
import { clearTaskBeneficiary as clearTaskBeneficiaryAction } from '@actions/taskActions';

// Data
import { filterBeneficiary } from '@constants/tableFilters';

// Elements
import { Grid } from '@mui/material';
import TableTemplate from '@templates/TableTemplate';

// Data
const headCells = [
  { type: 'name', label: 'Imię' },
  { type: 'surname', label: 'Nazwisko' },
  { type: 'gender', label: 'Płeć' },
  { type: 'pwd', label: 'PWD' },
];

// Component
const TaskBeneficiary = ({
  clearTaskBeneficiary,
  fetchTaskBeneficiaryList,
  task_beneficiaries,
}) => {
  const { id } = useParams();

  useEffect(() => {
    clearTaskBeneficiary();
  }, []);

  const triggerFetchList = useCallback(
    (myid) => fetchTaskBeneficiaryList(myid),
    [fetchTaskBeneficiaryList],
  );

  useEffect(() => {
    triggerFetchList(id);
  }, [id, triggerFetchList]);

  return (
    <>
      <Grid className="beneficiaryTask" container spacing={2}>
        <Grid item xs={12}>
          {task_beneficiaries && (
            <TableTemplate
              headCells={headCells}
              data={task_beneficiaries}
              filters={filterBeneficiary}
              isnav
              link="/beneficjenci"
              tableName="Beneficjenci"
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

TaskBeneficiary.propTypes = {
  clearTaskBeneficiary: PropTypes.func,
  fetchTaskBeneficiaryList: PropTypes.func,
  task: PropTypes.shape({
    id: PropTypes.number,
    program: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
  task_beneficiaries: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      age_type: PropTypes.string,
      beneficiary_gender: PropTypes.string,
      beneficiary_last_name: PropTypes.string,
      beneficiary_name: PropTypes.string,
      date_added_to_program: PropTypes.string,
      date_modified: PropTypes.string,
      educational_facility: PropTypes.string,
      modified_by: PropTypes.string,
    }),
  ),
};

TaskBeneficiary.defaultProps = {
  clearTaskBeneficiary: null,
  fetchTaskBeneficiaryList: null,
  task: null,
  task_beneficiaries: [],
};

const mapStateToProps = ({ taskReducer }) => ({
  task: taskReducer.task,
  task_beneficiaries: taskReducer.beneficiary,
});

const mapDispatchToProps = (dispatch) => ({
  clearTaskBeneficiary: () => dispatch(clearTaskBeneficiaryAction()),
  fetchTaskBeneficiaryList: (id) => dispatch(fetchTaskBeneficiaryService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskBeneficiary);
