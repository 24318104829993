// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

import Button from '@components/atoms/Button/Button';

// Services
import {
  inviteProgram as inviteProgramService,
  allInvitedProgram as allInvitedProgramService,
  cancelInvitationToProgram as cancelInvitationToProgramService,
  setAdvancedrodoProgram as setAdvancedrodoProgramService,
  fetchProgramConsortium,
  saveConsortiumLeader as saveConsortiumLeaderService,
} from '@services/programServices';

import Checkbox from '@components/atoms/Checkbox/Checkbox';

// Actions
import { clearProgramConsortium as clearProgramConsortiumAction } from '@actions/programActions';

// Formik
import { Formik } from 'formik';
import {
  COORDINATOR /* , PROGRAM_COORDINATOR */,
  ORGANIZATION_ADMINISTRATOR,
  PROGRAM_COORDINATOR,
} from '@constants/roles';

import typ1 from '@img/typ1_wspoladministratorzy.png';
import typ2 from '@img/typ2_jeden_administrator.png';
import typ3 from '@img/typ3_niezalezni_administratorzy.png';

// Element
import {
  // Box,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  // Checkbox,
} from '@mui/material';
import Select from '@components/atoms/Select/Select';
import Heading from '@components/atoms/Heading/Heading';
import Modal from '@components/organisms/Modal/Modal';
import useModal from '@components/organisms/Modal/useModal';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import ProgramSurvey from './ProgramSurvey';

// Styles
import { StyledGrid, StyledGridItem, StyledButton } from './ProgramConsortium.styles';

// Component
const ProgramConsortium = ({
  clearProgramConsortium,
  fetchConsortium,
  details,
  organizations,
  inviteProgram,
  allInvitedProgram,
  cancelInvitationToProgram,
  setAdvancedrodoProgram,
  saveConsortiumLeader,
}) => {
  const { id } = useParams();
  const { isShowing, toggle } = useModal();
  const [consortiumLeaderId, setConsortiumLeaderId] = useState(null);
  const [valueRadio, setValueRadio] = useState('');
  const isEditable = true;

  useEffect(() => {
    clearProgramConsortium();
  }, []);

  useEffect(() => {
    const newData = {};
    setConsortiumLeaderId(details.leader_organization_id);
    details.organizations.map((el) => {
      newData[el.id] = false;

      return true;
    });
  }, [details]);

  const triggerFetchConsortium = useCallback((myid) => fetchConsortium(myid), [fetchConsortium]);

  useEffect(() => {
    triggerFetchConsortium(id);
  }, [id]);

  //  console.log(valueRadio);

  return (
    <>
      <StyledGrid container spacing={2}>
        {details.id && (
          <>
            <GuardedComponent allowed_user_roles={[COORDINATOR]}>
              <Grid item xs={12}>
                <Heading.Subtitle3>Lider konsorcjum</Heading.Subtitle3>
              </Grid>
              <Grid item xs={8}>
                <Select
                  disabled={!isEditable || false}
                  name="leader_organization_id"
                  label="Wybierz organizację"
                  text="leader_organization_id"
                  selectValue={consortiumLeaderId}
                  data={
                    details.program_organizations &&
                    details.program_organizations.map((el) => {
                      return {
                        value: el.organization.id,
                        name: el.organization.name,
                      };
                    })
                  }
                  variant="filled"
                  onChange={(_, v) => setConsortiumLeaderId(v)}
                />
              </Grid>
              <StyledGridItem item xs={4}>
                <StyledButton
                  variant="outlined"
                  onClick={() =>
                    saveConsortiumLeader(id, { leader_organization_id: consortiumLeaderId })
                  }
                >
                  ZAPISZ
                </StyledButton>
              </StyledGridItem>
            </GuardedComponent>
          </>
        )}
        <Grid item xs={12}>
          <Heading.H5>Organizacje wchodzące w skład konsorcjum: </Heading.H5>
        </Grid>
        <Grid item xs={12}>
          {details !== undefined &&
            details.program_organizations &&
            details.program_organizations.map((el) => {
              return (
                <React.Fragment key={el.id}>
                  <StyledGrid container alignItems="center" spacing={1}>
                    <Grid item xs={8}>
                      {details.leader_organization_id &&
                      details.leader_organization_id === el.organization.id ? (
                        <Heading.Subtitle1>
                          {el.organization.name}{' '}
                          {details.leader_organization_id &&
                          details.leader_organization_id === el.organization.id
                            ? '(lider konsorcjum)'
                            : ''}
                        </Heading.Subtitle1>
                      ) : (
                        <Heading.Body1>{el.organization.name}</Heading.Body1>
                      )}
                    </Grid>
                  </StyledGrid>
                </React.Fragment>
              );
            })}
        </Grid>
        {details.advanced_rodo_preset &&
          (details.advanced_rodo === true || details.advanced_rodo === false) && (
            <Grid item xs={12}>
              {details.advanced_rodo_preset === 'Typ1' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flexStart',
                    textAlign: 'center',
                    width: '240px',
                  }}
                >
                  <img
                    src={typ1}
                    alt="Typ 1"
                    style={{
                      border: valueRadio === 'Typ1' ? '3px solid black' : '1px solid gray',
                      cursor: 'pointer',
                      width: '240px',
                      height: '144px',
                      marginBottom: '1rem',
                      display: 'block',
                    }}
                  />
                  <span>
                    Organizacje są współadministratorami wszystkich rodzajów danych w projekcie
                  </span>
                </div>
              )}
              {details.advanced_rodo_preset === 'Typ2' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flexStart',
                    textAlign: 'center',
                    width: '240px',
                  }}
                >
                  <img
                    src={typ2}
                    alt="Typ 2"
                    style={{
                      border: valueRadio === 'Typ2' ? '3px solid black' : '1px solid gray',
                      cursor: 'pointer',
                      width: '240px',
                      height: '144px',
                      marginBottom: '1rem',
                      display: 'block',
                    }}
                  />
                  <span>
                    Jeden administrator (lub grupa współadministratorów) pozostali partnerzy są
                    podmiotami przetwarzającymi
                  </span>
                </div>
              )}
              {details.advanced_rodo_preset === 'Typ3' && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flexStart',
                    textAlign: 'center',
                    width: '240px',
                  }}
                >
                  <img
                    src={typ3}
                    alt="Typ 3"
                    style={{
                      border: valueRadio === 'Typ3' ? '3px solid black' : '1px solid gray',
                      cursor: 'pointer',
                      width: '240px',
                      height: '144px',
                      marginBottom: '1rem',
                      display: 'block',
                    }}
                  />
                  <span>Partnerzy jako niezależni administratorzy danych</span>
                </div>
              )}
            </Grid>
          )}
        {details.advanced_rodo === true && <ProgramSurvey />}

        {/* /////////////////////////////////////////////////////////////////////////////////////////// */}
        {details !== undefined &&
          details.invited_organizations &&
          details.invited_organizations.length > 0 && (
            <>
              <Grid item xs={12}>
                <Heading.Subtitle3>Organizacje zaproszone do konsorcjum</Heading.Subtitle3>
              </Grid>
              <Grid item xs={12}>
                {details.invited_organizations.map((el) => {
                  return (
                    <StyledGrid key={el.invitation_id} alignItems="center" container spacing={1}>
                      <Grid item xs={8}>
                        <Heading.Body2>{el.name}</Heading.Body2>
                      </Grid>
                      {details.onlyShow === false && (
                        <GuardedComponent
                          allowed_user_roles={[
                            COORDINATOR,
                            ORGANIZATION_ADMINISTRATOR,
                            PROGRAM_COORDINATOR,
                          ]}
                        >
                          <StyledGridItem item xs={4}>
                            <StyledButton
                              onClick={() =>
                                cancelInvitationToProgram(details.id, {
                                  invitation_id: el.invitation_id,
                                })
                              }
                            >
                              COFNIJ
                            </StyledButton>
                          </StyledGridItem>
                        </GuardedComponent>
                      )}
                    </StyledGrid>
                  );
                })}
              </Grid>
            </>
          )}
        {details.advanced_rodo === null && details.onlyShow === false && details.id && (
          <>
            <GuardedComponent
              allowed_user_roles={[COORDINATOR, ORGANIZATION_ADMINISTRATOR, PROGRAM_COORDINATOR]}
            >
              {!details.all_invited && (
                <>
                  <Grid item xs={12}>
                    <Heading.Subtitle3>Zaproś do konsorcjum</Heading.Subtitle3>
                  </Grid>
                  <Formik
                    initialValues={{
                      organization_id: null,
                    }}
                    enableReinitialize
                    onSubmit={(values) => {
                      // console.log(id, values);
                      inviteProgram(id, values);
                    }}
                  >
                    {({ values, setFieldValue, handleSubmit }) => (
                      <>
                        <Grid item xs={8}>
                          <Select
                            disabled={!isEditable || false}
                            name="organization_id"
                            label="Wybierz organizacje z bazy"
                            text="organization_id"
                            selectValue={values.organization_id}
                            data={organizations.map((el) => {
                              return {
                                value: el.id,
                                name: el.name,
                              };
                            })}
                            variant="filled"
                            onChange={setFieldValue}
                          />
                        </Grid>
                        <StyledGridItem item xs={4}>
                          <StyledButton variant="outlined" onClick={handleSubmit}>
                            ZAPROŚ
                          </StyledButton>
                        </StyledGridItem>
                      </>
                    )}
                  </Formik>
                </>
              )}
              <Grid item xs={6}>
                <Checkbox
                  label="Wszystkie organizacje zostały już zaproszone"
                  name="all_invited"
                  onChange={(e) => {
                    allInvitedProgram(id, { all_invited: e.target.checked });
                  }}
                  isEditable={!details.all_invited && details.program_organizations.length > 1}
                  value={details.all_invited}
                  disabled={
                    details.invited_organizations.length > 0 ||
                    details.program_organizations.length < 2
                  }
                />
              </Grid>
              {details.program_organizations && details.program_organizations.length === 1 && (
                <Grid item xs={12}>
                  <Heading.Subtitle3>
                    Konsorcjum musi składać się z co najmniej dwóch organizacji.
                  </Heading.Subtitle3>
                </Grid>
              )}
            </GuardedComponent>
          </>
        )}

        {details.invited_organizations && details.invited_organizations.length > 0 ? (
          <Grid item xs={12}>
            <Heading.Subtitle3>
              Konosrcjum posiada oczekujące na odpowiedź zaproszenia, aby kontynuować konfigurację,
              cofnij je lub poczekaj na ich przejęcie przez zaproszone organizacje.
            </Heading.Subtitle3>
          </Grid>
        ) : (
          <Grid item xs={12} style={{ paddingTop: '2rem', paddingBottom: '4rem' }}>
            {details.name !== '' && details.all_invited && (
              <>
                <Heading.Subtitle3>
                  {details.advanced_rodo === null && details.onlyShow === true ? (
                    <>Konsorcjum nie jest skonfigurowane</>
                  ) : (
                    <>
                      {details.advanced_rodo === null ? (
                        'Konsorcjum nie jest skonfigurowane'
                      ) : (
                        <>
                          {/* Konsorcjum współadministruje{' '}
                          <u>{details.advanced_rodo === false ? 'wszystkimi' : 'wybranymi'}</u>{' '}
                          rodzajami danych */}
                        </>
                      )}
                    </>
                  )}
                </Heading.Subtitle3>

                {details.advanced_rodo === null && details.onlyShow === false && (
                  <>
                    <Heading.Subtitle3>
                      Wybierz sposób administrowania danymi w konsorcjum
                    </Heading.Subtitle3>
                    <FormControl
                      className="radioGrupa-konsorcjum"
                      component="fieldset"
                      style={{ paddingTop: '2rem' }}
                    >
                      <RadioGroup
                        row
                        aria-label="advancedRodoPreset"
                        name="advancedRodoPreset"
                        value={valueRadio}
                        onChange={(e) => setValueRadio(e.target.value)}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                        }}
                        className="radio_class"
                      >
                        <FormControlLabel
                          value="Typ1"
                          control={
                            <Radio
                              color="primary"
                              style={{
                                display: 'block',
                              }}
                            />
                          }
                          label={
                            <Button tabindex={0} onClick={() => setValueRadio('Typ1')}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flexStart',
                                  textAlign: 'center',
                                  width: '240px',
                                }}
                              >
                                <img
                                  src={typ1}
                                  alt="Typ 1"
                                  style={{
                                    border:
                                      valueRadio === 'Typ1' ? '3px solid black' : '1px solid gray',
                                    cursor: 'pointer',
                                    width: '240px',
                                    height: '144px',
                                    marginBottom: '1rem',
                                    display: 'block',
                                  }}
                                />
                                <span>
                                  Organizacje są współadministratorami wszystkich rodzajów danych w
                                  projekcie
                                </span>
                              </div>
                            </Button>
                          }
                        />
                        <FormControlLabel
                          value="Typ2"
                          control={
                            <Radio
                              color="primary"
                              style={{
                                display: 'block',
                              }}
                            />
                          }
                          label={
                            <Button tabindex={0} onClick={() => setValueRadio('Typ2')}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flexStart',
                                  textAlign: 'center',
                                  width: '240px',
                                }}
                              >
                                <img
                                  src={typ2}
                                  alt="Typ 2"
                                  style={{
                                    border:
                                      valueRadio === 'Typ2' ? '3px solid black' : '1px solid gray',
                                    cursor: 'pointer',
                                    width: '240px',
                                    height: '144px',
                                    marginBottom: '1rem',
                                    display: 'block',
                                  }}
                                />
                                <span>
                                  Jeden administrator (lub grupa współadministratorów) pozostali
                                  partnerzy są podmiotami przetwarzającymi
                                </span>
                              </div>
                            </Button>
                          }
                        />
                        <FormControlLabel
                          value="Typ3"
                          control={
                            <Radio
                              color="primary"
                              style={{
                                display: 'block',
                              }}
                            />
                          }
                          label={
                            <Button tabindex={0} onClick={() => setValueRadio('Typ3')}>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flexStart',
                                  textAlign: 'center',
                                  width: '240px',
                                }}
                              >
                                <img
                                  src={typ3}
                                  alt="Typ 3"
                                  style={{
                                    border:
                                      valueRadio === 'Typ3' ? '2px solid black' : '1px solid gray',
                                    cursor: 'pointer',
                                    width: '240px',
                                    height: '144px',
                                    marginBottom: '1rem',
                                    display: 'block',
                                  }}
                                />
                                <span>Partnerzy jako niezależni administratorzy danych</span>
                              </div>
                            </Button>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                    <StyledGridItem item xs={4} style={{ paddingTop: '2rem' }}>
                      <StyledButton
                        variant="outlined"
                        onClick={toggle}
                        disabled={valueRadio === '' || details.program_organizations.length < 2}
                      >
                        ZAPISZ
                      </StyledButton>
                    </StyledGridItem>
                    <Modal
                      isShowing={isShowing}
                      hide={toggle}
                      yes={() => {
                        setAdvancedrodoProgram(details.id, {
                          advanced_rodo: valueRadio !== 'Typ1',
                          advanced_rodo_preset: valueRadio,
                        });
                        toggle();
                      }}
                      no={() => {
                        toggle();
                      }}
                      text={
                        <Grid item xs={12}>
                          <Heading.H6 style={{ marginBottom: '2rem' }}>
                            Potwierdź sposób administrowania danymi w konsorcjum
                          </Heading.H6>
                          <Heading.Subtitle1 style={{ marginBottom: '2rem' }}>
                            Wybór sposobu współadministowania wszystkimi rodzajami danych jest
                            nieodwracalny.
                          </Heading.Subtitle1>
                          <Heading.Subtitle1 style={{ marginBottom: '2rem' }}>
                            Czy chcesz kontunuować?
                          </Heading.Subtitle1>
                        </Grid>
                      }
                    />
                  </>
                )}
              </>
            )}
          </Grid>
        )}
      </StyledGrid>
    </>
  );
};

ProgramConsortium.propTypes = {
  clearProgramConsortium: PropTypes.func,
  fetchConsortium: PropTypes.func,
  details: PropTypes.shape({
    id: PropTypes.number,
    leader_organization_id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.number,
    advanced_rodo: PropTypes.bool,
    onlyShow: PropTypes.bool,
    organizations: PropTypes.arrayOf(
      PropTypes.shape({
        organization_id: PropTypes.number,
        organization_name: PropTypes.string,
      }),
    ),
    program_organizations: PropTypes.arrayOf(PropTypes.any),
    invited_organizations: PropTypes.arrayOf(
      PropTypes.shape({
        organization_id: PropTypes.number,
        organization_name: PropTypes.string,
      }),
    ),
    all_invited: PropTypes.bool,
    advanced_rodo_preset: PropTypes.string,
  }),
  organizations: PropTypes.arrayOf(PropTypes.any),
  inviteProgram: PropTypes.func,
  cancelInvitationToProgram: PropTypes.func,
  setAdvancedrodoProgram: PropTypes.func,
  saveConsortiumLeader: PropTypes.func,
  allInvitedProgram: PropTypes.func,
};

ProgramConsortium.defaultProps = {
  clearProgramConsortium: null,
  fetchConsortium: null,
  details: {
    id: null,
    leader_organization_id: null,
    name: '',
    onlyShow: false,
    type: null,
    organizations: [],
    invited_organizations: [],
    program_organizations: [],
    advanced_rodo: null,
    all_invited: false,
    advanced_rodo_preset: '',
  },
  organizations: [],
  inviteProgram: null,
  cancelInvitationToProgram: null,
  setAdvancedrodoProgram: null,
  saveConsortiumLeader: null,
  allInvitedProgram: null,
};

const mapStateToProps = ({ programReducer, organizationReducer }) => ({
  details: programReducer.consortium,
  organizations: organizationReducer.organizations,
});

const mapDispatchToProps = (dispatch) => ({
  clearProgramConsortium: () => dispatch(clearProgramConsortiumAction()),
  fetchConsortium: (id) => dispatch(fetchProgramConsortium(id)),
  inviteProgram: (id, data) => dispatch(inviteProgramService(id, data)),
  cancelInvitationToProgram: (id, data) => dispatch(cancelInvitationToProgramService(id, data)),
  setAdvancedrodoProgram: (id, data) => dispatch(setAdvancedrodoProgramService(id, data)),
  saveConsortiumLeader: (id, data) => dispatch(saveConsortiumLeaderService(id, data)),
  allInvitedProgram: (id, data) => dispatch(allInvitedProgramService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProgramConsortium);
