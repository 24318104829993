// React
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import {
  StyledAccordion,
  StyledAccordionSummary,
  StyledAccordionWrapper,
  StyledExpandMoreIcon,
  StyledHeadingSubtitle1,
} from './Accordion.styles';

// Component
const MyAccordion = ({ title, children, hide }) => (
  <StyledAccordion hide={hide}>
    <StyledAccordionSummary expandIcon={<StyledExpandMoreIcon />}>
      <StyledHeadingSubtitle1>{title}</StyledHeadingSubtitle1>
    </StyledAccordionSummary>
    <StyledAccordionWrapper>{children}</StyledAccordionWrapper>
  </StyledAccordion>
);

MyAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  hide: PropTypes.string,
};

MyAccordion.defaultProps = {
  children: null,
  hide: 'false',
};

export default MyAccordion;
