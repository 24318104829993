// React
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Services
import {
  fetchUser as fetchUserService,
  editUser as editUserService,
  updateUserStatus as updateUserStatusService,
  userSuspension as userSuspensionService,
  userLockTime as userLockTimeService,
  initUserPasswordChange as initUserPasswordChangeService,
} from '@services/userServices';

// Actions
import { clearUser as clearUserAction } from '@actions/userActions';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Data
import { COORDINATOR } from '@constants/roles';
import { gender, suspendTime } from '@constants/selectLists';

// Elements
import { Grid, DialogActions } from '@mui/material';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import Heading from '@components/atoms/Heading/Heading';
import Button from '@components/atoms/Button/Button';
import EditButton from '@components/atoms/EditButton/EditButton';
import SwitchButton from '@components/atoms/SwitchButton/SwitchButton';
import Calendar from '@components/atoms/Calendar/Calendar';
import Dialog from '@components/organisms/Dialog/Dialog';

// Styles
import { StyledGrid, StyledButton, StyledCenterText, StyledTitle } from './UserDescription.styles';

// Component
const UserDescription = ({
  clearUser,
  fetchUser,
  user,
  editUser,
  updateUserStatus,
  initUserPasswordChange,
  userSuspension,
  userLockTime,
}) => {
  const { id } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [editView, setEditView] = useState(false);
  const [suspendAccountDialog, setSuspendAccountDialog] = useState(false);
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [completePasswordDialog, setCompletePasswordDialog] = useState(false);
  const [valueLockTime, setValueLockTime] = useState('dzien');

  useEffect(() => {
    clearUser();
  }, []);

  const triggerFetchUser = useCallback(
    (myid) => {
      if (myid !== undefined) {
        return fetchUser(myid);
      }

      return null;
    },
    [fetchUser],
  );

  useEffect(() => {
    triggerFetchUser(id);
  }, [id, triggerFetchUser]);

  useEffect(() => {
    if (user.canIEdit) {
      setEditView(true);
    } else {
      setEditView(false);
    }
  }, [user]);

  const handleSuspension = () => {
    setSuspendAccountDialog(true);
  };

  return (
    <>
      <StyledTitle>
        <Heading.Subtitle1>Dane osobowe</Heading.Subtitle1>

        <GuardedComponent allowed_user_roles={[COORDINATOR]}>
          {editView && (
            <EditButton isEditable={isEditable || false} setIsEditable={setIsEditable} />
          )}
        </GuardedComponent>
      </StyledTitle>
      <Formik
        initialValues={{
          name: user.name ? user.name : '',
          surname: user.surname ? user.surname : '',
          secondName: user.second_name ? user.second_name : '',
          pesel: user.pesel ? user.pesel : '',
          gender: user.gender ? user.gender : 1,
          dateAdd: user.add_date ? user.add_date : '',
          lastModified: user.last_modified ? user.last_modified : '',
          email: user.email ? user.email : '',
          phone: user.phone ? user.phone : '',
        }}
        enableReinitialize
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(100, 'Maksymalnie 100 znaków')
            .required('pole wymagane!'),
          surname: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(100, 'Maksymalnie 100 znaków')
            .required('pole wymagane!'),
          pesel:
            id === undefined
              ? Yup.number()
                  .required('pole wymagane!')
                  .test('pesel', 'Nieprawidłowy numer', (pesel) => {
                    if (!pesel) return true;
                    if (!/^[0-9]{11}$/.test(pesel)) {
                      return false;
                    }
                    const times = [1, 3, 7, 9];
                    const digits = `${pesel}`.split('').map((digit) => parseInt(digit, 10));
                    const dig11 = digits.splice(-1)[0];
                    const control =
                      digits.reduce(
                        (previousValue, currentValue, index) =>
                          previousValue + currentValue * times[index % 4],
                      ) % 10;
                    return 10 - (control === 0 ? 10 : control) === dig11;
                  })
              : null,
          gender: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(32, 'Maksymalnie 100 znaków')
            .required('pole wymagane!'),
          email: Yup.string().email('Nieprawidłowy Email').required('pole wymagane!'),
          phone: Yup.string()
            .min(2, 'Minimum 2 znaki')
            .max(32, 'Maksymalnie 100 znaków')
            .required('pole wymagane!'),
        })}
        onSubmit={(values) => {
          editUser(id === undefined ? 'new' : id, values);
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue }) => (
          <StyledGrid container spacing={2}>
            <Grid item xs={4}>
              <Input
                disabled={!isEditable || false}
                label="Imię"
                variant="filled"
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
              />
              <ErrorMessage name="name">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={4}>
              <Input
                disabled={!isEditable || false}
                label="Nazwisko"
                variant="filled"
                type="text"
                name="surname"
                value={values.surname}
                onChange={handleChange}
              />
              <ErrorMessage name="surname">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={4}>
              <Input
                disabled={!isEditable || false}
                label="Drugie imię"
                variant="filled"
                type="text"
                name="secondName"
                value={values.secondName}
                onChange={handleChange}
              />
            </Grid>
            {id === undefined && (
              <Grid item xs={4}>
                <Input
                  disabled={!isEditable || false}
                  label="Pesel"
                  variant="filled"
                  type="text"
                  name="pesel"
                  value={values.pesel}
                  onChange={handleChange}
                />
                <ErrorMessage name="pesel">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
            )}
            <Grid item xs={4}>
              <Select
                disabled={!isEditable || false}
                label="Płeć"
                data={gender}
                selectValue={values.gender}
                text="gender"
                onChange={setFieldValue}
                variant="filled"
              />
              <ErrorMessage name="gender">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            {id === undefined && <Grid item xs={4} />}
            <Grid item xs={4}>
              <Calendar
                label="Data dodania"
                name="dateAdd"
                disabled
                value={values.dateAdd}
                onChange={setFieldValue}
                variant="filled"
              />
            </Grid>
            <Grid item xs={4}>
              <Calendar
                label="Data ostatniej modyfikacji"
                name="lastModified"
                disabled
                value={values.lastModified}
                onChange={setFieldValue}
                variant="filled"
              />
            </Grid>
            {id === undefined && <Grid item xs={4} />}
            <Grid item xs={4}>
              <Input
                disabled={!isEditable || false}
                label="email"
                variant="filled"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              <ErrorMessage name="email">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <Grid item xs={4}>
              <Input
                disabled={!isEditable || false}
                label="Telefon kontaktowy"
                variant="filled"
                type="text"
                name="phone"
                value={values.phone}
                onChange={handleChange}
              />
              <ErrorMessage name="phone">
                {(msg) => <div className="error-txt">{msg}</div>}
              </ErrorMessage>
            </Grid>
            <GuardedComponent allowed_user_roles={[COORDINATOR]}>
              <Grid item xs={4} />
              {id === undefined ? (
                <Grid item xs={4}>
                  <StyledButton
                    disabled={!isEditable || false}
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    Dodaj użytkownika
                  </StyledButton>
                </Grid>
              ) : (
                <Grid item xs={4}>
                  <StyledButton
                    disabled={!isEditable || false}
                    variant="outlined"
                    onClick={handleSubmit}
                  >
                    Zapisz użytkownika
                  </StyledButton>
                </Grid>
              )}
            </GuardedComponent>
          </StyledGrid>
        )}
      </Formik>
      {id !== undefined && (
        <GuardedComponent allowed_user_roles={[COORDINATOR]}>
          <StyledTitle>
            <Heading.Body1>Status użytkownika</Heading.Body1>
          </StyledTitle>
          <StyledGrid container spacing={2}>
            {user.status_value === 5 && (
              <Grid item xs={12}>
                Konto użytkownika jest zawieszone
              </Grid>
            )}
            <Grid container item xs={4}>
              {user.status_value === 7 ? (
                <>Użytkownik wymaga zatwierdzenia</>
              ) : (
                <SwitchButton
                  disabled={!isEditable || false}
                  checked={user.status_value === 1}
                  label="Użytkownik aktywny"
                  place="end"
                  setChecked={(e) => updateUserStatus(id, { status: e })}
                />
              )}
            </Grid>
            <Grid container item xs={4}>
              {user.status_value === 4 && <>Użytkownik zablokowany do: {user.duration_of_status}</>}
              <SwitchButton
                disabled={!isEditable || false}
                label="Blokuj tymczasowo"
                place="end"
                setChecked={() => {
                  userLockTime(id, { duration: valueLockTime });
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                disabled={!isEditable || false}
                label="Wybierz czas blokady"
                data={suspendTime}
                selectValue={valueLockTime}
                variant="filled"
                onChange={(text, value) => {
                  setValueLockTime(value);
                }}
              />
            </Grid>
          </StyledGrid>
          <StyledTitle>
            <Heading.Subtitle1>Zarządzanie kontem</Heading.Subtitle1>
          </StyledTitle>
          <StyledGrid container spacing={2}>
            <Grid item xs={3}>
              <StyledButton
                disabled={!isEditable || false}
                variant="outlined"
                onClick={() => {
                  initUserPasswordChange(id);
                }}
              >
                Zmień hasło
              </StyledButton>
            </Grid>
            <StyledCenterText item xs={9}>
              <Heading.Body2>Wyślij link inicjujący restart hasła</Heading.Body2>
            </StyledCenterText>
            <Grid item xs={4}>
              <StyledButton
                disabled={!isEditable || false}
                onClick={() => {
                  handleSuspension(id, { duration: valueLockTime });
                  setSuspendAccountDialog(true);
                }}
                variant="outlined"
                color="secondary"
              >
                Zawieś konto
              </StyledButton>
            </Grid>
          </StyledGrid>
          <Dialog
            open={suspendAccountDialog}
            setOpen={setSuspendAccountDialog}
            title="Uwaga ! Zmiana statusu."
          >
            <Heading.Body1>Konto zostanie zawieszone, potwierdzasz?</Heading.Body1>

            <DialogActions>
              <Button
                onClick={() => {
                  userSuspension(id);
                  setSuspendAccountDialog(false);
                }}
                color="primary"
              >
                Tak
              </Button>
              <Button
                variant="outlined"
                onClick={() => setSuspendAccountDialog(false)}
                color="primary"
                autoFocus
              >
                Nie
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={changePasswordDialog}
            setOpen={setChangePasswordDialog}
            title="Czy na pewno chcesz zawiesić konto?"
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Input label="Wpisz nowe hasło" variant="filled" type="text" />
              </Grid>
              <Grid item xs={6}>
                <StyledButton onClick={() => setChangePasswordDialog(false)} color="primary">
                  Generuj hasło
                </StyledButton>
              </Grid>
              <Grid item xs={6}>
                <Input label="Zatwierdź nowe hasło" variant="filled" type="text" />
              </Grid>
              <Grid item xs={12}>
                <Heading.Subtitle1>Ustaw pytanie kontrolne </Heading.Subtitle1>
              </Grid>
              <Grid item xs={12}>
                <Input label="Pytanie kontrolne" variant="filled" type="text" />
              </Grid>
              <Grid item xs={12}>
                <Input label="Odpowiedź" variant="filled" type="text" />
              </Grid>
            </Grid>

            <DialogActions>
              <Button onClick={() => setChangePasswordDialog(false)} color="primary">
                Anuluj
              </Button>
              <Button
                onClick={() => {
                  setChangePasswordDialog(false);
                  setCompletePasswordDialog(true);
                }}
                color="primary"
                autoFocus
              >
                Zatwierdź
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={completePasswordDialog}
            setOpen={setCompletePasswordDialog}
            title="Hasło zmienione"
          >
            <Heading.Body1>
              Zmiana hasła zakończyła się sukcesem. Pamietaj, żeby nie udostępniać haseł osobom
              trzecim oraz przechowywać je w bezpieczny sposób.
            </Heading.Body1>

            <DialogActions>
              <Button onClick={() => setCompletePasswordDialog(false)} color="primary">
                Rozumiem
              </Button>
            </DialogActions>
          </Dialog>
        </GuardedComponent>
      )}
    </>
  );
};

UserDescription.propTypes = {
  clearUser: PropTypes.func,
  fetchUser: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.number,
    add_date: PropTypes.string,
    email: PropTypes.string,
    gender: PropTypes.string,
    last_modified: PropTypes.string,
    name: PropTypes.string,
    pesel: PropTypes.string,
    phone: PropTypes.string,
    second_name: PropTypes.string,
    status_value: PropTypes.number,
    surname: PropTypes.string,
    canIEdit: PropTypes.bool,
    duration_of_status: PropTypes.string,
  }),
  editUser: PropTypes.func,
  updateUserStatus: PropTypes.func,
  initUserPasswordChange: PropTypes.func,
  userSuspension: PropTypes.func,
  userLockTime: PropTypes.func,
};

UserDescription.defaultProps = {
  clearUser: null,
  fetchUser: null,
  user: {
    id: null,
    add_date: '',
    email: '',
    gender: '',
    last_modified: '',
    name: '',
    pesel: '',
    phone: '',
    second_name: '',
    status_value: null,
    surname: '',
    canIEdit: false,
    duration_of_status: '',
  },
  editUser: null,
  updateUserStatus: null,
  initUserPasswordChange: null,
  userSuspension: null,
  userLockTime: null,
};

const mapStateToProps = ({ userReducer }) => ({
  user: userReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch(clearUserAction()),
  fetchUser: (id) => dispatch(fetchUserService(id)),
  editUser: (id, data) => dispatch(editUserService(id, data)),
  updateUserStatus: (id, data) => dispatch(updateUserStatusService(id, data)),
  initUserPasswordChange: (id) => dispatch(initUserPasswordChangeService(id)),
  userSuspension: (id) => dispatch(userSuspensionService(id)),
  userLockTime: (id, data) => dispatch(userLockTimeService(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDescription);
