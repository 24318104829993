// React
import React from 'react';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Assets
import SendIcon from '@mui/icons-material/Send';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Services
import { mailToAdmin as mailToAdminService } from '@services/userServices';

// Elements
import { Box, Grid } from '@mui/material';
import Heading from '@components/atoms/Heading/Heading';
import Input from '@components/atoms/Input/Input';

// Styles
import { StyledWrapper, StyledButton } from './ContactAdmin.styles';

// Component
const ContactAdmin = ({ mailToAdmin }) => (
  <>
    <StyledWrapper container spacing={2}>
      <Grid item xs={12}>
        <Heading.Subtitle1>Kontakt z administratorem</Heading.Subtitle1>
      </Grid>
      <Grid item xs={4}>
        <Formik
          initialValues={{
            subject: '',
            message: '',
          }}
          enableReinitialize
          validationSchema={Yup.object({
            subject: Yup.string().required('pole wymagane!'),
            message: Yup.string().min(10, 'min 10 znaków').required('pole wymagane!'),
          })}
          onSubmit={(values, { resetForm }) => {
            mailToAdmin(values);

            resetForm({
              subject: '',
              message: '',
            });
          }}
        >
          {({ values, setFieldValue, handleChange, handleSubmit }) => {
            return (
              <>
                <Box mb={2}>
                  <Input
                    name="subject"
                    label="Temat"
                    variant="filled"
                    value={values.subject}
                    setField={setFieldValue}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="subject">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Box>
                <Box mb={2}>
                  <Input
                    name="message"
                    label="Wiadomość"
                    charCounter={2000}
                    multiline
                    rows={15}
                    variant="filled"
                    value={values.message}
                    setField={setFieldValue}
                  />
                  <ErrorMessage name="message">
                    {(msg) => <div className="error-txt">{msg}</div>}
                  </ErrorMessage>
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <StyledButton endIcon={<SendIcon />} onClick={handleSubmit}>
                    Wyślij wiadomość
                  </StyledButton>
                </Box>
              </>
            );
          }}
        </Formik>
      </Grid>
    </StyledWrapper>
  </>
);

ContactAdmin.propTypes = {
  mailToAdmin: PropTypes.func,
};

ContactAdmin.defaultProps = {
  mailToAdmin: null,
};

const mapDispatchToProps = (dispatch) => ({
  mailToAdmin: (data) => dispatch(mailToAdminService(data)),
});

export default connect(null, mapDispatchToProps)(ContactAdmin);
