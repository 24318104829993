// React
import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Services
import { saveBeneficiaryContactDetails as saveBeneficiaryContactDetailsService } from '@services/beneficiaryServices';

// Data
import { districts } from '@constants/selectLists';

// Elements
import { DialogActions, Grid } from '@mui/material';
import Dialog from '@components/organisms/Dialog/Dialog';
import Heading from '@components/atoms/Heading/Heading';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';

// Styles
import { StyledButton, StyledWrapper } from './BeneficiaryContactDetailsForm.styles';

// Component
const BeneficiaryContactDetailsForm = ({
  openDialog,
  setOpenDialog,
  program_organization,
  saveBeneficiaryContactDetails,
}) => {
  const { id } = useParams();

  return (
    <>
      <Dialog open={openDialog} setOpen={setOpenDialog} size="md">
        <Formik
          initialValues={{
            contactDetailsId:
              program_organization && program_organization.data && program_organization.data.id,
            connectionWithProgram:
              program_organization && program_organization.connectionWithProgram,
            connectionWithOrganization:
              program_organization && program_organization.connectionWithOrganization,
            programId: program_organization && program_organization.programId,
            organizationId: program_organization && program_organization.organizationId,
            street:
              program_organization && program_organization.data && program_organization.data.street
                ? program_organization.data.street
                : '',
            houseNumber:
              program_organization &&
              program_organization.data &&
              program_organization.data.houseNumber
                ? program_organization.data.houseNumber
                : '',
            apartmentNumber:
              program_organization &&
              program_organization.data &&
              program_organization.data.apartmentNumber
                ? program_organization.data.apartmentNumber
                : '',
            zipCode:
              program_organization && program_organization.data && program_organization.data.zipCode
                ? program_organization.data.zipCode
                : '',
            district:
              program_organization &&
              program_organization.data &&
              program_organization.data.district
                ? program_organization.data.district
                : '',
            city:
              program_organization && program_organization.data && program_organization.data.city
                ? program_organization.data.city
                : '',
          }}
          enableReinitialize
          validationSchema={Yup.object({
            street: Yup.string()
              .min(2, 'Minimum 2 znaki')
              .max(30, 'Maksymalnie 30 znaków')
              .required('pole wymagane!'),
            houseNumber: Yup.string()
              .min(1, 'Minimum 1 znaki')
              .max(10, 'Maksymalnie 10 znaków')
              .required('pole wymagane!'),
            apartmentNumber: Yup.string().max(10, 'Maksymalnie 10 znaków'),
            zipCode: Yup.string()
              .min(2, 'Minimum 2 znaki')
              .max(10, 'Maksymalnie 10 znaków')
              .required('pole wymagane!'),
            district: Yup.string()
              .min(2, 'Minimum 2 znaki')
              .max(255, 'Maksymalnie 255 znaków')
              .required('pole wymagane!'),
            city: Yup.string()
              .min(2, 'Minimum 2 znaki')
              .max(255, 'Maksymalnie 255 znaków')
              .required('pole wymagane!'),
          })}
          onSubmit={(values) => {
            saveBeneficiaryContactDetails(id, values);
            setOpenDialog();
          }}
        >
          {({ values, handleChange, setFieldValue, handleSubmit }) => (
            <StyledWrapper container spacing={2}>
              <Grid item xs={12}>
                <Heading.H6>
                  Edycja kontaktu: {program_organization.name && program_organization.name}
                </Heading.H6>
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Ulica"
                  variant="filled"
                  type="text"
                  name="street"
                  onChange={handleChange}
                  value={values.street}
                  maxLength="100"
                />
                <ErrorMessage name="street">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={3}>
                <Input
                  label="Nr domu"
                  variant="filled"
                  type="text"
                  name="houseNumber"
                  onChange={handleChange}
                  value={values.houseNumber}
                  maxLength="16"
                />
                <ErrorMessage name="houseNumber">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={3}>
                <Input
                  label="Nr lokalu"
                  variant="filled"
                  type="text"
                  name="apartmentNumber"
                  onChange={handleChange}
                  value={values.apartmentNumber}
                  maxLength="6"
                />
                <ErrorMessage name="apartmentNumber">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={3}>
                <Input
                  label="Kod"
                  variant="filled"
                  type="text"
                  name="zipCode"
                  onChange={handleChange}
                  value={values.zipCode}
                  maxLength="6"
                />
                <ErrorMessage name="zipCode">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Input
                  label="Miejscowość"
                  variant="filled"
                  type="text"
                  name="city"
                  onChange={handleChange}
                  value={values.city}
                  maxLength="100"
                />
                <ErrorMessage name="city">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Dzielnica"
                  text="district"
                  selectValue={values.district}
                  name="district"
                  data={districts}
                  variant="filled"
                  onChange={setFieldValue}
                />
                <ErrorMessage name="district">
                  {(msg) => <div className="error-txt">{msg}</div>}
                </ErrorMessage>
              </Grid>
              <Grid item xs={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
                <StyledButton variant="outlined" onClick={() => setOpenDialog()}>
                  Anuluj
                </StyledButton>
                <StyledButton
                  variant="outlined"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Zapisz
                </StyledButton>
              </Grid>
            </StyledWrapper>
          )}
        </Formik>
        <DialogActions />
      </Dialog>
    </>
  );
};

BeneficiaryContactDetailsForm.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  program_organization: PropTypes.shape({
    contactDetailsId: PropTypes.number,
    connectionWithProgram: PropTypes.number,
    connectionWithOrganization: PropTypes.number,
    programId: PropTypes.number,
    organizationId: PropTypes.number,
    houseNumber: PropTypes.string,
    zipCode: PropTypes.string,
    district: PropTypes.string,
    city: PropTypes.string,
    apartmentNumber: PropTypes.string,
    name: PropTypes.string,
    data: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        street: PropTypes.string,
        houseNumber: PropTypes.string,
        apartmentNumber: PropTypes.string,
        city: PropTypes.string,
        zipCode: PropTypes.string,
        district: PropTypes.string,
        role: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
      }),
      PropTypes.arrayOf(PropTypes.any),
    ]).isRequired,
  }),
  saveBeneficiaryContactDetails: PropTypes.func,
};

BeneficiaryContactDetailsForm.defaultProps = {
  openDialog: false,
  setOpenDialog: null,
  program_organization: null,
  saveBeneficiaryContactDetails: null,
};

const mapDispatchToProps = (dispatch) => ({
  saveBeneficiaryContactDetails: (id, data) =>
    dispatch(saveBeneficiaryContactDetailsService(id, data)),
});

export default connect(null, mapDispatchToProps)(BeneficiaryContactDetailsForm);
