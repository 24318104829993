// Elements

import { Alert } from '@mui/material';

// Styles
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  && {
    .MuiAlert-icon .MuiSvgIcon-root {
      color: white;
    }
    .MuiAlert-message {
      font-size: ${({ theme }) => theme.fontSize.caption};
      display: flex;
      align-items: center;
      a {
        color: ${({ theme }) => theme.color.white};
        text-decoration: underline;
        font-weight: ${({ theme }) => theme.fontWeight.bold};
      }
    }
  }
`;
