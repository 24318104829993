import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
import {
  showOrganizationsAction,
  fetchOrganizationAction,
  fetchRepresentativeListAction,
  fetchOrganizationBeneficiariesAction,
  fetchOrganizationProgramsAction,
  fetchCoordinatorsListAction,
} from '@actions/organizationActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const fetchOrganizationList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/organizacje/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showOrganizationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchOrganizationFullList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/organizacje/pelna_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showOrganizationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchOrganizationListDataAdministration = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/organizacje/nowa_lista_administratorzy_danych')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showOrganizationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchAdministratedOrganizationList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/organizacje/administrowane_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showOrganizationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchOrganization = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/organizacja/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchOrganizationAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
          code: err.response.data.code ? err.response.data.code : 0,
        }),
      );
    });
};

export const fetchOrganizationBeneficiaries = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/organizacja/${id}/beneficjenci`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchOrganizationBeneficiariesAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, err.response.data.status),
          isActive: true,
          code: err.response.data.code ? err.response.data.code : 0,
        }),
      );
    });
};

export const fetchOrganizationPrograms = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/organizacja/${id}/programy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchOrganizationProgramsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
          code: err.response.data.code ? err.response.data.code : 0,
        }),
      );
    });
};
export const fetchOrganizationProgramsSimple = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/organizacja/${id}/programy_prosta_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchOrganizationProgramsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
          code: err.response.data.code ? err.response.data.code : 0,
        }),
      );
    });
};

export const fetchRepresentativesList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/organizacja/${id}/uzytkownicy_organizacji`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchRepresentativeListAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchOrganizationsForProgram = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/organizacje/lista/podstawowa')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showOrganizationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchCoordinatorsList = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/organizacja/${id}/koordynatorzy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchCoordinatorsListAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const editOrganization = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/organizacje/${id}/edytuj_organizacje`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować organizacji'),
          isActive: true,
        }),
      );
    });
};

export const detachOrganization = (id, parent_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/organizacje/${id}/odepnij_z_koalicji`)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchOrganization(parent_id));
      return result;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się odpiąć organizacji'),
          isActive: true,
        }),
      );
    });
};

// export const addOrganization = (id, data) => (dispatch) => {
//   dispatch(fetchRequest());
//   return axiosInstance
//     .post(`/organizacje/${id}/edytuj_organizacje`, data)
//     .then(() => {
//       dispatch(fetchSuccess());
//       // dispatch(fetchProgramAction(res.data));
//     })
//     .catch((err) => {
//       dispatch(
//         fetchFailure({
//           type: 'error',
//           message: checkStatusCode(err, 'Nie udało się dodać organizacji'),
//           isActive: true,
//         }),
//       );
//     });
// };

export const addOrganizationChild = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/organizacje/organizacja/dodaj`, data)
    .then(() => {
      dispatch(fetchSuccess());
      // dispatch(fetchProgramAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się dodać organizacji'),
          isActive: true,
        }),
      );
    });
};

export const inviteUser = (id, data) => (dispatch) => {
  return axiosInstance
    .post(`/organizacje/${id}/zapros_uzytkownika`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się dodać organizacji'),
          isActive: true,
        }),
      );
    });
};
