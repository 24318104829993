import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure, redirect } from '@actions/appActions';
import {
  showBeneficiaries,
  fetchBeneficiaryAction,
  fetchBeneficiaryAdded,
  fetchBeneficiaryIndicatorsAction,
  fetchBeneficiaryBindingsAction,
  fetchBeneficiaryRodoAction,
  fetchBeneficiaryProgramsAction,
  fetchBeneficiaryProgramsSimpleAction,
  fetchBeneficiaryOrganizationsAction,
  fetchBeneficiarySupportAction,
  fetchBeneficiaryDiagnosisAction,
  fetchBeneficiaryNotesAction,
  fetchBeneficiaryDocumentsAction,
  fetchBeneficiaryIPPAction,
  fetchCWPInfoAction,
  fetchSingleDiagnoseAction,
  fetchSingleSurveyAction,
  fetchCWPListAction,
  fetchSingleIPPAction,
} from '@actions/beneficiaryActions';

import { fetchProgramBeneficiaries } from '@services/programServices';

import { fetchActionMembers } from '@services/actionServices';

import { checkStatusCode } from '@utils/checkStatusCode';

export const getCWAbyCWP = (cwp_id) => {
  return axiosInstance
    .get(`/beneficjenci/cwa_by_cwp/${cwp_id}`)
    .then((res) => {
      if (res.data) {
        return res.data;
      }

      return [];
    })
    .catch((err) => {
      fetchFailure({
        type: 'error',
        message: checkStatusCode(err),
        isActive: true,
      });
      return [];
    });
};

export const fetchBeneficiaryList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/beneficjenci/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showBeneficiaries(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryListByProgram = (program_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/program/${program_id}/nowa_lista`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showBeneficiaries(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiary = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryAction(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const anonymizeCWO = (cwo_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/cwo/${cwo_id}/anonymize`)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiary(res.data.beneficiary_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};
export const anonymizeCWP = (cwp_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/cwp/${cwp_id}/anonymize`)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiary(res.data.beneficiary_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryBindings = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/powiazania`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryBindingsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryIndicators = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/wskazniki`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryIndicatorsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const addBeneficiaryIndicatorPeriod = (beneficjentId, indicatorId, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${beneficjentId}/wskaznik/${indicatorId}/dodaj_okres`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryIndicators(beneficjentId));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać wskaźnika beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const removeBeneficiaryIndicatorPeriod =
  (beneficjentId, indicatorId, data) => (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${beneficjentId}/wskaznik/${indicatorId}/usun_okres`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchBeneficiaryIndicators(beneficjentId));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się zapisać wskaźnika beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const setBeneficiaryIndicatorPeriodBase =
  (beneficjentId, indicatorId, data) => (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${beneficjentId}/wskaznik/${indicatorId}/ustaw_pomiar_bazowy`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchBeneficiaryIndicators(beneficjentId));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się zapisać wskaźnika beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const setBeneficiarySSPeriodBase =
  (beneficjentId, ssperiodical_measurements_id, data) => (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(
        `/beneficjenci/${beneficjentId}/przedmioty_wspierane/${ssperiodical_measurements_id}/ustaw_pomiar_bazowy`,
        data,
      )
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchBeneficiaryIndicators(beneficjentId));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się zapisać wskaźnika beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const saveBeneficiaryIndicatorValue = (beneficjentId, indicatorId, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(
      `/beneficjenci/${beneficjentId}/wskazniki_beneficjenta/${indicatorId}/edytuj_rezultat`,
      data,
    )
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryIndicators(beneficjentId));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać wskaźnika beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveBeneficiarySubjectValue = (beneficjentId, subjectId, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${beneficjentId}/przedmioty_wspierane/${subjectId}/edytuj_rezultat`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryIndicators(beneficjentId));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać oceny beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryRodo = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/rodo`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryRodoAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryPrograms = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/programy_i_dzialania`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryProgramsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryProgramsSimple = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/programy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryProgramsSimpleAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryOrganizations = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/organizacje`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryOrganizationsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiarySupportedSubjects = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/przedmioty_wspierane`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiarySupportAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const removeBeneficiarySupportedSubjectPeriod =
  (beneficiaryId, periodId, data) => (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${beneficiaryId}/przedmioty_wspierane/${periodId}/usun_okres`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchBeneficiarySupportedSubjects(beneficiaryId));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(
              err,
              'Nie udało się usunąć okresu pomiaru przedmiotu wspieranego',
            ),
            isActive: true,
          }),
        );
      });
  };

export const removeBeneficiarySupportedSubject = (beneficiaryId, subjectId, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${beneficiaryId}/przedmioty_wspierane/${subjectId}/usun_przedmiot`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiarySupportedSubjects(beneficiaryId));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się usunąć przedmiotu wspieranego'),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryDiagnosis = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/diagnozy`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryDiagnosisAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryIPP = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/indywidualny_plan`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryIPPAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryDocuments = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/dokumenty`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryDocumentsAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchBeneficiaryNotes = (id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/beneficjenci/${id}/notatki`)
    .then(({ data }) => {
      dispatch(fetchSuccess());
      dispatch(fetchBeneficiaryNotesAction(data.data.notes));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchCWPInfo = (id) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get(`/cwp_info/${id}`)
    .then(({ data }) => {
      dispatch(fetchSuccess());
      dispatch(fetchCWPInfoAction(data.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const checkNewBeneficiary = (data) => (dispatch) => {
  dispatch(fetchRequest());

  const url = `/beneficjenci/beneficjent/check_new`;

  return axiosInstance
    .post(url, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      return result.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się sprawdzić beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const removeCWP = (beneficiary_id, id) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/beneficjenci/${beneficiary_id}/usun_cwp/${id}`;
  return axiosInstance
    .post(url)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryPrograms(beneficiary_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać danych beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const endBeneficiaryParticipationInProgram =
  (beneficiary_id, cwa_id, data) => (dispatch) => {
    dispatch(fetchRequest());
    const url = `/beneficjenci/${beneficiary_id}/zakoncz_cwp/${cwa_id}`;
    return axiosInstance
      .post(url, data)
      .then((result) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: result.data.status,
            isActive: true,
          }),
        );
        dispatch(fetchBeneficiaryPrograms(beneficiary_id));
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się zapisać danych beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const saveBeneficiaryPWD = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/beneficjenci/${id}/edytuj_pwd_beneficjenta`;
  return axiosInstance
    .post(url, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiary(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać danych beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveBeneficiaryData = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/beneficjenci/${id}/edytuj_beneficjenta`;
  return axiosInstance
    .post(url, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiary(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać danych beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveNewBeneficiary = (data, connections) => (dispatch) => {
  dispatch(fetchRequest());
  const url = `/beneficjenci/beneficjent/dodaj_beneficjenta`;
  return axiosInstance
    .post(url, { beneficiary: data, connections })
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryAdded(res.data));
      return res.data;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać nowego beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const updateBeneficiaryProgramStartDate = (cwp_id, data, program_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${cwp_id}/edytuj_date_beneficjenta_w_programie`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchProgramBeneficiaries(program_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const updateBeneficiaryActionStartDate = (cwa_id, data, action_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${cwa_id}/edytuj_date_beneficjenta_w_dzialaniu`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchActionMembers(action_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveBeneficiaryEducationalFacility = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/edytuj_szkole_beneficjenta`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryBindings(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveBeneficiaryGuardianDetails = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/edytuj_opiekuna_beneficjenta`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryBindings(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const removeBeneficiaryGuardian = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/usun_opiekuna_beneficjenta`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryBindings(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const saveBeneficiaryContactDetails = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/edytuj_kontakt_beneficjenta`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryBindings(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się edytować beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const addBeneficiarySupportedSubjects = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/dodaj_przedmiot_beneficjenta`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiarySupportedSubjects(id));

      return res.data.id;
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać przedmiotu beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const addBeneficiarySupportedSubjectsPeriod = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/dodaj_okres_pomiaru_przedmiotu`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiarySupportedSubjects(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać przedmiotu beneficjenta'),
          isActive: true,
        }),
      );
    });
};

export const addBeneficiaryPrograms = (id, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/dodaj_programy_beneficjenta`, data)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(
        redirect({
          url: `/beneficjenci/${res.data.new_beneficiary_id}`,
          snack: null,
        }),
      );
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać'),
          isActive: true,
        }),
      );
    });
};

export const saveConnectionWithProgram = (id, connectionId, data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/beneficjenci/${id}/connection/${connectionId}/edytuj_zgody`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchBeneficiaryPrograms(id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zapisać danych'),
          isActive: true,
        }),
      );
    });
};

export const saveBeneficiaryNote =
  (id, data, edit = false, fetch = true) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${id}/${edit || data.id ? 'edytuj' : 'dodaj'}_notatke`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );
        if (fetch) {
          dispatch(fetchBeneficiaryNotes(id));
        }
        return res.data;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać notatki do beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const saveBeneficiaryIPP =
  (id, data, edit = false, redirectView = true) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${id}/${edit || data.id ? 'edytuj' : 'dodaj'}_plan`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );

        if (redirectView) {
          dispatch(fetchBeneficiaryIPP(id));
        }

        return res.data.id;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać planu do beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const saveBeneficiaryDiagnose =
  (id, data, edit = false, redirectView = true) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${id}/${edit || data.id ? 'edytuj' : 'dodaj'}_diagnoze`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );

        if (redirectView) {
          dispatch(fetchBeneficiaryDiagnosis(id));
        }

        return res.data.id;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać diagnozy do beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const saveBeneficiarySurvey =
  (id, data, edit = false, redirectView = true) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${id}/${edit || data.id ? 'edytuj' : 'dodaj'}_ankiete`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );

        if (redirectView) {
          dispatch(fetchBeneficiaryDiagnosis(id));
        }

        return res.data;
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać ankiety do beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const saveBeneficiaryDocument =
  (id, data, fetch = true) =>
  (dispatch) => {
    dispatch(fetchRequest());
    return axiosInstance
      .post(`/beneficjenci/${id}/dodaj_dokument`, data)
      .then((res) => {
        dispatch(
          fetchSuccess({
            type: 'success',
            message: res.data.status,
            isActive: true,
          }),
        );

        if (fetch) {
          dispatch(fetchBeneficiaryDocuments(id));
        }
      })
      .catch((err) => {
        dispatch(
          fetchFailure({
            type: 'error',
            message: checkStatusCode(err, 'Nie udało się dodać dokumentu do beneficjenta'),
            isActive: true,
          }),
        );
      });
  };

export const fetchSingleDiagnose = (id) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get(`/diagnoza/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchSingleDiagnoseAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchSingleSurvey = (id) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get(`/ankieta/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchSingleSurveyAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchCWPList = () => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get('/cwp/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchCWPListAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const fetchSingleIPP = (id) => (dispatch) => {
  dispatch(fetchRequest());

  return axiosInstance
    .get(`/indywidualny_plan/${id}`)
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(fetchSingleIPPAction(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const removeBeneficiaryNote = (id, beneficiary_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/usun_notatke/${id}`)
    .then((res) => {
      dispatch(fetchSuccess({ type: 'success', message: res.data.status, isActive: true }));
      dispatch(fetchBeneficiaryNotes(beneficiary_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const removeBeneficiaryDocument = (id, beneficiary_id) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get(`/usun_dokument/${id}`)
    .then((res) => {
      dispatch(fetchSuccess({ type: 'success', message: res.data.status, isActive: true }));
      dispatch(fetchBeneficiaryDocuments(beneficiary_id));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};
