// React
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';

// Formik
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// Services
import {
  inviteUser as inviteUserService,
  fetchRepresentativesList as fetchRepresentativesListService,
} from '@services/organizationServices';

// Actions
import { clearRepresentativeList as clearRepresentativeListAction } from '@actions/organizationActions';

// Data
import { filterOrganizationUsers } from '@constants/tableFilters';
import { organizationRoles } from '@constants/selectLists';
import { ORGANIZATION_ADMINISTRATOR, COORDINATOR, ORGANIZATION_EMPLOYEE } from '@constants/roles';

// Assets
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Elements
import { Grid } from '@mui/material';
import TableTemplate from '@templates/TableTemplate';
import Button from '@components/atoms/Button/Button';
import Input from '@components/atoms/Input/Input';
import Select from '@components/atoms/Select/Select';
import GuardedComponent from '@components/molecules/GuardedComponent/GuardedComponent';

// Styles
import { StyledWrapper } from './OrganizationUsers.styles';

// Data
const headCells = [
  { type: 'full_name', label: 'Imię i Nazwisko' },
  { type: 'gender', label: 'Płeć' },
  { type: 'phone', label: 'Telefon' },
  { type: 'email', label: 'Email' },
  { type: 'user_role', label: 'Rola w organizacji' },
];

// Component
const OrganizationUsers = ({
  clearRepresentativeList,
  fetchRepresentativesList,
  users,
  inviteUser,
  details,
}) => {
  const [showInvite, setShowInvite] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    clearRepresentativeList();
  }, []);

  useEffect(() => {
    fetchRepresentativesList(id);
  }, [id]);

  return (
    <>
      <TableTemplate
        headCells={headCells}
        data={users !== null ? users : []}
        isnav
        filters={filterOrganizationUsers}
        link="/uzytkownicy"
        tableName="Użytkownicy"
      />
      <GuardedComponent
        allowed_user_roles={[COORDINATOR]}
        allowed_organization_roles={[ORGANIZATION_ADMINISTRATOR, ORGANIZATION_EMPLOYEE]}
        organization_id={parseInt(id, 10)}
        parent_organization_id={details.parent ? details.parent.id : null}
      >
        <Button
          size="small"
          startIcon={<AddCircleIcon />}
          onClick={() => setShowInvite(!showInvite)}
          style={{ fontSize: '1.5rem' }}
        >
          Zaproś Użytkownika
        </Button>
        {showInvite && (
          <Formik
            initialValues={{ email: '' }}
            enableReinitialize
            validationSchema={Yup.object({
              email: Yup.string().email('Nieprawidłowy Email').required('pole wymagane!'),
            })}
            onSubmit={(values) => {
              inviteUser(id, values);
            }}
          >
            {({ values, handleChange, setFieldValue, handleSubmit }) => {
              return (
                <StyledWrapper container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      label="Adres e-mail użytkownika"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      variant="filled"
                      type="text"
                    />
                    <ErrorMessage name="email">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <Select
                      name="role"
                      label="Rola organizacji"
                      text="role"
                      selectValue={values.role}
                      data={organizationRoles}
                      variant="filled"
                      onChange={setFieldValue}
                    />
                    <ErrorMessage name="role">
                      {(msg) => <div className="error-txt">{msg}</div>}
                    </ErrorMessage>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Wyślij zaproszenie
                    </Button>
                  </Grid>
                </StyledWrapper>
              );
            }}
          </Formik>
        )}
      </GuardedComponent>
    </>
  );
};

OrganizationUsers.propTypes = {
  clearRepresentativeList: PropTypes.func,
  fetchRepresentativesList: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.any),
  inviteUser: PropTypes.func,
  details: PropTypes.shape({
    parent: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
};

OrganizationUsers.defaultProps = {
  clearRepresentativeList: null,
  fetchRepresentativesList: null,
  users: [],
  inviteUser: null,
  details: null,
};

const mapStateToProps = ({ organizationReducer }) => ({
  users: organizationReducer.representativesList,
  details: organizationReducer.organization,
});

const mapDispatchToProps = (dispatch) => ({
  clearRepresentativeList: () => dispatch(clearRepresentativeListAction()),
  fetchRepresentativesList: (id) => dispatch(fetchRepresentativesListService(id)),
  inviteUser: (id, values) => dispatch(inviteUserService(id, values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUsers);
