// React
import React from 'react';
import PropTypes from 'prop-types';

// Moment
import moment from 'moment';

// PDF
import { Page, Text, View, Document, Font } from '@react-pdf/renderer';

// Fonts
import robotoRegularSRC from '@font/Roboto-Regular.ttf';
import robotoBoldSRC from '@font/Roboto-Bold.ttf';

// Styles
import { styles } from '@utils/pdfStyles';

Font.register({
  family: 'Roboto',
  fonts: [{ src: robotoRegularSRC }, { src: robotoBoldSRC, fontWeight: 700 }],
});

const BeneficiaryIPPPDF = ({ data }) => {
  return data && Object.keys(data).length > 0 ? (
    <Document language="pl">
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.title}>Informacje podstawowe</Text>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Tytuł planu: </Text>
            <Text>{data.title}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Imię i nazwisko beneficjenta: </Text>
            <Text>{data.beneficiary}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Program: </Text>
            <Text>{data.program}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Data przeprowadzenia: </Text>
            <Text>{moment(data.execution_date).format('DD-MM-YYYY')}</Text>
          </View>
          <View style={styles.textFlex}>
            <Text style={styles.textBold}>Okres obowiązywania: </Text>
            <Text>
              {moment((data.range_date && data.range_date.startDate) || data.date_start).format(
                'DD-MM-YYYY',
              )}{' '}
              -{' '}
              {moment((data.range_date && data.range_date.endDate) || data.date_end).format(
                'DD-MM-YYYY',
              )}
            </Text>
          </View>
          <Text style={styles.title}>Cel i działania</Text>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Cel planu:</Text>
            <Text>{data.rows.plan[0].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Planowane działania z dzieckiem i rodziną:</Text>
            <Text>{data.rows.plan[1].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Przebieg i realizacja planu:</Text>
            <Text>{data.rows.plan[2].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Odstępstwa od planu / korekta planu:</Text>
            <Text>{data.rows.plan[3].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Ocena koncepcji:</Text>
            <Text>{data.rows.plan[4].value}</Text>
          </View>
          <Text style={styles.title}>Podsumowanie realizacji planu</Text>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Ocena realizacji:</Text>
            <Text>{data.rows.summary[0].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Ocena współpracy z dzieckiem i z jego rodziną:</Text>
            <Text>{data.rows.summary[1].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>
              Własne trudności w pracy z dzieckiem i z jego rodziną:
            </Text>
            <Text>{data.rows.summary[2].value}</Text>
          </View>
          <View style={styles.text}>
            <Text style={styles.textBoldMB}>Efekty i wnioski z realizacji planu:</Text>
            <Text>{data.rows.summary[3].value}</Text>
          </View>
        </View>
      </Page>
    </Document>
  ) : (
    <Document>
      <Page size="A4" style={styles.page} />
    </Document>
  );
};

BeneficiaryIPPPDF.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
};

BeneficiaryIPPPDF.defaultProps = {
  data: {},
};

export default BeneficiaryIPPPDF;
