// Assets
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';

// Elements
import { Menu, MenuItem, Badge } from '@mui/material';
import Button from '@components/atoms/Button/Button';

// Styles
import styled, { css } from 'styled-components';

const iconStyles = css`
  && {
    width: 2.5rem;
    height: 2.5rem;
    fill: ${({ theme }) => theme.color.darkGray};
  }
`;

export const StyledMenu = styled(Menu)`
  && {
    & .MuiPaper-root {
      background-color: ${({ theme }) => theme.color.white};
      z-index: 9999999;
    }
  }
`;

export const StyledTooltip = styled.div`
  && {
    padding: 1.5rem 1rem;
    line-height: 1.1;

    > * {
      margin-bottom: 1rem;
    }

    p {
      &:first-child {
        margin-bottom: 1.5rem;
      }

      &:last-child {
        margin-top: 1rem;
      }
    }
  }
`;

export const StyledBadge = styled(Badge)`
  && {
    span {
      font-size: 1.3rem;
      background-color: ${({ theme }) => theme.color.red};
    }
  }
`;

export const StyledNotificationsRoundedIcon = styled(NotificationsRoundedIcon)`
  && {
    ${iconStyles};
  }
`;

export const StyledNotification = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
  }
`;

export const StyledButton = styled(Button)`
  && {
    font-size: 1.4rem;
    text-transform: lowercase;
    color: ${({ theme }) => theme.color.darkGray};
    font-family: ${({ theme }) => theme.fontFamily.base};
    border: 2px solid white;
    border-radius: 10px;
    margin-left: 2rem;
  }
`;
