import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
} from '@mui/material';

import Select from '@components/atoms/Select/Select';

import { connect } from 'react-redux';
import Heading from '@components/atoms/Heading/Heading';
import TableTemplate from '@templates/TableTemplate';
import FloatingButton from '@components/atoms/FloatingButton/FloatingButton';
import {
  fetchPeriods as fetchPeriodsService,
  savePeriod as savePeriodService,
  deletePeriod as deletePeriodService,
} from '@services/selectServices';

import { indicatorTimePeriodInts } from '@constants/selectLists';

import { StyledTitle, StyledWrapper } from './PeriodSettings.styles';
import './PeriodSettings.scss';

const headCellsPeriods = [
  {
    type: 'measurement_period_name',
    label: 'Nazwa',
  },
  { type: 'order', label: 'Kolejność' },
  { type: 'time_period_label', label: 'Typ Okresu' },
];

const filterPeriods = [{ key: 'time_period_label', label: 'Typ okresu' }];

const PeriodSettings = ({ fetchPeriods, periods, savePeriod, deletePeriod }) => {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const triggerFetchPeriods = useCallback(() => fetchPeriods(), [fetchPeriods]);

  useEffect(() => {
    triggerFetchPeriods();
  }, [triggerFetchPeriods]);

  const handleDeleteClick = (period) => {
    setSelectedPeriod(period);
    setDeleteDialogOpen(true);
  };

  const handleEditClick = (period) => {
    setSelectedPeriod(period);
    setEditDialogOpen(true);
  };

  const confirmDelete = () => {
    setDeleteDialogOpen(false);
    deletePeriod(selectedPeriod.measurement_period_id);
  };

  const handleEditSave = () => {
    setEditDialogOpen(false);
    savePeriod(selectedPeriod.measurement_period_id, selectedPeriod);
  };

  return (
    <StyledWrapper className="periods" container spacing={2}>
      <Grid item xs={10}>
        <StyledTitle>
          <Heading.Subtitle1>Okresy pomiarów</Heading.Subtitle1>
        </StyledTitle>
        <TableTemplate
          headCells={headCellsPeriods}
          data={periods.map((period) => ({
            ...period,
            time_period_label: indicatorTimePeriodInts.find(
              (timePeriod) => timePeriod.value === period.time_period,
            )?.name,
          }))}
          filters={filterPeriods}
          rowActions={[
            {
              name: 'edytuj',
              action: handleEditClick,
            },
            {
              name: 'usuń',
              action: handleDeleteClick,
            },
          ]}
        />
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Potwierdzenie usunięcia</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Czy na pewno chcesz usunąć okres pomiarów `{selectedPeriod?.measurement_period_name}`?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Usuń
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit/Add Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
        <DialogTitle>
          {selectedPeriod?.measurement_period_id === 'new'
            ? 'Dodaj Okres Pomiarów'
            : 'Edytuj Okres Pomiarów'}
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Nazwa Okresu Pomiarów"
            fullWidth
            margin="normal"
            value={selectedPeriod?.measurement_period_name || ''}
            onChange={(e) =>
              setSelectedPeriod({ ...selectedPeriod, measurement_period_name: e.target.value })
            }
          />
          <Select
            label="Przedział czasowy"
            data={indicatorTimePeriodInts}
            selectValue={selectedPeriod?.time_period || ''}
            text="timePeriod"
            onChange={(_e, value) => setSelectedPeriod({ ...selectedPeriod, time_period: value })}
            variant="filled"
          />
          <TextField
            label="Kolejność"
            fullWidth
            margin="normal"
            type="number"
            value={selectedPeriod?.order || ''}
            onChange={(e) => setSelectedPeriod({ ...selectedPeriod, order: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Zapisz
          </Button>
        </DialogActions>
      </Dialog>

      {/* Floating Button for Adding New Period */}
      <FloatingButton
        onClick={() => {
          setSelectedPeriod({ measurement_period_id: 'new' });
          setEditDialogOpen(true);
        }}
      />
    </StyledWrapper>
  );
};

PeriodSettings.propTypes = {
  fetchPeriods: PropTypes.func,
  savePeriod: PropTypes.func,
  deletePeriod: PropTypes.func,
  periods: PropTypes.arrayOf(
    PropTypes.shape({
      measurement_period_id: PropTypes.number,
      measurement_period_name: PropTypes.string,
      time_period: PropTypes.number,
      order: PropTypes.number,
    }),
  ),
};

PeriodSettings.defaultProps = {
  fetchPeriods: null,
  savePeriod: null,
  deletePeriod: null,
  periods: [],
};

const mapStateToProps = ({ selectReducer }) => ({
  periods: selectReducer.periods,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPeriods: () => dispatch(fetchPeriodsService()),
  savePeriod: (id, data) => dispatch(savePeriodService(id, data)),
  deletePeriod: (id) => dispatch(deletePeriodService(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeriodSettings);
