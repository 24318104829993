// React
import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { fetchMe as fetchMeService } from '@services/userServices';

// Context
import { Context } from '@context';
import { DashboardContext } from '@contexts/DashboardContext';

// Routes
import routes from '@routes/routes';

// Elements
import { List } from '@mui/material';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

// Styles
import {
  StyledDrawer,
  StyledListItemText,
  StyledListItemIcon,
  StyledListItem,
  StyledImage,
} from './Sidebar.styles';

// Component
const Sidebar = ({ me, fetchMe }) => {
  const { isSidebarHide } = useContext(DashboardContext);
  const { whichItem, setWhichItem } = useContext(Context);

  useEffect(() => {
    fetchMe();
  }, []);

  return (
    <StyledDrawer variant="permanent" hide={isSidebarHide ? 1 : 0}>
      <List>
        {routes
          .filter(
            ({ route, roles }) =>
              route === 'sidebar' &&
              (!roles || (roles && me.user_role && roles.some((r) => me.user_role.includes(r)))),
          )
          .map(({ sidebarName, path, icon }) => (
            <StyledListItem
              onClick={() => setWhichItem(sidebarName)}
              style={{
                fontWeight: whichItem === sidebarName ? '700' : '400',
              }}
              button
              exact
              key={sidebarName}
              component={NavLink}
              to={path}
              activeclass="active"
            >
              <StyledListItemIcon>{icon}</StyledListItemIcon>
              <StyledListItemText primary={sidebarName} />
            </StyledListItem>
          ))}
        <StyledListItem
          component={NavLink}
          to="/files/Podrecznik_użytkownika_BM2_2024-Final.pdf"
          onClick={() => window.open('/files/Podrecznik_użytkownika_BM2_2024-Final.pdf', '_blank')}
        >
          <StyledListItemIcon>
            <LibraryBooksIcon />
          </StyledListItemIcon>
          <StyledListItemText primary="Podręcznik użytkownika" />
        </StyledListItem>
      </List>

      <StyledImage hide={isSidebarHide} />
    </StyledDrawer>
  );
};

Sidebar.propTypes = {
  me: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    surname: PropTypes.string,
    programs: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    user_role: PropTypes.arrayOf(PropTypes.string),
  }),
  fetchMe: PropTypes.func,
};

Sidebar.defaultProps = {
  fetchMe: null,
  me: {
    id: null,
    name: '',
    surname: '',
    programs: [],
    user_role: [],
  },
};

const mapStateToProps = ({ userReducer }) => ({
  me: userReducer.me,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMe: () => dispatch(fetchMeService()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
