// Elements
import { Grid } from '@mui/material';
import Button from '@components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  && {
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '70%')};
    margin-top: ${({ margin }) => margin || '0rem'};

    &.MuiGrid-spacing-xs-2 {
      margin-top: ${({ margin }) => margin || '6rem'};
    }
  }
`;

export const StyledButton = styled(Button)`
  && {
    margin-left: ${({ margin }) => margin || '0rem'};
    padding: ${({ padding }) => padding || '1rem 2.5rem'};
  }
`;
