import {
  FETCH_BENEFICIARY,
  CLEAR_BENEFICIARY,
  FETCH_BENEFICIARY_ADDED,
  FETCH_BENEFICIARY_BINDINGS,
  CLEAR_BENEFICIARY_BINDINGS,
  FETCH_BENEFICIARY_DIAGNOSIS,
  CLEAR_BENEFICIARY_DIAGNOSIS,
  FETCH_BENEFICIARY_INDICATORS,
  CLEAR_BENEFICIARY_INDICATORS,
  FETCH_BENEFICIARY_RODO,
  CLEAR_BENEFICIARY_RODO,
  FETCH_BENEFICIARY_PROGRAMS,
  CLEAR_BENEFICIARY_PROGRAMS,
  FETCH_BENEFICIARY_PROGRAMS_SIMPLE,
  CLEAR_BENEFICIARY_PROGRAMS_SIMPLE,
  FETCH_BENEFICIARY_ORGANIZATIONS,
  CLEAR_BENEFICIARY_ORGANIZATIONS,
  FETCH_BENEFICIARY_SUPPORT,
  CLEAR_BENEFICIARY_SUPPORT,
  SHOW_BENEFICIARIES,
  CLEAR_BENEFICIARIES,
  FETCH_BENEFICIARY_IPP,
  CLEAR_BENEFICIARY_IPP,
  FETCH_BENEFICIARY_DOCUMENTS,
  CLEAR_BENEFICIARY_DOCUMENTS,
  FETCH_BENEFICIARY_NOTES,
  CLEAR_BENEFICIARY_NOTES,
  FETCH_NEW_BENEFICIARY_PROGRAMS,
  FETCH_CWP_INFO,
  CLEAR_CWP_INFO,
  FETCH_SINGLE_DIAGNOSE,
  CLEAR_SINGLE_DIAGNOSE,
  FETCH_SINGLE_SURVEY,
  CLEAR_SINGLE_SURVEY,
  FETCH_CWP_LIST,
  CLEAR_CWP_LIST,
  FETCH_SINGLE_IPP,
  REMOVE_SINGLE_NOTE,
  CLEAR_SINGLE_IPP,
} from '@constants/actionTypes';

export function showBeneficiaries(payload) {
  return { type: SHOW_BENEFICIARIES, payload };
}

export function clearBeneficiaries(payload) {
  return { type: CLEAR_BENEFICIARIES, payload };
}

export function fetchBeneficiaryAction(payload) {
  return { type: FETCH_BENEFICIARY, payload };
}

export function clearBeneficiaryAction(payload) {
  return { type: CLEAR_BENEFICIARY, payload };
}

export function fetchBeneficiaryIndicatorsAction(payload) {
  return { type: FETCH_BENEFICIARY_INDICATORS, payload };
}

export function clearBeneficiaryIndicators(payload) {
  return { type: CLEAR_BENEFICIARY_INDICATORS, payload };
}

export function fetchBeneficiaryBindingsAction(payload) {
  return { type: FETCH_BENEFICIARY_BINDINGS, payload };
}

export function clearBeneficiaryBindings(payload) {
  return { type: CLEAR_BENEFICIARY_BINDINGS, payload };
}

export function fetchBeneficiaryProgramsSimpleAction(payload) {
  return { type: FETCH_BENEFICIARY_PROGRAMS_SIMPLE, payload };
}

export function clearBeneficiaryProgramsSimple(payload) {
  return { type: CLEAR_BENEFICIARY_PROGRAMS_SIMPLE, payload };
}

export function fetchBeneficiaryOrganizationsAction(payload) {
  return { type: FETCH_BENEFICIARY_ORGANIZATIONS, payload };
}

export function clearBeneficiaryOrganizations(payload) {
  return { type: CLEAR_BENEFICIARY_ORGANIZATIONS, payload };
}

export function fetchBeneficiaryRodoAction(payload) {
  return { type: FETCH_BENEFICIARY_RODO, payload };
}
export function fetchBeneficiaryAdded(payload) {
  return { type: FETCH_BENEFICIARY_ADDED, payload };
}

export function clearBeneficiaryRodo(payload) {
  return { type: CLEAR_BENEFICIARY_RODO, payload };
}

export function fetchBeneficiaryProgramsAction(payload) {
  return { type: FETCH_BENEFICIARY_PROGRAMS, payload };
}
export function clearBeneficiaryPrograms(payload) {
  return { type: CLEAR_BENEFICIARY_PROGRAMS, payload };
}

export function fetchBeneficiarySupportAction(payload) {
  return { type: FETCH_BENEFICIARY_SUPPORT, payload };
}

export function clearBeneficiarySupport(payload) {
  return { type: CLEAR_BENEFICIARY_SUPPORT, payload };
}

export function fetchBeneficiaryDiagnosisAction(payload) {
  return { type: FETCH_BENEFICIARY_DIAGNOSIS, payload };
}

export function clearBeneficiaryDiagnosis(payload) {
  return { type: CLEAR_BENEFICIARY_DIAGNOSIS, payload };
}

export function fetchBeneficiaryIPPAction(payload) {
  return { type: FETCH_BENEFICIARY_IPP, payload };
}

export function clearBeneficiaryIPP(payload) {
  return { type: CLEAR_BENEFICIARY_IPP, payload };
}

export function fetchBeneficiaryDocumentsAction(payload) {
  return { type: FETCH_BENEFICIARY_DOCUMENTS, payload };
}

export function clearBeneficiaryDocuments(payload) {
  return { type: CLEAR_BENEFICIARY_DOCUMENTS, payload };
}

export function fetchBeneficiaryNotesAction(payload) {
  return { type: FETCH_BENEFICIARY_NOTES, payload };
}

export function clearBeneficiaryNotes(payload) {
  return { type: CLEAR_BENEFICIARY_NOTES, payload };
}

export function fetchNewBeneficiaryPrograms(payload) {
  return { type: FETCH_NEW_BENEFICIARY_PROGRAMS, payload };
}

export function fetchCWPInfoAction(payload) {
  return { type: FETCH_CWP_INFO, payload };
}

export function clearCWPInfo(payload) {
  return { type: CLEAR_CWP_INFO, payload };
}

export function fetchSingleDiagnoseAction(payload) {
  return { type: FETCH_SINGLE_DIAGNOSE, payload };
}

export function clearSingleDiagnose(payload) {
  return { type: CLEAR_SINGLE_DIAGNOSE, payload };
}

export function fetchSingleSurveyAction(payload) {
  return { type: FETCH_SINGLE_SURVEY, payload };
}

export function clearSingleSurvey(payload) {
  return { type: CLEAR_SINGLE_SURVEY, payload };
}

export function fetchCWPListAction(payload) {
  return { type: FETCH_CWP_LIST, payload };
}

export function clearCWPList(payload) {
  return { type: CLEAR_CWP_LIST, payload };
}

export function fetchSingleIPPAction(payload) {
  return { type: FETCH_SINGLE_IPP, payload };
}

export function removeBeneficiaryNoteAction(payload) {
  return { type: REMOVE_SINGLE_NOTE, payload };
}

export function clearSingleIPPAction() {
  return { type: CLEAR_SINGLE_IPP };
}
