import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';
import { showApplications } from '@actions/applicationActions';
import { checkStatusCode } from '@utils/checkStatusCode';

export const fetchApplicationList = () => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .get('/wnioski/nowa_lista')
    .then((res) => {
      dispatch(fetchSuccess());
      dispatch(showApplications(res.data));
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err),
          isActive: true,
        }),
      );
    });
};

export const changeApplicationStatus = (id, data) => (dispatch) => {
  return axiosInstance
    .post(`/wnioski/wniosek/${id}/edytuj_wniosek`, data)
    .then((result) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: result.data.status,
          isActive: true,
        }),
      );
      dispatch(fetchApplicationList());
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się zmienić statusu'),
          isActive: true,
        }),
      );
    });
};
