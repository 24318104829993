import {
  FETCH_MESSAGE,
  CLEAR_MESSAGE,
  FETCH_MESSAGE_SINGLE,
  CLEAR_MESSAGE_SINGLE,
  SEND_MESSAGE,
} from '@constants/actionTypes';

export function fetchMessageAction(payload) {
  return { type: FETCH_MESSAGE, payload };
}

export function clearMessage(payload) {
  return { type: CLEAR_MESSAGE, payload };
}

export function fetchMessageSingleAction(payload) {
  return { type: FETCH_MESSAGE_SINGLE, payload };
}

export function clearMessageSingle(payload) {
  return { type: CLEAR_MESSAGE_SINGLE, payload };
}

export function sendMessageAction(payload) {
  return { type: SEND_MESSAGE, payload };
}
