// Elements
import { Grid } from '@mui/material';
import Button from '@components/atoms/Button/Button';

// Styles
import styled from 'styled-components';

export const StyledBox = styled.div`
  && {
    max-width: 60%;
  }
`;

export const StyledWrapper = styled(Grid)`
  && {
    margin-top: 4rem;
    margin-bottom: 1rem;
  }
`;

export const StyledButton = styled(Button)`
  && {
    width: auto;
    margin: 1rem;
  }
`;

export const StyledTitle = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      margin-top: 3rem;
    }

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
`;
