import axiosInstance from '@utils/axios';
import { fetchRequest, fetchSuccess, fetchFailure } from '@actions/appActions';

import { checkStatusCode } from '@utils/checkStatusCode';

export const importLibrusOceny = (data) => (dispatch) => {
  dispatch(fetchRequest());
  // console.log(data);
  return axiosInstance
    .post(`/import/oceny`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się importować pliku Librus'),
          isActive: true,
        }),
      );
    });
};

export const importLibrusFrekwencja = (data) => (dispatch) => {
  dispatch(fetchRequest());
  return axiosInstance
    .post(`/import/frekwencja`, data)
    .then((res) => {
      dispatch(
        fetchSuccess({
          type: 'success',
          message: res.data.status,
          isActive: true,
        }),
      );
    })
    .catch((err) => {
      dispatch(
        fetchFailure({
          type: 'error',
          message: checkStatusCode(err, 'Nie udało się importować pliku Librus'),
          isActive: true,
        }),
      );
    });
};
